import * as angular from 'angular';

export enum FeedbackMode {
	REVIEW = 'review',
	VIEW = 'view'
}

export enum MediaDisplayMode {
	RECORD = 'record',
	PLAYBACK = 'playback',
	LIVE = 'live',
	CONVERSATION = 'conversation'
}

export interface FeedbackSessionOptions {
	commentCaptionsEnabled?;
	disableSessionRecording?;
	feedbackMode?;
	feedbackOnlyMode?;
	feedbackTools?;
	manualPostDiscard?;
	mediaDisplayMode?;
	printFeedbackUrl?;
	scale?;
	throttleWait?;
}

// states
const STATE = {
	RECORDED: 'recorded',
	UNRECORDED: 'unrecorded'
};

// list of media display modes
const mediaDisplayModes = [
	MediaDisplayMode.RECORD,
	MediaDisplayMode.PLAYBACK,
	MediaDisplayMode.LIVE,
	MediaDisplayMode.CONVERSATION
];

// default options
const options: FeedbackSessionOptions = {};

/**
 * Initialize default options
 */
function initDefaultOptions () {

	// default media display mode
	options.mediaDisplayMode = MediaDisplayMode.LIVE;

	// default feedback mode
	options.feedbackMode = FeedbackMode.REVIEW;

	// whether manual post discard is enabled
	options.manualPostDiscard = false;

	// whether feedback tools are enabled
	options.feedbackTools = true;

	const commentCaptionsEnabled = localStorage.getItem('commentCaptionsEnabled');
	// whether or not comment captions are enabled
	options.commentCaptionsEnabled = commentCaptionsEnabled !== null ? commentCaptionsEnabled === 'true' : true;

	// print feedback url
	options.printFeedbackUrl = '';

	// Whether feedback only mode is enabled
	// which just means either the feedback display
	// is showing or the media display
	options.feedbackOnlyMode = false;

	// Wait duration. When the throttle function is called,
	// it creates and returns a new, throttled version of the passed function, that, when invoked repeatedly,
	// will only actually call the original function at most once per every wait milliseconds
	options.throttleWait = 300;

	// Flag for disabling session recording
	options.disableSessionRecording = false;
}

/**
 * Feedback session config
 *
 * @constructor
 */
function FeedbackSession () {

	let recordingRenablementPromises = [];

	// expose states
	this.STATE = STATE;

	// expose media display modes
	this.MediaDisplayMode = MediaDisplayMode;

	// expose feedback modes
	this.FeedbackMode = FeedbackMode;

	// store last media display mode
	this.lastMediaDisplayMode = options.mediaDisplayMode;

	// expose options
	this.options = options;

	/**
	 * Get session recorder id
	 *
	 * @param session
	 * @returns {string}
	 */
	this.sessionRecorderId = function (session) {
		return 'sessionRecorder' + session.getId();
	};

	/**
	 * Get session player id
	 *
	 * @param session
	 * @returns {string}
	 */
	this.sessionPlayerId = function (session) {
		return 'session-player-' + session.getId();
	};

	/**
	 * Get source player id
	 *
	 * @param session
	 * @returns {string}
	 */
	this.sourcePlayerId = function (session) {
		return 'source-player-' + session.getId();
	};

	/**
	 * Get live timer id
	 *
	 * @param session
	 * @returns {string}
	 */
	this.liveTimerId = function (session) {
		return 'live-timer-' + session.getId();
	};

	/**
	 * Set media display mode
	 *
	 * @param mode
	 */
	this.setMediaDisplayMode = function (mode) {

		// ensure mode exists
		if (mediaDisplayModes.indexOf(mode) < 0) {
			mode = MediaDisplayMode.LIVE;
		}

		this.lastMediaDisplayMode = options.mediaDisplayMode;
		options.mediaDisplayMode = mode;
	};

	/**
	 * Whether media display mode is record mode
	 *
	 * @returns {boolean}
	 */
	this.isRecordMode = function () {
		return options.mediaDisplayMode === MediaDisplayMode.RECORD;
	};

	/**
	 * Whether media display mode is playback mode
	 *
	 * @returns {boolean}
	 */
	this.isPlaybackMode = function () {
		return options.mediaDisplayMode === MediaDisplayMode.PLAYBACK;
	};

	/**
	 * Whether media display mode is live playback mode
	 *
	 * @returns {boolean}
	 */
	this.isLiveMode = function () {
		return options.mediaDisplayMode === MediaDisplayMode.LIVE;
	};

	/**
	 * Whether media display mode is conversation playback mode
	 *
	 * @returns {boolean}
	 */
	this.isConversationMode = function () {
		return options.mediaDisplayMode === MediaDisplayMode.CONVERSATION;
	};

	/**
	 * Whether session is of recording mode
	 *
	 * @returns {boolean}
	 */
	this.isRecording = function () {
		return this.isRecordMode()
			|| this.isConversationMode()
			|| this.isLiveMode();
	};

	/**
	 * Set the current feedback mode
	 */
	this.setFeedbackMode = function (mode) {
		options.feedbackMode = mode;
	};

	/**
	 * Whether feedback mode is review mode
	 *
	 * @returns {boolean}
	 */
	this.isReviewMode = function () {
		return options.feedbackMode === FeedbackMode.REVIEW;
	};

	/**
	 * Set whether post / discard feature is enabled
	 *
	 * @param value
	 */
	this.setManualPostDiscard = function (value) {
		options.manualPostDiscard = !!value;
	};

	/**
	 * Flag session recording as disabled and postpone its
	 * re-enablement until the supplied promise is resolved.
	 *
	 * @param promise
	 */
	this.deferRecordingReEnablement = function (promise) {
		options.disableSessionRecording = true;
		recordingRenablementPromises.push(promise);
		promise.finally(function () {
			const index = recordingRenablementPromises.indexOf(promise);
			if (index !== -1) {
				recordingRenablementPromises.splice(index, 1);
			}
			if (!recordingRenablementPromises.length) {
				options.disableSessionRecording = false;
			}
		});
	};

	/**
	 * Toggle feedback only mode on / off
	 *
	 * @param value
	 */
	this.toggleFeedbackOnlyMode = function (value) {
		if (angular.isUndefined(value)) {
			value = !options.feedbackOnlyMode;
		}
		options.feedbackOnlyMode = value;
	};

	/**
	 * Toggle feedback tools on / off
	 *
	 * @param value
	 * @returns {*}
	 */
	this.toggleFeedbackTools = function (value) {
		if (angular.isUndefined(value)) {
			value = !options.feedbackTools;
		}
		options.feedbackTools = value;
		return value;
	};

	/**
	 * Toggle comment captions on / off
	 */
	this.toggleCommentCaptions = function () {
		options.commentCaptionsEnabled = !options.commentCaptionsEnabled;
	};

	/**
	 * Restore settings defaults
	 */
	this.restoreDefaults = function () {
		initDefaultOptions();

		// Do some cleanup.
		// Clear out all the promises associated with recording re-enablement.
		// See the `deferRecordingReEnablement` function for more information.
		recordingRenablementPromises = [];
	};
}

export class FeedbackSessionProvider {

	/** @ngInject */
	constructor () {
		// Set default options
		initDefaultOptions();
	}

	/** @ngInject */
	public $get = () => {
		return new FeedbackSession();
	};
}
