import { noop } from 'angular';
import type { CreateUpdateMediaParameters } from 'go-modules/models/media';
import { MediaSource } from '../../models/media';
import { UniversalMediaChooserController } from '../umc.controller';
import { UADetect as UADetectClass } from '../../detect/ua-detect.service';
import { ZoomService } from 'go-modules/services/zoom/zoom.service';
import { Recording } from 'go-modules/services/zoom/recording';
import { MessageModal } from 'go-modules/modals/message/modal.factory';
import { UmcInstancesService } from 'go-modules/services/umc-instances/umc-instances.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';

export interface Bindings {
	umcController: UniversalMediaChooserController;
	options: any;
	umcInstanceName: string;
}

export class UniversalMediaChooserZoomController implements Bindings {

	// Bindings
	public umcController: UniversalMediaChooserController;
	public options: any;
	public umcInstanceName: string;

	public TAB_CLOUD_RECORDINGS: string = 'cloud recordings';
	public TAB_COMPUTER_RECORDINGS: string = 'computer recordings';
	public isFetchingRecordings: boolean = true;
	public isReady: boolean = false;
	public zoomLoginUrl: string;
	public scrollTop: number = 0;
	public uploadOptions: any;
	public currentTab: string;
	public selectedDownloadUrl: string;
	private media: any;
	private umc: any;
	private recordings: Recording[];
	private nextPageToken: string;
	private allowedRecordingTypes: string[] = [
		'shared_screen_with_speaker_view(cc)',
		'shared_screen_with_speaker_view',
		'shared_screen_with_gallery_view',
		'speaker_view',
		'gallery_view',
		'shared_screen',
		'audio_only',
		'active_speaker'
	];
	private eventSubscription: any;
	private eventNames: string[];

	/* @ngInject */
	constructor (
		private $scope: ng.IScope,
		private MediaModel: any,
		private UniversalMediaChooser: any,
		private $window: ng.IWindowService,
		private $document: ng.IDocumentService,
		private UADetect: UADetectClass,
		private zoomService: ZoomService,
		private messageModal: MessageModal,
		private $q: ng.IQService,
		private umcInstancesService: UmcInstancesService,
		private eventService: EventService,
		private ngxDowngradeModalService: DowngradeModalService,
		private $translate
	) {
		this.eventNames = [
			EVENT_NAMES.MEDIA_SYNC,
			EVENT_NAMES.FINE_UPLOADER_UPLOAD,
			EVENT_NAMES.FINE_UPLOADER_SUCCESS,
			EVENT_NAMES.UMC_CLOSE
		];
		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => this.eventNames.includes(ev.name)))
			.subscribe((ev: GoEvent) => {
				switch (ev.name) {
					case EVENT_NAMES.MEDIA_SYNC:
						if (this.media instanceof this.MediaModel
							&& parseInt(this.media.media_id, 10) === parseInt(ev.data.media_id, 10)
						) {
							Object.assign(this.media, ev.data);
						}
						break;
					case EVENT_NAMES.FINE_UPLOADER_UPLOAD:
						const file = ev.data.file;
						const key = ev.data.key;
						this.media.filename_original = file.name;
						this.media.title = file.title || this.media.title; // Allow typed filename to override
						// Even though this component does not set the filename in the DB, it is still needed
						// to exist to be available in case this is a captions upload. So we will set it, but not
						// "save" it. It ultimately will match.
						this.media.$save().then(() => this.media.filename = key);
						this.umcInstancesService.registerFile(file);

						this.umcController.handleMediaDetermined(this.media);
						break;
					case EVENT_NAMES.FINE_UPLOADER_SUCCESS:
						this.umc.selectedMedia = this.$q.when(this.media);

						// automatically trigger a choose action
						if (this.options.chooseAutomatically) {
							this.eventService.broadcast(EVENT_NAMES.UMC_CHOOSE, this.media);
						}
						break;
					case EVENT_NAMES.UMC_CLOSE:
						this.eventService.broadcast(EVENT_NAMES.FINE_UPLOADER_CANCEL_ALL, this.media);
						break;
				}
			});
	}

	public $onInit () {
		if (!this.options || !this.umcInstanceName || !this.options.groupId) {
			throw new Error('Must provide all required params (groupId, umcInstanceName)');
		}

		this.umc = this.UniversalMediaChooser.get(this.umcInstanceName);
		this.uploadOptions = Object.assign({
			blacklist: 'fine-uploader/src/partials/zoom-whitelist.json',
			request: {
				params: {
					group_id: this.options.groupId,
					resource_id: this.options.resourceId,
					resource_type: this.options.resourceType,
					user_agent: this.$window.navigator.userAgent,
					timestamp: () => new Date().getTime()
				}
			},
			validation: {
				minSizeLimit: this.options.uploadMinSizeLimit,
				// Override the default upload size limit when the options specify one
				sizeLimit: this.options.uploadSizeLimit > 0 ? this.options.uploadSizeLimit : undefined
			},
			uploadMessageFn: this.options.uploadMessageFn,
			shouldDisplayZoomHelpText: true
		}, this.options.fineUploader);

		if (!this.uploadOptions.validation.sizeLimit) {
			delete this.uploadOptions.validation.sizeLimit;
		}

		// Listen page focus changes
		this.$document.on('visibilitychange', this.handleVisibilityChange);

		if (!this.zoomService.isAuthenticated()) {
			this.zoomService.getLoginUrl()
				.then((response) => this.zoomLoginUrl = response.data.login_url);
		}

		this.initAsync();
	}

	public $onDestroy (): void {
		this.eventSubscription?.unsubscribe();
		this.zoomService.clearRecordings();
		this.$document.off('visibilitychange', this.handleVisibilityChange);
	}

	public setCurrentTab (tab) {
		switch (tab) {
			case this.TAB_COMPUTER_RECORDINGS:
				if (this.currentTab === this.TAB_COMPUTER_RECORDINGS) return;
				this.umc.selectedMedia = null;
				this.selectedDownloadUrl = null;
				this.umc.selectedZoomTab = tab;
				this.updateMediaSource(MediaSource.ZOOM_UPLOAD);
				this.currentTab = tab;
				break;
			case this.TAB_CLOUD_RECORDINGS:
				if (this.currentTab === this.TAB_CLOUD_RECORDINGS) return;
				this.umcInstancesService.canChangeTab()
					.then(() => {
						this.umc.selectedMedia = null;
						this.selectedDownloadUrl = null;
						this.umc.selectedZoomTab = tab;
						this.updateMediaSource(MediaSource.ZOOM_IMPORT);
						this.getRecordings();
						this.currentTab = tab;
					}).catch(noop);
				break;
		}
	}

	public isMobile () {
		return this.UADetect.isMobile();
	}

	public showLoadingSpinner (): boolean {
		return this.isFetchingRecordings && this.recordings.length === 0;
	}

	public disconnect (): void {
		this.ngxDowngradeModalService.openConfirmDialog({
			title: this.$translate.instant('modal-zoom-disconnect_title'),
			message: this.$translate.instant('modal-zoom-disconnect_message'),
			confirmText: this.$translate.instant('modal-zoom-disconnect_disconnect')
		}).then(() => {
			this.zoomService.disconnect().then(() => {
				this.recordings = [];
				this.nextPageToken = '';
				this.umc.selectedZoomTab = this.TAB_CLOUD_RECORDINGS;
				this.currentTab = this.TAB_CLOUD_RECORDINGS;
			});
		}).catch(noop);
	}

	// This is delegated to the umc-infinite-scroll directive.
	public onScroll () {
		if (!this.isFetchingRecordings && this.nextPageToken) {
			this.getRecordings();
		}
	}

	public selectRecording (downloadUrl: string): void {
		this.selectedDownloadUrl = downloadUrl;
		this.media.zoom_download_url = downloadUrl;
		this.umc.selectedMedia = this.$q.when(this.media);
	}

	public getDuration (start: string, end: string): string {
		const ms = new Date(end).getTime() - new Date(start).getTime();
		let h, m, s;
		h = Math.floor(ms / 1000 / 60 / 60);
		m = Math.floor((ms / 1000 / 60 / 60 - h) * 60);
		s = Math.floor(((ms / 1000 / 60 / 60 - h) * 60 - m) * 60);
		h = h < 1 ? '' : `${h}:`;
		m = m < 10 ? `0${m}` : `${m}`;
		s = s < 10 ? `0${s}` : `${s}`;
		return `${h}${m}:${s}`;
	}

	private initAsync (): ng.IPromise<void> {
		this.recordings = [];
		this.nextPageToken = '';

		return this.createMedia().then((media) => {
			this.media = media;
			this.uploadOptions.request.params.media_id = this.media.media_id;
			this.uploadOptions.request.params.user_id = this.media.created_by;
			this.uploadOptions.uniqueId = this.media.filename; // Use this from backend
			const currentTab = this.isMobile()
				? this.TAB_CLOUD_RECORDINGS
				: this.TAB_COMPUTER_RECORDINGS;
			this.setCurrentTab(currentTab);
			this.isReady = true;
		});
	}

	private handleVisibilityChange = () => {
		// If GoReact regains focus while the cloud recordings
		// tab is opened and the user is not yet authenticated with Zoom
		if (this.$document[0].hidden === false && this.zoomService.isAuthenticated() === false) {
			this.getRecordings();
		}
	};

	private createMedia (): ng.IPromise<any> {
		const params: CreateUpdateMediaParameters = {
			add_to_collection: this.options.addToCollection,
			add_to_folder: this.options.addToFolder,
			group_id: this.options.groupId,
			source: MediaSource.ZOOM_UPLOAD,
			media_status: this.MediaModel.PENDING,
			title: this.options.mediaTitle,
			filename: null, // needs to null until a file is chosen from the file system (see above)
			media_url: null,
			resource_type: this.options.resourceType
		};

		if (this.options.mediaType) {
			params.media_type = this.options.mediaType;
		}

		return this.umcController.createOrUpdatePlaceholderMedia(params);
	}

	private updateMediaSource (source: MediaSource): ng.IPromise<void> {
		if (this.media.source !== source) {
			this.media.source = source;
			return this.media.$save();
		}
	}

	private getRecordings (isSync: boolean = false) {
		if (!isSync && this.recordings.length !== 0 && this.nextPageToken === '') return;
		if (isSync) {
			this.zoomService.clearRecordings();
			this.recordings = [];
			this.nextPageToken = '';
		}
		this.isFetchingRecordings = true;
		if (!this.zoomService.isAuthenticated()) {
			this.umc.isZoomCloudRecordingsTabReady = false;
		}

		this.zoomService.getRecordings(this.nextPageToken).then((response) => {
			this.nextPageToken = response.nextPageToken;
			this.recordings = response.recordings.filter((recording) => {
				return this.allowedRecordingTypes.includes(recording.recordingType);
			});
		}).catch(() => {
			if (this.zoomService.isAuthenticated()) {
				const modalData = {
					title: 'zoom-fetch-recordings-error_title',
					message: 'zoom-fetch-recordings-error_message',
					resolveBtnText: 'common_close',
					resolveBtnClass: 'primary-btn'
				};

				this.messageModal.open({ modalData });
			}
		}).finally(() => {
			this.isFetchingRecordings = false;
			this.umc.isZoomCloudRecordingsTabReady = true;
		});
	}
}
