import * as dayjs from 'dayjs';
import { Product } from 'go-modules/services/group/product';
import type { License } from 'go-modules/services/group/license';
import { UserService } from 'go-modules/models/user/user.service';
import type { BillingOptions, GroupService as GrService } from 'go-modules/services/group/group.service';
import { ExpirationPolicies } from 'ngx/go-modules/src/enums/salesforce-license';
import { GoLocalizeHelperService } from 'go-modules/translation-helper/go-localize-helper.service';
import {
	LICENSE_TRANSACTION_TYPE,
	PAYMENT_TYPES,
	PURCHASE_TYPES
} from 'go-modules/payment-panel/payment-panel.controller';
import { LazyPaymentLoader } from 'go-modules/payment-panel/lazy-payment-loader.service';
import { ORG_TYPE_LABELS } from 'go-modules/org-settings/org-settings.constants';

export interface CourseEditorOptions {
	orgSettings: {
		group_id: number;
		course_max_duration_in_months: number;
		course_default_duration_in_months: number;
		org_type: string;
	};
	isLti: boolean;
	hideName: boolean;
	selfRegistrationEnabled: boolean;
	autoSelectLicense: boolean;
}

interface BillingOption<T extends License | Product> {
	title: string;
	description: string;
	option: T;
	selectedTitle: string;
	expired: boolean;
}

const isProduct = (option: License | Product): option is Product => {
	return 'product_id' in option;
};

const isProductOption = (option: BillingOption<License | Product>): option is BillingOption<Product> => {
	return 'product_id' in option.option;
};

const isLicenseOption = (option: BillingOption<License | Product>): option is BillingOption<License> => {
	return !isProductOption(option);
};

export interface Course {
	group_id: number;
	start_date: Date;
	end_date: Date;
	product_id?: string;
	parent_id: number;
	name: string;
	billing_entity_id: string;
	has_paid_user: boolean;
}

export interface CourseEditorBindings {
	options: CourseEditorOptions;
	course: Course;
	duplicateFromGroup: Course;
	isLoading?: boolean;
}

export class CourseEditorController implements CourseEditorBindings {

	public duplicateFromGroup: any;
	public options: CourseEditorOptions;
	public course: Course;
	public rangeLimit: number;
	public dateLockEnabled: boolean = false;
	public billingOption: BillingOption<License | Product>;
	public pseudoOptionSelected = false;
	public billingOptions: BillingOption<License | Product>[] = [];
	public isRoot: boolean;
	public isDatesAdded: boolean = true;
	public yesterday = null;
	public isLoading: boolean;

	public datepickerMaxDate = null;
	public datepickerMinDate = null;

	public courseStartMaxDate;

	public billingOptionInitialized = false;
	public courseCurrentStartDate = null;
	public courseCurrentEndDate = null;

	public lastPickedStartDate = null;
	public lastPickedEndDate = null;

	private pseudoSlug = 'pseudo-slug';

	/** @ngInject */
	constructor (
		private $scope: ng.IScope,
		private $timeout: ng.ITimeoutService,
		private $element: ng.IAugmentedJQuery,
		private $log: ng.ILogService,
		private dateDiffExceedsLimit,
		private GroupService: GrService,
		private translateFilter: ng.translate.ITranslateFilter,
		private goLocalizeHelper: GoLocalizeHelperService,
		private userService: UserService,
		private PaymentLoader: LazyPaymentLoader,
		private Group
	) {}

	public $onInit () {
		if (!this.options.orgSettings && !this.options.isLti) {
			throw new Error('OrgSettings required if showing dates');
		}

		this.isRoot = this.userService.currentUser ? this.userService.currentUser.is_root_user : false;

		this.setRangeLimit();
		this.yesterday = this.isRoot ? null : dayjs().subtract(1, 'day').toDate();

		if (!this.options.isLti) {
			// On edit mode get copy of course original dates
			if (this.course.group_id) {
				this.courseCurrentStartDate = this.course.start_date;
				this.courseCurrentEndDate = this.course.end_date;
			}

			this.$scope.$watch('$ctrl.billingOption', (billingOption: BillingOption<License | Product>) => {
				if (!billingOption) {
					return;
				}

				// Uncheck checkbox and hide dates by default when editing a course that is never-ending
				if (this.course.group_id && this.course.end_date === null) {
					this.isDatesAdded = false;
				}

				this.setRangeLimit();

				if (!this.billingOptionInitialized) {
					this.billingOptionInitialized = true;

					// create mode, first load
					if (!this.course.group_id) {
						// hide dates for biz orgs
						if (
							this.options.orgSettings.org_type === ORG_TYPE_LABELS.BIZ && !this.duplicateFromGroup ||
							(this.course.end_date === null && this.duplicateFromGroup)
						) {
							this.isDatesAdded = false;
						} else if(this.course.end_date === null) {
							this.course.end_date = this.defaultEndDate();
						}
					}

					this.dateLockEnabled = isProduct(billingOption.option) && this.dateDiffExceedsLimit(
						this.course.start_date,
						this.course.end_date,
						this.rangeLimit
					);
				} else {
					// always show dates for student pay
					if (isProduct(billingOption.option)) {
						this.isDatesAdded = true;
					}

					// edit mode
					if (this.course.group_id) {
						this.course.start_date = this.lastPickedStartDate ?? this.course.start_date;
						this.course.end_date = this.lastPickedEndDate ?? this.course.end_date;
					}
					// create mode
					else {
						this.updateDefautCourseDates();
					}
				}

				// No need to set course date if not a license
				// Or overage policy is not restricted
				if (!this.isLicenseRestrictedExpirationPolicy()) {
					// When date lock is enabled, Reset the course date value to course original date
					const courseCurrentStartDate = this.dateLockEnabled ? this.courseCurrentStartDate : null;
					const courseCurrentEndDate = this.dateLockEnabled ? this.courseCurrentEndDate : null;

					const startDateCurrent = courseCurrentStartDate ?? this.course.start_date;
					const endDateCurrent = courseCurrentEndDate ?? this.course.end_date;

					this.course.start_date = null;
					this.course.end_date = null;

					// Need to null the course end_date first and re-apply the value on next digest
					// to trigger the date-range validator
					this.$timeout(() => {
						this.course.start_date = startDateCurrent;
						this.course.end_date = endDateCurrent;
					});

					return;
				}

			});

			this.$scope.$watch('[$ctrl.course.start_date, $ctrl.course.end_date]', ([startDate, endDate]: [Date, Date]) => {
				this.setDatePickerMaxAndMinDate();

				if (!this.isLicenseRestrictedExpirationPolicy()) {
					this.studentPayDateCheck(startDate, endDate);
				}
			});
		}

		if (this.shouldShowDates()) {
			this.isDatesAdded = true;
		}

		this.setInitialBillingOption();
	}

	public licenseDaysRemaining (): number {
		let days = 0;
		if (isLicenseOption(this.billingOption)) {
			days = dayjs(this.billingOption.option.ends_at).diff(dayjs(), 'days');
		}
		return Math.max(0, days);
	}

	public optionIsFreeTrialLicense (): boolean {
		if (this.billingOption && isLicenseOption(this.billingOption)) {
			return this.billingOption.option.salesforce_license.is_free_trial;
		}
		return false;
	}

	public setLastPickStartDate () {
		this.$timeout(() => this.lastPickedStartDate = this.course.start_date);
	}

	public setLastPickEndDate () {
		this.$timeout(() => this.lastPickedEndDate = this.course.end_date);
	}

	public shouldShowDates (): boolean {
		return this.isDatesAdded && !this.options.isLti;
	}

	public shouldShowDateCheckbox () {
		if (!this.billingOption) {
			return false;
		}

		return !(isProductOption(this.billingOption) || this.options.isLti);
	}

	public updateDefautCourseDates () {
		if (!this.isDatesAdded) {
			this.lastPickedStartDate = this.course.start_date;
			this.lastPickedEndDate = this.course.end_date;

			this.course.start_date = dayjs().toDate();
			this.course.end_date = null;
		} else {
			this.course.start_date = this.lastPickedStartDate ?? dayjs().toDate();
			this.course.end_date = this.lastPickedEndDate ?? this.defaultEndDate();
		}
	}

	public getDateFormat (): string {
		return this.goLocalizeHelper.longDateFormat;
	}

	public canEditStartDate (): boolean {
		const isLicense = !isProduct(this.billingOption?.option ?? {} as any);
		return this.isRoot
			|| (!this.dateLockEnabled && !this.course.has_paid_user)
			|| isLicense
			|| this.duplicateFromGroup;
	}

	public canEditEndDate (): boolean {
		const isLicense = !isProduct(this.billingOption?.option ?? {} as any);
		return this.isRoot
			|| !this.dateLockEnabled
			|| isLicense
			|| this.duplicateFromGroup;
	}

	public setBillingOption (option: License | Product): void {
		if ('product_id' in option) {
			const product = option;
			this.course.product_id = product.product_id.toString();

			if (this.course.group_id === null) {
				this.course.billing_entity_id = null;
			}

		} else {
			const license = option as License;
			this.course.billing_entity_id = license.billing_entity_id.toString();
			this.course.product_id = null;
		}
	}

	public showPurchaseLicense (): boolean {
		return !this.billingOptions.some((option) =>
			isLicenseOption(option) && !option.option.salesforce_license.is_free_trial
		);
	}

	public purchaseLicense () {
		this.PaymentLoader.openPayForm(
			this.userService.currentUser,
			this.Group.get(this.course.parent_id),
			() => {
				this.setInitialBillingOption();
			},
			null,
			PAYMENT_TYPES.CARD,
			PURCHASE_TYPES.LICENSE,
			LICENSE_TRANSACTION_TYPE.INITIAL
		);
	}

	private defaultEndDate () {
		return dayjs(this.course.start_date)
			.add(this.options.orgSettings.course_default_duration_in_months, 'month')
			.toDate();
	}

	public showOptionsDropdown (): boolean {
		return this.billingOptions.some((option) => !this.isPseudoOption(option)) &&
			!this.options.autoSelectLicense;
	}

	private isPseudoOption (option: BillingOption<License | Product>): boolean {
		return option.option.product_slug === this.pseudoSlug;
	}

	private isLicenseRestrictedExpirationPolicy (): boolean {
		if (!this.billingOption?.option.salesforce_license) {
			return false;
		}

		return this.billingOption.option.salesforce_license.expiration_policy === ExpirationPolicies.RESTRICTED;
	}

	// Determine what the valid course date range limit is
	private setRangeLimit () {
		if (!this.options.isLti) {
			const isLicense = !isProduct(this.billingOption?.option ?? {});

			// set range to infinity if user is root or license
			this.rangeLimit = this.isRoot || isLicense
				? Infinity
				: this.options.orgSettings.course_max_duration_in_months;
		}
	}

	/**
	 * For Date picker min and max attribute
	 * to determine user selected out of range date.
	 */
	private setDatePickerMaxAndMinDate () {

		// avoid Date(Invalid Date) when infinite range and converting toDate
		if (this.rangeLimit !== Infinity) {
			this.datepickerMinDate = dayjs(this.course.end_date).add(-this.rangeLimit, 'month').toDate();
			this.datepickerMaxDate = dayjs(this.course.start_date).add(this.rangeLimit, 'month').toDate();
		} else {
			this.datepickerMinDate = null;
			this.datepickerMaxDate = null;
		}
	}

	/**
	 * For Accessibility - whenever course date range exceeded
	 * Screen reader will read the message set in this element
	 */
	private studentPayDateCheck (startDate: Date, endDate: Date) {
		const selector = '#courseEditorDateRangeExceeded';

		if (startDate && endDate) {
			const message = this.translateFilter('course-template_invalid-date-range', { months: this.options.orgSettings.course_max_duration_in_months });
			const isExceeded = this.dateDiffExceedsLimit(startDate, endDate, this.rangeLimit);

			// Need delay so it will update the value after the digest cycle.
			// Beacause if we use binding on this case the screen reader misses it sometimes.
			this.$element[0].querySelector(selector).textContent = '';
			this.$timeout(() => this.$element[0].querySelector(selector).textContent = isExceeded ? message : '', 100);
		} else {
			this.$element[0].querySelector(selector).textContent = '';
		}
	}

	private buildBillingOption = (option: License | Product): BillingOption<License | Product> => {
		if (isProduct(option)) {
			const title = `$${option.price} ${option.display_name}`;
			return {
				option,
				title,
				selectedTitle: title,
				description: this.translateFilter('course-editor_total-seats', {seats: option.base_product_qty}),
				expired: false
			};
		} else {
			if (option.salesforce_license.salesforce_id != null && !option.salesforce_license.self_pay) {
				return {
					option,
					title: this.translateFilter('course-editor_pre-paid-unlimited-license'),
					description: option.salesforce_license.name,
					selectedTitle: option.salesforce_license.name,
					expired: false
				};
			} else {
				const expired = !option.is_active ||
					option.salesforce_license.expiration_policy === ExpirationPolicies.RESTRICTED &&
					dayjs().isAfter(option.ends_at);
				return {
					option,
					title: option.salesforce_license.name,
					description: `${dayjs(option.starts_at).format('MM/DD/YYYY')} - ${dayjs(option.ends_at).format('MM/DD/YYYY')}`,
					selectedTitle: option.salesforce_license.name,
					// We don't want to warn people that their license is expired if the course is also expired.
					// we don’t need people worrying about expired courses on non-current licenses.
					// Most expired courses will be on expired licenses.
					// Note if the end_date is null, then it's never ending and it'll always display
					expired: expired && !dayjs().isAfter(this.course.end_date)
				};
			}
		}
	};

	private setInitialBillingOption (): void {
		const groupId = this.course.group_id ?? this.course.parent_id;

		this.GroupService.getEligibleBilling(groupId).then((data: BillingOptions) => {
			const licenses = data.licenses;
			const products = data.products;

			this.billingOptions = []
				.concat(licenses)
				.concat(products)
				.map(this.buildBillingOption);

			// If course is using product, find the product
			// If course already exists (has group_id) and has no product, find the license in the licenses
			// If course doesn't already exist (no group_id) and has no product,
			// but the only license available is Use Time, then default to the product
			let billingOption;
			if (this.course.product_id != null) {
				billingOption = this.billingOptions
					.filter(isProductOption)
					.find((option) => option.option.product_id.toString()
						=== this.course.product_id.toString());

				/**
				 * If a biz org gets in here, they won't have a student pay product so add a pseudo option
				 * to prevent an error with the empty options.
				 */
				if (typeof billingOption === 'undefined') {
					billingOption = {
						option: {
							product_id: this.course.product_id,
							product_slug: this.pseudoSlug
						},
						title: this.translateFilter('course-editor_pseudo-product-title'),
						selectedTitle: this.translateFilter('course-editor_pseudo-product-title'),
						description: this.translateFilter('course-editor_pseudo-product-description')
					};
					this.billingOptions.unshift(billingOption);
					this.pseudoOptionSelected = true;
				}
				this.setBillingOption(billingOption.option);
			} else if (this.course.group_id) {
				billingOption = this.billingOptions
					.filter(isLicenseOption)
					.find((option) => option.option.billing_entity_id.toString()
						=== this.course.billing_entity_id.toString());

				// use time license only visible to root users
				// billingOption will be undefined for non root users if the course is using use time license
				if (billingOption !== undefined) {
					this.setBillingOption(billingOption.option);
				}
			} else if (licenses.length > 1 || (licenses[0] && licenses[0].salesforce_license.name !== 'Use Time')) {
				billingOption = this.billingOptions
					.filter((option) => isLicenseOption(option))
					.reduce((latestOption, currentOption) => {
						const currentEndDate = dayjs(currentOption.option.ends_at);
						const latestEndDate = latestOption ? dayjs(latestOption.option.ends_at) : null;
						return !latestEndDate || currentEndDate.isAfter(latestEndDate) ? currentOption : latestOption;
					}, null);

				this.setBillingOption(billingOption.option);
			} else {
				billingOption = this.billingOptions.find((option) => isProductOption(option));
				if (billingOption) {
					this.setBillingOption(billingOption.option);
				}
			}

			this.billingOption = billingOption;
			this.isLoading = false;
		}).catch(this.$log.error);
	}
}
