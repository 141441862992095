import * as angular from 'angular';
import * as uiTabs from 'angular-ui-bootstrap/src/tabs';

import { SidePanelModule } from '../side-panel';
import { SessionEditorPanelService } from './session-editor-panel.service';
import './style.less';
import { goTypeaheadSelectModule } from 'go-modules/go-typeahead-select';
import { userModel } from 'go-modules/models/user';
import { confirmModalModule } from 'go-modules/modals/confirm';
import { ngxSessionListDataSourceModule } from 'ngx/go-modules/src/services/session-list-datasource';
import { ngxSessionServiceModule } from 'ngx/go-modules/src/services/session';

// TODO: fix circular dependency.
// Until then, we need to import directly to force side-effect of angular module
// loading. If we leave the `fooModule from` part, webpack will use tree-shaking
// and the module will not be loaded.
// import /*{ sessionEditorModule } from*/ '../session-editor';
import '../session-editor';
import { messageModalModule } from 'go-modules/modals/message';

export const sessionEditorPanelModule = angular.module('session-editor-panel', [
	uiTabs,
	SidePanelModule,
	goTypeaheadSelectModule,
	userModel,
	confirmModalModule,
	messageModalModule,
	ngxSessionListDataSourceModule,
	ngxSessionServiceModule,

	// TODO: use module strings directly until circular dependency is fixed
	'go.session-editor'
])
	.service(SessionEditorPanelService.NG1_INJECTION_NAME, SessionEditorPanelService)
	.name;
