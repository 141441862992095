import { Component, Inject } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { Activity } from 'ngx/go-modules/src/interfaces/activity';
import { UADetect as UADetectClass, uaDetectToken } from 'go-modules/detect/ua-detect.service';

@Component({
	selector: 'start-test-dialog',
	template: require('./start-test-dialog.component.html'),
	styles: [require('./start-test-dialog.component.scss')]
})
export class StartTestDialogComponent {
	constructor (
		public dialogRef: GoDialogRef,
		@Inject(GO_MODAL_DATA) public data: {
			currentActivity: Activity;
		},
		@Inject(uaDetectToken) private UADetect: UADetectClass
	) {}

	public cancel () {
		this.dialogRef.close(false);
	}

	public confirm () {
		this.dialogRef.close(true);
	}

	public showScreenCaptureWarning (): boolean {
		return this.UADetect.browserDetector.isFirefox() || this.UADetect.browserDetector.isSafari();
	}
}
