// Prefix all routes that need auth with "root"
// Any route that does not need auth, NEVER start with "root"
// Otherwise, on token timeout, the un-authed pages might see the session timeout modal

export enum States  {
	AUTH = 'auth',
	AUTH_LOGIN = 'auth.login',
	AUTH_SIGNUP = 'auth.signup',
	AUTH_FORGOT = 'auth.forgot',
	AUTH_RESET = 'auth.reset',
	AUTH_COURSE_CONFIRM= 'auth.course-confirm',
	DASHBOARD_ROOT = 'root',
	DASHBOARD_FOLDERS = 'root.folders',
	DASHBOARD_EXTERNAL_SESSION = 'root.external-session',
	DASHBOARD_NO_GROUPS = 'root.no-groups',
	DASHBOARD_ACTIVITY_VIEW = 'root.activity-view',
	DASHBOARD_SESSION_VIEW = 'root.session-view',
	DASHBOARD_FOLDER_VIEW = 'root.folder-view',
	LICENSE_MANAGEMENT = 'root.license-management',
	LICENSE_SEATS_MANAGEMENT = 'root.license-seats-management',
	GUEST_REVIEW = 'root.guest-review',
	DASHBOARD_SETTINGS = 'root.settings',
	DASHBOARD_DASHBOARD = 'root.dashboard',
	DASHBOARD_DASHBOARD_FOLDERS = 'root.folders',
	DASHBOARD_REVIEW = 'root.review',
	DASHBOARD_LICENSE_SEATS_MANAGEMENT = 'root.license_seats_management',
	DASHBOARD_LICENSE_MANAGEMENT = 'root.license-management',
	DASHBOARD_LICENSE_MANAGEMENT_ORG = 'root.license-management-org',
	DASHBOARD_ARCHIVE = 'root.archive',
	DASHBOARD_QUICKSTART_GUIDE = 'root.quickstart-guid',
	DASHBOARD_WIZARD = 'root.wizard'
}
