import { StartTestDialogComponent } from 'ngx/go-modules/src/components/dialogs/start-test-dialog/start-test-dialog.component';
import type { SessionCreationWizardOptions } from '../options';
import type { SessionCreationWizardStep } from './';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';

export class StartTestStep implements SessionCreationWizardStep {

	/* @ngInject */
	constructor (
		private Session: any,
		private ngxGoModalService: GoModalService,
		private $q: ng.IQService
	) {}

	/**
	 * Run this step
	 */
	public run (options: SessionCreationWizardOptions): ng.IPromise<void> {
		return this.$q((resolve, reject) => {
			this.ngxGoModalService.open(StartTestDialogComponent, false, {
				data: {
					currentActivity: options.activity
				}
			}).afterClosed().subscribe((confirm) => {
				if (confirm) {
					// When the test is officially started, move the session into an awaiting start status
					// so that the user cannot exit after seeing the instructions and start a new test later.
					options.session.setStatus(this.Session.WAITING);
					options.session.setViewedRecordingInstructions();
					options.session.save().then(() => {
						resolve();
					}).catch(reject);
				} else {
					reject();
				}
			});
		});
	}

	/**
	 * Determine whether this step is required
	 */
	public isRequired (options: SessionCreationWizardOptions): boolean {
		return options.activity.has_single_recording_attempt;
	}
}
