import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface AccountLockoutDialogData {
	message: string;
	forgotPasswordUrl: string;
}

@Component({
	selector: 'account-lockout-dialog',
	template: require('./account-lockout-dialog.component.html'),
	styles: [require('./account-lockout-dialog.component.scss')]
})
export class AccountLockoutDialogComponent {

	constructor (
		private dialogRef: MatDialogRef<AccountLockoutDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: AccountLockoutDialogData
	) {}

	public close () {
		this.dialogRef.close();
	}
}
