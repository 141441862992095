import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { NgxGroupService } from 'ngx/go-modules/src/services/group/group.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSessionService, SharePublicResponse } from 'ngx/go-modules/src/services/session/session.service';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { VIDEO_SHARE_SETTINGS } from 'go-modules/org-settings/org-settings.video-share.enum';
import { UserRole } from 'ngx/go-modules/src/interfaces';
import { OrgSettings } from 'ngx/go-modules/src/interfaces/groups/org-settings';
import { ReviewerTourService } from 'ngx/go-modules/src/tours/reviewer-tour/reviewer-tour.service';
import { UserFormFieldComponent } from '../../user-form-field/user-form-field.component';
import { MatChipInputEvent } from '@angular/material/chips';

export interface VideoShareDialogData {
	orgSettings: OrgSettings;
	sessionId: number;
}

@Component({
	selector: 'video-share-dialog',
	template: require('./video-share-dialog.component.html'),
	styles: [require('./video-share-dialog.component.scss')]
})
export class VideoShareDialogComponent implements OnInit, AfterViewInit {
	@ViewChild(UserFormFieldComponent) public userFormFieldComponent: UserFormFieldComponent;
	public shareLink: string;
	public guestReviewersCount: number;
	public creatingLink$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public users: UserRole[] = [];
	public form = new FormGroup({
		childForm: new FormControl({})
	});
	public loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	public isSaving$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor (
		@Inject(GO_MODAL_DATA) public data: VideoShareDialogData,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		private dialogRef: GoDialogRef,
		private groupService: NgxGroupService,
		private sessionService: NgxSessionService,
		private reviewerTour: ReviewerTourService
	) {}

	public ngOnInit (): void {
		if (this.allowsInternalShare()) {
			this.groupService.getUsers(this.selectedService.getGroup().group_id).subscribe((res) => {
				this.users = res;
			});
		}
		this.sessionService.getSession(this.data.sessionId).subscribe((session) => {
			if (session.external_session) {
				this.shareLink = `${clientSettings.VideoShareUrl}/sessions/${session.external_session}`;
				this.guestReviewersCount = session.guest_reviewers.length;
			}
			this.loading$.next(false);
		});
	}

	public ngAfterViewInit (): void {
		if (this.showReviewersField()) {
			this.reviewerTour.getTour().start();
		}
	}

	public showReviewersField (): boolean {
		return this.isPrivateShare() ||
			this.isPublicAndPrivateShare() ||
			this.allowsInternalShare();
	}

	public showSeparator (): boolean {
		return (this.selectedService.getActivity().is_video_share_enabled ||
			this.selectedService.getGroup().hasInstructorRole(true)) &&
			(this.isPublicAndPrivateShare() ||
			(this.isPublicShare() && this.allowsInternalShare()));
	}

	public showInviteButtonAbove (): boolean {
		return this.showSeparator() && Object.keys(this.form.value.childForm).length > 0;
	}

	public isPrivateShare (): boolean {
		return this.data.orgSettings.allow_video_share === VIDEO_SHARE_SETTINGS.PRIVATE;
	}

	public isPublicShare (): boolean {
		return this.data.orgSettings.allow_video_share === VIDEO_SHARE_SETTINGS.PUBLIC;
	}

	public isPublicAndPrivateShare (): boolean {
		return this.data.orgSettings.allow_video_share === VIDEO_SHARE_SETTINGS.BOTH;
	}

	public isExternalVideoShareDisabled (): boolean {
		return this.data.orgSettings.allow_video_share === null ||
			(!this.selectedService.getActivity().is_video_share_enabled &&
			!this.selectedService.getGroup().hasInstructorRole(true));
	}

	public allowsInternalShare (): boolean {
		return this.data.orgSettings.internal_reviewers_enabled;
	}

	public allowsExternalShare (): boolean {
		return (this.isPublicAndPrivateShare() || this.isPublicShare()) &&
			(this.selectedService.getActivity().is_video_share_enabled ||
			this.selectedService.getGroup().hasInstructorRole(true));
	}

	public createPublicShareLink (): void  {
		this.creatingLink$.next(true);
		this.sessionService.sharePublic(this.data.sessionId).subscribe((res: SharePublicResponse) => {
			this.shareLink = `${clientSettings.VideoShareUrl}/sessions/${res.uuid}`;
			this.creatingLink$.next(false);
		});
	}

	public sendInvite (): void  {
		const unsavedValue = this.userFormFieldComponent.userInputCtrl.value;
		if (unsavedValue) {
			const isValidEmail = new FormControl(unsavedValue, Validators.email).valid;
			if (isValidEmail) {
				const matChipEvent: MatChipInputEvent = {
					input: this.userFormFieldComponent.userInput.nativeElement,
					value: this.userFormFieldComponent.userInput.nativeElement.value
				};
				this.userFormFieldComponent.add(matChipEvent);
			}
		}
		if (!this.form.valid) {
			return;
		}
		if (this.form.pristine ||
			(!this.form.value.childForm.external_reviewer_emails.length &&
				!this.form.value.childForm.internal_reviewer_user_ids.length)) {
			return this.cancel();
		}
		this.isSaving$.next(true);
		this.sessionService.sharePrivate(
			this.data.sessionId,
			this.form.value.childForm
		).subscribe({
			next: (res) => {
				const {invalidExternalUsers: invalidEmails, invalidInternalUsers: invalidUserIds } = res;
				const invalidUserNames = this.users
					.filter((user) => invalidUserIds.includes(user.user_id))
					.map((user) => `${user.first_name} ${user.last_name}`);
				this.dialogRef.close({ invalidEmails, invalidUserNames });
			},
			error: (err) => {
				this.dialogRef.close(err);
			}
		});
	}

	public manageVideoSharing (): void  {
		this.dialogRef.close({ dismissed: true, openEditUserPanel: true });
	}

	public cancel (): void  {
		this.dialogRef.close({ dismissed: true });
	}
}
