import { merge } from 'lodash';
import type { MediaResource } from 'go-modules/models/media';
import { MediaSource } from 'go-modules/models/media';
import { UniversalMediaChooserController } from '../umc.controller';
import type { CreateUpdateMediaParameters } from 'go-modules/models/media';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { Events, Reasons, States } from 'go-modules/video-scene/state-emitter/state-emitter';
import { VideoSceneController } from 'go-modules/video-scene/video-scene.controller';
import { VideoSceneOptions } from 'go-modules/video-scene/video-scene.options';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';
import { StatusOverlayable } from 'ngx/go-modules/src/components/video-scene/status-overlay/status-overlay.component';
import { StatusOverlayReason } from 'ngx/go-modules/src/components/video-scene/status-overlay/status-overlay-reason';
import * as dayjs from 'dayjs';

export interface Bindings {
	umcController: UniversalMediaChooserController;
}

export type UMCRecordType = 'audio' | 'video';

export abstract class UMCRecordController implements Bindings {

	// Bindings
	public umcController: UniversalMediaChooserController;
	public options: any;
	public umcInstanceName: string;
	public isReady: boolean;
	public goRecorderSettings: VideoSceneOptions;
	public environmentVarsService: EnvironmentVarsService;
	public statusOverlay: StatusOverlayable;
	private media: any;
	private umc: any;
	public reasons = Reasons;
	public previouslyHadBadConnection: boolean = true;

	/* @ngInject */
	constructor (
		private featureFlag: FeatureFlag,
		private UniversalMediaChooser: any,
		private MediaModel: any,
		private User: any,
		private eventService: EventService,
		private $q: ng.IQService,
		private $translate,
		private $scope: ng.IScope
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
		this.isReady = false;
	}

	public $onInit (): void {
		if (!this.options || !this.options.groupId) {
			throw new Error('umc-record:: needs groupId');
		}

		this.umc = this.UniversalMediaChooser.get(this.umcInstanceName);

		this.goRecorderSettings = merge({
			audioOnly: this.getType() === 'audio'
		}, this.options.goRecorder);

		this.statusOverlay = new StatusOverlayReason(Reasons.OPENTOK_CONNECTING);

		this.initAsync();
	}

	public onRecorderInit (goRecorder: VideoSceneController): void {
		this.umcController.handleEngineInit(goRecorder);

		goRecorder.on(Events.STATE_CHANGE, (state) => {
			switch (state) {
				case States.STARTING:
					this.umcController.handleMediaDetermined(this.media);
					break;
				case States.RECORDING:
					this.onRecordingStart(goRecorder);
					break;
				case States.STOPPED:
					this.onRecordingStop(goRecorder);
					break;
			}
		});
	}

	public onConnectionStatus (goodConnection: boolean) {
		if (goodConnection) {
			// so we don't clear out a different overlay
			if (this.previouslyHadBadConnection) {
				this.previouslyHadBadConnection = false;
				this.statusOverlay = null;
			}
		} else {
			this.previouslyHadBadConnection = true;
			this.statusOverlay = new StatusOverlayReason(Reasons.OPENTOK_POOR_CONNECTION);
		}
		this.$scope.$apply();
	}

	private initAsync (): ng.IPromise<any> {
		const environment = this.environmentVarsService.get(EnvironmentVarsService.VAR.ENVIRONMENT) as any;
		const isLtiEnvironment = environment && environment.name === ENVIRONMENTS.LTI;

		// Create media if necessary and build streaming parameters
		const mediaPromise = this.$q.when(this.options.media || this.createMedia(MediaSource.OPENTOK));
		return mediaPromise.then((media) => {
			const mediaResource: MediaResource = this.options.resourceId ? {
				id: this.options.resourceId,
				type: this.options.resourceType
			} : null;
			return this.User.getIpAddress().$promise.then((response) => {
				const connectionParams = media.buildStreamingConnectionParams(
					this.options.groupId, response.userIp, mediaResource
				);
				return media.getStreamingConnectionInfo()
					.then((connectionInfo) => {
						this.statusOverlay = null;
						// Load dynamic recorder settings
						this.goRecorderSettings.opentok = connectionInfo;
						this.goRecorderSettings.mediaRecorder = !isLtiEnvironment && this.featureFlag.isAvailable('USE_MEDIA_RECORDER') && this.umcInstanceName === 'umcModal';
						this.goRecorderSettings.params = connectionParams;

						this.media = media;
						this.isReady = true;
					}).catch(() => {
						this.statusOverlay = new StatusOverlayReason(Reasons.OPENTOK_CONNECTION_FAILED);
					});
			});
		});
	}

	private createMedia (source: MediaSource): ng.IPromise<any> {
		const mediaType = this.getType() === 'audio' ? this.MediaModel.TYPE.AUDIO : this.MediaModel.TYPE.VIDEO;
		const params: CreateUpdateMediaParameters = {
			add_to_collection: this.options.addToCollection,
			add_to_folder: this.options.addToFolder,
			group_id: this.options.groupId,
			source,
			media_status: this.MediaModel.PENDING,
			media_type: mediaType,
			media_url: null,
			title: this.options.mediaTitle ?? this.$translate.instant('modal-umc_recording-title', {date: dayjs().format('YYYY-MM-DD')}),
			resource_type: this.options.resourceType
		};

		return this.umcController.createOrUpdatePlaceholderMedia(params);
	}

	private onRecordingStart (_goRecorder: VideoSceneController): void {
		this.umc.selectedMedia = null;
		this.eventService.broadcast(EVENT_NAMES.UMC_START_MEDIA_CAPTURE);
	}

	private onRecordingStop (goRecorder: VideoSceneController): void {
		this.media.setDuration(goRecorder.getDuration());
		this.umc.selectedMedia = this.$q.resolve(this.media);

		if (this.options.chooseAutomatically) {
			this.eventService.broadcast(EVENT_NAMES.UMC_CHOOSE, this.media);
		}
	}

	protected abstract getType (): UMCRecordType;
}
