import * as angular from 'angular';
import { FeedbackSessionControllerClass } from '../feedback-session.controller';
import { UserService } from 'go-modules/models/user/user.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { FeedbackTabs, Kind } from '../services/session-feedback.factory';
import { ResizeSensor } from 'css-element-queries';
import { Sizes } from 'go-modules/responsive-view/responsive-view.service';
import { CommentFilterService, UniqueUser } from 'ngx/go-modules/src/services/comment-filter/comment-filter.service';
import { Comment } from 'ngx/go-modules/src/interfaces/comments/comment';
import { Marker } from 'ngx/go-modules/src/interfaces/markers/marker';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { AIMarkersDialogComponent } from 'ngx/go-modules/src/components/dialogs/ai-markers-dialog/ai-markers-dialog.component';
import type { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { GoTourService } from 'go-modules/go-tour/go-tour.service';
import dayjs from 'dayjs';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import { TranscriptionService } from 'ngx/go-modules/src/services/transcription/transcription.service';
import { EMPTY, Subject } from 'rxjs';
import { ProductTier } from 'ngx/go-modules/src/interfaces/licenses/product-tier';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { ExpirationPolicies } from 'ngx/go-modules/src/enums/salesforce-license';

export interface FeedbackDisplayControllerScope extends ng.IScope {
	mainController: FeedbackSessionControllerClass;
}

export enum TooltipAndAriaLabel {
	tooltip = 'tooltip',
	ariaLabel = 'ariaLabel'
}

export const FEEDBACK_DISPLAY_AI_MARKERS_TOUR = 'feedback-display-ai-markers-tour';
export const FEEDBACK_DISPLAY_AI_MARKERS_TOUR_CONSTRAINTS = {
	isOnboarding: false,
	tourStartDate: dayjs('2024-07-01 09:00:00').toDate()
};

export const FEEDBACK_DISPLAY_MANAGE_AI_MARKERS_TOUR = 'feedback-display-manage-ai-markers-tour';
export const FEEDBACK_DISPLAY_MANAGE_AI_MARKERS_TOUR_CONSTRAINTS = {
	isOnboarding: false,
	tourStartDate: dayjs('2025-01-31 09:00:00').toDate()
};

export class FeedbackDisplayController {
	public feedbackKinds: any[] = [];
	public initialFeedbackLoaded: boolean = false;
	public currentComment: string;
	public autoPlayEnabled: boolean;
	public tooltipAndAriaLabel = TooltipAndAriaLabel;
	public scoreAriaLabel: string;
	public environmentVarsService: EnvironmentVarsService;
	public oneClickMarkerEnabled: boolean = false;
	public uniqueUsers: UniqueUser[] = [];
	public uniqueMarkers: Marker[] = [];
	public rerunningAiFeedback: boolean = false;
	public hasComments = false;
	public tourCanBeViewed: boolean = false;
	public showManageAiMarkerTour = false;
	public readonly componentDestroyed$$ = new Subject();
	public showNoFeedbackShownZeroState = false;

	private eventSubscription: any;
	private eventNames: string[];
	private sensor: ResizeSensor;

	/* @ngInject */
	constructor (
		public $scope,
		private $timeout,
		private feedbackSession,
		public feedbackSettings,
		public ngxCommentFilterService: CommentFilterService,
		private SessionFeedback,
		private $translate: ng.translate.ITranslateService,
		private $element: ng.IRootElementService,
		private goPrintService: any,
		private userService: UserService,
		private $debounce: ng.IDebounceService,
		private Session,
		private eventService: EventService,
		private featureFlag: FeatureFlag,
		private $http: ng.IHttpService,
		private ngxGoToastService: NgxGoToastService,
		private goTour: GoTourService,
		private selectedService: SelectedService,
		private ngxGoModalService: GoModalService,
		private transcriptionService: TranscriptionService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	/**
	 * Handles controller init lifecycle hook
	 */
	public $onInit (): void {
		this.feedbackSettings.onKindFilterChange(this.updateCategoryFilter);
		this.eventNames = [
			EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_DELETED,
			EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_LOADED,
			EVENT_NAMES.FEEDBACK_TREE_TIME_REACHED,
			EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_SAVED,
			EVENT_NAMES.FEEDBACK_SESSION_OPEN_AI_MARKERS
		];
		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => this.eventNames.includes(ev.name)))
			.subscribe((ev: GoEvent) => {
				switch (ev.name) {
					case EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_DELETED:
					case EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_SAVED:
						this.checkIfHasComments();
						this.updateCategoryFilter();
						break;
					case EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_LOADED:
						this.feedbackLoaded();
						this.checkToShowManageAiMarkersTour();
						this.showFeedbackDisplayAiMarkersTour();
						break;
					case EVENT_NAMES.FEEDBACK_TREE_TIME_REACHED:
						this.timeReached(ev.data);
						break;
					case EVENT_NAMES.FEEDBACK_SESSION_OPEN_AI_MARKERS:
						this.openAiMarkers();
						break;
				}
			});

		this.$scope.$watch('mainController.activity', this.activityWatch);
		this.autoPlayEnabled = this.feedbackSettings.autoPlay;
		this.oneClickMarkerEnabled = this.feedbackSettings.oneClickMarker;

		const feedbackDisplay = angular.element(this.$element[0].querySelector('.feedback-display-content'));
		feedbackDisplay.on('scroll', this.$debounce<void, any>(() => {
			this.onScroll();
		}, 100));
	}

	public $onDestroy (): void {
		this.eventSubscription?.unsubscribe();
		this.sensor?.detach();
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
	}

	public $postLink () {
		this.sensor = new ResizeSensor(this.$element[0].parentElement, (measurements) => {
			for (const css of this.$element[0].classList.values()) {
				if (css.startsWith('resize-sensor-')) {
					this.$element[0].classList.remove(css);
				}
			}

			[Sizes.XXSMALL, Sizes.XSMALL].forEach((width) => {
				if (measurements.width <= width) {
					this.$element[0].classList.add(`resize-sensor-${width}`);
				}
			});

			this.eventService.broadcast(EVENT_NAMES.DOC_RESIZE);

			this.$scope.$apply();
		});
	}

	public onScroll () {
		const commentIds = [];
		const commentElements = this.$element[0].querySelectorAll('[data-comment-id]');
		const feedbackDisplay = this.$element[0].querySelector('.feedback-display-content');
		commentElements.forEach((el) => {
			const commentId = el.getAttribute('data-comment-id');
			const rect = el.getBoundingClientRect();
			// if comment is within the visible area (minus 1 to account for disprepency w/ last comment)
			// approximate bottom as top + 56px so comment children aren't included
			if (rect.top >= feedbackDisplay.getBoundingClientRect().top &&
			(rect.top + 56) <= feedbackDisplay.getBoundingClientRect().bottom) {
				// when comment is first created then it wont have a comment id, in which case, do nothing
				if (!commentId) return;

				const comments = this.$scope.mainController.sessionFeedback.comment.children;
				// search through comments for commentId
				let comment = comments.find((comm) => Number(comm.comment_id) === Number(commentId));
				// if comment isn't found then it must be a reply
				if (!comment) {
					comment = comments.flatMap((comm) => comm.children).find((reply) => {
						return Number(reply.comment_id) === Number(commentId);
					});
				}
				let alreadyViewed = false;
				if (comment.views) {
					alreadyViewed = comment.views.some((e) => {
						return e.user_id.toString() === this.userService.currentUser.user_id.toString();
					});
				}
				if (!commentIds.includes(commentId) && !comment.viewed_by_me && !alreadyViewed) {
					commentIds.push(commentId);
					if (comment.views) {
						comment.views.push({user_id: this.userService.currentUser.user_id});
					} else {
						comment.views = [{user_id: this.userService.currentUser.user_id}];
					}
				}
			}
		});
		if (commentIds.length) {
			this.Session.saveCommentViews({session_id: this.$scope.mainController.session.session_id, commentIds});
		}
	}

	public hideFeedbackCreator = () => {
		const { activity, session, user, license, userGroup } = this.$scope.mainController;
		return session.isLimitCommentOnlySingleAttempt(activity, user) ||
			!userGroup.validateCanCreateSession(user, license).isValid;
	};

	public mayEditScore = () => {
		if (this.environmentVarsService.get(EnvironmentVarsService.VAR.READONLY)) {
			return false;
		}

		if (this.$scope.mainController.activity.isAutoScored()) {
			return false;
		}

		if (!this.$scope.mainController.activity.mayScore(this.$scope.mainController.userGroup)) {
			return false;
		}

		return true;
	};

	public canEditCommentKind = () => {
		if (this.environmentVarsService.get(EnvironmentVarsService.VAR.READONLY)) {
			return false;
		}

		return this.$scope.mainController.canSeeCommentKind();
	};

	/**
	 * Whether feedback of kind 'comment' are showing
	 *
	 * @returns {boolean}
	 */
	public isCommentTabActive = () => {
		return this.feedbackSettings.activeTab === FeedbackTabs.COMMENT;
	};

	public removeRubric = (rubric) => {
		this.$scope.contentController.removeFeedbackItem(rubric);
	};

	public addRubric = (rubric) => {
		this.$scope.contentController.addFeedbackItem(rubric);
	};

	/**
	 * Whether feedback of kind 'rubric' are showing
	 *
	 * @returns {boolean}
	 */
	public isRubricTabActive = () => {
		return this.feedbackSettings.activeTab === FeedbackTabs.RUBRIC;
	};

	public isInformationTabActive = () => {
		return this.feedbackSettings.activeTab === FeedbackTabs.INFORMATION;
	};

	public isTranscriptionTabActive = () => {
		return this.feedbackSettings.activeTab === FeedbackTabs.TRANSCRIPTION;
	};

	public isAnalyticsTabActive = () => {
		return this.feedbackSettings.activeTab === FeedbackTabs.ANALYTICS;
	};

	/**
	 * Feedback filter function
	 *
	 * @param feedbackItem
	 * @returns {*}
	 */
	public feedbackFilter = (feedbackItem) => {
		return this.ngxCommentFilterService.shouldCommentShow(
			feedbackItem, this.feedbackSettings.filterOutSyncEventComments);
	};

	/**
	 * Users should not be able toggle the auto play setting
	 * for conversational activities that don't require response media.
	 */
	public shouldDisplayAutoPlayToggleCapability (): boolean {
		return this.feedbackSession.isPlaybackMode();
	}

	/**
	 * Toggle autoplay comment
	 */
	 public toggleAutoplayComments = (event) => {
		this.autoPlayEnabled = !this.autoPlayEnabled;
		this.feedbackSettings.toggleAutoPlay(this.autoPlayEnabled);
		event.preventDefault();
	};

	/**
	 * Handles auto play setting change event
	 */
	public handleAutoPlaySettingChange (): void {
		this.feedbackSettings.toggleAutoPlay(this.autoPlayEnabled);
	}

	/**
	 * Toggle one-click marker
	 */
	 public toggleOneClickMarker = (event) => {
		this.oneClickMarkerEnabled = !this.oneClickMarkerEnabled;
		this.feedbackSettings.toggleOneClickMarker(this.oneClickMarkerEnabled);
		event.preventDefault();
	};

	/**
	 * Handles one-click marker setting change event
	 */
	 public handleOneClickMarkerSettingChange (): void {
		this.feedbackSettings.toggleOneClickMarker(this.oneClickMarkerEnabled);
	}

	public seekToTimestamp = (startMS: number) => {
		if (startMS == null) return;
		if (Number.isNaN(startMS)) return;

		const time = Math.max(0, startMS);
		this.$scope.mainController.playerSync.seek(time);
	};

	/**
	 * Whether the sync event comments filter button should be shown
	 *
	 * @returns {boolean}
	 */
	public showSyncEventCommentsFilterButton = () => {
		if (!this.isCommentTabActive()) {
			return false;
		}

		const collection = this.$scope.mainController.sessionFeedback.getComments();
		return collection.filter((comment) => comment.isSyncEventType()).length > 0;
	};

	/**
	 * Toggle feedback auto play
	 */
	public toggleSyncEventCommentsFilter = (value?: boolean) => {
		this.feedbackSettings.toggleSyncEventCommentsFilter(value);

		// Update category filter
		this.updateCategoryFilter();
	};

	/**
	 * Whether the Re-run AI Feedback button should be shown
	 *
	 * @returns {boolean}
	 */
	public showReRunAiFeedbackButton = () => {
		const { activity, session } = this.$scope.mainController;

		return this.userService.currentUser.is_root_user &&
			activity.ai_prompts_enabled &&
			!session.media.isYoutube();
	};

	/**
	 * Toggle feedback auto play
	 */
	public rerunAiFeedback = () => {
		this.rerunningAiFeedback = true;
		const { session } = this.$scope.mainController;

		this.$http.post(`${clientSettings.GoReactV2API}/sessions/${session.session_id}/generate/ai-feedback`, {})
			.then(() => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: 'feedback-session-rerun-ai-feedback-success-message'
				});

				if (this.tourCanBeViewed) {
					this.closeFeedbackDisplayAiMarkersTour();
				}
			})
			.catch(() => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.ERROR,
					message: 'feedback-session-rerun-ai-feedback-error-message'
				});
			})
			.finally(() => this.rerunningAiFeedback = false);
	};

	/**
	 * Get label for score display
	 *
	 * @param score
	 * @returns {string}
	 */
	public getScoreLabel = (score) => {
		let label = '—';
		score = parseFloat(score);

		if (!isNaN(score)) {
			score = Math.round(score * 100) / 100 + ''; // round to 2 decimal places
			label = score;
			this.scoreAriaLabel = this.$translate.instant('feedback-session-feedback-display_points-assigned', { score });
		} else if (this.mayEditScore() && !this.$scope.mainController.session.isScoreAssigned()) {
			label = this.$translate.instant('feedback-session_controller-placeholder-pts');
			this.scoreAriaLabel = this.$translate.instant('feedback-session-feedback-display_no-points-assigned');
		}
		return label;
	};

	public getScoreTooltipAndAriaLabel = (type: string) => {
		let ariaLabel = '';
		let tooltip = '';
		const score = this.$scope.mainController.session.score;

		if (this.$scope.mainController.activity.isRubricEnabled()) {
			tooltip = this.$translate.instant('feedback-session-feedback-display_score-calculated');

			if (score === null) {
				ariaLabel = this.$translate.instant('feedback-session-feedback-display_no-rubric-no-score-aria-label');
			} else {
				ariaLabel = this.$translate.instant('feedback-session-feedback-display_no-rubric-has-score-aria-label', {
					score: Math.round(score * 100) / 100 + '',
					possible: this.$scope.mainController.activity.getTotalPoints()
				});
			}
		} else {
			if (score === null) {
				tooltip = this.$translate.instant('feedback-session-feedback-display_no-points-assigned');
				ariaLabel = this.$translate.instant('feedback-session-feedback-display_no-points-assigned');
			} else {
				const scorePossible = {
					score: Math.round(score * 100) / 100 + '',
					possible: this.$scope.mainController.activity.getTotalPoints()
				};

				tooltip = this.$translate.instant('feedback-session-feedback-display_points-possible', scorePossible);
				ariaLabel = this.$translate.instant('feedback-session-feedback-display_points-graded', scorePossible);
			}
		}

		return type === TooltipAndAriaLabel.tooltip ? tooltip : ariaLabel;
	};

	public printComments () {
		this.goPrintService.print(
			this.$element[0].querySelector('feedback-tree'),
			'Printed Comments'
		);
	}

	public addUniqueCommenters (comments: Comment[]): void {
		this.uniqueUsers = this.ngxCommentFilterService.getUniqueUsers(comments);
		this.uniqueMarkers = this.ngxCommentFilterService.getUniqueMarkers(comments);
	}

	public addAiAssistant () {
		const { activity } = this.$scope.mainController;

		return this.ngxGoModalService.open(AIMarkersDialogComponent, true, {
			data: {
				activity,
				aiPrompts: activity.ai_prompts,
				aiTranscriptionLanguage: activity.ai_transcription_language
			}
		}).afterClosed().subscribe((result) => {
			if (result) {
				this.$scope.mainController.activity.ai_transcription_language = result.ai_transcription_language;
				this.$scope.mainController.activity.ai_prompts = result.prompts;
				this.$scope.mainController.activity.save();
			}
		});
	}

	public showFeedbackDisplayAiMarkersTour () {
		const { activity, session } = this.$scope.mainController;

		if(!session.media || session.media.isYoutube() || !activity.has_response_media) return;

		this.transcriptionService.getTranscription(session.media, false)
			.pipe(
				takeUntil(this.componentDestroyed$$),
				catchError(() => {
					return EMPTY;
				})
			)
			.subscribe(() => {
				const license = this.selectedService.getLicense();
				if (
					this.selectedService.getGroup().hasInstructorRole(true) &&
					activity.ai_prompts_enabled &&
					!activity.ai_prompts.length &&
					this.$scope.mainController.sessionFeedback.comment?.children?.length > 0 &&
					this.featureFlag.isAvailable('LICENSE_UPGRADE_PURCHASE') &&
					license?.salesforce_license.tier_name === ProductTier.Plus
				) {
					this.goTour.canTourBeViewed(
						FEEDBACK_DISPLAY_AI_MARKERS_TOUR,
						FEEDBACK_DISPLAY_AI_MARKERS_TOUR_CONSTRAINTS
					).then(()=> {
						this.tourCanBeViewed = true;
					}).catch(angular.noop);
				}
			});
	}

	public closeFeedbackDisplayAiMarkersTour (): void {
		this.goTour.markTourViewed(FEEDBACK_DISPLAY_AI_MARKERS_TOUR);
		this.tourCanBeViewed = false;
	}

	public checkToShowManageAiMarkersTour () {
		const { activity, session } = this.$scope.mainController;

		if(!session.media || session.media.isYoutube() || !activity.has_response_media) return;

		const license = this.selectedService.getLicense();

		if (
			this.featureFlag.isAvailable('AI_DEFAULT') &&
			this.selectedService.getGroup().hasInstructorRole(true) &&
			license?.salesforce_license.tier_name === ProductTier.Plus &&
			activity.ai_prompts_enabled &&
			activity.ai_prompts.length > 0 &&
			!(activity.is_parent_sync_enabled && activity.parent_child_sync) &&
			!this.environmentVarsService.get(EnvironmentVarsService.VAR.DISABLE_ACTIVITY_EDITING)
		) {
			this.goTour.canTourBeViewed(
				FEEDBACK_DISPLAY_MANAGE_AI_MARKERS_TOUR,
				FEEDBACK_DISPLAY_MANAGE_AI_MARKERS_TOUR_CONSTRAINTS
			).then(()=> {
				this.showManageAiMarkerTour = true;
			}).catch(angular.noop);
		}
	}

	public closeManageAiMarkersTour (): void {
		this.goTour.markTourViewed(FEEDBACK_DISPLAY_MANAGE_AI_MARKERS_TOUR);
		this.showManageAiMarkerTour = false;
	}

	public openAiMarkers () {
		const { activity } = this.$scope.mainController;

		return this.ngxGoModalService.open(AIMarkersDialogComponent, true, {
			data: {
				activity,
				aiPrompts: activity.ai_prompts,
				aiTranscriptionLanguage: activity.ai_transcription_language
			}
		}).afterClosed().subscribe((result) => {
			if (result) {
				this.$scope.mainController.activity.ai_transcription_language = result.ai_transcription_language;
				this.$scope.mainController.activity.ai_prompts = result.prompts;
				this.$scope.mainController.activity.save();
				if (result.prompts.length) {
					this.rerunAiFeedback();
				}
			}
		});
	}

	public commentsUpdated () {
		this.checkIfHasComments();
		// we need to call this in the main controller so it will
		// render the comments correctly
		this.$scope.mainController.commentsUpdated();
	}

	public getReadOnlyText () {
		const license = this.selectedService.getLicense();
		const group = this.selectedService.getGroup();

		if (this.environmentVarsService.get(EnvironmentVarsService.VAR.READONLY)) {
			return 'session-read-only-text_lti-read-only';
		}

		if (!this.hasCourseStarted(group)) {
			return 'session-read-only-text_course-not-started';
		}

		if (this.hasCourseEnded(group)) {
			return 'session-read-only-text_course-ended';
		}

		if (
			license &&
			(
				!this.isLicenseActive(license) ||
				(
					license.salesforce_license.expiration_policy === ExpirationPolicies.RESTRICTED &&
					(!this.hasLicenseStarted(license) || this.isLicenseExpired(license))
				)
			)
		) {
			return 'session-read-only-text_invalid-license';
		}

		return null;
	}

	private hasCourseStarted (group): boolean {
		const currentDateUTC = dayjs().utc();
		return !currentDateUTC.isBefore(dayjs(group.start_date));
	}

	private hasCourseEnded (group): boolean {
		const currentDateUTC = dayjs().utc();
		return group.end_date && currentDateUTC.isAfter(dayjs(group.end_date));
	}

	private isLicenseActive (license): boolean {
		return license.is_active;
	}

	private hasLicenseStarted (license): boolean {
		const currentDateUTC = dayjs().utc();
		return !currentDateUTC.isBefore(dayjs.utc(license.current_access_date));
	}

	private isLicenseExpired (license): boolean {
		const currentDateUTC = dayjs().utc();
		return currentDateUTC.isAfter(dayjs.utc(license.ends_at));
	}

	private checkIfHasComments () {
		this.hasComments = this.$scope.mainController.sessionFeedback.comment?.children.length > 0;
	}

	public shouldShowCommentZeroStates () {
		return this.shouldShowAddAiMarkersCommentZeroState() ||
			this.shouldShowAddCommentsZeroState() ||
			this.showNoFeedbackShownZeroState;
	}

	public shouldShowAddAiMarkersCommentZeroState () {
		const { activity } = this.$scope.mainController;

		return !this.hasComments &&
			this.initialFeedbackLoaded &&
			this.$scope.mainController.license?.salesforce_license.ai_prompts_enabled &&
			activity.ai_prompts_enabled &&
			this.$scope.mainController.userGroup.hasInstructorRole(true) &&
			this.$scope.mainController.session.media &&
			!this.$scope.mainController.session.media.isYoutube() &&
			(!activity.ai_prompts ||
				activity.ai_prompts.length === 0) &&
			!(activity.parent_child_sync && activity.is_parent_sync_enabled) &&
			!this.environmentVarsService.get(EnvironmentVarsService.VAR.DISABLE_ACTIVITY_EDITING);
	}

	public shouldShowAddCommentsZeroState () {
		const { activity } = this.$scope.mainController;

		return !this.hasComments &&
			this.initialFeedbackLoaded &&
			(!this.$scope.mainController.license?.salesforce_license.ai_prompts_enabled ||
				(this.$scope.mainController.license?.salesforce_license.ai_prompts_enabled &&
				this.$scope.mainController.userGroup.hasPresenterRole()) ||
				((activity.parent_child_sync && activity.is_parent_sync_enabled) ||
					this.environmentVarsService.get(EnvironmentVarsService.VAR.DISABLE_ACTIVITY_EDITING))
			);
	}

	public toggleNoFeedbackShownZeroState () {
		const collection = this.$scope.mainController.sessionFeedback.getComments();
		this.showNoFeedbackShownZeroState = collection.length > 0
			&& collection.every((comment) => !this.feedbackFilter(comment));
	}

	/**
	 * Update the feedback category filter
	 */
	private updateCategoryFilter = () => {
		// TODO: add tests
		const currentKind = this.feedbackSettings.getKindFilter();
		if (currentKind !== Kind.SLIDE_TIMINGS) {
			this.addUniqueCommenters(
				this.$scope.mainController.sessionFeedback.getFeedbackByKind(currentKind)
			);
		}
	};

	private feedbackLoaded = () => {
		this.checkIfHasComments();

		// update category filter
		this.updateCategoryFilter();
		this.$scope.mainController.updateDegradedState();

		// Don't want to load rubrics until feedback is loaded
		// so we can accurately know if a user needs to create a new one
		// or use an existing one
		this.initialFeedbackLoaded = true;

		// set initially visible comments as viewed
		this.$timeout(() => {
			this.onScroll();
		});
	};

	private timeReached = (comment) => {
		this.currentComment = comment.text;
	};

	private activityWatch = () => {
		// add feedback kinds used in this session
		if (this.$scope.mainController.canSeeCommentKind()) {
			this.feedbackKinds.push({
				value: this.SessionFeedback.KIND.COMMENT, label: 'feedback-session_controller-comments'
			});
		}
		if (this.$scope.mainController.canSeeRubricKind()) {
			this.feedbackKinds.push({
				value: this.SessionFeedback.KIND.RUBRIC, label: 'feedback-session_controller-evaluations'
			});
		}

		// set current kind filter
		if (this.feedbackKinds.length) {
			this.feedbackSettings.setKindFilter(this.feedbackKinds[0].value);
			const activity = this.$scope.mainController.activity;

			if (this.feedbackSession.isRecording()) {
				if (activity.hasRecordingInstructions()) {
					this.feedbackSettings.setActiveTab(FeedbackTabs.INFORMATION);
				}
			} else {
				this.feedbackSettings.setActiveTab(FeedbackTabs.COMMENT);
			}
		} else {
			this.feedbackSettings.setActiveTab(FeedbackTabs.INFORMATION);
		}
	};
}
