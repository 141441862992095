import * as dayjs from 'dayjs';
import { clientSettings } from 'go-modules/models/common/client.settings';
import type { License } from 'go-modules/services/group/license';
import { Product } from './product';
import { DataTransformer } from 'go-modules/models/common/data-transformer';
import type { CourseGroup } from 'go-modules/models/group-dep/group.interface';
import { CourseFolder } from 'ngx/go-modules/src/services/course/interfaces/course-folder.interface';

export interface BillingOptions {
	products: Product[];
	licenses: License[];
}

export interface BillingEntity {
	id: number;
	entity_type: string;
	upload_limit: number;
}

interface IdMap {
	[key: number]: number;
}

interface OrgUpdate {
	name: string;
	is_public: boolean;
	org_type: string;
	block_presenter_downloads: boolean;
}

/* @ngInject */
export class GroupService {
	constructor (private $http: ng.IHttpService) {}

	public getGroup (groupId: number): ng.IPromise<any> {
		return this.$http.get(`${clientSettings.GoReactV2API}/groups/${groupId}`)
			.then((response: ng.IHttpResponse<any>) => {
				return response.data;
			});
	}

	public getProducts (groupId: number): ng.IPromise<Product[]> {
		return this.$http.get(`${clientSettings.GoReactV2API}/courses/${groupId}/products`)
			.then((response: ng.IHttpResponse<Product[]>) => {
				return response.data;
			});
	}

	public getCourseUsersList (courseId: number): ng.IPromise<any> {
		return this.$http.get(`${clientSettings.GoReactV2API}/courses/${courseId}/users`)
			.then((response: ng.IHttpResponse<any>) => {
				return response.data;
			});
	}

	public consumeCredit (groupId: number): ng.IPromise<BillingEntity> {
		return this.$http.post(`${clientSettings.GoReactV2API}/courses/${groupId}/consume_credit`, null).then((response: ng.IHttpResponse<BillingEntity>) => {
			return response.data;
		});
	}

	public getEligibleBilling (groupId: number): ng.IPromise<BillingOptions> {
		return this.$http.get(
			`${clientSettings.GoReactV2API}/groups/${groupId}/eligible-billing`
		).then((response: ng.IHttpResponse<BillingOptions>) => {
			const data = response.data;
			data.licenses.forEach((license) => ['starts_at', 'ends_at', 'current_access_date'].forEach((prop) => license[prop] = DataTransformer.transformToDate(license, prop)));
			return data;
		});
	}

	public saveCourse (payload: any): ng.IPromise<CourseGroup> {
		if (payload.group_id > 0) {
			return this.updateCourse(payload);
		}

		return this.createCourse(payload);
	}

	public createCourse (payload: any): ng.IPromise<CourseGroup> {
		return this.$http.post<CourseGroup>(
			`${clientSettings.GoReactV2API}/accounts/${payload.parent_id}/courses`,
			payload
		).then((response) => {
			return response.data;
		});
	}

	public updateCourse (payload: any): ng.IPromise<CourseGroup> {
		return this.$http.put<CourseGroup>(
			`${clientSettings.GoReactV2API}/courses/${payload.group_id}`,
			payload
		).then((response) => {
			return response.data;
		});
	}

	public copyActivities (copyFrom: number, copyTo: number): ng.IPromise<IdMap> {
		return this.$http.post(
			`${clientSettings.GoReactV2API}/courses/${copyFrom}/activities:copy`,
			{ targetGroup: copyTo }
		).then((response: ng.IHttpResponse<IdMap>) => {
			return response.data;
		});
	}

	public updateOrg (orgId: number, orgUpdate: OrgUpdate): ng.IPromise<void> {
		return this.$http.put(
			`${clientSettings.GoReactV2API}/orgs/${orgId}`,
			orgUpdate
		).then((response: ng.IHttpResponse<void>) => {
			return response.data;
		});
	}

	public getCourses (orgId: number) {
		return this.$http.get<CourseFolder[]>(`${clientSettings.GoReactV2API}/orgs/${orgId}/folders`).then((response) => {
			const data = response.data;
			data.forEach((group) => ['end_date'].forEach((prop) => group[prop] = DataTransformer.transformToDate(group, prop)));
			return data;
		});
	}

	public getLicense (groupId: number): ng.IPromise<any> {
		return this.$http.get(`${clientSettings.GoReactV2API}/courses/${groupId}/license`)
			.then((response: ng.IHttpResponse<any>) => {
				if (response.data) {
					response.data.starts_at = dayjs.utc(response.data.starts_at).toDate();
					response.data.ends_at = dayjs.utc(response.data.ends_at).toDate();
					response.data.current_access_date = dayjs
						.utc(response.data.current_access_date).toDate();
					return response.data;
				}
				return null;
			});
	}

	public getAccount (groupId: number): ng.IPromise<any> {
		return this.$http.get(`${clientSettings.GoReactV2API}/groups/${groupId}`).then((response) => {
			return response.data;
		});
	}
}
