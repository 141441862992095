import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { LibraryCollectionPermission } from 'ngx/go-modules/src/interfaces/library/library-collection';
import { CreateMarkerSetPayload, MarkerSet } from 'ngx/go-modules/src/interfaces/markers/marker';
import { LIBRARY_TYPES } from '../../components/library/library-collections-viewer/library-collection-viewer.constants';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { ENVIRONMENTS } from '../environment-vars/environments';
import { NgxAuthService } from '../auth/auth.service';
import { EnvironmentVarsService } from '../environment-vars/environment-vars.service';

export interface ShareLibraryItemResponse {
	invalidUsers: string[];
}

export interface CollectionPermission {
	id: number;
	email: string;
}

export interface LibraryCollectionFolder {
	id: number;
	name: string;
	library_collection_id: number;
	created_at: string;
	updated_at: string;
	created_by: number;
}

@Injectable({
	providedIn: 'root'
})
export class LibraryService {
	public environmentVarsService: EnvironmentVarsService;

	constructor (
		private http: HttpClient,
		private authService: NgxAuthService,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(userServiceToken) private userService: UserService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public getRubricItemUsage (rubricId: number, orgId: number): Observable<any> {
		return this.http.get<any>(`${clientSettings.GoReactV2API}/collection-items/rubric/${rubricId}/usage?org_id=${orgId}`);
	}

	public copyCollectionItem (payload: any): Observable<any>  {
		return this.http.post<any>(
			`${clientSettings.GoReactV2API}/collection-items/batch-copy`,
			payload
		);
	}

	public moveCollectionItem (payload: any): Observable<any>  {
		return this.http.put<any>(
			`${clientSettings.GoReactV2API}/collection-items/move`,
			payload
		);
	}

	public removeCollectionItem (collectionId, items): Observable<any> {
		const payload = {
			collectionId,
			itemIds: []
		} as any;

		items.forEach( (item) => payload.itemIds.push(item.id));

		const headers = new HttpHeaders();

		return this.http.delete<any>(`${clientSettings.GoReactV2API}/collection-items/delete`, {headers, body: payload});
	}

	public checkRubricUsage (rubricId: number): Observable<any> {
		return this.http.get<any>(`${clientSettings.GoReactV2API}/rubric_templates/${rubricId}/usage`);
	}

	public shareCollectionItem (payload: any): Observable<ShareLibraryItemResponse> {
		return this.http.post<ShareLibraryItemResponse>(
			`${clientSettings.GoReactV2API}/collection-items/batch-share`,
			payload
		);
	}

	public removeFolder (folderId: number): Observable<any> {
		return this.http.delete(`${clientSettings.GoReactV2API}/collection-folders/${folderId}`);
	}

	public updateCollection (collectionID: number, payload: any): Observable<any> {
		return this.http.put(
			`${clientSettings.GoReactV2API}/library-collections/${collectionID}`,
			payload
		);
	}

	public removeCollectionPermission (permissions: LibraryCollectionPermission[]): Observable<any> {
		const payload = {
			permissionIds: []
		} as any;

		permissions.forEach( (permission) => {
			payload.permissionIds.push(permission.id);
		});

		const headers = new HttpHeaders();

		return this.http.delete(`${clientSettings.GoReactV2API}/collection-permissions/batch-remove`, {
			headers,
			body: payload
		});
	}

	public inviteToCollection (collectionID: number, payload: any): Observable<any> {
		return this.http.post(
			`${clientSettings.GoReactV2API}/library-collections/${collectionID}/invite`,
			payload
		);
	}

	public createCollection (payload: any): Observable<any> {
		return this.http.post(
			`${clientSettings.GoReactV2API}/library-collections`,
			payload
		);
	}

	public updateCollectionPermission (payload: any): Observable<any> {
		return this.http.put(
			`${clientSettings.GoReactV2API}/collection-permissions/batch-update`,
			payload
		);
	}

	public removeCollection (collection): Observable<any> {
		return this.http.delete(`${clientSettings.GoReactV2API}/library-collections/${collection.id}`);
	}

	public getCollections (): Observable<any> {
		return this.http.get(`${clientSettings.GoReactV2API}/library-collections`);
	}

	public getCollectionItemTypes (): Observable<any> {
		return this.http.get(`${clientSettings.GoReactV2API}/library-collection-item-types`);
	}

	public getCollectionItems (collection, type): Observable<any> {
		return this.http.get(`${clientSettings.GoReactV2API}/collection-items/${collection}/${type}`);
	}

	public getCollectionFolders (collectionID: number): Observable<any> {
		return this.http.get(`${clientSettings.GoReactV2API}/collection-folders/${collectionID}`);
	}

	public createFolder (collectionId: number, folderName: string): Observable<any> {
		return this.http.post(`${clientSettings.GoReactV2API}/collection-folders/${collectionId}`, {
			name: folderName
		});
	}

	public editFolder (folderId: number, folderName: string): Observable<any> {
		return this.http.put(`${clientSettings.GoReactV2API}/collection-folders/${folderId}`, {
			name: folderName
		});
	}

	public createMarkerSet (payload: CreateMarkerSetPayload): Observable<MarkerSet> {
		return this.http.post<MarkerSet>(`${clientSettings.GoReactV2API}/tag_sets`, payload);
	}

	public updateMarkerSet (markerSetId: number, payload: MarkerSet): Observable<MarkerSet> {
		return this.http.put<MarkerSet>(`${clientSettings.GoReactV2API}/tag_sets/${markerSetId}`, payload);
	}

	public isOrgCollectionAndCanModifyItems (selectedCollection): boolean {
		// only care about org collections here
		if (selectedCollection.type !== LIBRARY_TYPES.ORG ) {
			return false;
		}
		// lti use cookie since is_admin not set on orgs
		if (this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI)) {
			return this.authService.isAdmin();
		}
		// root user can always modify
		if (this.userService.currentUser.is_root_user) {
			return true;
		}
		const orgToManage = this.selectedService.getMyOrgs().find((org) => org.group_id === selectedCollection.org_id);
		return orgToManage.is_admin;
	}

	public getCollectionItemDetails (collectionItem): Observable<any> {
		return this.http.get(`${clientSettings.GoReactV2API}/collection-items/${collectionItem}`);
	}
}
