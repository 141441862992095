import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/* @ngInject */
export function FineUploaderFileController ($scope, $timeout, eventService: EventService) {
	const vm = this;
	let file;
	let destroy$: Subject<void>;

	vm.$onInit = () => {
		destroy$ = new Subject<void>();
		// initialize file title to file name
		file = $scope.file;
		file.title = file.name;
		vm.mode = '';
		vm.showSlowUploadWarning = false;

		/**
		 * Cancel all event handler
		 */
		eventService.listen([
			EVENT_NAMES.FINE_UPLOADER_CANCEL_ALL,
			EVENT_NAMES.FINE_UPLOADER_SLOW_UPLOAD_SPEED,
			EVENT_NAMES.FINE_UPLOADER_NORMAL_UPLOAD_SPEED
		])
			.pipe(takeUntil(destroy$))
			.subscribe((ev: GoEvent) => {
				switch (ev.name) {
					case EVENT_NAMES.FINE_UPLOADER_CANCEL_ALL:
						const media = ev.data;
						// When the `$uploading` method is absent, this tells us that the fine uploader
						// lib has already removed the helper properties/methods from file object.
						$timeout(() => {
							if (file.hasOwnProperty('$uploading')) {
								if (file.$uploading()) {
									vm.confirmAbort();
									media.$delete();
								}
							}
						});
						break;
					case EVENT_NAMES.FINE_UPLOADER_SLOW_UPLOAD_SPEED:
						$scope.$applyAsync(()=> vm.showSlowUploadWarning = true);
						break;
					case EVENT_NAMES.FINE_UPLOADER_NORMAL_UPLOAD_SPEED:
						$scope.$applyAsync(()=> vm.showSlowUploadWarning = false);
						break;
				}
			});
	};

	vm.$onDestroy = () => {
		if (destroy$) {
			destroy$.next();
			destroy$.complete();
		}
	};

	/**
	 * Request upload abort
	 */
	vm.requestAbort = function () {
		if (file.$uploading()) {
			vm.mode = 'abort';
		} else {
			vm.confirmAbort();
		}
	};

	/**
	 * Confirm upload abort
	 */
	vm.confirmAbort = function () {
		file.$cancel();
		vm.mode = '';
	};

	/**
	 * Cancel upload abort
	 */
	vm.cancelAbort = function () {
		vm.mode = '';
	};

	/**
	 * On title change event handler
	 */
	vm.onTitleChange = function () {
		$scope.fup.extendParams({media_title: file.title}, file.$id());
	};
}
