import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { Media } from 'go-modules/services/attachment/media';
import * as Highcharts from 'highcharts';
import type { PlayerSync } from 'ngx/go-modules/src/interfaces/media/media';
import { FormatTimePipe } from 'ngx/go-modules/src/pipes/format-time/format-time.pipe';
import { Utterance } from 'ngx/go-modules/src/components/feedback-session/transcript-viewer/transcript-viewer.component';
import {
	TEASED_MEDIA_DURATION
} from 'ngx/go-modules/src/components/feedback-session/session-analytics/session-analytics.component';

@Component({
	selector: 'pace-metric-graph',
	template: require('./pace-metric-graph.component.html'),
	styles: [require('./pace-metric-graph.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaceMetricGraphComponent implements OnChanges {
	@Output() public onExpanded = new EventEmitter();

	@Input() public isExpanded: boolean;
	@Input() public media: Media;
	@Input() public playerSync: PlayerSync;
	@Input() public utterances: Utterance[];
	@Input() public shouldTease: boolean;

	@ViewChild('chart') public chartRef;

	public Highcharts: typeof Highcharts = Highcharts;
	public chartOptions: Highcharts.Options;
	public seriesData = [];

	constructor (
		public translate: TranslateService
	) {}

	public ngOnChanges () {
		this.setChartOptions();
	}

	public setChartOptions (): void
	{
		const normalWPMFloor = 120;
		const normalWPMCeil = 150;

		// destroy previous chart so it renders correctly
		this.chartRef?.ngOnDestroy();
		// reset seriesData to build it from scratch
		this.seriesData = [];
		this.utterances.forEach((utterance) => this.constructPaceData(utterance));

		this.chartOptions = {
			chart: {
				type: 'spline',
				height: 200,
				plotBackgroundColor: '#F7F7F7'
			},
			title: {
				text: ''
			},
			subtitle: {
				text: this.translate.instant('session-analytics_pace-graph-subtitle', {floor: normalWPMFloor, ceil: normalWPMCeil}),
				align: 'left'
			},
			xAxis: {
				minPadding: 0.025,
				maxPadding: 0.025,
				startOnTick: false,
				lineWidth: 0,
				tickWidth: 0,
				labels: {
					overflow: 'justify',
					// eslint-disable-next-line object-shorthand
					formatter: function () {
						const timePipe = new FormatTimePipe();
						return timePipe.transform(Number(this.value));
					}
				},
				softMin: 0,
				max: this.shouldTease ? TEASED_MEDIA_DURATION : this.media.duration
			},
			yAxis: {
				labels: {
					enabled: false
				},
				title: {
					text: null
				},
				gridLineColor: '#F7F7F7',
				plotBands: [
					{
					  from: normalWPMFloor,
					  to: normalWPMCeil,
					  color: '#F5E18C'
					}
				],
				plotLines: [
					{
						color: '#333333',
						width: 1,
						value: normalWPMFloor,
						dashStyle: 'ShortDashDot'
					},
					{
						color: '#333333',
						width: 1,
						value: normalWPMCeil,
						dashStyle: 'ShortDot'
					}
				]
			},
			colorAxis: {
				lineColor: '#707070'
			},
			legend: {
				enabled: false
			},
			plotOptions: {
				spline: {
					point: {
						events: {
							click: (e) => this.playerSync.seek(e.point.x)
						}
					}
				},
				series: {
					label: {
						connectorAllowed: false
					},
					cursor: 'pointer'
				}
			},
			tooltip: {
				outside: true,
				headerFormat: '',
				formatter: (a) => {
					const point = a.chart.hoverPoint as any;
					const timePipe = new FormatTimePipe();
					const time = timePipe.transform(point.x);
					return this.translate.instant('session-analytics_pace-graph-tooltip', {time, pace: point.y});
				}
			},
			series: this.seriesData,
			credits: {
				enabled: false
			},
			accessibility: {
				enabled: !this.shouldTease,
				description: this.translate.instant('session-analytics_pace-graph-accessibility-desc', {avg: this.getAveragePace()})
			}
		};
	}

	public getAveragePace (): number {
		if (!this.seriesData.length) {
			return 0;
		}

		const allData = this.seriesData.reduce((accumulator, currentItem) => {
			  accumulator.push(...currentItem.data);
			return accumulator;
		  }, []);

		const paceItems = allData.map((pace) => pace[1]);
		const average = paceItems.reduce((sum, value) => sum + value, 0) / paceItems.length;
		return Math.round(average);
	}

	private constructPaceData (utterance: Utterance): void {
		const plusMinusRange = 5;
		const paceData = [];

		for (let i = 0; i < utterance.words.length; i++) {
			const lower = Math.max(i - plusMinusRange, 0);
			const upper = Math.min(i + plusMinusRange, utterance.words.length - 1);
			const range = upper - lower + 1;
			const ms = utterance.words[upper].end - utterance.words[lower].start;
			const pace = this.getPaceInMinutes(range, ms);
			paceData.push([utterance.words[i].start, pace]);
		}

		this.seriesData.push({
			color: '#FF7979',
			pointStart: utterance.start,
			data: paceData
		});
	}

	private getPaceInMinutes (numWords: number, duration: number): number {
		return Math.round((numWords / duration) * 60 * 1000);
	}
}
