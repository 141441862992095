import { Component, Input } from '@angular/core';
import { BadgeColors } from './badge.constants';

@Component({
	selector: 'badge',
	template: require('./badge.component.html'),
	styles: [require('./badge.component.scss')]
})
export class BadgeComponent {
	@Input() public badgeLabel: string;
	@Input() public badgeColor: BadgeColors;

	constructor () {}
}
