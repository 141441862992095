import {
	CONTENT_TYPES,
	LIBRARY_TYPES,
	MODES,
	SORT_OPTIONS,
	SortOrderTranslations
} from './library-collection-viewer.constants';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { FullstoryService, fullstoryToken } from 'go-modules/services/fullstory/fullstory.service';
import { FULLSTORY_EVENTS } from 'go-modules/services/fullstory/fullstory.events';
import {
	ActivityEditorPanel,
	activityEditorPanelToken
} from 'go-modules/activity-editor-panel/activity-editor-panel.service';
import { finalize } from 'rxjs/operators';
import { MediaSource } from 'go-modules/models/media';
import { VideoQualityUtil } from 'ngx/go-modules/src/utilities/video-quality/video-quality.util';
import { GoSidepanelService } from 'ngx/go-modules/src/services/go-sidepanel/go-sidepanel.service';
import {
	ManageCollectionsPanelComponent
} from 'ngx/go-modules/src/components/library/manage-collections-panel/manage-collections-panel.component';
import {
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2
} from '@angular/core';
import { LibraryService } from 'ngx/go-modules/src/services/library/library.service';
import { goModal, goModalToken } from 'go-modules/modals/go-modal.factory';
import { umcChooserToken, UniversalMediaChooserFactory } from 'go-modules/universal-media-chooser/umc.factory';
import { rubricEditorModalToken } from 'go-modules/modals/rubric-editor/modal.factory';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { activityToken } from 'go-modules/models/activity/activity.factory';
import { activityTemplateModelToken } from 'go-modules/models/activity-template/activity-template.factory';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { NgxGroupService } from 'ngx/go-modules/src/services/group/group.service';
import { ZoomService } from 'ngx/go-modules/src/services/zoom/zoom.service';
import { MessageDialogComponent } from '../../dialogs/message-dialog/message-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { NgxActivityService } from 'ngx/go-modules/src/services/activity/activity.service';
import { ConfirmDialogComponent, ConfirmDialogData } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { SearchPipe } from 'ngx/go-modules/src/pipes/search/search.pipe';
import { Sizes } from 'go-modules/responsive-view/responsive-view.service';
import { NgxMediaService } from 'ngx/go-modules/src/services/media/media.service';
import { FormControl, Validators } from '@angular/forms';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import {
	DESTINATION_MODES
} from 'ngx/go-modules/src/components/library/collection-destination/collection-destination.constants';
import {
	CollectionDestinationComponent,
	CollectionDestinationData
} from 'ngx/go-modules/src/components/library/collection-destination/collection-destination.component';
import {
	ManageLibraryCollectionFolderComponent
} from '../manage-library-collection-folder/manage-library-collection-folder.component';
import { MarkerSetDialogComponent } from '../../dialogs/marker-set-dialog/marker-set-dialog.component';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
import { LibraryFilterAndSortTourService } from 'ngx/go-modules/src/tours/library-filter-and-sort-tour/library-filter-and-sort-tour.service';
import type { ContentTypeType } from 'ngx/go-modules/src/interfaces/library/library-types';
import type { LibraryCollection, LibraryCollectionItem, LibraryCollectionItemsAndFolders } from 'ngx/go-modules/src/interfaces/library/library-collection';
import { ProductTier } from 'ngx/go-modules/src/interfaces/licenses/product-tier';

@Component({
	selector: 'ngx-library-collection-viewer',
	template: require('./library-collection-viewer.component.html'),
	styles: [require('./library-collection-viewer.component.scss')]
})
export class LibraryCollectionViewerComponent implements OnInit, OnDestroy {
	public mainPanelLoading: boolean = true;
	public sidePanelLoading: boolean = true;
	public sidePanelVisible: boolean = true;
	public collectionsExpanded: boolean = false;
	public sortBy: string = SORT_OPTIONS.LASTMODIFIED;
	public sortOptions = Object.values(SORT_OPTIONS);
	public sortOrderTranslations = SortOrderTranslations;
	public collections: LibraryCollection[];
	public archiveCollection: LibraryCollection;
	public visibleCollections: LibraryCollection[];
	public selectedCollection: LibraryCollection;
	public contentTypes: ContentTypeType[];
	public selectedContentType: ContentTypeType;
	public collectionItems: LibraryCollectionItemsAndFolders;
	public expanded: string[] = [];
	public expandedFolders: number[] = [];
	public currentlySelected: any;
	public itemHeight: number = 45;
	public expandedHeight: number = 370;
	public searchStrForm = new FormControl('', Validators.minLength(2));
	public searchStr: string = '';
	private searchStrSub: Subscription;
	private previousValue: string;
	@Input() public filterType: CONTENT_TYPES;
	@Input() public mode: string;
	public modes = MODES;
	public libraryTypes = LIBRARY_TYPES;
	@Input() public previewable: boolean = false;
	@Input() public collectionManagement: boolean = true;
	@Input() public create: boolean = false;
	@Input() public openToOrg: number;
	public itemsWithoutFolder: LibraryCollectionItem[];
	@Output() public selectItem: EventEmitter<any> = new EventEmitter();
	@Output() public cancel: EventEmitter<any> = new EventEmitter();
	public multiSelect: boolean = false;
	public selectAllChecked: boolean = false;
	public orgSettings;
	public selectedGroup;
	private umc: any;
	public activityPanelIsOpen: boolean;
	public toggledItemEl: HTMLButtonElement;
	private resizeObserver: ResizeObserver;
	public shouldTrapFocusToSidepanel: boolean = false;
	public sidePanelDestroyed$$ = new Subject();
	public CONTENT_TYPES = CONTENT_TYPES;
	public contentTypesWithoutAll: ContentTypeType[];

	constructor (
		private libraryService: LibraryService,
		private ngxGoSidepanelService: GoSidepanelService,
		private modal: GoModalService,
		private dialog: MatDialog,
		private groupService: NgxGroupService,
		private elementRef: ElementRef,
		private zoomService: ZoomService,
		private translate: TranslateService,
		private activityService: NgxActivityService,
		private searchPipe: SearchPipe,
		private mediaService: NgxMediaService,
		private renderer2: Renderer2,
		private featureFlag: NgxFeatureFlagService,
		private libraryFilterAndSortTourService: LibraryFilterAndSortTourService,
		@Inject(goModalToken) private goModalService: ReturnType<typeof goModal>,
		@Inject(umcChooserToken) private UniversalMediaChooser: ReturnType<typeof UniversalMediaChooserFactory>,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(rubricEditorModalToken) private rubricEditorModal,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(fullstoryToken) private fullstoryService: FullstoryService,
		@Inject(activityToken) private ActivityModel,
		@Inject(activityTemplateModelToken) private ActivityTemplateModel,
		@Inject(activityEditorPanelToken) private activityEditorPanel: ActivityEditorPanel
	) {}

	public ngOnInit () {
		if (!this.isAllLibraryFlagEnabled()) {
			this.sortOptions = this.sortOptions.filter((option) =>
				option !== SORT_OPTIONS.NEWEST && option !== SORT_OPTIONS.OLDEST
			);
		}

		this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_LOADED, {});
		if (this.mode === this.modes.SELECT) {
			this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_SELECT_MODE, {});
		}
		this.selectedGroup = this.selectedService.getGroup() || this.selectedService.getAccount();

		forkJoin([
			this.groupService.getOrgSettings(this.selectedService.getOrg().group_id),
			this.libraryService.getCollections(),
			this.libraryService.getCollectionItemTypes()
		]).subscribe((results) => {
			const [orgSettings, collections, types] = results;
			this.orgSettings = orgSettings;
			this.collections = collections.sort((a, b) =>
				this.findPermission(a).sort_order - this.findPermission(b).sort_order);

			// Hide My History collection on SELECT mode
			if (this.mode === MODES.SELECT) {
				this.collections = this.collections.filter((collection) =>
					collection.type !== this.libraryTypes.ARCHIVE);
			}

			this.archiveCollection = collections.filter((collection) =>
				collection.type === this.libraryTypes.ARCHIVE)[0];

			this.visibleCollections = this.collections.filter((collection) =>
				this.findPermission(collection).is_hidden === false);
			// set selectedCollection to lowest sort order, which is already sorted above
			this.selectedCollection = this.visibleCollections[0];
			const localCollectionId = localStorage.getItem('last-selected-library-collection');
			if (this.openToOrg) {
				const orgCollection = this.collections
					.find((collection) => collection.org_id === this.openToOrg);
				if (orgCollection !== undefined) {
					this.selectedCollection = orgCollection;
				}
			} else if (localCollectionId) {
				const localCollection = this.visibleCollections
					.find((collection) => collection.id === parseInt(localCollectionId, 10));
				if (localCollection !== undefined) {
					this.selectedCollection = localCollection;
				}
			}

			if (this.isAllLibraryFlagEnabled()) {
				this.contentTypes = [{ name: 'All', slug: 'all', sort_order: -1 }, ...types];
			} else {
				this.contentTypes = types;
			}
			if (this.filterType) {
				this.contentTypes = this.contentTypes.filter((type) =>
					this.filterType.includes(type.slug));
			}
			this.selectedContentType = this.contentTypes.reduce((prev, curr) =>
				prev.sort_order < curr.sort_order ? prev : curr);
			const lastContentTypeSlug = localStorage.getItem('last-selected-content-type');
			if (lastContentTypeSlug) {
				const selectedContentType = this.contentTypes.find((type) => type.slug === lastContentTypeSlug);
				if (selectedContentType !== undefined) {
					this.selectedContentType = selectedContentType;
				}
			}
			this.contentTypesWithoutAll = types.filter((type) => type.slug !== 'all');

			this.sidePanelLoading = false;

			this.libraryService.getCollectionItems(this.selectedCollection.id,
				this.selectedContentType.slug)
				.subscribe((items) => {
					this.collectionItems = items;
					if (this.previewable && this.selectedContentType.slug === CONTENT_TYPES.DOCUMENTS ) {
						this.collectionItems = {
							...items,
							items: items.items.filter(this.isPreviewable)
						};
					}
					this.mainPanelLoading = false;
					this.itemsWithoutFolder = this.findItemsInFolder(null);
				});

			if (this.create) {
				this.addActivity(this.selectedCollection, null, null);
			}
		});

		this.searchStrSub = this.searchStrForm.valueChanges.subscribe((newValue) => {
			if (this.searchStrForm.valid) {
				this.searchStr = newValue;
			}
			this.searchStrChanged(newValue, this.previousValue);
			this.previousValue = newValue;
		});

		this.observeSize();

		this.umc = this.UniversalMediaChooser.get('umcModal');

		if (this.isAllLibraryFlagEnabled() && !this.mode) {
			this.libraryFilterAndSortTourService.getTour().start();
		}
	}

	public select (event) {
		this.selectItem.emit(event.item);
	}

	public updateItems () {
		this.itemsWithoutFolder = this.findItemsInFolder(null);
	}

	public setActivityPanelIsOpen (val) {
		this.activityPanelIsOpen = val;
	}

	public setCurrentlySelected (val) {
		this.currentlySelected = val;
	}

	public setSelectAllChecked (val) {
		this.selectAllChecked = val;
	}

	public searchStrChanged (searchStr?: string, oldValue?: string) {
		if (searchStr != null && searchStr !== '' || oldValue && oldValue.length > 0 && (searchStr == null || searchStr === '')) {
			this.selectAllChecked = false;
			this.collectionItems.items.map((item) => item.checked = false);
		}
	}

	public showActionContainer () {
		const collectionType = this.selectedCollection?.type;

		if (collectionType === this.libraryTypes.MANAGED) {
			return this.userService.currentUser.is_root_content;
		}

		const excludedTypes = [
			this.libraryTypes.DIRECT_SHARE,
			this.libraryTypes.ARCHIVE,
			this.libraryTypes.LEGACY
		];

		return !excludedTypes.includes(collectionType);
	}

	public hideOnArchiveAndManaged () {
		return this.selectedCollection?.type === this.libraryTypes.ARCHIVE ||
			(this.selectedCollection?.type === this.libraryTypes.MANAGED &&
				!this.userService.currentUser.is_root_content);
	}

	public ngOnDestroy () {
		this.searchStrSub?.unsubscribe();
		this.resizeObserver.disconnect();
		this.sidePanelDestroyed$$.next(true);
		this.sidePanelDestroyed$$.complete();
	}

	private observeSize () {
		const checkSidePanel = (entries) => {
			const measurements = entries[0].contentRect;
			this.sidePanelVisible = true;
			this.expandedHeight = 370;
			for (const css of this.elementRef.nativeElement.classList.values()) {
				if (css.startsWith('resize-sensor-')) {
					this.elementRef.nativeElement.classList.remove(css);
				}
			}

			[Sizes.XXSMALL, 380, Sizes.XSMALL, Sizes.TABLET].forEach((width) => {
				if (measurements.width <= width) {
					this.elementRef.nativeElement.classList.add(`resize-sensor-${width}`);
				}
			});

			if (measurements.width <= Sizes.TABLET) {
				this.sidePanelVisible = false;
			}

			if (measurements.width >= Sizes.XSMALL) {
				this.expandedHeight = 226;
			}
		};

		this.resizeObserver = new ResizeObserver(checkSidePanel);
		this.resizeObserver.observe(this.elementRef.nativeElement);
	}

	public showOverlay () {
		return this.sidePanelVisible;
	}

	public toggleSidePanel () {
		this.sidePanelVisible = !this.sidePanelVisible;

		if (this.sidePanelVisible) {
			setTimeout(() => {
				this.shouldTrapFocusToSidepanel = true;
				const sidePanel = this.elementRef.nativeElement.querySelector('.sidepanel');
				const focusableElements = sidePanel.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');

				// Find the first focusable element
				const firstFocusable = Array.from(focusableElements)[0] as HTMLElement;

				if (firstFocusable) {
					firstFocusable.focus();
				}

				this.listenForEscape();
			});
		} else {
			this.shouldTrapFocusToSidepanel = false;
			this.toggleButtonFocus();
			this.sidePanelDestroyed$$.next(true);
			this.sidePanelDestroyed$$.complete();
			// Reinitialize the Subject after completing it
			this.sidePanelDestroyed$$ = new Subject();
		}
	}

	public toggleButtonFocus () {
		setTimeout(() => {
			const toggleButton = this.elementRef.nativeElement.querySelector('.toggle-side-panel-btn');
			if (toggleButton) {
				toggleButton.focus();
			}
		});
	}

	public selectCollection (collection) {
		if (this.selectedCollection.type !== this.libraryTypes.LEGACY && collection.type === this.libraryTypes.LEGACY) {
			this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_VIEWED_LEGACY, {});
		}
		localStorage.setItem('last-selected-library-collection', collection.id);
		this.selectedCollection = collection;
		this.mainPanelLoading = true;
		this.loadNewItems();
		if (this.multiSelect) {
			this.toggleMultiSelect();
		}
	}

	public selectContentType (type) {
		localStorage.setItem('last-selected-content-type', type.slug);
		this.selectedContentType = type;
		this.mainPanelLoading = true;
		this.loadNewItems();
		if (this.multiSelect) {
			this.toggleMultiSelect();
		}
	}

	public loadNewItems (expandItem?: any) {
		if (expandItem && this.mode === MODES.SELECT) {
			this.selectedCollection = expandItem.collection;
		}
		this.libraryService.getCollectionItems(this.selectedCollection.id,
			this.selectedContentType.slug)
			.subscribe((items) => {
				this.collectionItems = items;
				if (this.previewable && this.selectedContentType.slug === CONTENT_TYPES.DOCUMENTS ) {
					this.collectionItems = {
						...items,
						items: items.items.filter(this.isPreviewable)
					};
				}
				this.mainPanelLoading = false;
				this.expanded = [];
				this.itemsWithoutFolder = this.findItemsInFolder(null);
				if (expandItem?.folder?.id > 0 && this.mode === MODES.SELECT) {
					setTimeout(() => {
						this.expandedFolders.push(expandItem.folder.id);
					});
				}
				if (expandItem?.item && this.mode === MODES.SELECT) {
					let id;
					if (expandItem.item.media_id) {
						id = expandItem.item.media_id;
					} else if (expandItem.item.activity_id) {
						id = expandItem.item.activity_id;
					} else {
						id = expandItem.item.id;
					}

					const collectionItem = this.findItemById(id)[0];
					if (this.isAllLibraryFlagEnabled()) {
						this.libraryService.getCollectionItemDetails(collectionItem.id).subscribe((res)=> {
							collectionItem.item = res.item;
							this.expanded.push(collectionItem.hash);
						});
					} else {
						this.expanded.push(collectionItem.hash);
					}
				}
			});
	}

	public isCollectionVisible (collection) {
		return this.visibleCollections.indexOf(collection) <= 4 ||
		this.collectionsExpanded;
	}

	public toggleCollectionsExpanded () {
		this.collectionsExpanded = !this.collectionsExpanded;
		if (this.collectionsExpanded){
			setTimeout(() => {
				const element = this.elementRef.nativeElement.querySelectorAll('.collection-list-item')[5];
				element.focus();
			});
		}
	}

	public openManageCollectionsPanel () {
		this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_MANAGE_COLLECTIONS, {});
		const libraryModal = document.querySelector('.modal-content') as HTMLElement;
		this.ngxGoSidepanelService.open(ManageCollectionsPanelComponent, {
			inputs: {
				collections: [...this.collections]
			},
			noFooter: true,
			closeOnBackdropClick: true,
			closeOnEscape: true,
			panelClass: ['manage-collections-panel']
		}, libraryModal).afterClosed().subscribe((collections) => {
			if (collections) {
				this.collections = collections;
				this.selectedCollection = collections.find((collection) => {
					return collection.id === this.selectedCollection.id;
				});
				this.visibleCollections = collections.filter((collection) =>
					this.findPermission(collection)?.is_hidden === false);
			}
			const toggleButton = this.elementRef.nativeElement.querySelector('.manage-collections-btn');
			toggleButton.focus();
		});
	}

	public getSortKey (isFolder: boolean = false) {
		let itemField: string = 'name';

		if (this.sortBy === SORT_OPTIONS.ALPHANUMERICREVERSE) {
		  itemField = '-name';
		} else if (this.sortBy === SORT_OPTIONS.LASTMODIFIED) {
		  itemField = isFolder ? '-updated_at' : '-modified_at';
		} else if (this.sortBy === SORT_OPTIONS.NEWEST) {
			itemField = '-created_at';
		} else if (this.sortBy === SORT_OPTIONS.OLDEST) {
			itemField = 'created_at';
		}

		return itemField;
	}

	public getResultsCount () {
		return this.searchPipe.transform(this.collectionItems.items, this.searchStr, ['name', 'description', 'creator_name']).length;
	}

	public clearSearch () {
		this.searchStrForm.setValue('');
		setTimeout(() => {
			this.elementRef.nativeElement.querySelector('.search-input').focus();
		});
	}

	public addLibraryItem (selectedType) {
		this.clearSearch();

		const destinationModalData: CollectionDestinationData = {
			collections: this.collections,
			selectedCollection: this.selectedCollection,
			contentType: selectedType.slug,
			mode: DESTINATION_MODES.ADD,
			defaultFolderId: null,
			allowSkip: this.mode === MODES.SELECT && selectedType.slug !== CONTENT_TYPES.ACTIVITIES
		};
		if (this.currentlySelected?.id) {
			destinationModalData.defaultFolderId = this.currentlySelected.id;
		} else if (this.currentlySelected && this.currentlySelected.hash && this.currentlySelected?.folderId) {
			destinationModalData.defaultFolderId = this.currentlySelected.folderId;
		}

		this.modal.open(CollectionDestinationComponent, false, {
			data: destinationModalData
		}).afterClosed().subscribe((res) => {
			const toggleButton = this.elementRef.nativeElement.querySelector('.add-btn');
			toggleButton.focus();
			if (res.dismissed) {
				if (res.hasFolderAdded) {
					if (res.collection.id === this.selectedCollection.id) {
						this.loadNewItems();
					}
				}
				return;
			}
			const {collection, folder, autoSelect} = res;
			const modalData = {
				options: {}
			};
			let addToFolder = null;
			if (folder?.id > 0){
				addToFolder = folder.id;
			}

			if (selectedType.slug === CONTENT_TYPES.MEDIA ||
				selectedType.slug === CONTENT_TYPES.DOCUMENTS) {
				if (selectedType.slug === CONTENT_TYPES.MEDIA){
					const videoQuality = this.selectedService.getLicense()?.salesforce_license.video_quality ??
						VideoQualityUtil.MINIMUM_RESOLUTION;

					modalData.options = {
						defaultSection: this.umc.MEDIA_TYPE.UPLOAD,
						headerOptions: [
							this.umc.MEDIA_TYPE.RECORD_VIDEO,
							this.umc.MEDIA_TYPE.UPLOAD,
							this.umc.MEDIA_TYPE.YOUTUBE,
							this.umc.MEDIA_TYPE.RECORD_AUDIO,
							this.umc.MEDIA_TYPE.ZOOM
						],
						chooseAutomatically: true,
						groupId: this.selectedGroup.group_id,
						goRecorder: {
							videoQuality
						},
						uploadSizeLimit: this.selectedGroup.upload_limit ?
							this.selectedGroup.upload_limit * 1024 * 1024 : null,
						addToCollection: collection?.id,
						addToFolder
					};
				} else {
					modalData.options = {
						blacklist: 'fine-uploader/src/partials/dangerous-file-blacklist.json',
						defaultSection: this.umc.MEDIA_TYPE.UPLOAD,
						headerOptions: [
							this.umc.MEDIA_TYPE.UPLOAD
						],
						chooseAutomatically: true,
						groupId: this.selectedGroup.group_id,
						uploadSizeLimit: this.selectedGroup.upload_limit ?
							this.selectedGroup.upload_limit * 1024 * 1024 : null,
						addToCollection: collection?.id,
						addToFolder,
						uploadMinSizeLimit: 1,
						mediaType: selectedType.slug
					};
				}
				this.goModalService.open({
					modal: 'umc',
					modalData
				}).result.then((res) => {
					if (res.source === MediaSource.ZOOM_IMPORT) {
						this.zoomService.queueImport(res.media_id, {
							group_id: this.selectedGroup.group_id,
							download_url: res.zoom_download_url,
							resource_id: this.selectedCollection.id,
							resource_type: 'library_collection'
						}).subscribe({
							next: () => {},
							error: () => {
								this.dialog.open(MessageDialogComponent, {
									data: {
										title: this.translate.instant('zoom-queue-recordings-error_title'),
										message: this.translate.instant('zoom-queue-recordings-error_message')
									}
								});
							}
						});
					}

					if (autoSelect) {
						this.selectItem.emit({item: res});
					} else {
						this.loadNewItems({collection, folder, item: res});
					}
				}).catch(() => {});
			} else if (selectedType.slug === CONTENT_TYPES.RUBRICS) {
				modalData.options = {
					schemaId: null,
					mode: 'edit',
					postData: {
						group_id: this.selectedGroup.group_id,
						add_to_collection: collection?.id,
						add_to_folder: addToFolder
					}
				};

				this.rubricEditorModal.open({
					modalData
				}).result.then((res) => {
					if (autoSelect) {
						this.selectItem.emit({item: res});
					} else {
						this.loadNewItems({collection, folder, item: res});
					}
				}).catch(() => {});
			} else if (selectedType.slug === CONTENT_TYPES.MARKERS) {
				this.modal.open(MarkerSetDialogComponent, false, {
					data: {
						addToCollection: collection?.id,
						addToFolder
					}
				}).afterClosed().subscribe((res) => {
					if (!res.dismissed) {
						if (autoSelect) {
							this.selectItem.emit({item: res});
						} else {
							this.loadNewItems({collection, folder, item: res});
						}
					}
				});
			} else if (selectedType.slug === CONTENT_TYPES.ACTIVITIES) {
				this.addActivity(collection, folder, addToFolder);
			}
		});
	}

	public openAddFolderModal () {
		this.clearSearch();

		this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_CREATE_FOLDER_CLICK, {});

		if (this.collectionItems.folders.length >= 100) {
			return this.dialog.open(MessageDialogComponent, {
				data: {
					title: this.translate.instant('library-collections_max-folders-title'),
					message: this.translate.instant('library-collections_max-folders-message')
				}
			});
		}

		return this.modal.open(ManageLibraryCollectionFolderComponent, false, {
			data: {
				createMode: true,
				existingFolders: this.collectionItems.folders,
				selectedCollection: this.selectedCollection
			}
		}).afterClosed().subscribe((res) => {
			const toggleButton = this.elementRef.nativeElement.querySelector('.add-folder-btn');
			toggleButton.focus();
			if (res.dismissed) {
				return;
			}
			if (res.request_failed) {
				return this.dialog.open(MessageDialogComponent, {
					data: {
						title: this.translate.instant('library-collections_add-folder-fail-title'),
						message: this.translate.instant('library-collections_add-folder-fail-message')
					}
				});
			}
			const newFolder = res;
			this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_FOLDER_CREATED, {});
			this.collectionItems.folders = [...this.collectionItems.folders, newFolder];
		});
	}

	public toggleMultiSelect () {
		if (!this.multiSelect) {
			this.selectAllChecked = false;
			this.collectionItems.items.map((item) => {
				item.checked = false;
			});
		}
	}

	public setActiveToggle ($event) {
		this.toggledItemEl = $event.currentTarget;
	}

	public selectAll () {
		this.collectionItems.items.map((item) => {
			item.checked = this.selectAllChecked;
		});
		if (this.selectAllChecked) {
			this.collectionItems.folders.map((folder) => {
				this.expandedFolders.push(folder.id);
			});
		}
		else {
			this.expandedFolders = [];
		}
	}

	public copyItems () {
		const checkedItems = this.collectionItems.items.filter((item) => item.checked);
		if (!checkedItems.length) {
			return this.dialog.open(MessageDialogComponent, {
				data: {
					title: this.translate.instant('library-collections_multi-select-none-selected'),
					message: this.translate.instant('library-collections_multi-select-none-selected-message')
				}
			});
		}
		const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: this.translate.instant('library-collections_multi-select-batch-copy-title'),
				message: this.translate.instant('library-collections_multi-select-batch-copy-message'),
				confirmText: this.translate.instant('common_continue'),
				cancelText: this.translate.instant('common_cancel')
			} as ConfirmDialogData
		});

		dialogRef.afterClosed().subscribe((confirm) => {
			if (confirm) {
				const destinationData: CollectionDestinationData = {
					collections: this.collections,
					selectedCollection: this.selectedCollection,
					contentType: this.selectedContentType.slug,
					mode: DESTINATION_MODES.COPY
				};
				this.modal.open(CollectionDestinationComponent, false, {
					data: destinationData
				}).afterClosed().subscribe((res) => {
					if (res.dismissed) {
						if (res.hasFolderAdded) {
							if (res.collection.id === this.selectedCollection.id) {
								this.loadNewItems();
							}
						}
						return;
					}
					const {collection, folder} = res;
					this.mainPanelLoading = true;
					let items = [];
					checkedItems.map((item, i) => {
						items = [...items, {
							collections: [{collection_id: collection.id}]
						}];

						if (folder?.id > 0) {
							items[i].collections[0].folder_id = folder.id;
						}

						// Handle items from archive that may not
						// have an actual libraryItem
						if (item.id) {
							items[i].itemId = item.id;
						} else {
							items[i].item = {
								item_id: item.item_id,
								item_type: item.collection_item_type
							};
						}
					});

					this.libraryService.copyCollectionItem({
						items
					}).subscribe(() => {
						if (this.selectedCollection.id === collection.id){
							this.loadNewItems();
						}
						this.selectAllChecked = false;
						this.mainPanelLoading = false;
					});
				});
			}
		});
	}

	public moveItems () {
		const checkedItems = this.collectionItems.items.filter((item) => item.checked);
		if (!checkedItems.length) {
			return this.dialog.open(MessageDialogComponent, {
				data: {
					title: this.translate.instant('library-collections_multi-select-none-selected'),
					message: this.translate.instant('library-collections_multi-select-none-selected-message')
				}
			});
		}
		const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: this.translate.instant('library-collections_multi-select-batch-move-title'),
				message: this.translate.instant('library-collections_multi-select-batch-move-message'),
				confirmText: this.translate.instant('common_continue'),
				cancelText: this.translate.instant('common_cancel')
			} as ConfirmDialogData
		});

		dialogRef.afterClosed().subscribe((confirm) => {
			if (confirm) {
				const destinationData: CollectionDestinationData = {
					collections: this.collections,
					selectedCollection: this.selectedCollection,
					contentType: this.selectedContentType.slug,
					mode: DESTINATION_MODES.MOVE
				};
				this.modal.open(CollectionDestinationComponent, false, {
					data: destinationData
				}).afterClosed().subscribe((res) => {
					if (res.dismissed) {
						if (res.hasFolderAdded) {
							if (res.collection.id === this.selectedCollection.id) {
								this.loadNewItems();
							}
						}
						return;
					}
					const {collection, folder} = res;
					this.mainPanelLoading = true;
					let items = [];
					checkedItems.map((item) => {
						items = [...items, {
							library_collection_id: this.selectedCollection.id,
							library_collection_folder_id: item.library_collection_folder_id,
							library_collection_item_id: item.id
						}];
					});
					const data = {
						new_library_collection_id: collection.id,
						new_library_collection_folder_id: folder?.id ?? null,
						items
					};
					this.libraryService.moveCollectionItem(data)
						.pipe(
							finalize(() => {
								this.mainPanelLoading = false;
							})
						)
						.subscribe({
							next: (res) => {
								this.loadNewItems();
								this.selectAllChecked = false;
								if (res.itemsNotMoved.length) {
									this.dialog.open(MessageDialogComponent, {
										data: {
											title: this.translate.instant('library-collections_item_move_error-title'),
											message: this.translate.instant('library-collections_item_move_error-batch-message')
										}
									});
								}
							},
							error: () => {
								return this.dialog.open(MessageDialogComponent, {
									data: {
										title: this.translate.instant('library-collections_item_move_error-title'),
										message: this.translate.instant('library-collections_item_move_error-batch-message')
									}
								});
							}
						});
				});
			}
		});
	}

	public removeItems () {
		const checkedItems = this.collectionItems.items.filter((item) => item.checked);
		if (!checkedItems.length) {
			return this.dialog.open(MessageDialogComponent, {
				data: {
					title: this.translate.instant('library-collections_multi-select-none-selected'),
					message: this.translate.instant('library-collections_multi-select-none-selected-message')
				}
			});
		}
		const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: this.translate.instant('library-collections_multi-select-batch-remove-title'),
				message: this.translate.instant('library-collections_multi-select-batch-remove-message'),
				confirmText: this.translate.instant('common_remove'),
				cancelText: this.translate.instant('common_cancel')
			} as ConfirmDialogData
		});

		dialogRef.afterClosed().subscribe((confirm) => {
			if (confirm) {
				this.mainPanelLoading = true;
				this.libraryService.removeCollectionItem(this.selectedCollection.id, checkedItems)
					.pipe(
						finalize(() => {
							this.mainPanelLoading = false;
						})
					)
					.subscribe({
						next: (res) => {
							this.loadNewItems();
							this.selectAllChecked = false;
							if (res.itemsNotDeleted.length) {
								this.dialog.open(MessageDialogComponent, {
									data: {
										title: this.translate.instant('library-collections_item_remove_error-title'),
										message: this.translate.instant('library-collections_item_remove_error-batch-message')
									}
								});
							}
						},
						error: () => {
							return this.dialog.open(MessageDialogComponent, {
								data: {
									title: this.translate.instant('library-collections_item_remove_error-title'),
									message: this.translate.instant('library-collections_item_remove_error-batch-message')
								}
							});
						}
					});
			}
		});
	}

	public isContentTypeSelected (contentType) {
		return this.selectedContentType?.slug === contentType.slug;
	}

	public isAllLibraryFlagEnabled () {
		return this.featureFlag.isAvailable('ALL_LIBRARY');
	}

	private isPreviewable = (item) => {
		return this.mediaService.isPreviewable(this.isAllLibraryFlagEnabled() ? {
			media_status: item.media_status,
			media_type: item.media_type,
			filename: item.filename
		} : item.item);
	};

	private findPermission = (collection) => {
		return collection.library_collection_permissions.find((permission) =>
			permission.user_id === this.userService.currentUser.user_id);
	};

	private findItemsInFolder = (folderId): LibraryCollectionItem[] => {
		return this.collectionItems.items.filter((item) => {
			return item.library_collection_folder_id === folderId;
		});
	};

	private findItemById = (id) => {
		return this.collectionItems.items.filter((item) => {
			return item.item_id === id;
		});
	};

	private listenForEscape () {
		const closeOnEscapeKeyHandler = ($event: KeyboardEvent) => {
			if ($event.key === 'Escape') {
				this.toggleSidePanel();
			}
		};

		const keydownEventDestroyFn = this.renderer2.listen(window, 'keydown', closeOnEscapeKeyHandler);

		this.sidePanelDestroyed$$.subscribe({
			complete: () => {
				keydownEventDestroyFn();
			}
		});
	}

	private addActivity (collection, folder, addToFolder) {
		this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_ADD_ASSIGNMENT, {});
		const headerOptions: any = {
			editMode: false,
			hideName: false
		};

		this.activityService.getDefault()
			.subscribe((defaultActivity) => {
				const activity = this.ActivityModel.useAsTemplate(this.ActivityModel.model(defaultActivity.activity));
				activity.populateEmptyActivityOnGroup(this.selectedGroup);
				activity.add_to_collection = collection.id;
				activity.add_to_folder = addToFolder;
				if (!activity.template.id) {
					if (activity.activity_template_id) {
						const template = this.ActivityTemplateModel.model({
							id: activity.activity_template_id
						});
						activity.setActivityTemplate(template);
					} else {
						this.ActivityTemplateModel.query().$promise.then((templates) => {
							const template = templates.find((t) =>
								t.slug === this.ActivityTemplateModel.TYPE.STANDARD);
							activity.setActivityTemplate(template);
						});
					}
				}

				const license = this.selectedService.getLicense();
				if (this.featureFlag.isAvailable('AI_DEFAULT') && license?.salesforce_license.tier_name === ProductTier.Plus) {
					activity.ai_prompts = defaultActivity.aiUseType?.defaultAiPrompts;
				}

				this.activityPanelIsOpen = true;
				const isOrgCollectionAndCanModifyItems =
					this.libraryService.isOrgCollectionAndCanModifyItems(collection);

				return this.activityEditorPanel.open({
					user: this.userService.currentUser,
					group: this.selectedGroup,
					activity,
					headerOptions,
					libraryMode: true,
					isOrgCollectionAndCanModifyItems,
					orgSettings: this.orgSettings,
					firstFocusSelector: '#activity-editor-name-input'
				}).result.then((newActivity) => {
					this.activityPanelIsOpen = false;
					this.loadNewItems({collection, folder, item: newActivity});
				}).catch(() => {})
					.finally(() => {
						this.activityPanelIsOpen = false;
					});
			});
	}
}
