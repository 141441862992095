import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { Observable } from 'rxjs';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';

export interface AiPrompt {
	id: number;
	name: string;
	description: string;
	tag: any;
	use_types: any;
	licenses?: any;
	checked?: boolean;
}

export interface Language {
	language_code: string;
	language_name: string;
}

@Injectable({
	providedIn: 'root'
})
export class NgxAiPromptService {
	constructor (
		private http: HttpClient,
		@Inject(selectedServiceToken) public selectedService: SelectedService
	) {}

	public all (): Observable<AiPrompt[]> {
		const group = this.selectedService.getGroup();
		return this.http.get<AiPrompt[]>(`${clientSettings.GoReactV2API}/groups/${group.group_id}/ai-prompts`);
	}

	public getPrompts (group): Observable<AiPrompt[]> {
		return this.http.get<AiPrompt[]>(`${clientSettings.GoReactV2API}/groups/${group.group_id}/ai-prompts`);
	}

	public languages (): Observable<Language[]> {
		return this.http.get<Language[]>(`${clientSettings.GoReactV2API}/assembly-ai/supported-languages`);
	}
}
