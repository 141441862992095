const subDomain = 'https://help.goreact.com';

export const helpUrlsLookup = {
	target: '_blank',
	helpCenter: `${subDomain}`,
	activityHelp: `${subDomain}/hc/en-us/articles/360002945831`,
	chooseActivityTypeHelp: `${subDomain}/hc/en-us/articles/360002945831`,
	activityMarkersHelp: `${subDomain}/hc/en-us/articles/360003085771`,
	deprecatedLaunch: `${subDomain}/hc/en-us/articles/29332328355213-Unresolved-Deprecations-LTI-1-1`,
	userPermsHelp: `${subDomain}/hc/en-us/articles/360002909631`,
	chromeBugIssues: `${subDomain}/hc/en-us/articles/360018806691#Chromebooks`,
	rubricHelp: `${subDomain}/hc/en-us/articles/360003085551`,
	studentRubrics: `${subDomain}/hc/en-us/articles/360003085551#Student_Rubrics`,
	videoRecordingList: `${subDomain}/hc/en-us/articles/360003496891`,
	recordVideoTroubleshoot: `${subDomain}/hc/en-us/articles/360018806691`,
	videoTroubleshoot: `${subDomain}/hc/en-us/articles/360004957092`, // equipment check
	cameraTroubleshoot: `${subDomain}/hc/en-us/articles/360019099751`,
	microphoneTroubleshoot: `${subDomain}/hc/en-us/articles/360020089991`,
	supportedBrowserTroubleshoot: `${subDomain}/hc/en-us/articles/360017524832`,
	networkTroubleshoot: `${subDomain}/hc/en-us/articles/360028451291`,
	sourceMediaHelp: `${subDomain}/hc/en-us/articles/360002946471`,
	captions: `${subDomain}/hc/en-us/articles/360002895492`,
	cameraFixHelp: `${subDomain}/hc/en-us/articles/360019099751`,
	submitSupportRequest: `${subDomain}/hc/en-us/requests/new?instructor=true`,
	inviteHelp: `${subDomain}/hc/en-us/articles/360002909631`,
	security: `${subDomain}/hc/en-us/articles/115000273226`,
	contactSupport: `${subDomain}/hc/en-us/requests/new?instructor=true`,
	lmsIntegrationDetails: `${subDomain}/hc/en-us/articles/115005055823`,
	blackboardSetup: `${subDomain}/hc/en-us/articles/360003784432`,
	brightspaceSetup: `${subDomain}/hc/en-us/articles/360003809372`,
	canvasSetup: `${subDomain}/hc/en-us/categories/360001398752`,
	moodleSetup: `${subDomain}/hc/en-us/articles/360003826751`,
	schoologySetup: `${subDomain}/hc/en-us/articles/360004148812`,
	instructorHelp: `${subDomain}/hc/en-us/categories/115000013563`,
	instructorSupport: 'https://goreact.com/instructor',
	feedbackHold: `${subDomain}/hc/en-us/articles/360028621112-Feedback-Hold`,
	multipleCameras: `${subDomain}/hc/en-us/articles/360021009852`,
	accessibility: `${subDomain}/hc/en-us/articles/360033491191-VPAT-2-3-508-Compliance`,
	masterActivity: `${subDomain}/hc/en-us/articles/30867194759309`
};
