import * as angular from 'angular';
import { noop } from 'angular';
import { AuthService } from 'go-modules/services/auth/auth.service';
import { clientSettings } from '../../models/common/client.settings';
import { MessageModal } from '../message/modal.factory';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';

/* @ngInject */
export function EditUserProfileModalController (
	$log: angular.ILogService,
	$scope,
	$location,
	User,
	Group,
	authService: AuthService,
	goModal,
	messageModal: MessageModal,
	UserGroupModel,
	masquerade,
	$translate,
	$filter,
	helpUrls,
	ngxDowngradeModalService: DowngradeModalService
) {
	const vm = this;

	/**
	 * Controller init life cycle hook
	 */
	vm.$onInit = function () {
		// get all roles excluding the owner role
		if ($scope.group) {
			$scope.roles = Group.roles.filter(function (role) {
				return role !== Group.role.OWNER
					&& Group.rolePrecedence[role] >= Group.rolePrecedence[$scope.group.role];
			}).map((role) => {
				// These keys are being used dynamically here. This comment helps the un-used translations check
				// modal-invite-users_controller-invite-confirm-role-presenter
				// modal-invite-users_controller-invite-confirm-role-reviewer
				// modal-invite-users_controller-invite-confirm-role-instructor
				// modal-invite-users_controller-invite-confirm-role-admin
				return {
					role,
					roleKey: `modal-invite-users_controller-invite-confirm-role-${role}`
				};
			});
		}

		$scope.adminToolsUrl = clientSettings.AdminToolsUrl;

		$scope.userCopy = angular.copy($scope.user);

		// Expose helpUrls
		$scope.helpUrls = helpUrls;

		// Save any role information
		$scope.userData = $scope.user;

		// Mask redirect url
		$scope.redirectUrl = encodeURI($location.absUrl());

		// get groups by user
		Group.getByUser({user_id: $scope.user.user_id})
			.$promise.then(function (groups) {
			// Sort groups by start date
				$scope.groups = $filter('orderBy')(groups, ['-start_date']);
			}).catch($log.error);

		// Load root specific requests
		if ($scope.currentUser.is_root_user) {
			// get credits for roots
			User.getBillingBalances({userId: $scope.user.user_id})
				.$promise.then((balance) => $scope.credits = balance)
				.catch($log.error);

			// load user login history
			User.getRecentLoginHistory({user_id: $scope.user.user_id})
				.$promise.then((recentLogins) => $scope.recentLogins = recentLogins)
				.catch($log.error);

			// load linked users
			User.getLinkedUsers({user_id: $scope.user.user_id})
				.$promise.then((linkedUsers) => $scope.linkedUsers = linkedUsers)
				.catch($log.error);

			User.get({user_id: $scope.user.user_id})
				.$promise.then((user) => {
					$scope.ssoProviders = user.auth_aliases.filter((alias) => {
						return alias.auth_provider.is_web_app && alias.auth_provider.auth_type === 'oauth';
					}).map((alias) => alias.auth_provider.provider_name)
						.join(', ');
				})
				.catch($log.error);
		}

		// Load user only actions
		if($scope.currentUser.user_id === $scope.user.user_id) {
			// check to see if user is internal to goreact,
			// meaning they have a goreact auth provider
			authService.hasGoreactProvider($scope.user.user_id)
				.then((response) => $scope.isInternal = response.data.hasGoreactProvider)
				.catch($log.error);
		}

		// load current user role for group
		if ($scope.group) {
			UserGroupModel.get({
				user_id: $scope.currentUser.user_id, group_id: $scope.group.group_id
			}, function (userGroup) {
				$scope.currentUserGroup = Group.model(userGroup);
			});
		}
	};

	/**
	 * Whether has paid icon should be shown
	 *
	 * @returns {boolean}
	 */
	$scope.showHasPaid = function (group) {
		return group.requiresPayment() &&
			group.hasPaid() &&
			group.hasPresenterRole();
	};

	/**
	 * Whether has not paid icon should be shown
	 *
	 * @returns {boolean}
	 */
	$scope.showHasNotPaid = function (group) {
		return group.requiresPayment() &&
			!group.hasPaid() &&
			group.hasPresenterRole();
	};

	$scope.canEditUserInfo = function () {
		return $scope.currentUser.user_id === $scope.userData.user_id;
	};

	$scope.editUser = function (user) {
		goModal.open({
			modal: 'editUser',
			modalData: {
				user,
				currentUser: $scope.currentUser,
				edit: false
			}
		}).result.then(function (resource) {
			if (resource) {
				$scope.$close(resource);
			}
		}).catch($log.error);
	};

	$scope.changePassword = function () {
		ngxDowngradeModalService.openChangePasswordDialog({
			user: $scope.user,
			currentUser: $scope.currentUser
		}).then((result) => {
			if (result.success === false) {
				$scope.$close();
			}
		}).catch(noop);
	};

	$scope.changeEmail = function () {
		const user = angular.copy($scope.user);
		ngxDowngradeModalService.openChangeEmailDialog({
			user
		}).then((result) => {
			if (result.success === false) {
				$scope.$close();
				return;
			}

			$scope.userCopy.email = result.email;
			$scope.user.email = result.email;
			$scope.$apply();
		}).catch(noop);
	};

	/** Save a user */
	$scope.saveUser = function (user, form) {

		if (!user || form.$invalid) {
			return;
		}

		// save the user
		user.save().then(function () {
			$scope.user.email = user.email;
			$scope.user.first_name = user.first_name;
			$scope.user.last_name = user.last_name;
			$scope.user.fullname = $scope.user.first_name + ' ' + $scope.user.last_name;
			$scope.user.language = user.language;
		}).catch($log.error);
	};

	/**
	 * Update a user role
	 *
	 * @param user
	 * @param group
	 * @param role
	 */
	$scope.updateUserRole = function (user, group, role) {
		if (!user || !group) {
			return;
		}

		// update user role in group
		const result = group.updateUserRole(user, role);

		result.$promise.then(function (userRole) {
			angular.extend(user, User.model(userRole));
		}).catch(function () {
			if (user.revert) {
				user.role = user.revert.role;
			}
		});
	};

	/**
	 * On user moved event handler
	 *
	 * @param groupTo
	 */
	$scope.onUserMoved = function (groupTo) {
		// redirect to destination group
		$location.path('/course/' + groupTo.group_id + '/settings/users');
		$scope.$dismiss();
	};

	/**
	 * Show activity history modal
	 */
	$scope.activityHistory = function () {
		goModal.open({
			modal: 'userActivityHistory',
			modalData: {user: $scope.user}
		}).result.then(function (resource) {
			if (resource) {
				$scope.$close(resource);
			}
		}).catch($log.error);
	};

	$scope.maskAs = function (user) {
		return masquerade.as(user).catch(() => {
			messageModal.open({
				modalData: {
					title: $translate.instant('mask-user-error_title'),
					message: $translate.instant('mask-user-error_message')
				}
			});
		});
	};

	$scope.isMasked = function () {
		return masquerade.isMasked();
	};

	$scope.close = $scope.$dismiss;
}
