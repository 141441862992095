import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
	BehaviorSubject,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	EMPTY,
	map,
	Observable,
	scan,
	shareReplay,
	Subject,
	switchMap,
	tap
} from 'rxjs';
import { catchError, filter, take, takeUntil } from 'rxjs/operators';
import { ScoreFilter, paginationScoreMap, scorePaginationMap } from 'ngx/go-modules/src/enums/score-filter';
import { MinePeerFilter } from 'ngx/go-modules/src/enums/mine-peer-filter';
import { SortOrder } from 'ngx/go-modules/src/enums/sort-order-main';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { Session as SessionModel, sessionToken } from 'go-modules/models/session/session.service';
import { BaseDataSource } from 'ngx/go-modules/src/classes/base-data-source';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import {
	SelectedInterface,
	SelectedService,
	selectedServiceToken
} from 'go-modules/services/selected/selected.service';
import { accumulateArr, accumulateMap } from 'ngx/go-modules/src/rxjs/accumulate/accumulate';
import { EventService, GoEvent } from '../event/event.service';
import { EVENT_NAMES } from '../event/event-names.constants';
import type { Media } from 'go-modules/services/attachment/media';
import { NgxSessionService } from '../session/session.service';
import { States } from 'go-modules/enums/states.enum';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { mediaToken } from 'go-modules/models/media/media.factory';

// See the SessionListResource
export interface Session {
	session_id: number;
	group_id: number;
	activity_id: number;
	media_id: number;
	status: number;
	description: string;
	score: number | null;
	tag_set_id: number | null;
	created_by: number;
	created_at: string;
	viewed_recording_instructions_at: string | null;
	started_at: string;
	posted_by: number;
	posted_at: string;
	archived_by: number | null;
	archived_at: string | null;
	source_media_id: number | null;
	is_activity_placeholder: boolean;
	recording_user_ip?: string | null;
	owner_name: string;
	viewed?: boolean;
	activity?: object;
	is_fully_loaded?: boolean;
	media?: Media | null;
	have_i_commented?: boolean;
	sync_events?: any;
	external_session?: string;
	guest_reviewers?: GuestReviewer[];
	presenters?: Presenter[];
}

export interface Presenter {
	presenter_id: number,
	user_id: number;
	session_id: number;
	email: string;
	first_name: string;
	last_name: string;
}

export interface GuestReviewer {
	created_at: Date;
	created_by: number;
	deleted_at: Date | null;
	deleted_by: number | null;
	email: string;
	id: number;
	session_id: number;
	updated_at: Date;
	updated_by: number;
	user_id: number;
	uuid: string;
}

export interface CurrentSessionListParams {
	activityId: number;
	textSearch: string;
	sortBy: SortOrder;
	scored: ScoreFilter;
	minePeer: MinePeerFilter;
	filterByUserId: number[] | null;
}

export interface SessionListResponse {
	data: any[];
	links: {
		first: string;
		last: string;
		next?: string;
		prev?: string;
	};
	meta: {
		current_page: number;
		from: number;
		last_page: number;
		links: {
			url: string | null;
			label: string;
			active: boolean;
		}[];
		path: string;
		per_page: number;
		to: number;
		total: number;
	};
}

export const DEBOUNCE_TIME = 50;
export const SCROLL_DEBOUNCE_TIME = 50;
export const SESSION_SCORE_FILTER_KEY = 'session-score-filter';
export const SESSION_MINE_PEER_FILTER_KEY = 'session-mine-peer-filter';
export const SESSION_SORT_KEY = 'session-sort';

@Injectable({
	providedIn: 'root'
})
export class SessionListDataSource extends BaseDataSource<Session, any> implements OnDestroy {
	public sessionList: any[];
	public textSearch$;
	public isFiltered$: Observable<boolean>;

	private activityId$$ = new BehaviorSubject<number>(null);
	private textSearch$$ = new BehaviorSubject<string>('');
	private sortBy$$ = new BehaviorSubject<SortOrder>(SortOrder.NEWEST);
	private scored$$ = new BehaviorSubject<ScoreFilter>(ScoreFilter.ALL);
	private minePeer$$ = new BehaviorSubject<MinePeerFilter>(MinePeerFilter.ALL);
	private page$$ = new BehaviorSubject<number>(1);

	private add$$ = new Subject<ReturnType<typeof SessionModel>>();
	private edit$$ = new Subject<ReturnType<typeof SessionModel>>();
	private remove$$ = new Subject<ReturnType<typeof SessionModel>>();

	private scrollBuffer = 200;
	private onScroll$$ = new Subject();
	private componentDestroyed$$ = new Subject();

	constructor (
		private http: HttpClient,
		private eventService: EventService,
		private sessionService: NgxSessionService,
		@Inject(mediaToken) private MediaModel,
		@Inject(sessionToken) private Session: ReturnType<typeof SessionModel>,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject($stateToken) private $state
	) {
		super();

		this.setupFilters();
		this.params$ = combineLatest([
			this.activityId$$.pipe(distinctUntilChanged()),
			this.textSearch$$.pipe(distinctUntilChanged()),
			this.sortBy$$.pipe(distinctUntilChanged()),
			this.scored$$.pipe(distinctUntilChanged()),
			this.minePeer$$.pipe(distinctUntilChanged()),
			this.selectedService.getMentoredUserIds$().pipe(distinctUntilChanged()),
			this.reload$$
		], (activityId, textSearch, sortBy, scored, minePeer, filterByUserId, _reload) => {
			return {
				activityId,
				textSearch,
				sortBy,
				scored,
				minePeer,
				filterByUserId
			} as CurrentSessionListParams;
		});
		this.textSearch$ = this.textSearch$$.asObservable();
		this.isFiltered$ = combineLatest({
			scored: this.scored$$,
			minePeer: this.minePeer$$,
			textSearch: this.textSearch$$
		}).pipe(
			map(({scored, minePeer, textSearch}) =>
				scored !== ScoreFilter.ALL || minePeer === MinePeerFilter.PEER || !!textSearch
			)
		);

		const activity = this.selectedService.getActivity();
		if (activity) {
			this.setActivity(activity.activity_id);
		}

		this.selectedService.selectedSubject.asObservable()
			.pipe(
				map((selected: SelectedInterface) => selected.activity?.activity_id || null),
				filter((activityId) => activityId !== null)
			)
			.subscribe((activityId: number) => {
				this.setActivity(activityId);
			});

		this.results$ = this.getSessionList();

		this.onScroll$$.pipe(
			takeUntil(this.componentDestroyed$$),
			debounceTime(SCROLL_DEBOUNCE_TIME)
		).subscribe(() => {
			this.loadMoreRecords();
		});

		this.initEventListeners();
		this.observeDeletedSession();
	}

	public ngOnDestroy (): void {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
	}

	public connect (): Observable<readonly Session[]> {
		return this.results$;
	}

	public disconnect (): void {}

	public setActivity (activityId: number) {
		this.activityId$$.next(activityId);
	}

	public setPage (page: number) {
		this.page$$.next(page);
	}

	public setSearchTerm (text: string) {
		this.textSearch$$.next(text);
	}

	public setSortBy (sortBy: SortOrder) {
		localStorage.setItem(`${this.userService.currentUser.user_id}-` + SESSION_SORT_KEY, sortBy);
		this.sortBy$$.next(sortBy);
	}

	public getCurrentSort () {
		return this.sortBy$$.getValue();
	}

	public setScoreFilter (scoreFilter: ScoreFilter) {
		localStorage.setItem(`${this.userService.currentUser.user_id}-` + SESSION_SCORE_FILTER_KEY, scoreFilter);
		this.scored$$.next(scoreFilter);
		this.Session.setActivePaginationFilter(this.getSessionFilter());
	}

	public getCurrentScoreFilter () {
		return this.scored$$.getValue();
	}

	public setMinePeerFilter (createdByFilter: MinePeerFilter) {
		localStorage.setItem(`${this.userService.currentUser.user_id}-` + SESSION_MINE_PEER_FILTER_KEY, createdByFilter);
		this.minePeer$$.next(createdByFilter);
	}

	public getCurrentMinePeerFilter () {
		return this.minePeer$$.getValue();
	}

	public addSession (session: ReturnType<typeof SessionModel>) {
		this.Session.pagination[this.getSessionFilter()].meta.total++;
		this.add$$.next(session);
	}

	public editSession (session: ReturnType<typeof SessionModel>) {
		this.edit$$.next(session);
	}

	public removeSession (session: ReturnType<typeof SessionModel>) {
		this.Session.pagination[this.getSessionFilter()].meta.total--;
		this.remove$$.next(session);
	}

	// TODO: Temporary Only. Remove and use new endpoint (DEV-13889)
	public getSessions (params: CurrentSessionListParams, page: number): Observable<SessionListResponse> {
		const path = `${clientSettings.GoReactV2API}/activities/${params.activityId}/submissions`;
		const queryParams = new URLSearchParams();
		queryParams.append('page', page.toString());

		if (params.textSearch) {
			queryParams.append('query', params.textSearch);
		}

		if(params.sortBy) {
			queryParams.append('sort_by', params.sortBy);
		}

		if(params.scored && params.scored !== ScoreFilter.ALL && this.selectedService.getActivity().total_score > 0) {
			queryParams.append('graded', params.scored === ScoreFilter.SCORED ? 'true' : 'false');
		}

		if (params.minePeer && params.minePeer !== MinePeerFilter.ALL) {
			queryParams.append('users[]', this.userService.currentUser.user_id);
			queryParams.append('exclude_by_user_id', (params.minePeer === MinePeerFilter.PEER).toString());
		}

		// override the value if filterByUserId is not null
		if (params.filterByUserId !== null) {
			params.filterByUserId.forEach((userId) => {
				queryParams.append('users[]', userId.toString());
			});
			if (!params.filterByUserId.length) {
				queryParams.append('users[]', '');
			}
		}

		return this.http.get<SessionListResponse>(`${path}?${queryParams}`);
	}

	public onScroll (event: any) {
		const viewportHeight = event.target.offsetHeight; // viewport height
		const viewportScrollHeight = event.target.scrollHeight; // viewport scroll height
		const scrollLocation = event.target.scrollTop; // viewport scroll position

		// If the user has scrolled within 200px of the bottom, add more data
		const limit = viewportScrollHeight - viewportHeight - this.scrollBuffer;

		if (scrollLocation > limit) {
			this.onScroll$$.next(scrollLocation);
		}
	}

	public loadMoreRecords () {
		const lastPage = this.Session.pagination[this.getSessionFilter()].meta.last_page;
		const currentPage = this.Session.pagination[this.getSessionFilter()].meta.current_page;

		if(currentPage < lastPage && !this.loading$$.getValue()) {
			this.setPage(this.page$$.getValue() + 1);
		}
	}

	private setupFilters () {
		const selectedScoreFilter = localStorage.getItem(`${this.userService.currentUser.user_id}-` + SESSION_SCORE_FILTER_KEY);
		if (selectedScoreFilter && Object.values(ScoreFilter).includes(selectedScoreFilter as ScoreFilter)) {
			this.setScoreFilter(selectedScoreFilter as ScoreFilter);
		} else {
			/*
			Dev-15527: LTI SpeedGrader
			Get initial value from session service active pagination which is recordings
			equivalent to all in the scoreFilter, the default value will only change
			when user is in session-view and reload the page where we get the active
			pagination filter in the session storage
			*/
			const currentScoreFilter = paginationScoreMap.get(this.Session.getActivePaginationFilter());
			if (currentScoreFilter) {
				this.setScoreFilter(currentScoreFilter);
			}
		}

		const selectedMinePeerFilter = localStorage.getItem(`${this.userService.currentUser.user_id}-` + SESSION_MINE_PEER_FILTER_KEY);
		if (selectedMinePeerFilter
			&& Object.values(MinePeerFilter).includes(selectedMinePeerFilter as MinePeerFilter)) {
			this.setMinePeerFilter(selectedMinePeerFilter as MinePeerFilter);
		}

		const selectedSort = localStorage.getItem(`${this.userService.currentUser.user_id}-` + SESSION_SORT_KEY);
		const sortOrderOptions = Object.values(SortOrder).filter((option) => option !== SortOrder.DEFAULT);
		if (selectedSort && sortOrderOptions.includes(selectedSort as SortOrder)) {
			this.setSortBy(selectedSort as SortOrder);
		}
	}

	private observeDeletedSession () {
		this.eventService.listen([EVENT_NAMES.FEEDBACK_SESSION_DELETED])
			.pipe(switchMap((event: GoEvent) => {
				return this.results$.pipe(map((sessions) => {
					return sessions.find((session) => session.session_id === event.data.session_id);
				}));
			}),
			filter((session) => !!session)
			)
			.subscribe((session) => {
				this.removeSession(session);
			});
	}

	private initEventListeners () {
		this.eventService.listen([
			EVENT_NAMES.SESSION_GRADED,
			EVENT_NAMES.SESSION_UNGRADED,
			EVENT_NAMES.MEDIA_SYNC,
			EVENT_NAMES.ACTIVITY_VIEW_SESSION_SYNC
		]).subscribe((event: GoEvent) => {
			this.results$.pipe(take(1))
				.subscribe((sessions) => {
					const sessionData = event.data;
					const existingSession = sessions
						.find((session) => session.session_id === sessionData.session_id);
					switch (event.name) {
						case EVENT_NAMES.SESSION_GRADED:
						case EVENT_NAMES.SESSION_UNGRADED:
							if (existingSession) {
								const currentFilter = this.getCurrentScoreFilter();
								if (currentFilter === ScoreFilter.SCORED &&
									event.name === EVENT_NAMES.SESSION_GRADED ||
									currentFilter === ScoreFilter.ALL
								) {
									existingSession.score = sessionData.score;
									this.editSession(existingSession);
								} else if (currentFilter === ScoreFilter.SCORED &&
									event.name === EVENT_NAMES.SESSION_UNGRADED
								) {
									this.removeSession(existingSession);
								} else if (currentFilter === ScoreFilter.NO_SCORE &&
									event.name === EVENT_NAMES.SESSION_GRADED
								) {
									this.removeSession(existingSession);
								}
							}
							break;
						case EVENT_NAMES.MEDIA_SYNC:
							const sessionWithMedia = sessions.find((session) => {
								return Number(session.media?.media_id) === Number(event.data.media_id);
							});
							if (sessionWithMedia) {
								sessionWithMedia.media = this.MediaModel.model(event.data);
								this.editSession(sessionWithMedia);
							}
							break;
						case EVENT_NAMES.ACTIVITY_VIEW_SESSION_SYNC:
							const activity = this.selectedService.getActivity();
							if (Number(activity.activity_id) !== Number(sessionData.activity_id)) {
								return;
							}
							if (existingSession) {
								if (sessionData.archived_by) {
									this.removeSession(existingSession);
								} else {
									if (activity.isCommentOnlySingleAttempt()) {
										this.sessionService.getSession(sessionData.session_id)
											.subscribe((session) => {
												this.Session.model(existingSession).extend(session);
												this.editSession(existingSession);
											});
									} else {
										this.Session.model(existingSession).extend(sessionData);
										this.editSession(existingSession);
									}
								}
							}

							break;
					}
				});
		});
	}

	private errorHandler (err) {
		if (err.status === 403) {
			const group = this.selectedService.getGroup();
			this.$state.go(States.DASHBOARD_FOLDER_VIEW, {folder_id: group.group_id});
		}
		this.onError$$.next(err);
		this.setLoading(false);
		return EMPTY;
	}

	private getSessionList () {
		return this.params$.pipe(
			debounceTime(DEBOUNCE_TIME),
			switchMap((sessionListParams: CurrentSessionListParams) => {
				this.setPage(1);

				return this.page$$.pipe(
					distinctUntilChanged(),
					tap(() => this.setLoading()),
					switchMap((page) =>
						this.getSessions(sessionListParams, page)
							.pipe(catchError(this.errorHandler.bind(this)))
					),
					map((response: SessionListResponse) => {
						this.setLoading(false);
						this.Session.pagination[this.getSessionFilter()] = {
							meta: response.meta,
							links: response.links
						};
						return response.data.map(this.Session.model);
					}),
					scan((acc, sessions: ReturnType<typeof SessionModel>[]) => {
						// prevent edge case where new paginated data returns existing sessions in the list
						return acc.concat(sessions.filter(
							(session) => !acc.includes(session)
						));
					}, [])
				);
			}),
			switchMap((sessionList: ReturnType<typeof SessionModel>[]) => {
				const added$ = this.add$$.pipe(accumulateArr());
				const editted$ = this.edit$$.pipe(accumulateMap('session_id'));
				const removed$ = this.remove$$.pipe(accumulateArr());
				return combineLatest({added: added$, editted: editted$, removed: removed$}).pipe(
					map(({added, editted, removed}) =>
						added.concat(sessionList)
							.map((session) => editted[session.session_id] ?? session)
							.filter((session) => !removed.includes(session))
					)
				);
			}),
			shareReplay(1),
			catchError(this.errorHandler.bind(this))
		);
	}

	private getSessionFilter () {
		return scorePaginationMap.get(this.scored$$.getValue());
	}
}
