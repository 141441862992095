import { UIRouterGlobals } from '@uirouter/angularjs';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';

export class SessionInterceptor {
	public static responseError (
		response,
		sessionManager,
		eventService: EventService,
		$uiRouterGlobals: UIRouterGlobals
	) {
		// Handle the case where the user is not authenticated
		if ($uiRouterGlobals.$current.name.startsWith('root') && sessionManager.isEnded()) {
			eventService.broadcast(EVENT_NAMES.AUTH_ENDED);
		}

		return response;
	}
}
