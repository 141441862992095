import * as angular from 'angular';
import { noop } from 'angular';
import { MODE } from './modes';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { filter, map } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import { combineLatest, from, Observable, of } from 'rxjs';

/* @ngInject */
export const SessionEditorController = function (
	$scope,
	$filter,
	$analytics,
	MediaModel,
	sessionEditor,
	User,
	helpUrls,
	messageModal,
	eventService: EventService,
	$translate: ng.translate.ITranslateService,
	ngxDowngradeModalService: DowngradeModalService,
	selectedService: SelectedService
) {

	const vm = this;

	/**
	 * Handles init life cycle hook event
	 */
	vm.$onInit = () => {
		vm.filterUsers = filterUsers;

		const mentoredUserIds$: Observable<number[]> = selectedService.getMentoredUserIds$();
		const users$: Observable<any> = vm.users ? of(vm.users) : from(User.getByGroup({
			groupId: vm.group.getId()
		}).$promise);

		vm.usersSubscription = combineLatest([users$, mentoredUserIds$])
			.pipe(
				map(([users, mentoredUserIds]) => {
					if (mentoredUserIds && mentoredUserIds.length > 0) {
						return users.filter((user) => user.user_id === vm.user.user_id
							|| mentoredUserIds.includes(user.getId())
						);
					}

					return users;
				})
			)
			.subscribe((filteredUsers) => {
				$scope.$evalAsync(() => {
					vm.users = filteredUsers;
				});
			});

		// set uneditable user
		// make sure current user is presenter
		if (vm.group && vm.group.hasPresenterRole()) {
			if (vm.session && !vm.session.presenters.length) {
				vm.session.presenters = [vm.user];
			}
		}
		if (vm.group.hasPresenterRole()) {
			// user presenter that cannot be removed
			vm.uneditableUser = vm.user;
		}

		/**
		 * Options change event handler
		 */
		$scope.$watch(() => {
			return vm.options;
		}, (newVal, oldVal) => {
			if (newVal !== oldVal && getOption('newVal.mode')) {
				vm.currentMode = newVal.mode;
			}
		});

		// expose helpurls
		vm.helpUrls = helpUrls;

		// expose SessionEditor
		vm.sessionEditor = sessionEditor;

		vm.today = new Date();
		vm.filteredGroups = [];
		vm.filteredUsers = [];
		vm.selectedActivity = null;

		vm.media = MediaModel.model({media_status: MediaModel.READY});

		// Activity selection is disabled when an activity has test
		// settings and the user has a presenter role in the group.
		// This cannot be a function because we have to capture
		// the initial state. This will allow the user to go back
		// and forth between activities before making their final decision
		// in the scenario where is is not disabled initially.
		vm.isActivitySelectionDisabled = vm.activity.has_single_recording_attempt && vm.group.hasPresenterRole();

		vm.selectedActivity = vm.activity;
		vm.sourceMedia = vm.session.source_media || null;

		// Handle media data sync updates
		vm.eventSubscription = eventService.events
			.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.MEDIA_SYNC))
			.subscribe((ev: GoEvent) => {
				if (vm.sourceMedia) {
					if (parseInt(vm.sourceMedia.media_id, 10) === parseInt(ev.data.media_id, 10)) {
						Object.assign(vm.sourceMedia, ev.data);
						if (ev.data && ev.data.media_status === 'ready') {
							vm.onStimulusSaved({ media: ev.data });
						}
					}
				}
			});
	};

	vm.$onDestroy = () => {
		vm.eventSubscription?.unsubscribe();
		vm.usersSubscription?.unsubscribe();
	};

	/**
	 * If form is submitted and valid, either save the source media or delete the source media
	 */
	vm.$doCheck = () => {
		if (vm.formController.$submitted && vm.formController.$valid) {
			vm.session.setSourceMedia(vm.sourceMedia);
		}
	};

	/**
	 * Add stimulus to the session
	 */
	vm.addStimulus = () => {
		vm.sessionEditor.addStimulus(vm.session, vm.activity, vm.group).result.then((sourceMedia) => {
			vm.sourceMedia = sourceMedia;
			vm.onStimulusSaved({ media: sourceMedia });

			if (vm.activity.isSlidesEnabled()) {
				$analytics.eventTrack('add-deck', {category: 'session-editor'});
			}
		});
	};

	/**
	 * Whether or not to show score editor
	 *
	 * @returns {boolean|*}
	 */
	vm.showScoreEditor = () => {
		// show when enabled and not hidden, and when you are a reviewer or above or the mode is not create mode
		// Dont want to show students the score if they are creating a new session
		return vm.activity.isScoreEnabled() && !getOption('hideScore') && vm.group.hasReviewerRole(true) && !vm.isCreateMode();
	};

	/**
	 * Whether or not to show attachments
	 * @returns {boolean}
	 */
	vm.showAttachments = () => {
		return !!vm.session.session_id && !getOption('hideAttachments') && !vm.isCreateMode();
	};

	/**
	 * Whether or not to show slide deck section
	 * @returns {boolean}
	 */
	vm.showSlideDeckSection = () => {
		return vm.activity.isSlidesEnabled() &&
			(vm.showSlideDeckViewSection() || vm.showSlideDeckAddSection());
	};

	/**
	 * If a student tries to remove themselves, don't let them
	 */
	vm.onPresenterRemove = (presenters) => {
		if(vm.uneditableUser &&
			!vm.session.presenters.some((presenter) => {
				return Number(presenter.user_id) === Number(vm.uneditableUser.user_id);
			})) {
			vm.session.presenters.push(vm.uneditableUser);
			return messageModal.open({
				modalData: {
					title: 'session-editor_presenter-delete-title-error',
					message: 'session-editor_presenter-delete-message-error',
					resolveBtnClass: 'primary-btn'
				}
			});
		}

		vm.session.presenters = presenters;
	};

	/**
	 * Whether or not to show slide deck add section
	 * @returns {boolean}
	 */
	vm.showSlideDeckAddSection = () => {
		return vm.session.isOwner(vm.user) && !vm.sourceMedia;
	};

	/**
	 * Whether or not to show slide deck view section
	 * @returns {boolean}
	 */
	vm.showSlideDeckViewSection = () => {
		return !!vm.sourceMedia;
	};

	/**
	 * Whether or not score may be edited
	 *
	 * @returns {boolean}
	 */
	vm.mayEditScore = () => {
		return vm.activity.mayScore(vm.group) && !vm.activity.isAutoScored();
	};

	/**
	 * Whether or not score may be viewed
	 *
	 * @returns {boolean}
	 */
	vm.mayViewScore = () => {
		return vm.group.hasInstructorRole(true) || vm.session.isOwner(vm.user);
	};

	/**
	 * Whether we are in create mode
	 * @returns {boolean}
	 */
	vm.isCreateMode = () => {
		return vm.currentMode === MODE.CREATE;
	};

	// Delete slide deck from session
	vm.deleteSlideDeck = () => {
		return ngxDowngradeModalService.openConfirmDialog({
			title: $translate.instant('modal-confirm-delete-slide-deck_remove-slide-deck'),
			message: $translate.instant('modal-confirm-delete-slide-deck_remove-slide-deck-warning'),
			confirmText: $translate.instant('common_remove')
		}).then(() => {
			vm.sourceMedia = null;
			$scope.$apply();
		}).catch(noop);
	};

	/**
	 * Handles activity selection change
	 */
	vm.onActivitySelectionChange = () => {
		if (vm.selectedActivity) {
			vm.session.activity_id = vm.selectedActivity.getId();
		}
	};

	vm.shouldShowAccessibilityAttachments = (): boolean => {
		return vm.session.media && !vm.session.media.isYoutube();
	};

	/**
	 * Filter users given a query string
	 *
	 * @param query
	 * @returns {Array}
	 */
	function filterUsers (query) {
		let result = $filter('filter')(vm.users, notSelectedFilter());

		if (query) {
			result = $filter('filter')(result, queryFilter(query));
		}

		return $filter('orderBy')(result, ['last_name', 'first_name']);
	}

	/**
	 * Query string filter
	 */
	function queryFilter (query) {
		const lowercaseQuery = query.toLowerCase();
		return function filterFn (user) {
			const fullname = angular.isString(user.fullname) ? user.fullname.toLowerCase() : '',
				email = angular.isString(user.email) ? user.email.toLowerCase() : '';
			return fullname.indexOf(lowercaseQuery) !== -1 ||
				email.indexOf(lowercaseQuery) !== -1;
		};
	}

	/**
	 * Not selected filter
	 */
	function notSelectedFilter () {
		return function filterFn (user) {
			return !containsUser(user);
		};
	}

	/**
	 * Whether session presenters contains a given user
	 *
	 * @param user
	 * @returns {boolean}
	 */
	function containsUser (user) {
		const result = vm.session.presenters.filter((presenter) => {
			return Number(presenter.user_id) === Number(user.user_id);
		});

		return result.length > 0;
	}

	/**
	 * Get specific option
	 *
	 * @param name
	 * @returns {*}
	 */
	function getOption (name) {
		return angular.isObject(vm.options) ? vm.options[name] : undefined;
	}
};
