import { Inject, Injectable } from '@angular/core';
import {
	LICENSE_TRIALS_CACHE_KEY,
	LicenseTrialsService
} from 'ngx/go-modules/src/services/license-trials/license-trials.service';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { defer, EMPTY, Observable } from 'rxjs';
import {
	AiTrialCourseSelectorDialogComponent
} from 'ngx/go-modules/src/components/dialogs/ai-trial-course-selector-dialog/ai-trial-course-selector-dialog.component';
import type { AiTrialCourseSelectorResult } from 'ngx/go-modules/src/components/dialogs/ai-trial-course-selector-dialog/ai-trial-course-selector-dialog.component';
import {
	AiTrialSuccessDialogComponent
} from 'ngx/go-modules/src/components/dialogs/ai-trial-success-dialog/ai-trial-success-dialog.component';
import { MessageDialogComponent } from 'ngx/go-modules/src/components/dialogs/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { License } from 'ngx/go-modules/src/interfaces/licenses';

export interface CourseFreeTrialResult {
	license: License,
	selectedCourse: AiTrialCourseSelectorResult;
}

@Injectable({
	providedIn: 'root'
})
export class CourseTrialService {

	constructor (
		private dialog: MatDialog,
		private licenseTrialService: LicenseTrialsService,
		private translate: TranslateService,
		@Inject(userServiceToken) private userService: UserService
	) {
	}

	public licenseTrialBlocked (org_id: number): boolean {
		const cache = JSON.parse(sessionStorage.getItem(LICENSE_TRIALS_CACHE_KEY) || '[]');
		return cache.includes(org_id);
	}

	public blockLicenseTrial (org_id: number) {
		if (org_id == null) return;

		const cache = JSON.parse(sessionStorage.getItem(LICENSE_TRIALS_CACHE_KEY) || '[]');
		cache.push(org_id);
		sessionStorage.setItem(LICENSE_TRIALS_CACHE_KEY, JSON.stringify(cache));
	}

	public createTeaseWallCourseTrialPromptAction ({org_id}: {org_id: number}): Observable<CourseFreeTrialResult> {
		// Defer so that the dialog doesn't open until this observable is subscribed to
		return defer(()=> {
			return this.dialog.open<AiTrialCourseSelectorDialogComponent, never, AiTrialCourseSelectorResult>
			(AiTrialCourseSelectorDialogComponent).afterClosed().pipe(
				filter((selectedCourse) => !!selectedCourse),
				switchMap((selectedCourse) => {
					return this.licenseTrialService.startTrial({
						group_id: selectedCourse.folderId,
						user_id: this.userService.currentUser.user_id
					}).pipe(
						map((license) => ({ license, selectedCourse })),
						tap(() => this.blockLicenseTrial(org_id)),
						switchMap(({ license, selectedCourse }) => {
							const dialogData = {
								data: {
									folder_id: selectedCourse.folderId
								}
							};
							return this.dialog.open(AiTrialSuccessDialogComponent, dialogData)
								.afterClosed()
								.pipe(
									// reached end of successful flow when closing/cancelling out of this dialog
									map(() => ({
										license,
										selectedCourse
									}))
								);
						}),
						catchError(() => {
							this.dialog.open(MessageDialogComponent, {
								data: {
									title: this.translate.instant('ai-trial-failed-title'),
									message: this.translate.instant('ai-trial-failed-message')
								}
							});
							return EMPTY;
						})
					);
				})
			);
		});
	}
}
