import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import type { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Marker, MarkerSet } from 'ngx/go-modules/src/interfaces/markers/marker';

export interface PreviewMarkerSetDialogData {
	markerSet: MarkerSet;
	aiStyle?: boolean;
	withName?: boolean;
}

@Component({
	selector: 'preview-marker-set-dialog',
	template: require('./preview-marker-set-dialog.component.html'),
	styles: [require('./preview-marker-set-dialog.component.scss')]
})
export class PreviewMarkerSetDialogComponent {
	public markers: Marker[] = [];
	constructor (
		@Inject(MAT_DIALOG_DATA) public data: PreviewMarkerSetDialogData,
		private dialogRef: MatDialogRef<PreviewMarkerSetDialogData>
	) {
		this.markers = this.data.markerSet.tags.sort((a, b) => a.sort_index - b.sort_index);
	}

	public static open (
		dialog: MatDialog,
		config: MatDialogConfig<PreviewMarkerSetDialogData>
	): MatDialogRef<PreviewMarkerSetDialogComponent, void> {
		return dialog.open<
		PreviewMarkerSetDialogComponent,
		PreviewMarkerSetDialogData
		>(PreviewMarkerSetDialogComponent, config);
	}

	public close () {
		this.dialogRef.close();
	}
}
