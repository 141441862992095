import * as angular from 'angular';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

/* @ngInject */
export function UniversalMediaChooserFactory () {
	const umcCache = {}, _types = [];

	// Add extra params to a stream for video/audio
	// to ensure it gets associated with the correct object
	const timeoutRegex = /&timeout=http.*/;
	function modifyStreamName (stream, params) {
		const str = [];
		for (const param in params) {
			if (params.hasOwnProperty(param)) {
				str.push(param + '=' + params[param]);
			}
		}

		const uriStr = encodeURIComponent(str.join('&'));

		stream = stream.replace(timeoutRegex, '%26' + uriStr + '$&');
		stream += '%26' + uriStr;

		return stream;
	}

	function UmcFactory (name) {
		const _name = name;
		let _currentSection = null;
		let _selectedMedia = null;
		let _currentSections: any[] = Object.values(UmcFactory.MEDIA_TYPE);

		return {
			// methods
			modifyStreamName,

			// properties
			MEDIA_TYPE: UmcFactory.MEDIA_TYPE,

			// getters/setters
			get name () {
				return _name;
			},
			get currentSection () {
				return _currentSection;
			},
			set currentSection (val) {
				if (val != null && _types.indexOf(val) === -1) {
					throw new Error('UniversalMediaChooser:: Current Section must be a valid section type');
				}
				_selectedMedia = null;
				_currentSection = val;
			},
			get currentSections () {
				return _currentSections;
			},
			set currentSections (sections: any[]) {
				_currentSections = sections;
			},
			get selectedMedia () {
				return _selectedMedia;
			},
			set selectedMedia (val) {
				if (val != null && (!val.then || !angular.isFunction(val.then))) {
					throw new Error('UniversalMediaChooser:: Selected Media must be a promise');
				}
				_selectedMedia = val;
			}
		};
	}

	// Media types
	UmcFactory.MEDIA_TYPE = {
		RECORD_SESSION: {
			directive: 'umc-record-session',
			name: 'universal-media-chooser_record-session',
			iconClass: 'ficon-app-camcorder',
			selectedAriaLiveMessage: 'universal-media-chooser-header_record-session-selected'
		},
		RECORD_VIDEO: {
			directive: 'umc-record-video',
			name: 'universal-media-chooser_record',
			iconClass: 'ficon-app-camcorder',
			selectedAriaLiveMessage: 'universal-media-chooser-header_video-selected'
		},
		UPLOAD: {
			directive: 'umc-upload-video',
			name: 'universal-media-chooser_upload',
			iconClass: 'ficon-upload-outlined',
			selectedAriaLiveMessage: 'universal-media-chooser-header_upload-selected'
		},
		GO_UPLOAD: {
			directive: 'umc-go-upload',
			name: 'universal-media-chooser_upload-new',
			iconClass: 'ficon-app-upload',
			selectedAriaLiveMessage: 'universal-media-chooser-header_go-upload-selected'
		},
		YOUTUBE: {
			directive: 'umc-youtube-link',
			name: 'universal-media-chooser_youtube',
			iconClass: 'ficon-youtube-icon',
			selectedAriaLiveMessage: 'universal-media-chooser-header_youtube-selected'
		},
		RECORD_AUDIO: {
			directive: 'umc-record-audio',
			name: 'universal-media-chooser_audio',
			iconClass: 'ficon-microphone-outlined',
			selectedAriaLiveMessage: 'universal-media-chooser-header_audio-selected'
		},
		LIBRARY: {
			directive: 'umc-library',
			name: 'universal-media-chooser_library',
			iconClass: 'ficon-app-library',
			selectedAriaLiveMessage: 'universal-media-chooser-header_library-selected'
		},
		ZOOM: {
			directive: 'umc-zoom',
			name: 'universal-media-chooser_zoom',
			iconClass: 'ficon-zoom-icon',
			selectedAriaLiveMessage: 'universal-media-chooser-header_zoom-selected'
		}
	} as any;

	UmcFactory.get = function (name) {
		if (!name) {
			throw new Error('UniversalMediaChooser must have a name');
		}

		if (!umcCache[name]) {
			umcCache[name] = UmcFactory(name);
		}

		return umcCache[name];
	};

	// For ensuring calls to change section are valid
	Object.keys(UmcFactory.MEDIA_TYPE)
		.forEach((key) => _types.push(UmcFactory.MEDIA_TYPE[key]));

	return UmcFactory;
}

UniversalMediaChooserFactory.NG1_INJECTION_NAME = 'UniversalMediaChooser' as const;
export const umcChooserToken = upgradeNg1Dependency(UniversalMediaChooserFactory);
