import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, forwardRef, Inject, Input, ViewChild } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { TranslateService } from '@ngx-translate/core';
import { VIDEO_SHARE_SETTINGS } from 'go-modules/org-settings/org-settings.video-share.enum';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'user-form-field',
	template: require('./user-form-field.component.html'),
	styles: [require('./user-form-field.component.scss')],
	providers: [
		{
		  provide: NG_VALUE_ACCESSOR,
		  useExisting: forwardRef(() => UserFormFieldComponent),
		  multi: true
		},
		{
		  provide: NG_VALIDATORS,
		  useExisting: forwardRef(() => UserFormFieldComponent),
		  multi: true
		}
	],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class UserFormFieldComponent implements ControlValueAccessor, Validator {
	@Input() public peers: any[];
	@Input() public allowVideoShare: VIDEO_SHARE_SETTINGS;
	@ViewChild('userInput') public userInput: ElementRef<HTMLInputElement>;
	@ViewChild('auto') public matAutocomplete;
	public selectedUsers: AbstractControl = new FormControl([], [this.customChipValidator()]);
	public selectable = true;
	public removable = true;
	public separatorKeysCodes: number[] = [ENTER, COMMA];
	public filteredUsers: Observable<any[]>;
	public userInputCtrl = new FormControl();
	public onChangeFn: any;
	public onTouchedFn: any;

	constructor (
		private translate: TranslateService,
		@Inject(selectedServiceToken) private selectedService: SelectedService
	) {
		this.filteredUsers = this.userInputCtrl.valueChanges.pipe(
			map((input: string) => {
				return this._filter(input);
			})
		);

		this.userInputCtrl.valueChanges.subscribe(() => {
			this.onChange();
			this.onTouched();
		});
	}

	// ControlValueAccessor interface methods
	public onChange () {
		const returnVal = this.selectedUsers.valid ? {
			internal_reviewer_user_ids: this.getInternalReviewers(),
			external_reviewer_emails: this.getExternalReviewers()
		} : {
			valid: false
		};
		if (this.onChangeFn) this.onChangeFn(returnVal);
	}
	public onTouched () {
		if (this.onTouchedFn) this.onTouchedFn();
	}
	public registerOnChange (onChange: any): void {
		this.onChangeFn = onChange;
	}
	public registerOnTouched (onTouched: any): void {
		this.onTouchedFn = onTouched;
	}
	public writeValue (): void {}
	public validate (_control: AbstractControl): ValidationErrors | null {
		return this.selectedUsers.valid ? null : { invalidForm: { valid: false, message: 'Child form is invalid' } };
	}

	public add (event: MatChipInputEvent): void {
		if (!event.value) {
			return;
		}

		if (this.matAutocomplete.options.length > 0) {
			this.matAutocomplete.options.first.select();
			return;
		}

		const value = event.value.trim();
		if (value) {
			// dont add again if already there
			if (this.selectedUsers.value.some((user) => user.email === value)) {
				event.chipInput.clear();
				this.userInputCtrl.setValue(null);
				return;
			}
			const chips = [...this.selectedUsers.value, {
				email: value,
				external: true
			}];
			this.selectedUsers.setValue(chips);
			this.selectedUsers.updateValueAndValidity();
		}

		if (event.chipInput) {
			event.chipInput.clear();
		} else {
			event.input.value = '';
		}
		this.userInputCtrl.setValue(null);
	  }

	public remove (toRemove): void {
		const updatedUsers = this.selectedUsers.value.filter((user) => {
			if (toRemove.external === true) {
				return toRemove.email !== user.email;
			}
			return toRemove.user_id !== user.user_id;
		});
		this.selectedUsers.setValue(updatedUsers);
		this.selectedUsers.updateValueAndValidity();
		this.onChange();
		this.onTouched();
	}

	public selected (event: MatAutocompleteSelectedEvent): void {
		const selectedUser = event.option.value;
		const updatedUsers = [...this.selectedUsers.value, selectedUser];
		this.selectedUsers.setValue(updatedUsers);
		this.selectedUsers.updateValueAndValidity();

		this.userInput.nativeElement.value = '';
		this.userInputCtrl.setValue(null);
	}

	public getChipDisplay (user) {
		if (user.external === true) {
			return user.email;
		}
		return user.first_name + ' ' + user.last_name;
	}

	public allowsExternalShare (): boolean {
		return (this.allowVideoShare === VIDEO_SHARE_SETTINGS.PRIVATE ||
			this.allowVideoShare === VIDEO_SHARE_SETTINGS.BOTH) &&
			(this.selectedService.getActivity().is_video_share_enabled ||
			this.selectedService.getGroup().hasInstructorRole(true));
	}

	public getValidationError (): string {
		if (!this.allowsExternalShare()) {
			return this.translate.instant('modal-video-share-dialog_error');
		}
		const emails = this.selectedUsers.getError('invalidEmails')
			.map((error) => error.email)
			.join(', ');
		return this.translate.instant('common-validation_emails-invalid', {emails});
	}

	public customChipValidator (): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const chips = control.value;
			const invalidChips = [];

			for (const chip of chips) {
				if (chip.external) {
					const emailControl = new FormControl(chip.email, Validators.email);
					if (!this.allowsExternalShare()) {
						invalidChips.push(chip);
						chip.invalid = true;
					} else if (emailControl.invalid) {
						invalidChips.push(chip);
						chip.invalid = true;
					}
				}
			}

			return invalidChips.length > 0 ? { invalidChip: true, invalidEmails: invalidChips } : null;
		};
	}

	private getInternalReviewers (): number[] {
		return this.selectedUsers.value.filter((user) => !user.external).map((user) => {
			return user.user_id;
		});
	}

	private getExternalReviewers (): string[] {
		return this.selectedUsers.value.filter((user) => user.external).map((user) => {
			return user.email;
		});
	}

	private getAllUsersExceptSelected () {
		return this.peers.filter((user) =>
			!this.selectedUsers.value.some((selectedUser) => selectedUser.user_id === user.user_id)
		);
	}

	private _filter (value): any {
		if (typeof value === 'string' && this.peers){
			const filterValue = value.toLowerCase();
			return this.peers.filter((user) => {
				const fullName = user.first_name + ' ' + user.last_name;
				const userNameMatch = fullName.toLowerCase().includes(filterValue);
				const emailMatch = user.email.toLowerCase().includes(filterValue);
				const isNotSelected = !this.selectedUsers.value.some((selectedUser) => {
					return selectedUser.user_id === user.user_id;
				});

				return (userNameMatch || emailMatch) && isNotSelected;
			});
		} else {
			return this.getAllUsersExceptSelected();
		}
	}
}
