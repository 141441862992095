import { ITimeoutService, IAugmentedJQuery } from 'angular';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { FeedbackSessionContentControllerClass } from 'go-modules/feedback-session/content/content.controller';

interface Bindings {
	session: any;
	rubricList: any[];
	onRubricAdded: Function;
	onRubricSaved: Function;
	onRubricRemoved: Function;
	userGroup: any;
	activity: any;
}

export class RubricFeedbackViewerController implements Bindings {

	public session;
	public rubricList;
	public onRubricAdded;
	public userGroup;
	public activity;
	public onRubricSaved;
	public onRubricRemoved;

	private areDescriptionsShowing: boolean;
	private selectedRubric: any;
	private rubricOptions: any;
	private rubricStorage: any;
	private templateCache: any;
	private eventSubscription: any;
	private eventNames: string[];
	private environmentVarsService;
	private contentController: FeedbackSessionContentControllerClass;

	/* @ngInject */
	constructor (
		private feedbackSettings,
		private $timeout: ITimeoutService,
		private Group: any,
		private WebStorage: any,
		private RubricSessionModel: any,
		private RubricTemplateModel: any,
		private feedbackSession,
		private $scope: any,
		private confirmModal: any,
		private $element: IAugmentedJQuery,
		private goPrintService: any,
		private $q: any,
		private eventService: EventService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public $onInit () {
		this.templateCache = {};
		this.rubricStorage = new this.WebStorage('rubric');
		this.areDescriptionsShowing = !this.rubricStorage.get('hideDesc');

		this.eventNames = [
			EVENT_NAMES.RUBRIC_SAVE_SESSION_DONE,
			EVENT_NAMES.RUBRIC_CHANGE
		];
		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => this.eventNames.includes(ev.name)))
			.subscribe((ev: GoEvent) => {
				switch (ev.name) {
					case EVENT_NAMES.RUBRIC_SAVE_SESSION_DONE:
						this.$scope.saving = false;
						if (ev.data.succeeded) {
							this.$scope.dirty = false;

							if (ev.data.rubric.published_at) {
								this.session.setScore(ev.data.rubric.points);
							} else {
								this.session.setScore(null);
							}

							this.onRubricSaved({rubric: ev.data.rubric});
							this.eventService.broadcast(EVENT_NAMES.RUBRIC_FEEDBACK_VIEWER_RUBRIC_LIST_CHANGE,
								this.rubricList);
						}
						break;
					case EVENT_NAMES.RUBRIC_CHANGE:
						this.$scope.dirty = true;
						break;
				}
			});

		this.setup();
	}

	public $onDestroy (): void {
		this.eventSubscription?.unsubscribe();
	}

	public toggleDescriptions () {
		this.areDescriptionsShowing = !this.areDescriptionsShowing;
		this.rubricStorage.put('hideDesc', !this.areDescriptionsShowing);
		this.rubricOptions.hideDesc = !this.areDescriptionsShowing;
	}

	public saveSession () {
		this.$scope.saving = true;
		this.eventService.broadcast(EVENT_NAMES.RUBRIC_SAVE);
	}

	public togglePublish (rubric: any) {
		this.$scope.saving = true;

		this.eventService.broadcast(EVENT_NAMES.RUBRIC_SAVE, {callback: (succeeded: boolean) => {
			this.$scope.saving = false;
			if (!succeeded) {
				return;
			}

			this.$scope.publishing = true;
			rubric.publish()
				.then(() => {
					this.onRubricSaved({rubric});
					this.$scope.dirty = false;
				}).finally(() => {
					this.$scope.publishing = false;
				});
		}});
	}

	public shouldShowZeroState () {
		return this.rubricList.length === 0;
	}

	public shouldShowRubricScore (rubric: any, userGroup: any) {
		return userGroup.hasReviewerRole(true) || this.Group.model(rubric).hasReviewerRole(true);
	}

	public printRubric (rubric: any) {
		const mode = this.rubricOptions.mode;

		// We want rubric in print mode when we send the element
		// to print service, flip it back to what it was right after
		this.rubricOptions.mode = 'print';
		this.$timeout(() => {
			this.goPrintService.print(
				this.$element[0].querySelector('.rubric-feedback-viewer-content > .rubric'),
				`Rubric: ${rubric.user.first_name} ${rubric.user.last_name}`
			);
			this.rubricOptions.mode = mode;
		});
	}

	public deleteRubric (rubric: any) {
		this.confirmModal.open({
			modalData: {
				title: 'rubric-feedback-viewer_remove-rubric',
				message: 'rubric-feedback-viewer_confirm-remove-rubric',
				yes: 'common_remove',
				no: 'common_cancel'
			}
		}).result.then(() => {
			rubric.delete()
				.then(() => {
					this.removeRubric(rubric);
					this.setup();
				});
		});
	}

	public shouldShowDelete (): boolean {
		if (this.environmentVarsService.get(EnvironmentVarsService.VAR.READONLY)) {
			return false;
		}

		return this.selectedRubric.isSaved() &&
			(this.selectedRubric.isOwner(this.userGroup) || this.userGroup.hasInstructorRole(true));
	}

	public shouldShowPublishToggle (): boolean {
		if (this.environmentVarsService.get(EnvironmentVarsService.VAR.READONLY)) {
			return false;
		}

		return (this.selectedRubric?.id != null || this.$scope.dirty) && this.selectedRubric?.isOwner(this.userGroup);
	}

	public mayEditRubric (rubric) {
		if (this.environmentVarsService.get(EnvironmentVarsService.VAR.READONLY)) {
			return false;
		}

		return rubric.isOwner(this.userGroup);
	}

	public setRubric (rubric: any) {
		this.rubricOptions = null;

		this.$timeout(() => {
			this.rubricOptions = {
				session: rubric,
				schemaId: rubric.template_id,
				mode: this.mayEditRubric(rubric) ? 'live' : 'print',
				postData: {session_id: this.session.getId()},
				hideDesc: !this.areDescriptionsShowing,
				userGroup: this.userGroup
			};
		});

		// Set total on this rubric
		this.getRubricTemplate(rubric.template_id)
			.then((template) => {
				rubric.total = template.total;
			});
	}

	public onRubricChange (rubric) {
		this.$scope.$evalAsync(() => {
			this.selectedRubric = rubric.value;
			this.setRubric(this.selectedRubric);
		});
	}

	private setup () {
		// This will create and add a rubric if the current user
		// can create one for this activity
		this.addMyRubric(this.rubricList, this.userGroup);

		this.rubricList = this.sortRubrics().map((rubric) => {

			// Mark self-critique rubrics for correct icon
			rubric.isSelfCritique = this.session.isOwner({
				user_id: rubric.created_by
			});

			rubric.hideScore = !this.shouldShowRubricScore(rubric, this.userGroup);

			return rubric;
		});

		// Don't attempt to select a rubric if we don't have one
		if (this.rubricList.length === 0) {
			return;
		}

		// Determine which rubric to select initially
		// If you are capable of creating one, select that
		// Otherwise the first in the list
		this.selectedRubric = this.findInitialRubric(this.rubricList, this.userGroup);
		this.setRubric(this.selectedRubric);
	}

	private findInitialRubric (rubricList: any[], user: any) {
		const rubric = rubricList.filter((_rubric) => {
			return _rubric.isOwner(user);
		});

		return rubric[0] || rubricList[0];
	}

	private sortRubrics () {
		return this.rubricList.sort((a, b) => {
			const aGroup = this.Group.model(a), bGroup = this.Group.model(b);

			if (aGroup.hasReviewerRole(true) && bGroup.hasPresenterRole()) {
				return -1;
			}

			if (bGroup.hasReviewerRole(true) && aGroup.hasPresenterRole()) {
				return 1;
			}

			// Sort by last name after putting reviewer and above first
			return a.user.last_name.toLowerCase() > b.user.last_name.toLowerCase() ? 1 : -1;
		});
	}

	private addMyRubric (rubricList: any[], userGroup: any) {
		const existingRubric = rubricList.filter((rubric) => {
			return rubric.isOwner(userGroup);
		});

		if (existingRubric.length === 0 && this.shouldAddRubric(userGroup, this.activity)) {
			const rubric = this.RubricSessionModel.newInstance();
			rubric.session_id = this.session.getId();
			rubric.setOwner(userGroup);
			rubric.isSelfCritique = this.session.isOwner(userGroup);
			rubric.role = userGroup.role;
			rubric.hideScore = !this.shouldShowRubricScore(rubric, userGroup);

			// Need to set right total based on which template to use
			const templateId = userGroup.hasReviewerRole(true)
				? this.activity.getRubricTemplateId() : this.activity.getPeerRubricTemplateId();
			rubric.setTemplate(templateId);

			this.getRubricTemplate(templateId)
				.then((template) => {
					rubric.total = template.total;
				});

			rubricList.push(rubric);

			this.onRubricAdded({rubric});
			this.eventService.broadcast(EVENT_NAMES.RUBRIC_FEEDBACK_VIEWER_RUBRIC_LIST_CHANGE, this.rubricList);
		}
	}

	private shouldAddRubric (userGroup: any, activity: any) {
		return activity.getRubricTemplateId() && userGroup.hasReviewerRole(true)
			|| (activity.getPeerRubricTemplateId() && !userGroup.hasReviewerRole(true));
	}

	private getRubricTemplate (templateId: string) {
		return this.$q((resolve, _reject) => {
			let template = this.templateCache[templateId];
			if (!template) {
				template = this.RubricTemplateModel.get({id: templateId});
				this.templateCache[templateId] = template;
			}
			template.$promise.then((_template) => {
				resolve(_template);
			});
		});
	}

	/**
	 * Remove a rubric from the list
	 */
	private removeRubric (rubric: any): void {
		const index: number = this.rubricList.indexOf(rubric);
		if (index !== -1) {
			this.rubricList.splice(index, 1);
			this.onRubricRemoved({rubric});
			this.eventService.broadcast(EVENT_NAMES.RUBRIC_FEEDBACK_VIEWER_RUBRIC_LIST_CHANGE, this.rubricList);
		}
	}
}
