import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { License } from 'ngx/go-modules/src/interfaces/licenses';

export const LICENSE_TRIALS_CACHE_KEY = 'checkAllowedCache';

export interface LicenseTrialRequest {
	group_id: number;
	user_id: number;
}

@Injectable({
	providedIn: 'root'
})
export class LicenseTrialsService {
	constructor (private http: HttpClient) {}

	public checkAllowed (orgId: number): Observable<{allowed: boolean}> {
		return this.http.get<{allowed: boolean}>(`${clientSettings.GoReactV2API}/orgs/${orgId}/license-trials/allowed`);
	}

	public startTrial (payload: LicenseTrialRequest) {
		return this.http.post<License>(`${clientSettings.GoReactV2API}/license-trials`, payload);
	}
}
