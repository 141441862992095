import * as angular from 'angular';
import * as noUiSlider from 'nouislider';
import { PLAYBACK_RATES } from './playback-rates';
import { ResizeSensor } from 'css-element-queries';
import { GoMediaPlayer } from 'go-modules/media-player';
import { focusable } from 'tabbable';
import { ResponsiveViewService, Sizes } from 'go-modules/responsive-view/responsive-view.service';
import { AllPlayersService } from 'ngx/go-modules/src/services/all-players/all-players.service';
import { STATES as MediaPlayerStates } from 'go-modules/media-player';
import { UserService } from 'go-modules/models/user/user.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { Subject, takeUntil } from 'rxjs';

const LEFT_RIGHT_ADVANCE_TIME_MS = 5000;
const PAGE_UP_DOWN_ADVANCE_TIME_MS = 30000;
const LEFT_RIGHT_VOLUME_PERCENT = 5;
const PAGE_UP_DOWN_VOLUME_PERCENT = 20;

// To help with intelisense, create a class to set vm to, but allow it open ended
// Eventually we'll want to convert vm to an es6 class
export interface PlayerControlsControllerClass extends ng.IController {
	[key: string]: any;
	player: GoMediaPlayer;
	mediaId: number;
	mainSlider: noUiSlider.noUiSlider;
	mainSliderOptions: noUiSlider.Options;
	mainSliderEvents: {
		[key: string]: noUiSlider.Callback;
	};
	volSlider: noUiSlider.noUiSlider;
	sourceVolSlider: noUiSlider.noUiSlider;
	getCurrentTime: () => number;
	setCurrentTime: (time: number) => void;
	getMinTime: () => number;
	setMinTime: (time: number) => void;
	getMaxTime: () => number;
	setMaxTime: (time: number) => void;
	getDuration: () => number;
	options: {
		[key: string]: any;
		trimActive: boolean;
	};
}

/* @ngInject */
export function PlayerControlsController (
	$analytics: angulartics.IAnalyticsService,
	$document: ng.IDocumentService,
	$element: ng.IRootElementService,
	$filter: ng.IFilterService,
	$q: ng.IQService,
	$scope: ng.IScope,
	$translate: ng.translate.ITranslateService,
	$timeout: ng.ITimeoutService,
	$window: ng.IWindowService,
	Hotkeys,
	PlayerSync,
	responsiveView: ResponsiveViewService,
	allPlayersService: AllPlayersService,
	MediaModel,
	userService: UserService,
	eventService: EventService
) {
	const vm: PlayerControlsControllerClass = this;

	/**
	 * Handles controller init life cycle hook
	 */
	vm.$onInit = () => {
		allPlayersService.register(vm.player, vm.mediaId);
		// Duration of skipping or replaying. 10 seconds
		vm.SKIP_DURATION = 10000;

		// Duration of trim increment/decrement
		vm.TRIM_STEP = 1000;

		// Vars to keep track of player state between slider events
		vm.sliding = false;

		vm.showMuteWarning = false;

		// Initialize elapsed time
		vm.elapsedTime = 0;

		const commentCaptionsEnabled = localStorage.getItem('commentCaptionsEnabled');

		// Default options configuration
		const defaultOptions = {
			autoPlay: false,
			playerSyncSourcePlayer: null,
			playerSyncSessionPlayer: null,
			liveStream: false,
			liveStreamCount: 0,
			cuepointsActive: commentCaptionsEnabled !== null ? commentCaptionsEnabled === 'true' : true,
			captionsActive: false,
			isCommentOnlySingleAttempt: false,
			disableControlsForSingleRecording: false,
			shouldDisplayAudioDescriptionButton: false,
			audioDescriptionActive: false,
			toggleCommentCaptionsCallback: null,
			toggleAudioDescriptionCallback: null,
			trimActive: false,
			allowFullscreen: true,
			playbackRateDisabled: false,
			showTrim: false,
			showSettings: true
		};

		Object.assign(vm.options, {
			...defaultOptions,
			...vm.options
		});

		// Override default fullscreen option for player sync mode
		// Check if Full screen API is supported
		if (vm.isPlayerSync() || !vm.player.canGoFullScreen()) {
			vm.options.allowFullscreen = false;
		}

		// Check if we can go fullscreen
		if (inIframe()) {
			if (!$window.frameElement.hasAttribute('allowFullScreen')) {
				vm.options.allowFullscreen = false;
			}
		}

		vm.playbackRate = PLAYBACK_RATES[0];
		vm.liveMode = false;
		vm.settingsActive = false;
		vm.volumeActive = false;
		vm.isXSmallScreen = responsiveView.isAtMost(Sizes.XSMALL);
		vm.Sizes = Sizes;
		vm.responsiveView = responsiveView;
		vm.cuepointsActive = vm.options.cuepointsActive;

		// Set initial volume level. Should match 'start' prop of noUiSlider in link function.
		vm.volumeLevel = 100;
		vm.volumeLevelStore = 100;

		// Set initial source volume level.
		vm.sourceVolumeLevel = 100;
		vm.sourceVolumeLevelStore = 100;

		vm.options.trimActive = false;

		if (!vm.isPlayerSync()) {
			vm.player.setVolume(vm.volumeLevel);

			vm.player.on('displayClick', this.onDisplayClick);
		}

		// Run digest on each play/pause/complete event to keep UI in sync with jwplayer
		vm.player.on('play', this.onPlay);
		vm.player.on('pause', this.onPause);
		vm.player.on('complete', this.onComplete);

		// Hotkey for pause/play
		Hotkeys.add('ctrl+space', false, () => {
			const oneOrMorePlaying = allPlayersService.players.some((player) => {
				return player.state === MediaPlayerStates.PLAYING;
			});
			if (oneOrMorePlaying) {
				allPlayersService.pauseAll();
			} else {
				allPlayersService.playLastPlayed();
			}
		});

		// Determine if the closed captions button should be displayed
		// and if they should initially be toggled on / off.
		let promise = $q.when(vm.player);
		// When the player is a player sync instance, we have
		// to wait for all the tracks to be loaded as well
		// before we make calls to the player sync instance.
		if (vm.isPlayerSync()) {
			promise = (vm.player as typeof PlayerSync).getReady();
		}
		promise.then(() => {
			if (vm.player.captionsEnabled()) {
				vm.toggleCaptions(true);
			}
		});

		vm.destroy$ = new Subject<void>();
		eventService.listen(EVENT_NAMES.FEEDBACK_SESSION_TRIM_DEACTIVATE)
			.pipe(takeUntil(vm.destroy$))
			.subscribe(()=> {
				if (vm.options.trimActive) {
					vm.deactivateTrim();
				}
			});
	};

	vm.download = () => {
		MediaModel.download(vm.mediaId);
	};

	vm.onDisplayClick = () => {
		if (vm.options.liveStream) {
			return false;
		}
		vm.togglePlay();
	};

	vm.onPlay = () => {
		// Animate causes `transition: 0.3s` on the slider, which is nice when scrubbing by clicking
		// but while we are playing, starting on Chrome 89, this causes the handle to twitch back and forth
		// So we'll disable the animate while we are playing
		vm.mainSlider?.updateOptions({
			animate: false,
			start: vm.getMainSliderValuesRaw()
		});

		vm.isEnded = false;

		// watch play in live mode to set toggle to true
		if (vm.options.liveStream) {
			vm.liveMode = true;
		}

		safeDigest();
	};

	vm.onPause = () => {
		// Turn animate back on
		vm.mainSlider?.updateOptions({
			animate: true,
			start: vm.getMainSliderValuesRaw()
		});

		if (vm.options.liveStream) {
			vm.liveMode = false;
		}

		safeDigest();
	};

	vm.onComplete = () => {
		vm.isEnded = true;

		if (vm.options.liveStream) {
			vm.liveMode = false;
		} else {
			const end = vm.mainSliderOptions.range.max as number;
			vm.setCurrentTime(end);
		}

		safeDigest();
	};

	vm.onTime = () => {
		vm.onTimeCallback(vm.player.getTime());
	};

	vm.$onDestroy = () => {
		allPlayersService.unregister(vm.player, vm.mediaId);
		vm.player?.off('displayClick', this.onDisplayClick);
		vm.player?.off('play', this.onPlay);
		vm.player?.off('pause', this.onPause);
		vm.player?.off('complete', this.onComplete);
		vm.player?.off('time', this.onTime);
		vm.reSizeSensor?.detach();
		vm.volSlider?.destroy();
		vm.sourceVolSlider?.destroy();
		vm.mainSlider?.destroy();
		$document.off('click', vm.closeSettingsClickOutside);

		if (vm.destroy$) {
			vm.destroy$.next();
			vm.destroy$.complete();
		}
	};

	vm.closeSettingsClickOutside = (event: MouseEvent) => {
		const target = event.target as HTMLElement;
		const clickedSettingsPanelOrIcon = target.closest('player-controls-settings') || target.closest('.settings-button') || target.closest('.volume-button');
		if (!clickedSettingsPanelOrIcon && (vm.settingsActive || vm.volumeActive)) {
			$scope.$apply(() => vm.settingsActive = false);
			$scope.$apply(() => vm.volumeActive = false);
		}
	};

	vm.closeSettingsOnEscape = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			event.preventDefault();
			event.stopPropagation();
			vm.settingsActive = false;
			vm.volumeActive = false;
		}
	};

	/**
	 * Handles post link life cycle hook
	 */
	vm.$postLink = () => {
		// Handle closing settings and volume popover if clicking anywhere else
		$document.on('click', vm.closeSettingsClickOutside);

		// On desktop, hide/show controls on hover
		// If player sync mode, get the proper vm.wrapper to hide/show controls
		if (vm.isPlayerSync()) {
			vm.wrapper = $element.parent();
		} else {
			vm.wrapper = angular.element(vm.player.getContainer());
		}

		const elem = $element[0];
		vm.playerContainer = elem;

		// We want a set time for left/right, but nouislider works in percent
		// And we want a set time for page up/down, but nouislider works in a multiplier
		// So calculate the percent/multiple, based on the time
		const leftRightVolumePercent = 100 / LEFT_RIGHT_VOLUME_PERCENT;
		const pageUpDownVolumeMultiplier = PAGE_UP_DOWN_VOLUME_PERCENT / LEFT_RIGHT_VOLUME_PERCENT;
		const volConfig: noUiSlider.Options = {
			range: {
				min: 0,
				max: 100
			},
			keyboardDefaultStep: leftRightVolumePercent,
			keyboardPageMultiplier: pageUpDownVolumeMultiplier,
			behaviour: 'snap',
			start: 100,
			orientation: 'horizontal',
			connect: 'lower',
			ariaFormat: {
				to (val: number): string {
					return val.toFixed(0) + '%';
				},
				// required but unused
				from: angular.noop
			}
		};

		vm.volSlider = noUiSlider.create(elem.querySelector('.volume-slider'), volConfig);
		const volumeHandleDom = vm.volSlider.target.querySelector('.noUi-handle');
		volumeHandleDom.setAttribute('aria-label', vm.isPlayerSync() ?
			$translate.instant('player-controls_volume-slider_volume-handles', {num: 1}) :
			$translate.instant('player-controls_volume-slider_volume-handle'));

		// Change volume on slider change.
		vm.volSlider.on('update', () => {
			const player = vm.options.playerSyncSessionPlayer || vm.player;

			$timeout(() => {
				const volume = parseInt(vm.volSlider.get() as string, 10);
				vm.volumeLevel = volume;
				player.setVolume?.(volume);
			});
		});

		if (vm.isPlayerSync()) {
			// Source volume slider setup. Identical to normal volume setup
			const sourceVolConfig: noUiSlider.Options = {
				range: {
					min: 0,
					max: 100
				},
				keyboardDefaultStep: leftRightVolumePercent,
				keyboardPageMultiplier: pageUpDownVolumeMultiplier,
				behaviour: 'snap',
				start: 100,
				orientation: 'horizontal',
				connect: 'lower',
				ariaFormat: {
					to (val: number): string {
						return val.toFixed(0) + '%';
					},
					// required but unused
					from: angular.noop
				}
			};

			vm.sourceVolSlider = noUiSlider.create(elem.querySelector('.source-volume-slider'), sourceVolConfig);
			const sourceColumeHandleDom = vm.volSlider.target.querySelector('.noUi-handle');
			sourceColumeHandleDom.setAttribute('aria-label',
				$translate.instant('player-controls_volume-slider_volume-handles', {num: 2}
				));
			vm.sourceVolSlider.on('update', () => {
				const player = vm.options.playerSyncSourcePlayer;

				$timeout(() => {
					const volume = parseInt(vm.sourceVolSlider.get() as string, 10);
					vm.sourceVolumeLevel = volume;
					player?.setVolume?.(volume);
				});
			});
		}

		// We want a set time for left/right, but nouislider works in percent
		// And we want a set time for page up/down, but nouislider works in a multiplier
		// So calculate the percent/multiple, based on the time
		const duration = vm.player.getDuration();
		const leftRightAdvancePercent = duration / LEFT_RIGHT_ADVANCE_TIME_MS;
		const pageUpDownAdvanceMultiplier = PAGE_UP_DOWN_ADVANCE_TIME_MS / LEFT_RIGHT_ADVANCE_TIME_MS;

		// While format.to is used for display, it's also used to get current handle values
		// We need the exact ms value rather than '01:46:21' which is rounded to seconds
		// So we'll temporarily return raw when calling vm.mainSlider.get()
		vm.sliderFormatRaw = false;
		vm.mainSliderOptions = {
			range: {
				min: 0,
				max: duration
			},
			keyboardDefaultStep: leftRightAdvancePercent,
			keyboardPageMultiplier: pageUpDownAdvanceMultiplier,
			start: 0,
			connect: 'lower',
			tooltips: true,
			format: {
				to (val: number): string | number {
					if (vm.sliderFormatRaw) {
						return val;
					}
					return $filter<(time: number) => string>('timeFormat')(val);
				},
				from: Number
			},
			ariaFormat: {
				to (val: number): string {
					return $filter<(time: number, aria: boolean) => string>('timeFormat')(val, true);
				},
				// required but unused
				from: angular.noop
			}
		};

		const events = {
			currentTime: {
				start () {
					vm.sliding = true;
				},
				slide: () => {
					vm.constrainCurrentTime();
					vm.player.seek(vm.getCurrentTime());
					$scope.$evalAsync(() => vm.elapsedTime = vm.getCurrentTime());
				},
				change () {
					if (!vm.sliding) {
						vm.constrainCurrentTime();
						vm.player.seek(vm.getCurrentTime());
					}
				},
				end () {
					vm.sliding = false;
				}
			},
			min: {
				slide () {
					vm.constrainMinTime();
					vm.constrainCurrentTime();
				},
				set () {
					vm.options.trimPoints.start = vm.getMinTime();
				}
			},
			max: {
				slide () {
					vm.constrainMaxTime();
					vm.constrainCurrentTime();
				},
				set () {
					vm.options.trimPoints.end = vm.getMaxTime();
				}
			}
		};

		vm.mainSliderEvents = {};
		for (const event of ['start', 'slide', 'update', 'change', 'set', 'end']) {
			vm.mainSliderEvents[event] = (_values: any[], handle: number) => {
				events[vm.getHandleName(handle)][event]?.();
			};
		}

		if (!vm.options.liveStream) {
			// Create main slider
			vm.mainSlider = noUiSlider.create(elem.querySelector('.main-slider'), vm.mainSliderOptions);
			const handleDom = vm.mainSlider.target.querySelector('.noUi-handle');
			handleDom.setAttribute('aria-label', $translate.instant('player-controls_main-slider_current-time-handle'));
			addSliderEvents(vm.mainSlider, vm.mainSliderEvents);
			vm.player.on('time', this.onTime);
		}

		// Auto play if we should
		if (vm.options.autoPlay) {
			vm.player.play();
			vm.liveMode = true;
		}

		if (vm.isPlaybackRateEnabled()) {
			vm.playbackRate = vm.getCachePlaybackRate();
			vm.player.playbackRate = vm.playbackRate;
		}

		if (vm.shouldStartMuted()) {
			vm.player.setMute(true);
			vm.showMuteWarning = true;
			$timeout(() => vm.showMuteWarning = false, 5000);
		}

		// Listen for liveMode changes to trigger analytics
		$scope.$watch(() => {
			return vm.liveMode;
		}, (liveMode) => {
			if (liveMode && vm.options.autoPlay) {
				$analytics.eventTrack('watch-live', {
					category: 'session'
				});
			}
		});

		// prevent touches from propogating
		$element.on('touchstart', (e) => {
			e.stopPropagation();
		});

		vm.observePlayerControlsSize();
	};

	/**
	 * Whether the player instance is of type `PlayerSync`
	 *
	 * @returns {boolean}
	 */
	vm.isPlayerSync = () => {
		return vm.player instanceof PlayerSync;
	};

	vm.isPlaying = () => {
		return vm.player.isPlaying();
	};

	vm.isFinishedPlaying = () => {
		return vm.player.isComplete();
	};

	vm.togglePlayOnKeydown = ($event: KeyboardEvent) => {
		if ($event.key === ' ' || $event.key === 'Enter') {
			vm.togglePlay();
		}
	};

	vm.togglePlay = () => {
		if (vm.options.trimActive && vm.isOutsideTrimRange()) {
			vm.seek(vm.getMinTime());
		} else if (vm.player.isPaused()) {
			vm.player.play();
		} else if (vm.isEnded) {
			vm.player.play();
		} else {
			vm.player.pause();
		}
	};

	vm.pause = () => {
		vm.player.pause();
	};

	vm.seek = (pos) => {
		vm.player.seek(parseInt(pos, 10));
	};

	vm.validate = () => {
		(vm.player as typeof PlayerSync).validate();
	};

	vm.skipForward = () => {
		const current = vm.getCurrentTime();
		const maxTime = vm.getMaxTime();

		let newPos = current + vm.SKIP_DURATION;
		if (newPos > maxTime) {
			newPos = maxTime;
		}

		vm.player.seek(newPos);
	};

	vm.skipBackward = () => {
		const current = vm.getCurrentTime();
		const minTime = vm.getMinTime();

		let newPos = current - vm.SKIP_DURATION;
		if (newPos < minTime) {
			newPos = minTime;
		}

		vm.player.seek(newPos);
	};

	vm.getVolume = () => {
		const player = vm.options.playerSyncSessionPlayer || vm.player;
		if (player.getVolume != null) {
			return player.getVolume();
		}
		return vm.volumeLevel;
	};

	vm.getMute = () => {
		const player = vm.options.playerSyncSessionPlayer || vm.player;
		if (player.getMute != null) {
			return player.getMute();
		}
		return vm.volumeLevel;
	};

	vm.toggleMute = () => {
		// Because we love making things outside angular
		// we need this to be part of a digest
		const player = vm.options.playerSyncSessionPlayer || vm.player;
		let mutePromise;
		if (player.getMute()) {
			player.setVolume(vm.volumeLevel);
			mutePromise = player.setMute(false);
			vm.saveMuteLocalStorage(false);
		} else {
			mutePromise = player.setMute(true);
			vm.saveMuteLocalStorage(true);
		}
		mutePromise.then(() => {
			$scope.$evalAsync();
		});
	};

	vm.shouldStartMuted = (): boolean => {
		return localStorage.getItem(userService.currentUser.user_id + '-mute-player') === 'true';
	};

	vm.saveMuteLocalStorage = (isMuted: boolean): void => {
		localStorage.setItem(userService.currentUser.user_id + '-mute-player', String(isMuted));
	};

	vm.toggleSettings = () => {
		vm.settingsActive = !vm.settingsActive;
		vm.volumeActive = false;
		vm.isXSmallScreen = responsiveView.isAtMost(Sizes.XSMALL);

		if(vm.settingsActive) {
			vm.openControlSettings();
		}
	};

	vm.toggleVolume = () => {
		vm.volumeActive = !vm.volumeActive;
		vm.settingsActive = false;
		vm.isXSmallScreen = responsiveView.isAtMost(Sizes.XSMALL);

		if(vm.volumeActive) {
			vm.openControlSettings();
		}
	};

	vm.openControlSettings = () => {
		$timeout(() => {
			const playerControls = $element.find('player-controls-settings');
			focusable(playerControls[0])[0].focus();
		});
	};

	vm.toggleCommentCaptions = () => {
		vm.cuepointsActive = !vm.cuepointsActive;
		(vm.options.toggleCommentCaptionsCallback || angular.noop)(vm.cuePointsActive);
		localStorage.setItem('commentCaptionsEnabled', vm.cuepointsActive);

		// If closed captions were on and we turned on comment captions, turn them off
		if (vm.cuepointsActive && vm.player.captionsEnabled()) {
			vm.player.toggleCaptions(false);
		}
	};

	vm.toggleCaptions = (value) => {
		if (value == null) {
			value = !vm.player.captionsEnabled();
		}

		// Turn off comment captions if captions are turning on
		if (value && vm.cuepointsActive) {
			vm.toggleCommentCaptions();
		}

		vm.player.toggleCaptions(value);
	};

	vm.toggleAudioDescription = () => {
		vm.options.audioDescriptionActive = !vm.options.audioDescriptionActive;
		if (angular.isFunction(vm.options.toggleAudioDescriptionCallback)) {
			vm.options.toggleAudioDescriptionCallback(vm.options.audioDescriptionActive);
		}
	};

	vm.showTrimButton = () => {
		return vm.options.showTrim && vm.options.trimPoints != null;
	};

	vm.showVolumeDisplay = (event) => {

		const volumeContainer = closest(event.target, '.volume-controls'),
			volumeSliderContainer = volumeContainer.querySelector('.volume-slider-container');

		volumeSliderContainer.style.display = 'block';
		vm.volumeButtonActive = true;
	};

	vm.hideVolumeDisplay = (event) => {

		const volumeContainer = closest(event.target, '.volume-controls'),
			volumeSliderContainer = volumeContainer.querySelector('.volume-slider-container');

		volumeSliderContainer.style.display = 'none';
		vm.volumeButtonActive = false;
	};

	vm.getState = () => {
		return vm.state;
	};

	vm.toggleFullscreen = () => {
		if (!vm.player.getFullscreen()) {
			vm.player.goFullscreen();
		} else {
			vm.player.exitFullscreen();
		}
	};

	vm.isPlaybackRateEnabled = () => {
		return !vm.options.playbackRateDisabled &&
			!vm.options.shouldDisplayAudioDescriptionButton &&
			vm.player.isPlaybackRateSupported();
	};

	vm.togglePlaybackRate = (): void => {
		const currentPosition = PLAYBACK_RATES.indexOf(vm.playbackRate);
		vm.playbackRate = currentPosition + 1 < PLAYBACK_RATES.length ?
			PLAYBACK_RATES[currentPosition + 1] : PLAYBACK_RATES[0];
		vm.player.playbackRate = vm.playbackRate;

		vm.savePlayBackRate();
	};

	vm.getCachePlaybackRate = (): number => {
		const cache = localStorage.getItem('playbackRate');
		return +cache || 1;
	};

	vm.savePlayBackRate = (): void => {
		localStorage.setItem('playbackRate', vm.playbackRate);
	};

	vm.toggleLiveMode = () => {
		if (!vm.player.isPlaying()) {
			vm.player.play();
		} else {
			vm.player.stop();
		}
	};

	/**
	 * END SECTION: basic player functionality
	 */

	/**
	 * SECTION: Player sync functions are being kept separate with the expectation player sync functionality
	 * will be moved into its own directive
	 */
	vm.shouldShowSourceSyncAndAudio = () => {
		const player = vm.options.playerSyncSourcePlayer;
		return !!player && !player.isDocumentPlayer;
	};

	vm.getSourceVolume = () => {
		const player = vm.options.playerSyncSourcePlayer;
		if (player && player.getVolume != null) {
			return player.getVolume();
		}
		return vm.sourceVolumeLevel;
	};

	vm.getSourceMute = () => {
		const player = vm.options.playerSyncSourcePlayer;
		if (player && player.getMute != null) {
			return player.getMute();
		}
		return vm.sourceVolumeLevel;
	};

	vm.toggleSourceMute = () => {
		const player = vm.options.playerSyncSourcePlayer;
		let mutePromise = Promise.resolve();
		if (player && player.getMute != null) {
			if (player.getMute()) {
				player.setVolume(vm.sourceVolumeLevel);
				mutePromise = player.setMute(false);
			} else {
				mutePromise = player.setMute(true);
			}
		}
		mutePromise.then(() => {
			$scope.$evalAsync();
		});
	};

	/**
	 * END SECTION: Player sync functions
	 */

	/**
	 * SECTION: trim functionality
	 */
	vm.activateTrim = () => {
		vm.options.trimActive = true;
		eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_TRIM_ACTIVATE);

		// When we activate trim, we will be adding two additional handles to the mainbar
		vm.mainSliderOptions.start = [
			vm.getMinTime(),
			vm.getCurrentTime(),
			vm.getMaxTime()
		];

		// We only want a tooltip on the current time
		vm.mainSliderOptions.tooltips = [false, true, false];

		// Fill in bars between:
		// 0: start and trim start
		// 1: trim start and current time
		// 2: current time and trim end
		// 3: trim end and end
		vm.mainSliderOptions.connect = [false, true, true, false];

		// We want the sliders to "push" each other
		// Nouislider doesn't support this, but we can achieve it by disabling
		// the behavior that doesn't allow sliders to move past each other
		// then implement pushing in the slide event listner
		vm.mainSliderOptions.behaviour = 'unconstrained';

		// Setup trim slider
		vm.options.trimPoints.start = vm.mainSliderOptions.start[0];
		vm.options.trimPoints.end = vm.mainSliderOptions.start[2];

		vm.mainSlider = updateSlider(vm.mainSlider, vm.mainSliderOptions, vm.mainSliderEvents);
		const handlesDom = vm.mainSlider.target.querySelectorAll('.noUi-handle');
		handlesDom[0].setAttribute('aria-label', $translate.instant('player-controls_main-slider_trim-start-handle'));
		handlesDom[1].setAttribute('aria-label', $translate.instant('player-controls_main-slider_current-time-handle'));
		handlesDom[2].setAttribute('aria-label', $translate.instant('player-controls_main-slider_trim-end-handle'));
	};

	vm.deactivateTrim = () => {
		vm.options.trimActive = false;
		eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_TRIM_DEACTIVATE);

		// Revert back to one handle
		vm.mainSliderOptions.start = vm.getCurrentTime();
		vm.mainSliderOptions.connect = 'lower';
		vm.mainSliderOptions.tooltips = true;
		vm.mainSliderOptions.behaviour = 'tap';

		vm.mainSlider = updateSlider(vm.mainSlider, vm.mainSliderOptions, vm.mainSliderEvents);
		const handleDom = vm.mainSlider.target.querySelector('.noUi-handle');
		handleDom.setAttribute('aria-label', $translate.instant('player-controls_main-slider_current-time-handle'));

		// Reset trim vals to null
		vm.options.trimPoints.start = null;
		vm.options.trimPoints.end = null;
	};

	vm.getHandleName = (handle: number): 'currentTime' | 'min' | 'max' => {
		if (!vm.options.trimActive || handle === 1) {
			return 'currentTime';
		} else if (handle === 0) {
			return 'min';
		} else if (handle === 2) {
			return 'max';
		}
	};

	vm.getMainSliderValuesRaw = (): number | number[] => {
		try {
			vm.sliderFormatRaw = true;
			return vm.mainSlider.get() as unknown as number | number[];
		} finally {
			vm.sliderFormatRaw = false;
		}
	};

	// Either the slider has one handle (non-trim mode) and get() returns the value
	// Or the slider has 3 handles (trimstart, time, trimend), and we want the middle one
	vm.getCurrentTime = (): number => {
		const handles = vm.getMainSliderValuesRaw();
		return Array.isArray(vm.getMainSliderValuesRaw()) ? handles[1] : handles;
	};

	// If trim is active, then we want to set the middle handle (nouislider's set treats null as "no change")
	// If trim isn't active, then there's only one handle to set
	vm.setCurrentTime = (time: number): void => {
		vm.mainSlider.set(vm.options.trimActive ? [null, time, null] : time);
		$scope.$evalAsync(() => vm.elapsedTime = time);
	};

	// If current time is outside bounds, set it back inside bounds
	vm.constrainCurrentTime = (): void => {
		if (vm.getCurrentTime() < vm.getMinTime()) {
			vm.setCurrentTime(vm.getMinTime());
			vm.player.seek(vm.getMinTime());
		}
		if (vm.getCurrentTime() > vm.getMaxTime()) {
			vm.setCurrentTime(vm.getMaxTime());
			vm.player.seek(vm.getMaxTime());
		}
	};

	vm.constrainMinTime = (): void => {
		if (vm.getMinTime() > vm.getMaxTime()) {
			vm.setMinTime(vm.getMaxTime());
		}
	};

	vm.constrainMaxTime = (): void => {
		if (vm.getMaxTime() < vm.getMinTime()) {
			vm.setMaxTime(vm.getMinTime());
		}
	};

	// Either the slider has one handle (non-trim mode) and we use the range min
	// Or the slider has 3 handles (trimstart, time, trimend), and we want the first one
	vm.getMinTime = (): number => {
		const handles = vm.getMainSliderValuesRaw();
		if (!Array.isArray(handles)) {
			return vm.mainSlider.options.range.min as number;
		}
		return handles[0];
	};

	vm.setMinTime = (newMinTime: number): void => {
		const handles = vm.getMainSliderValuesRaw();
		if (!Array.isArray(handles)) {
			vm.mainSlider.options.range.min = newMinTime;
		} else {
			vm.mainSlider.set([newMinTime, null, null]);
		}
		vm.constrainCurrentTime();
		vm.constrainMaxTime();
	};

	// Either the slider has one handle (non-trim mode) and we use the range min
	// Or the slider has 3 handles (trimstart, time, trimend), and we want the last one
	vm.getMaxTime = (): number => {
		const handles = vm.getMainSliderValuesRaw();
		if (!Array.isArray(handles)) {
			return vm.mainSlider.options.range.max as number;
		}
		return handles[2];
	};

	vm.setMaxTime = (newMaxTime: number): void => {
		const handles = vm.getMainSliderValuesRaw();
		if (!Array.isArray(handles)) {
			vm.mainSlider.options.range.max = newMaxTime;
		} else {
			vm.mainSlider.set([null, null, newMaxTime]);
		}
		vm.constrainCurrentTime();
		vm.constrainMinTime();
	};

	vm.getDuration = (): number => {
		return vm.getMaxTime() - vm.getMinTime();
	};

	vm.toggleTrimMode = () => {
		if (vm.options.trimActive) {
			vm.deactivateTrim();
		} else {
			vm.activateTrim();
		}
	};

	vm.isOutsideTrimRange = () => {
		const time = vm.player.getTime();
		return time < vm.getMinTime() || vm.getMaxTime() < time;
	};

	vm.incrementTrimStart = () => {
		vm.setMinTime(vm.getMinTime() + vm.TRIM_STEP);
	};

	vm.decrementTrimStart = () => {
		vm.setMinTime(vm.getMinTime() - vm.TRIM_STEP);
	};

	vm.incrementTrimEnd = () => {
		vm.setMaxTime(vm.getMaxTime() + vm.TRIM_STEP);
	};

	vm.decrementTrimEnd = () => {
		vm.setMaxTime(vm.getMaxTime() - vm.TRIM_STEP);
	};

	vm.resizeSensorHandler = ({ width }) => {
		$element.toggleClass('size-less-than-380', width < 380);
		$element.toggleClass('size-less-than-320', width < 320);
	};

	vm.observePlayerControlsSize = () => {
		vm.reSizeSensor = new ResizeSensor($element[0], vm.resizeSensorHandler);
	};
	/**
	 * END SECTION: trim functionality
	 */

	vm.onTimeCallback = (pos) => {
		if (pos > 0 && !vm.options.liveStream) {
			if (!vm.sliding) {
				vm.setCurrentTime(pos);
			}
		}

		// If in trim mode stop once we hit the end of the trim
		if (vm.options.trimActive) {
			const max = vm.getMaxTime();
			if (pos >= max) {
				vm.player.pause();
			}
		}
	};
	/**
	 * END SECTION: slider callbacks
	 */

	/**
	 * SECTION: helper functions
	 */

	function inIframe () {
		try {
			return $window.self !== $window.top;
		} catch (e) {
			return true;
		}
	}

	// http://stackoverflow.com/a/16430350
	function closest (elem, selector) {

		const matchesSelector =
			elem.matches || elem.webkitMatchesSelector || elem.mozMatchesSelector || elem.msMatchesSelector;

		while (elem) {
			if (matchesSelector.bind(elem)(selector)) {
				return elem;
			}
			elem = elem.parentElement;
		}
		return false;
	}

	function updateSlider (
		oldSlider: noUiSlider.noUiSlider,
		options: noUiSlider.Options,
		events: {[key: string]: noUiSlider.Callback}
	) {
		const target = oldSlider.target;
		oldSlider.destroy();
		const newSlider = noUiSlider.create(target, options);
		addSliderEvents(newSlider, events);
		return newSlider;
	}

	function addSliderEvents (slider: noUiSlider.noUiSlider, events: {[key: string]: noUiSlider.Callback}) {
		for (const [event, callback] of Object.entries(events)) {
			slider.on(event, callback);
		}
	}

	// update state please
	function safeDigest () {
		// TODO: Dont access angular private vars
		// eslint-disable-next-line angular/no-private-call
		const phase = $scope.$root.$$phase || $scope.$$phase;
		if (!phase) {
			$scope.$digest();
		}
	}

	/**
	 * END SECTION: helper functions
	 */
}
