import { StateEmitter } from '../state-emitter/state-emitter';
import { VideoSceneController } from '../video-scene.controller';

export enum DestinationType {
	OPENTOK = 'OPENTOK',
	MEDIARECORDER = 'MEDIARECORDER'
}

export interface DestinationOptions {
	videoScene: VideoSceneController;
	type: DestinationType;
}

export abstract class Destination extends StateEmitter {
	public static readonly TYPES = DestinationType;
	public static readonly event = 'server_connection_status';
	public readonly type: DestinationType;
	protected videoScene: VideoSceneController;

	constructor (options: DestinationOptions) {
		super();
		this.type = options.type;
		this.videoScene = options.videoScene;
	}

	/**
	 * Relying on init for setup rather than the constructor
	 * so that consumers can await in case of async setup.
	 */
	public destroy () {
		super.destroy();
	};
	public abstract init (stream: MediaStream): Promise<void>;
	public abstract start (): Promise<void>;
	public abstract pause (): Promise<void>;
	public abstract stop (): Promise<void>;
	public abstract getAudioStream(): MediaStream;
	public abstract setAudioSource (track: MediaStreamTrack): void;
	public abstract setVideoSource (track: MediaStreamTrack): void;
	public abstract publishAudio (publish: boolean): void;
	public abstract screenCaptureStart (): Promise<void>;
	public abstract updateScreenResolution (resolution: OT.GetUserMediaProperties['resolution'], shouldSetFocus: boolean): Promise<void>;
	public abstract remoteStreamStats (delay: number): Promise<any>;

	public get eventName (): string {
		return Destination.event;
	}
};
