import { EventEmitter } from '@angular/core';
import {
	Input,
	OnChanges,
	Component,
	Injector,
	ViewChild,
	SimpleChanges,
	OnInit,
	OnDestroy,
	Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { Marker } from 'ngx/go-modules/src/interfaces/markers/marker';
import { CommentFilterService } from 'ngx/go-modules/src/services/comment-filter/comment-filter.service';

@Component({
	selector: 'marker-filter-dropdown',
	template: require('./marker-filter-dropdown.component.html'),
	styles: [require('./marker-filter-dropdown.component.scss')]
})
export class MarkerFilterDropdownComponent implements OnInit, OnDestroy, OnChanges {
	@Output() public onFilter: EventEmitter<any>  = new EventEmitter();
	@Input() public uniqueMarkers: Marker[] = [];
	public form = new FormGroup({
		selectedMarkers: new FormControl([0])
	});
	public aiMarkers: Marker[] = [];
	public activityMarkers: Marker[] = [];
	@ViewChild('allSelected') private allSelected: MatOption;

	constructor (
		public commentFilterService: CommentFilterService,
		private injector: Injector
	) {}

	public ngOnInit () {
		// select all when unique markers are first populated
		this.form.controls.selectedMarkers.patchValue([
			...this.uniqueMarkers.map((marker) => marker.tag_id), 0
		]);
		this.commentFilterService.selectedMarkerIds = [];
		this.aiMarkers = this.uniqueMarkers.filter((marker) => marker.was_ai_generated);
		this.activityMarkers = this.uniqueMarkers.filter((marker) => !marker.was_ai_generated);
	}

	public ngOnDestroy () {
		this.commentFilterService.selectedMarkerIds = [];
	}

	public ngOnChanges (changes: SimpleChanges) {
		if (changes.uniqueMarkers) {
			// select new markers when new comments are added
			if (changes.uniqueMarkers.currentValue.length > changes.uniqueMarkers.previousValue.length) {
				const newMarkers = changes.uniqueMarkers.currentValue.filter((marker) => {
					return !changes.uniqueMarkers.previousValue.some((prevMarker) => {
						return prevMarker.tag_id === marker.tag_id;
					});
				});
				this.form.controls.selectedMarkers.patchValue([
					...this.form.controls.selectedMarkers.value,
					...newMarkers.map((marker) => marker.tag_id)
				]);
				if (!this.allSelected.selected) {
					this.commentFilterService.selectedMarkerIds = this.form.controls.selectedMarkers.value;
				}
			// remove markers from selected markers when unique markers are removed
			} else if (changes.uniqueMarkers.currentValue.length < changes.uniqueMarkers.previousValue.length) {
				const removedMarkers = changes.uniqueMarkers.previousValue.filter((marker) => {
					return !changes.uniqueMarkers.currentValue.some((currentMarker) => {
						return currentMarker.tag_id === marker.tag_id;
					});
				});
				this.form.controls.selectedMarkers.patchValue(
					this.form.controls.selectedMarkers.value.filter((id) => {
						return !removedMarkers.some((marker) => marker.tag_id === id);
					})
				);
				if (this.allSelected.selected) {
					this.commentFilterService.selectedMarkerIds = [];
				} else if (this.form.controls.selectedMarkers.value.length === 0) {
					this.commentFilterService.selectedMarkerIds = [-1];
				} else {
					this.commentFilterService.selectedMarkerIds = this.form.controls.selectedMarkers.value;
				}
			}
			this.aiMarkers = changes.uniqueMarkers.currentValue.filter((marker) => marker.was_ai_generated);
			this.activityMarkers = changes.uniqueMarkers.currentValue.filter((marker) => !marker.was_ai_generated);
			this.onFilter.emit();
		}
	}

	public toggleOne () {
		if (this.allSelected.selected) {
			this.allSelected.deselect();
			this.commentFilterService.selectedMarkerIds = this.form.controls.selectedMarkers.value;
			// if only one marker exists and it is deselected
			if (this.form.controls.selectedMarkers.value.length === 0) {
				this.commentFilterService.selectedMarkerIds = [-1];
			}
		} else {
			if (this.form.controls.selectedMarkers.value.length === this.uniqueMarkers.length) {
				this.allSelected.select();
				this.form.controls.selectedMarkers
					.patchValue([...this.uniqueMarkers.map((marker) => marker.tag_id), 0]);
				this.commentFilterService.selectedMarkerIds = [];
			} else if (this.form.controls.selectedMarkers.value.length === 0) {
				this.form.controls.selectedMarkers.patchValue([]);
				this.commentFilterService.selectedMarkerIds = [-1];
			} else {
				this.commentFilterService.selectedMarkerIds = this.form.controls.selectedMarkers.value;
			}
		}
		this.onFilter.emit();
		// trigger ng1 digest cycle
		this.injector.get('$rootScope').$new().$evalAsync();
	}

	public toggleAll () {
		if (this.allSelected.selected) {
			this.form.controls.selectedMarkers
				.patchValue([...this.uniqueMarkers.map((marker) => marker.tag_id), 0]);
			this.commentFilterService.selectedMarkerIds = [];
		} else {
			this.form.controls.selectedMarkers.patchValue([]);
			this.commentFilterService.selectedMarkerIds = [-1];
		}
		this.onFilter.emit();
		// trigger ng1 digest cycle
		this.injector.get('$rootScope').$new().$evalAsync();
	}
}
