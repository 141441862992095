import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Color, colors } from 'ngx/go-modules/src/interfaces/colors';
import { Marker, MarkerSet } from 'ngx/go-modules/src/interfaces/markers/marker';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogData } from '../confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { LibraryService } from 'ngx/go-modules/src/services/library/library.service';
import { PermanentlyInvalidValidator } from 'ngx/go-modules/src/validators/permanently-invalid';
import { InvalidValueValidator } from 'ngx/go-modules/src/validators/invalid-value';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';

@Component({
	selector: 'ngx-marker-set-dialog',
	template: require('./marker-set-dialog.component.html'),
	styles: [require('./marker-set-dialog.component.scss')]
})
export class MarkerSetDialogComponent implements OnInit {
	public markers: Marker[] = [];
	public readonly componentDestroyed$$ = new Subject();
	public pendingSave: boolean = false;
	public activeItem: number;
	public newTagColor: Color;
	public originalIndex: { [key: string]: number } = {};
	public readonly colors = colors;
	@ViewChildren('listItem') public listItems: QueryList<ElementRef>;

	public form = new FormGroup({
		title: new FormControl('', [Validators.required]),
		description: new FormControl('', [])
	});
	public newTagForm = new FormGroup({
		newTagName: new FormControl('', []),
		newTagAbbreviation: new FormControl(null, []),
		newTagComment: new FormControl(null, [])
	});

	constructor (
		@Inject(GO_MODAL_DATA) public data: {
			markerSet?: MarkerSet;
			addToCollection?: number;
			addToFolder?: number|null;
			copying?: boolean;
		},
		public dialogRef: GoDialogRef,
		public dialog: MatDialog,
		private translate: TranslateService,
		private libraryService: LibraryService,
		private elementRef: ElementRef,
		private ngxGoToastService: NgxGoToastService
	) {}

	public ngOnInit () {
		if (this.data.markerSet) {
			this.markers = structuredClone(this.data.markerSet.tags);
			// since new markers wont have an id we will use instance instead
			this.markers.map((marker, i) => {
				marker.instance = i;
				this.form.addControl('tagName' + i, new FormControl(marker.tag_name, Validators.required));
				this.form.addControl('tagAbbreviation' + i, new FormControl(marker.tag_abbreviation));
				this.form.addControl('tagComment' + i, new FormControl(marker.tag_preset_comment));
			});
			this.markers.sort((a, b) => a.sort_index - b.sort_index);
			this.form.controls.title.setValue(this.data.markerSet.title);
			this.form.controls.description.setValue(this.data.markerSet.description);
			this.updateOriginalIndex();
		}
		this.newTagColor = this.findNextUnusedColor();
	}

	public ngAfterViewInit () {
		this.listItems.changes
			.pipe(takeUntil(this.componentDestroyed$$))
			.subscribe(() => {
				this.setFocus();
			});
	}

	public ngOnDestroy (): void {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
	}

	public findColor (hex): Color {
		const deprecatedColors = [
			{hex: 'ffcc00', name: 'Tangerine Yellow', translation: 'colors_tangerine-yellow'},
			{hex: '55aa22', name: 'Kelly Green', translation: 'colors_kelly-green'},
			{hex: '1799dd', name: 'Pacific Blue', translation: 'colors_pacific-blue'},
			{hex: '448888', name: 'Paradiso Teal', translation: 'colors_paradiso-teal'},
			{hex: 'ff8800', name: 'Dark Orange', translation: 'colors_dark-orange'}
		];
		return colors.concat(deprecatedColors).find((color) => color.hex.toLowerCase() === hex.toLowerCase());
	}

	public onColorSelect (color, marker: Marker) {
		marker.tag_color = color.hex;
		this.pendingSave = true;
	}

	public changeNewColor (color) {
		this.newTagColor = color;
	}

	public removeTag (marker: Marker) {
		this.form.removeControl('tagName' + marker.instance);
		this.form.removeControl('tagAbbreviation' + marker.instance);
		this.form.removeControl('tagComment' + marker.instance);
		const index = this.markers.findIndex(((m) => m.instance === marker.instance));
		this.markers.splice(index, 1);
		this.pendingSave = true;
	}

	public newNameChanged (event) {
		if (!event.target.value) {
			this.newTagForm.controls.newTagName.clearValidators();
			this.newTagForm.controls.newTagName.updateValueAndValidity();
		}
	}

	public onNameChange (event, marker: Marker) {
		marker.tag_name = event.target.value;
		const trimmedValue = event.target.value.toLowerCase().trim();

		const isDuplicate = this.markers.filter((m) => m.tag_name.toLowerCase().trim() === trimmedValue).length > 1;
		if (isDuplicate) {
			event.target.blur();
			this.form.controls['tagName' + marker.instance].addValidators(InvalidValueValidator([trimmedValue]));
			this.form.controls['tagName' + marker.instance].updateValueAndValidity();
			event.target.focus();
		} else {
			this.form.controls['tagName' + marker.instance].clearValidators();
			this.form.controls['tagName' + marker.instance].addValidators(Validators.required);
			this.form.controls['tagName' + marker.instance].updateValueAndValidity();
		}
	}

	public onAbbreviationChange (event, marker: Marker) {
		marker.tag_abbreviation = event.target.value;
	}

	public onCommentChange (event, marker: Marker) {
		marker.tag_preset_comment = event.target.value;
	}

	public addNewMarker (newTagForm) {
		newTagForm.controls.newTagName.clearValidators();
		newTagForm.controls.newTagName.updateValueAndValidity();
		if (!newTagForm.value.newTagName) {
			newTagForm.controls.newTagName.addValidators(Validators.required);
			newTagForm.controls.newTagName.updateValueAndValidity();
			newTagForm.controls.newTagName.markAllAsTouched();
			this.focusNewTagName();
			return;
		}
		if (this.markers.some((marker) => marker.tag_name === newTagForm.value.newTagName)) {
			newTagForm.controls.newTagName.addValidators(InvalidValueValidator([
				newTagForm.value.newTagName.toLowerCase().trim()
			]));
			newTagForm.controls.newTagName.updateValueAndValidity();
			newTagForm.controls.newTagName.markAllAsTouched();
			this.focusNewTagName();
			return;
		}
		const newMarker = {
			sort_index: 0,
			tag_abbreviation: newTagForm.value.newTagAbbreviation,
			tag_color: this.newTagColor.hex,
			tag_name: newTagForm.value.newTagName,
			tag_preset_comment: newTagForm.value.newTagComment,
			instance: this.markers.length + 1
		};
		this.form.addControl('tagName' + newMarker.instance, new FormControl(newMarker.tag_name,  Validators.required));
		this.form.addControl('tagAbbreviation' + newMarker.instance, new FormControl(newMarker.tag_abbreviation));
		this.form.addControl('tagComment' + newMarker.instance, new FormControl(newMarker.tag_preset_comment));
		this.markers.unshift(newMarker as Marker);
		newTagForm.reset();
		this.newTagColor = this.findNextUnusedColor();
		this.pendingSave = true;
		this.updateOriginalIndex();
		if (this.showTagCreator()) {
			this.focusNewTagName();
		}
	}

	public focusNewTagName () {
		this.elementRef.nativeElement.querySelector('input[formControlName="newTagName"]').focus();
	}

	public findNextUnusedColor () {
		const unusedColor = this.colors.find((color) => {
			return !this.markers.some((marker) => marker.tag_color === color.hex);
		});
		return unusedColor ?? this.colors[0];
	}

	public dismiss () {
		if (!this.form.pristine || this.pendingSave) {
			return this.confirmDiscard();
		}
		this.dialogRef.close({ dismissed: true });
	}

	public confirmDiscard () {
		const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: this.translate.instant('modal-marker-editor_discard-title'),
				message: this.translate.instant('modal-marker-editor_discard-message'),
				confirmText: this.translate.instant('modal-marker-editor_discard-cancel')
			} as ConfirmDialogData
		});

		dialogRef.afterClosed().subscribe((confirm) => {
			if (confirm) {
				this.dialogRef.close({ dismissed: true });
			}
		});
	}

	public submit (form) {
		if (form.invalid) {
			return;
		}

		if (!this.markers.length ||
			this.newTagForm.value.newTagName ||
			this.newTagForm.value.newTagAbbreviation ||
			this.newTagForm.value.newTagComment
		) {
			this.newTagForm.controls.newTagName.addValidators(PermanentlyInvalidValidator('unsaved'));
			this.newTagForm.controls.newTagName.updateValueAndValidity();
			return;
		}

		if (form.pristine && !this.pendingSave && !this.data.copying) {
			return this.dismiss();
		}

		const payload:any = {
			title: this.form.value.title,
			description: this.form.value.description,
			tags: this.markers.map((marker) => {
				return {
					tag_id: marker.tag_id ?? null,
					tag_color: marker.tag_color,
					tag_name: marker.tag_name,
					tag_abbreviation: marker.tag_abbreviation ?? null,
					tag_preset_comment: marker.tag_preset_comment ?? null,
					sort_index: marker.sort_index
				};
			})
		};
		if (this.data.markerSet && !this.data.copying) {
			this.libraryService.updateMarkerSet(this.data.markerSet.id, payload)
				.subscribe({
					next: (res) => {
						this.dialogRef.close({
							...res,
							description: payload.description,
							modified_at: Date.now()
						});
					},
					error: () => {
						this.ngxGoToastService.createToast({
							type: GoToastStatusType.ERROR,
							message: 'common-save-failed'
						});
					}
				});
		} else {
			payload.add_to_collection = this.data.addToCollection ?? null;
			payload.add_to_folder = this.data.addToFolder ?? null;
			this.libraryService.createMarkerSet(payload)
				.subscribe({
					next: (res) => {
						this.dialogRef.close({
							...res,
							description: payload.description,
							modified_at: Date.now()
						});
					},
					error: () => {
						this.ngxGoToastService.createToast({
							type: GoToastStatusType.ERROR,
							message: 'common-save-failed'
						});
					}
				});
		}
	}

	public showTagCreator () {
		return this.markers.length < 30;
	}

	public setFocus () {
		if (this.activeItem >=0 && this.listItems.toArray()[this.activeItem]) {
			this.listItems.toArray()[this.activeItem].nativeElement.focus();
		}
	}

	public drop (event: CdkDragDrop<[]>) {
		moveItemInArray(this.markers, event.previousIndex, event.currentIndex);
		this.activeItem = event.currentIndex;
		this.setFocus();
		this.updateOriginalIndex();
		this.pendingSave = true;
	}

	public onActiveItemChange ({currentIndex, newIndex}) {
		moveItemInArray(this.markers, currentIndex, newIndex);
		this.activeItem = newIndex;
		this.setFocus();
	}

	public onFinalizePosition () {
		this.updateOriginalIndex();
		this.pendingSave = true;
	}

	public updateOriginalIndex () {
		this.markers.forEach((marker, index) => {
			this.originalIndex[marker.instance] = index;
		});
	}

	public onCancelReorder ({currentIndex, itemId}) {
		const originalIndex = this.originalIndex[itemId];
		moveItemInArray(this.markers, currentIndex, originalIndex);
		this.activeItem = originalIndex;
		this.setFocus();
	}
}
