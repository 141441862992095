import type { FeatureTypes, ComplexFeatureFlagValue } from './feature-flag-types';
import { UADetect as UADetectClass } from 'go-modules/detect/ua-detect.service';
import { clientSettings } from 'go-modules/models/common/client.settings';
import * as dayjs from 'dayjs';

export class FeatureTests {

	/** @ngInject */
	constructor (
		private UADetect: UADetectClass
	) {}

	public userCreatedAfter (createdAt: string|Date,
		next: (feature: FeatureTypes<FeatureTests>) => boolean) {

		return next(createdAt && dayjs(createdAt).utc().isBefore(dayjs(clientSettings.userCreatedAt).utc()));
	}

	// How to use userSegment flag
	// add a `userSegment` section to feature flags
	// The object can look as follows:
	// {
	//   <num-from-1-100>: <true/false/other-flag-config>,
	//   DEFAULT: {} <other-flag-config>
	// }
	// You may only use one of the first 3 options, and you must always include a DEFAULT.
	// To target a percentage of our users, use a num from 1-100: true
	// You can simulate A/B testing with two feature flags, one set to 50: true, DEFAULT: false
	// and the other set to 50: false, DEFAULT: true
	public userSegment (limiter: ComplexFeatureFlagValue<FeatureTests>,
		next: (feature: FeatureTypes<FeatureTests>) => boolean) {

		const keys = Object.keys(limiter);

		for (const key of keys) {
			if (key === 'DEFAULT') continue;
			const userPercentage = parseInt(key, 10);
			const userIdMod = clientSettings.userId % 100;
			if (userIdMod < userPercentage) {
				return next(limiter[key]);
			}
		}

		return next(limiter.DEFAULT);
	}

	public browsers (browsers: ComplexFeatureFlagValue<FeatureTests>,
		next: (feature: FeatureTypes<FeatureTests>) => boolean) {

		const browserName = this.UADetect.browserDetector.browser.uniqueName.toUpperCase();
		const hasBrowserTest = browserName in browsers;
		if (!hasBrowserTest) {
			return next(browsers.DEFAULT);
		}
		return next(browsers[browserName]);
	}

	// see possible values https://docs.uaparser.dev/info/os/name.html
	// when putting in a flag should be all uppercase and replace spaces with underscores
	public operatingSystems (operatingSystems: ComplexFeatureFlagValue<FeatureTests>,
		next: (feature: FeatureTypes<FeatureTests>) => boolean) {

		const osName = this.UADetect.browserDetector.result.os.name.toUpperCase().replaceAll(' ', '_');
		const hasOSTest = osName in operatingSystems;
		if (!hasOSTest) {
			return next(operatingSystems.DEFAULT);
		}
		return next(operatingSystems[osName]);
	}

	public partners (partners: ComplexFeatureFlagValue<FeatureTests>,
		next: (feature: FeatureTypes<FeatureTests>) => boolean) {

		const activePartners: string[] = (clientSettings.partners || []).map((value) => value.guid);
		const hasPartnerTest = activePartners.some((partner) => partner in partners);

		if (!hasPartnerTest) {
			return next(partners.DEFAULT);
		}

		return activePartners.some((partner) => next(partners[partner]));
	}

	public orgs (orgs: ComplexFeatureFlagValue<FeatureTests>,
		next: (feature: FeatureTypes<FeatureTests>) => boolean) {

		const activeOrgs: number[] = clientSettings.orgList || [];
		const hasOrgTest = activeOrgs.some((org) => org in orgs);
		if (!hasOrgTest) {
			return next(orgs.DEFAULT);
		}
		return activeOrgs.some((org) => next(orgs[org]));
	}

	public folders (folders: ComplexFeatureFlagValue<FeatureTests>,
		next: (feature: FeatureTypes<FeatureTests>) => boolean) {
		const selectedCourse = clientSettings.currentFolder ?? undefined;

		if (selectedCourse === undefined || folders[selectedCourse.group_id] === undefined) {
			return next(folders.DEFAULT);
		}

		return next(folders[selectedCourse.group_id]);
	}

	public orgTypes (orgsTypes: ComplexFeatureFlagValue<FeatureTests>,
		next: (feature: FeatureTypes<FeatureTests>) => boolean) {
		const orgSettings = clientSettings.orgSettings;

		if (!orgSettings) {
			return false;
		}

		const foundKey = Object.keys(orgsTypes).find((item) => orgSettings.org_type === item.toLowerCase());

		if (!foundKey) {
			return next(orgsTypes.DEFAULT);
		}

		return next(orgsTypes[foundKey]);
	}

	public releaseAt (releaseAt: string,
		next: (feature: FeatureTypes<FeatureTests>) => boolean) {

		return next(releaseAt && dayjs.utc(releaseAt).isBefore(dayjs()));
	}
}
