import * as angular from 'angular';

import { MessageModalController } from './message/modal.controller';
import messageModalTemplate from './message/modal.html';

import { UmcModalController } from './umc/modal.controller';
import umcModalTemplate from './umc/modal.html';

import { InviteUsersModalController } from './invite-users/modal.controller';
import inviteUsersModalTemplate from './invite-users/modal.html';

import { UserActivityHistoryModalController } from './user-activity-history/modal.controller';
import userActivityHistoryModalTemplate from './user-activity-history/modal.html';

import { EditUserProfileModalController } from './edit-user/modal.controller';
import editUserModalTemplate from './edit-user/modal.html';

import { SessionTimeoutModalController } from './session-timeout/modal.controller';
import sessionTimeoutModalTemplate from './session-timeout/modal.html';

import { EditSessionScoreModalController } from './edit-session-score/modal.controller';
import editSessionScoreModalTemplate from './edit-session-score/modal.html';

import rubricUnpublishedModalTemplate from './rubric-unpublished/modal.html';

import statusModalTemplate from './status/modal.html';

import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

// Warning: Do not attempt to use ModalTest to test a goModal. That's only for GoModalBootstrap, which is pretty similar
// to this, but different enough to be painful to try to get ModalTest to work for both.
/* @ngInject */
export function goModal (
	$uibModal, $uibModalStack, $rootScope, $log: angular.ILogService, $injector, $document: ng.IDocumentService) {

	const modals = {
		message: {
			template: messageModalTemplate,
			controller: MessageModalController,
			windowClass: 'message-modal'
		},
		umc: {
			template: umcModalTemplate,
			controller: UmcModalController,
			windowClass: 'universal-media-chooser-modal',
			keyboard: false,
			ariaLabelledBy: 'umc-modal',
			backdrop: 'static'
		},
		inviteUsers: {
			setup (modalData, options) {
				options.windowClass = modalData.group.isCourse() || modalData.group.isFolder() ? 'invite-users or-mode goreact-modal' : 'invite-users goreact-modal';
			},
			template: inviteUsersModalTemplate,
			controller: InviteUsersModalController,
			controllerAs: '$ctrl',
			keyboard: false,
			backdrop: 'static', // dont allow dismissing by clicking outside modal
			size: 'lg'
		},
		userActivityHistory: {
			template: userActivityHistoryModalTemplate,
			controller: UserActivityHistoryModalController,
			controllerAs: '$ctrl',
			size: 'lg',
			windowClass: 'user-activity-history'
		},
		editUser: {
			template: editUserModalTemplate,
			controller: EditUserProfileModalController,
			controllerAs: '$ctrl',
			size: 'xlg',
			backdrop: 'static',
			keyboard: false,
			windowClass: 'edit-user'
		},
		sessionTimeout: {
			template: sessionTimeoutModalTemplate,
			controller: SessionTimeoutModalController,
			windowClass: 'session-timeout-modal',
			keyboard: false,
			backdrop: 'static'
		},
		editSessionScore: {
			template: editSessionScoreModalTemplate,
			windowClass: 'edit-session-score',
			size: 'sm',
			controller: EditSessionScoreModalController,
			controllerAs: '$ctrl'
		},
		rubricUnpublished: {
			template: rubricUnpublishedModalTemplate,
			windowClass: 'rubric-unpublished-modal',
			backdrop: 'static' // dont allow dismissing by clicking outside modal
		},
		status: {
			template: statusModalTemplate,
			windowClass: 'status-indicator',
			backdrop: 'static',
			ariaLabelledBy: 'loading-modal',
			keyboard: false
		}
	};

	const openModals = {};

	function open (options) {
		const modalKey = options.modal;
		delete options.modal;

		if (!modalKey || !modals[modalKey]) {
			throw new Error('go.modal::cant find modal from options supplied');
		}

		const modal = angular.copy(modals[modalKey]);
		angular.extend(modal, options);

		options = modal;

		const modalScope = $rootScope.$new();
		if (options.modalData) {
			angular.extend(modalScope, options.modalData);
			options.scope = options.scope ? angular.extend(options.scope, modalScope) : modalScope;

			if (options.modalData.options?.appendTo) {
				const appendToElem = $document[0].querySelector(options.modalData.options.appendTo);
				options.appendTo = angular.element(appendToElem) as ng.IAugmentedJQuery;
				options.backdrop = false;
			}
		}

		options.windowClass = options.windowClass || '';
		options.windowClass += ' goreact-modal';

		(options.setup || angular.noop)(options.modalData, options);

		const instance = $uibModal.open(options);
		openModals[modalKey] = instance;

		instance.rendered.then(()=> {
			if (options.firstFocusSelector != null) {
				const el: HTMLElement | null = $document[0].querySelector(options.firstFocusSelector);
				if (el != null) {
					el.focus();
				}
			}
		}).catch($log.error);

		instance.opened.then(function () {
			$injector.get('eventService').broadcast(EVENT_NAMES.GO_MODAL_OPENED, options);
		}).catch($log.error);

		// success function (modal closed promise resolved)
		instance.result.then(() => {
			delete openModals[modalKey];
			$injector.get('eventService').broadcast(EVENT_NAMES.GO_MODAL_CLOSED, options);

			if (!$uibModalStack.getTop()) {
				$injector.get('eventService').broadcast(EVENT_NAMES.GO_MODAL_ALL_CLOSED, options);
			}

			modalScope.$destroy();
		// fail function (modal dismissed promise rejected)
		}, () => {
			delete openModals[modalKey];
			$injector.get('eventService').broadcast(EVENT_NAMES.GO_MODAL_CLOSED, options);

			if (!$uibModalStack.getTop()) {
				$injector.get('eventService').broadcast(EVENT_NAMES.GO_MODAL_ALL_CLOSED, options);
			}

			modalScope.$destroy();
		});

		return instance;
	}

	return {
		open,
		dismissAll: () => $uibModalStack.dismissAll(),
		getTop: () => $uibModalStack.getTop()
	};
}

goModal.NG1_INJECTION_NAME = 'goModal' as const;
export const goModalToken = upgradeNg1Dependency(goModal);
