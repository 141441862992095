import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { VolumeIndicatorHelper } from './volume-indicator.helper';

@Component({
	selector: 'volume-indicator',
	template: require('./volume-indicator.component.html'),
	styles: [require('./volume-indicator.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VolumeIndicatorComponent implements OnInit, OnDestroy {
	@Input() public audioContext: AudioContext;
	@Input() public mediaStream: MediaStream;
	@Input() public active: boolean;

	public numberOfCells: number;
	public noAudioClass = 'grey';

	private audioSourceNode: MediaStreamAudioSourceNode;
	private gainNode: GainNode;
	private gainLevel: number = 0;
	private volumeIndicatorHelper: VolumeIndicatorHelper;
	private processor: AudioWorkletNode;

	constructor () {}

	public ngOnInit (): void {
		this.numberOfCells = 25;
		this.volumeIndicatorHelper = new VolumeIndicatorHelper();
		this.gainLevel = this.gainLevel ?? 0;
	}

	public ngOnChanges (changes: SimpleChanges): void {
		if (changes.audioContext?.firstChange && changes.mediaStream?.firstChange) {
			return;
		}

		this.cleanup();

		if (!this.active) {
			document.querySelectorAll('div.volume-cell')
				.forEach((element: HTMLElement) => element.classList.add(this.noAudioClass));
			return;
		}

		if (this.audioContext && this.mediaStream && this.mediaStream.getAudioTracks().length) {
			let promise = Promise.resolve();

			try {
				this.processor = new AudioWorkletNode(this.audioContext, 'audio-processor');
			} catch (err) {
				promise = this.audioContext.audioWorklet.addModule(
					URL.createObjectURL(new Blob([this.volumeIndicatorHelper.generateAudioWorkletProcessor()], { type: 'application/javascript' }))
				).then(() => {
					this.processor = new AudioWorkletNode(this.audioContext, 'audio-processor');
				});
			}

			promise.then(() => {
				this.gainNode = this.audioContext.createGain();
				this.audioSourceNode = this.audioContext.createMediaStreamSource(this.mediaStream);
				this.audioSourceNode.connect(this.gainNode);
				this.audioSourceNode.connect(this.processor);
				this.processor.connect(this.audioContext.destination);
				this.processor.port.onmessage = this.onAudioProcessHandler.bind(this);
				this.gainLevel = this.gainNode.gain.value;
			});
		}
	}

	public ngOnDestroy (): void {
		this.cleanup();
	}

	private cleanup (): void {
		// Ensure all existing audio nodes are disconnected
		if (this.processor) {
			this.processor.disconnect();
		}
		if (this.audioSourceNode) {
			this.audioSourceNode.disconnect();
		}
		if (this.gainNode) {
			this.gainNode.disconnect();
		}
	}

	private onAudioProcessHandler (event): void {
		if (!this.active) return;
		const inputPercentLevel = event.data.volume;
		const trueInputLevel = inputPercentLevel * this.gainLevel;
		const blackIndexStop = Math.floor(
			(1 - trueInputLevel) * this.numberOfCells
		);

		try {
			document
				.querySelectorAll('div.volume-cell')
				.forEach((element) => element.classList.remove(this.noAudioClass));
			document
				.querySelectorAll(
					`div.volume-cell:nth-last-child(-n+${blackIndexStop})`
				)
				.forEach((element) => element.classList.add(this.noAudioClass));
		} catch (error) {
			// Do Nothing
		}
	}
}
