import * as angular from 'angular';
import * as ngCookies from 'angular-cookies';
import uiRouter from '@uirouter/angularjs';

import { sessionManagerConfig } from './session-manager.config';
import { SessionManagerService } from './session-manager.service';
import { SessionStorageService } from './session-storage.service';
import { authServiceModule } from 'go-modules/services/auth';
import { eventServiceModule } from 'ngx/go-modules/src/services/event';

export const sessionManager = angular.module('go.session-manager', [
	authServiceModule,
	ngCookies,
	eventServiceModule,
	uiRouter
])
	.config(sessionManagerConfig)
	.service(SessionManagerService.NG1_INJECTION_NAME, SessionManagerService)
	.service(SessionStorageService.NG1_INJECTION_NAME, SessionStorageService)
	.name;
