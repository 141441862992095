import type { SessionCreationWizardOptions } from '../options';
import type { SessionCreationWizardStep } from './';
import { MODE as INSTRUCTIONS_MODE } from '../../modals/activity-instructions/modes';
import { SelectedService } from 'go-modules/services/selected/selected.service';

export class InstructionsStep implements SessionCreationWizardStep {

	/* @ngInject */
	constructor (
		private activityInstructionsModal: any,
		private selectedService: SelectedService
	) {}

	/**
	 * Run this step
	 */
	public run (options: SessionCreationWizardOptions): ng.IPromise<void> {
		const group = this.selectedService.getGroup();
		const embiggen = options.activity.hasRecordingInstructions() &&
			!!options.activity.recording_instructions.media &&
			!group.license_trial_exists;
		const modalInstance: any = this.activityInstructionsModal.open({
			modalData: {
				activity: options.activity,
				mode: INSTRUCTIONS_MODE.RECORDING,
				showHeadPhones: this.isHeadPhonesInstructionsRequired(
					options.activity,
					options.session
				),
				embiggen
			}
		});

		// Set a flag that indicates that the session recording instructions have been viewed
		modalInstance.opened
			.then(() => {
				if (!options.session.hasViewedRecordingInstructions()) {
					options.session.setViewedRecordingInstructions();
				}
			});

		return modalInstance.result;
	}

	/**
	 * Determine whether this step is required
	 */
	public isRequired (options: SessionCreationWizardOptions): boolean {
		return options.activity.hasRecordingInstructions() ||
			this.isHeadPhonesInstructionsRequired(options.activity, options.session);
	}

	/**
	 * Determine whether the head phones instructions are required
	 */
	public isHeadPhonesInstructionsRequired (activity: any, session: any): boolean {
		let sourceMedia: any = null;
		if (session.source_media) {
			sourceMedia = session.source_media;
		} else if (activity.source_media) {
			sourceMedia = activity.source_media.media;
		}
		return !!sourceMedia && sourceMedia.hasAudio();
	}
}
