import * as angular from 'angular';
import * as uiTooltip from 'angular-ui-bootstrap/src/tooltip';
import * as uiPopover from 'angular-ui-bootstrap/src/popover';
import * as angulartics from 'angulartics';
import * as angularTranslate from 'angular-translate';
import 'bootstrap-css-only';


import { activityModel } from '../models/activity';
import { groupDepModel } from '../models/group-dep';
import { rubricEditorModalModule } from '../modals/rubric-editor';
import { goPopoverModule } from '../go-popover';
import { activityAttachments } from '../activity-attachments';
import { panel } from '../panel';

import { activityInfo } from './activity-info.component';

import './style.less';
import { ngxMediaCardModule } from 'ngx/go-modules/src/components/media-card';
import { ngxRichInstructionsModule } from 'ngx/go-modules/src/components/rich-instructions';
// TODO: fix circular dependency.
// Until then, we need to import directly to force side-effect of angular module
// loading. If we leave the `fooModule from` part, webpack will use tree-shaking
// and the module will not be loaded.
// eslint-disable-next-line @typescript-eslint/tslint/config
import /*{ mediaThumbnailModule } from*/ '../media-thumbnail';

export const activityInfoModule = angular.module('go.activity-info', [
	activityModel,
	groupDepModel,
	angularTranslate,
	rubricEditorModalModule,
	uiTooltip,
	uiPopover,
	goPopoverModule,
	angulartics,
	activityAttachments,
	panel,
	ngxMediaCardModule,
	ngxRichInstructionsModule,
	'go.mediaThumbnail'
])
	.component('activityInfo', activityInfo)
	.name;
