import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { map, Observable, throwError } from 'rxjs';
import type { Product } from 'go-modules/services/group/product';
import type { License, LicenseHolder, LicenseHolderInvite, LicenseSeatsResponse, LicenseSeatsQueryParams } from 'ngx/go-modules/src/interfaces/licenses';
import type { Account } from 'ngx/go-modules/src/interfaces/account';
import { groupToken } from 'go-modules/models/group-dep/group.factory';
import { catchError } from 'rxjs/operators';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';

export type LicenseHolderInviteCreate = Pick<LicenseHolderInvite, 'email' | 'is_admin'>;

export interface LicenseHoldersAndInvitesResponse {
	license_holders: LicenseHolder[];
	license_holder_invites: LicenseHolderInvite[];
}

@Injectable({
	providedIn: 'root'
})
export class LicenseService {
	public constructor (
		private http: HttpClient,
		private ngxGoToastService: NgxGoToastService,
		@Inject(groupToken) private groupModel
	) {}

	public getLicense (licenseId: number): Observable<License> {
		return this.http
			.get<License>(`${clientSettings.GoReactV2API}/licenses/${licenseId}`);
	}

	public getLicenseSeats (licenseId: number, queryParams: LicenseSeatsQueryParams): Observable<LicenseSeatsResponse> {
		const searchParams = new URLSearchParams();

		Object.keys(queryParams).forEach((key) => {
			if (queryParams[key] && typeof queryParams[key] === 'object') {
				for (const [objKey, objValue] of Object.entries(queryParams[key])) {
					searchParams.append(`${key}[${objKey}]`, String(objValue));
				}
			} else if (queryParams[key] !== null) {
				searchParams.append(key, queryParams[key]);
			}
		});

		return this.http
			.get<LicenseSeatsResponse>(`${clientSettings.GoReactV2API}/licenses/${licenseId}/seats?${searchParams.toString()}`);
	}

	public addLicenseHolders (
		licenseId: number,
		invites: LicenseHolderInviteCreate[]
	): Observable<LicenseHoldersAndInvitesResponse> {
		return this.http
			.post<LicenseHoldersAndInvitesResponse>(`${clientSettings.GoReactV2API}/licenses/${licenseId}/license-holders`, {
			license_holders: invites
		});
	}

	public licenseProduct (licenseId: number): Observable<Product|null> {
		return this.http
			.get<Product|null>(`${clientSettings.GoReactV2API}/licenses/${licenseId}/product`);
	}

	public updateLicenseHolder (licenseId: number, holder: LicenseHolder): Observable<void> {
		return this.http
			.put<void>(`${clientSettings.GoReactV2API}/licenses/${licenseId}/license-holders/${holder.id}`, holder);
	}

	public updateLicenseHolderInvite (licenseId: number, invite: LicenseHolderInvite) {
		return this.http
			.put<void>(`${clientSettings.GoReactV2API}/licenses/${licenseId}/license-holder-invites/${invite.id}`, invite);
	}

	public removeLicenseHolder (licenseId: number, id: number): Observable<void> {
		return this.http
			.delete<void>(`${clientSettings.GoReactV2API}/licenses/${licenseId}/license-holders/${id}`);
	}

	public removeLicenseHolderInvite (licenseId: number, id: number): Observable<void> {
		return this.http
			.delete<void>(`${clientSettings.GoReactV2API}/licenses/${licenseId}/license-holder-invites/${id}`);
	}

	public removeUserFromAllCourses (licenseId: number, userId: number) {
		return this.http
			.delete<void>(`${clientSettings.GoReactV2API}/licenses/${licenseId}/users/${userId}/seats`);
	}

	public requestUpgrade (licenseId: number, groupId?: number | null): Observable<void> {
		return this.http.post<void>(
			`${clientSettings.GoReactV2API}/licenses/${licenseId}/request-upgrade`,
			{ group_id: groupId ?? undefined }
		);
	}

	public contactSales (licenseId: number): Observable<void> {
		return this.http.post<void>(
			`${clientSettings.GoReactV2API}/licenses/${licenseId}/contact-sales`, {  }
		);
	}

	public fetchOrCreateUserAccount (licenseId: number, preferredOrg?: number | null): Observable<Account> {
		return this.http.put<Account>(
			`${clientSettings.GoReactV2API}/licenses/${licenseId}/account`,
			{ preferred_org: preferredOrg ?? undefined }
		).pipe(
			map((account) => this.groupModel.model(account)),
			catchError((err)=> {
				if(err instanceof HttpErrorResponse){
					if (err.status === 403) {
						this.ngxGoToastService.createToast({
							type: GoToastStatusType.ERROR,
							message: 'license-service-fetch-user-account-forbidden-error'
						});
					} else {
						this.ngxGoToastService.createToast({
							type: GoToastStatusType.ERROR,
							message: 'license-service-fetch-user-account-unknown-error'
						});
					}
				}

				return throwError(()=> err);
			})
		);
	}
}
