import { Component } from '@angular/core';
@Component({
	selector: 'network-indicator',
	template: require('./network-indicator.component.html'),
	styles: [require('./network-indicator.component.scss')]
})
export class NetworkIndicatorComponent {

	constructor () {}
}
