import { EVENTS } from '../events';
import { STATES } from '../states';
import { PROVIDERS } from '../providers';
import { GoMediaPlayer } from './media-player';
import type { PlaylistItem } from '../playlist-item';
import { GoMediaPlayerError } from '../errors/error';
import { VideoVolumeManager } from '../services/video-volume-manager';
import { noop } from 'angular';

export interface HTMLTrackOptions {
	default: boolean;
	kind: 'subtitles' | 'captions' | 'descriptions' | 'chapters' | 'metadata';
	label: string;
	src: string;
	srclang?: string; // BCP47. Not optional if kind === 'subtitles';
};

export interface HTML5PlayerOptions {
	url: string;
	thumbnailUrl: string | null;
	tracks: HTMLTrackOptions[];
}

export class HTML5PlayerAdapter extends GoMediaPlayer {
	private captionsOn: boolean;
	private videoVolumeManager: VideoVolumeManager;
	private playbackRateSupported: boolean = null;

	constructor (private video: HTMLVideoElement) {
		super();

		this.captionsOn = video.textTracks.length > 0;
		this.videoVolumeManager = new VideoVolumeManager(this.video);
		this.initEventAdapter();
	}

	public static setup (parentElement: HTMLElement, options: HTML5PlayerOptions): Promise<HTML5PlayerAdapter> {
		const video = document.createElement('video');

		// Stop small iOS device from playing full screen (DEV-8311)
		video.setAttribute('playsinline', '');

		// Credentials are in the query params, don't use cors
		video.setAttribute('crossorigin', 'anonymous');

		parentElement.appendChild(video);

		// Set up thumbnail before video loads.
		if (options.thumbnailUrl) {
			video.setAttribute('poster', options.thumbnailUrl);
		}

		return new Promise((resolve, reject) => {
			const errorLoadListener = (event: ErrorEvent) => {
				// If its a problem loading the video, there will be an
				// error on the target. If not, it could just be the poster so we don't care
				if ((event.target as HTMLVideoElement).error) {
					// We need to cancel the other event, but eslint is mad about our catch-22
					// For one listener has to be defined before the other.
					// eslint-disable-next-line @typescript-eslint/no-use-before-define
					video.removeEventListener('loadedmetadata', successLoadListener);
					reject(new GoMediaPlayerError(event.message));
				}
			};
			const successLoadListener = () => {
				video.removeEventListener('error', errorLoadListener);

				// Load caption files
				// We wait until now, just in case caption loading fails, as that'll race with the video loading
				for (const track of options.tracks) {
					// These are default on
					const trackDom = document.createElement('track');
					trackDom.setAttribute('crossorigin', 'anonymous');
					trackDom.toggleAttribute('default', track.default);
					trackDom.setAttribute('kind', track.kind);
					trackDom.setAttribute('label', track.label);
					trackDom.setAttribute('src', track.src);
					if (track.srclang != null) {
						trackDom.setAttribute('srclang', track.srclang);
					}
					video.appendChild(trackDom);
				}

				resolve(new HTML5PlayerAdapter(video));
			};

			video.addEventListener('error', errorLoadListener, {once: true});
			video.addEventListener('loadedmetadata', successLoadListener, {once: true});

			video.setAttribute('src', options.url);
		});
	}

	public destroy (): void {
		this.videoVolumeManager.destroy();
		this.removeEventListeners();
		// The `pause`, `removeAttribute` and `load` are a recommendation to remove the video element
		// from this SO question:
		//   https://stackoverflow.com/questions/3258587/how-to-properly-unload-destroy-a-video-element/40419032.
		// If we don't do it this way, memory leaks could occur.
		this.video.pause();
		this.video.removeAttribute('src');
		this.video.load();
		this.video.remove();
		super.destroy();
	}

	public play (): void {
		this.emit(EVENTS.REQUEST_PLAY);
		this.video.play().catch(noop);
	}

	public pause (): void {
		this.emit(EVENTS.REQUEST_PAUSE);
		this.video.pause();
	}

	public stop (): void {
		this.pause();
		this.seekTo(0);
	}

	public getTime (): number {
		return this.video.currentTime * 1000;
	}

	public getDuration (): number {
		return this.video.duration * 1000;
	}

	public getElement (): HTMLVideoElement {
		return this.video;
	}

	public getMute (): boolean {
		return this.videoVolumeManager.getMuted();
	}

	public async setMute (muted: boolean): Promise<void> {
		this.videoVolumeManager.setMuted(muted);
	}

	public setVolume (volume: number): void {
		this.videoVolumeManager.setVolume(volume / 100);
	}

	public getVolume (): number {
		return this.videoVolumeManager.getVolume() * 100;
	}

	public isPlaybackRateSupported (): boolean {
		// Return the cached value if we've figured this out
		if (this.playbackRateSupported != null) {
			return this.playbackRateSupported;
		}

		// If the rate is different than 1, then we know it's supported
		if (this.video.playbackRate !== 1) {
			this.playbackRateSupported = true;
			return true;
		}

		try {
			// We need to attempt to modify it
			this.video.playbackRate = 2;
		} catch (e) {
			// We know playback isn't supported if it errors
			this.playbackRateSupported = false;
			return false;
		}

		// It didn't error, but it could have silently failed. Check to see if it changed
		this.playbackRateSupported = this.video.playbackRate === 2;

		// And change it back to 1 so the user doesn't see anything different
		this.video.playbackRate = 1;

		return this.playbackRateSupported;
	}

	public get playbackRate (): number {
		return this.video.playbackRate;
	}

	public set playbackRate (value: number) {
		this.video.playbackRate = value;
	}

	public captionsEnabled (): boolean {
		return this.captionsOn;
	}

	public toggleCaptions (enable: boolean = !this.captionsOn): void {
		this.captionsOn = enable;

		if (this.video.textTracks.length > 0) {
			// We have manual captions. Toggle them instead.
			// We only support one caption track at a time
			this.video.textTracks[0].mode = this.captionsOn ? 'showing' : 'hidden';
			return;
		}

		// Otherwise we'll use Watson to generate automatic captions.
		// Emit an audio stream from the video for it to listen to.
		if (this.captionsOn) {
			this.emit(EVENTS.AUDIO_STREAM, this.videoVolumeManager.getUngainedStream());
		} else {
			this.emit(EVENTS.AUDIO_STREAM, null);
		}
	}

	public hasCaptions (): boolean {
		return true;
	}

	public getPlaylistItem (): PlaylistItem {
		return {
			file: this.video.getAttribute('src')
		};
	}

	public getProvider (): {name: PROVIDERS} {
		return { name: PROVIDERS.HTML5 };
	}

	protected seekTo (seconds: number): void {
		this.video.currentTime = seconds / 1000;
	}

	private initEventAdapter (): void {
		this.video.addEventListener('playing', this.playingListener);
		this.video.addEventListener('pause', this.pauseListener);
		this.video.addEventListener('ended', this.endedListener);
		this.video.addEventListener('error', this.errorListener);
	}

	private removeEventListeners (): void {
		this.video.removeEventListener('playing', this.playingListener);
		this.video.removeEventListener('pause', this.pauseListener);
		this.video.removeEventListener('ended', this.endedListener);
		this.video.removeEventListener('error', this.errorListener);
	}

	private playingListener = (): void => {
		this.setState(STATES.PLAYING);
	};

	private pauseListener = (): void => {
		this.setState(STATES.PAUSED);
	};

	private endedListener = (): void => {
		this.setState(STATES.COMPLETE);
	};

	private errorListener = (event: {message: string}): void => {
		this.emit(EVENTS.ERROR, new GoMediaPlayerError(event.message));
	};
}
