import { Component, Inject } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';

export interface Bindings {
	message: string;
	title: string;
	rememberKey: string;
	rememberText?: string;
	rejectBtnText?: string;
	resolveBtnClass?: string;
	resolveBtnText?: string;
	rejectBtnClass?: string;
}

@Component({
	selector: 'message-remembering',
	template: require('./message-remembering-dialog.component.html'),
	styles: [require('./message-remembering-dialog.component.scss')]
})
export class MessageRememberingDialogComponent {
	public message: string;
	public title: string;
	public remember: boolean = false;
	public rememberKey: string;
	public rememberText: string;
	public rejectBtnText?: string;
	public resolveBtnClass: string;
	public resolveBtnText: string;
	public rejectBtnClass: string;

	constructor (
		public dialogRef: GoDialogRef,
		@Inject(GO_MODAL_DATA) public data: Bindings
	) {}

	public ngOnInit () {
		if (!this.data.message || !this.data.title || !this.data.rememberKey) {
			throw new Error('MessageRememberingModalController:: must supply a message, title and rememberKey');
		}
		this.message = this.data.message;
		this.title = this.data.title;
		this.rememberKey = this.data.rememberKey;

		if (localStorage.getItem(this.rememberKey)) {
			this.dialogRef.close();
		}

		this.rememberText = this.data.rememberText || 'modal-message-remember_remember-text';
		this.resolveBtnClass = this.data.resolveBtnClass || 'primary-btn';
		this.resolveBtnText = this.data.resolveBtnText || 'common_close';
		this.rejectBtnClass = this.data.rejectBtnClass || 'secondary-btn';
	}

	public close () {
		if (this.remember) {
			localStorage.setItem(this.rememberKey, 'true');
		}
		this.dialogRef.close();
	}
}
