import { Injectable } from '@angular/core';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { HttpClient } from '@angular/common/http';

export interface ApiGetMediaRecordingStats {
	id: number;
	media_id: number;
	record_start_ms: number;
	record_end_ms: number;
	packets_sent: number;
	packets_lost: number;
	connection_quality: RecordingConnectionQuality;
	user_id: number;
	created_at: string;
}

export interface ApiPostMediaRecordingStats {
	record_start_ms: number;
	record_end_ms: number;
	packets_sent: number;
	packets_lost: number;
	connection_quality: RecordingConnectionQuality;
}

export enum RecordingConnectionQuality {
	POOR='poor',
	ACCEPTABLE='acceptable',
	GOOD='good'
}

@Injectable({
	providedIn: 'root'
})
export class NgxMediaRecordingStatsService {

	constructor (private http: HttpClient) {}

	public getMediaRecordingStats (mediaId: number) {
		return this.http.get<ApiGetMediaRecordingStats[]>(`${clientSettings.GoReactV2API}/media/${mediaId}/recording-statistics`);
	}

	public postMediaRecordingStats (mediaId: number, metrics: ApiPostMediaRecordingStats) {
		return this.http.post<void>(`${clientSettings.GoReactV2API}/media/${mediaId}/recording-statistics`, metrics);
	}
}
