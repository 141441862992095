import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { UADetect, uaDetectToken } from 'go-modules/detect/ua-detect.service';

@Component({
	selector: 'ios-upload-dialog',
	template: require('./ios-upload-dialog.component.html'),
	styles: [require('./ios-upload-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IosUploadDialogComponent {
	public iosVersion: number;
	public imageLoaded = false;

	constructor (
		public dialogRef: GoDialogRef,
		@Inject(uaDetectToken) public uaDetect: UADetect
	) {
		this.iosVersion = parseInt(this.uaDetect.browserDetector.result.os.version.split('.')[0], 10);
	}

	public close () {
		this.dialogRef.close();
	}
}
