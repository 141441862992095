import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { delay, interval, take } from 'rxjs';
import { NgxCommentService } from 'ngx/go-modules/src/services/comment/comment.service';
import { CommentReaction } from 'ngx/go-modules/src/interfaces/comments/comment';

export enum MODES {
	DISPLAY = 'display',
	ADD = 'add',
	FEEDBACK = 'feedback'
};

interface Reaction {
	emoji: string;
	count: number;
	i_reacted: boolean;
	variants: {
		emoji: string;
		count: number;
		reactors: string[];
		i_reacted: boolean;
	}[];
};

export const SKIN_TONE_KEY = 'emoji-mart.skin';

@Component({
	selector: 'ngx-comment-reactions',
	template: require('./comment-reactions.component.html'),
	styles: [require('./comment-reactions.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxCommentReactionsComponent implements OnInit {
	@Input() public commentId: number;
	@Input() public reactions: CommentReaction[];
	@Input() public mode: MODES;
	@Input() public isAiComment: boolean = false;
	@Output() public reactionsChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() public reactionAdded: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild(MatMenuTrigger) private emojiMenuTrigger: MatMenuTrigger;
	public uniqueReactions: Reaction[] = [];
	public modes = MODES;

	constructor (
		private cdr: ChangeDetectorRef,
		private commentService: NgxCommentService,
		private translate: TranslateService,
		@Inject(userServiceToken) private userService: UserService
	) {}

	public ngOnInit () {
		if (this.reactions || this.mode === MODES.FEEDBACK) {
			this.setUniqueReactions(this.reactions);
		}
	}

	public ngOnChanges (changes: SimpleChanges): void {
		if ('reactions' in changes && changes.reactions.currentValue != null) {
			this.setUniqueReactions(changes.reactions.currentValue);
		}
	}

	public focusSearchField (event) {
		event.stopPropagation();
		interval(100)
			.pipe(
				take(1),
				delay(1)
			).subscribe(() => {
				const searchField: HTMLElement = document.querySelector('div.emoji-mart-search input[type="search"]');
				searchField?.focus();
			});
	}

	// TODO simplify this with ngx-translate pluralization STAB-578
	public getTooltip (reaction) {
		if (this.mode === MODES.FEEDBACK) {
			if (this.getBaseEmoji(reaction.emoji) === '👍') {
				return this.translate.instant('feedback-panel-ai-feedback-good');
			}
			return this.translate.instant('feedback-panel-ai-feedback-bad');
		}
		let tooltip = '';
		reaction.variants.forEach((variant) => {
			if (variant.i_reacted) {
				if (variant.reactors.length > 1) {
					const names = [...variant.reactors];
					const lastName = names.pop();
					tooltip += this.translate.instant('feedback-panel-you-and-many-other-reactors', {
						names: names.join(', '),
						lastName,
						emoji: variant.emoji
					});
				} else if (variant.reactors.length === 1) {
					tooltip += this.translate.instant('feedback-panel-you-and-one-other-reactor', {
						name: variant.reactors[0],
						emoji: variant.emoji
					});
				} else {
					tooltip += this.translate.instant('feedback-panel-just-you-reactor', {
						emoji: variant.emoji
					});
				}
				tooltip += '\n';
			} else {
				if (variant.reactors.length > 2) {
					const names = [...variant.reactors];
					const lastName = names.pop();
					tooltip += this.translate.instant('feedback-panel-many-other-reactors', {
						names: names.join(', '),
						lastName,
						emoji: variant.emoji
					});
				} else if (variant.reactors.length === 2) {
					tooltip += this.translate.instant('feedback-panel-two-other-reactors', {
						nameOne: variant.reactors[0],
						nameTwo: variant.reactors[1],
						emoji: variant.emoji
					});
				} else {
					tooltip += this.translate.instant('feedback-panel-one-other-reactor', {
						name: variant.reactors[0],
						emoji: variant.emoji
					});
				}
				tooltip += '\n';
			}
		});
		return tooltip;
	}

	public toggleReaction (event, emoji) {
		event.stopPropagation();
		const alreadyAdded = this.checkIfAlreadyAdded(emoji);
		if (alreadyAdded) {
			this.removeReaction(emoji, alreadyAdded.id);
		} else {
			const withSkinTone = this.addSkinTone(this.getBaseEmoji(emoji));
			this.addEmoji(withSkinTone);
		}
	}

	public addReaction (event) {
		if (this.checkIfAlreadyAdded(event.emoji.native)) {
			return this.emojiMenuTrigger.closeMenu();
		} else {
			this.addEmoji(event.emoji.native);
			this.emojiMenuTrigger.closeMenu();
		}
	}

	private removeReaction (emoji, reactionId) {
		this.commentService.deleteReaction(this.commentId, reactionId)
			.subscribe(() => {
				const uniqueReaction = this.uniqueReactions.find(((reaction) => {
					const baseEmoji = this.getBaseEmoji(reaction.emoji);
					return baseEmoji === emoji;
				}));
				uniqueReaction.count--;
				if (uniqueReaction.count === 0 && this.mode !== MODES.FEEDBACK) {
					const uniqueIndex = this.uniqueReactions.findIndex(((reaction) => {
						const baseEmoji = this.getBaseEmoji(reaction.emoji);
						return baseEmoji === emoji;
					}));
					this.uniqueReactions.splice(uniqueIndex, 1);
				} else {
					uniqueReaction.i_reacted = false;
					const variant = uniqueReaction.variants.find((variant) => variant.i_reacted);
					variant.count--;
					if (this.mode !== MODES.FEEDBACK && variant.count === 0) {
						uniqueReaction.variants.splice(uniqueReaction.variants.indexOf(variant), 1);
					} else {
						variant.i_reacted = false;
					}
				}
				const reactionIndex = this.reactions.findIndex(((reaction) => {
					const baseEmoji = this.getBaseEmoji(reaction.emoji);
					return baseEmoji === emoji &&
						reaction.created_by_user_id === this.userService.currentUser.user_id;
				}));
				this.reactions.splice(reactionIndex, 1);
				if (!this.reactions.length) {
					return this.reactionsChange.emit(this.reactions);
				}
				this.cdr.detectChanges();
			});
	}

	private addEmoji (emoji) {
		this.commentService.addReaction(this.commentId, {emoji})
			.subscribe((res: CommentReaction) => {
				if (!this.reactions) {
					this.reactions = [res];
				} else {
					this.reactions.push(res);
				}
				if (this.mode === MODES.ADD || this.mode === MODES.FEEDBACK) {
					this.reactionsChange.emit(this.reactions);
				} else {
					const baseEmoji = this.getBaseEmoji(res.emoji);
					const objIndex = this.uniqueReactions.findIndex(((reaction) => reaction.emoji === baseEmoji));
					if (objIndex >= 0) {
						this.uniqueReactions[objIndex].count++;
						this.uniqueReactions[objIndex].i_reacted = true;
						const variant = this.uniqueReactions[objIndex].variants
							.find((variant) => variant.emoji === res.emoji);
						if (variant) {
							variant.count++;
							variant.i_reacted = true;
						} else {
							this.uniqueReactions[objIndex].variants.push({
								emoji: res.emoji, reactors: [], count: 1, i_reacted: true
							});
						}
					} else {
						this.uniqueReactions.push({
							emoji: this.getBaseEmoji(res.emoji),
							count: 1,
							i_reacted: true,
							variants: [{emoji: res.emoji, reactors: [], count: 1, i_reacted: true}]
						});
					}
					this.cdr.detectChanges();
				}
				this.reactionAdded.emit(res);
			});
	}

	public handleKeyDownEmojiPopup ($event) {
		if ($event.key === 'Escape') {
			this.emojiMenuTrigger.closeMenu();
		} else {
			$event.stopPropagation();
		}
	}

	private checkIfAlreadyAdded (emoji) {
		if (!this.reactions) {
			return null;
		}
		return this.reactions.find((reaction) => {
			const baseEmoji = this.getBaseEmoji(reaction.emoji);
			return baseEmoji === emoji && reaction.created_by_user_id === this.userService.currentUser.user_id;
		});
	}

	private setUniqueReactions (reactions) {
		this.uniqueReactions = Object.values(reactions.reduce((obj, item) => {
			const baseEmoji = this.getBaseEmoji(item.emoji);
			obj[baseEmoji] = obj[baseEmoji] ||
				{
					emoji: baseEmoji,
					variants: [],
					count: 0,
					i_reacted: false
				};
			if (!obj[baseEmoji].variants.some((variant) => variant.emoji === item.emoji)) {
				obj[baseEmoji].variants.push({emoji: item.emoji, reactors: [], count: 0, i_reacted: false});
			}
			obj[baseEmoji].count++;
			const variant = obj[baseEmoji].variants.find((variant) => variant.emoji === item.emoji);
			variant.count++;
			if (item.created_by_user_id === this.userService.currentUser.user_id) {
				obj[baseEmoji].i_reacted = true;
				variant.i_reacted = true;
			} else {
				obj[baseEmoji].variants.find((variant) => variant.emoji === item.emoji)
					.reactors.push(item.created_by_user_name);
			}
			return obj;
		}, {}));

		if (this.mode === MODES.FEEDBACK) {
			const thumbsUpExists = this.uniqueReactions.some((reaction) => this.getBaseEmoji(reaction.emoji) === '👍');
			if (!thumbsUpExists) {
				this.uniqueReactions.unshift({emoji: '👍', count: 0, i_reacted: false, variants: [
					{emoji: '👍', reactors: [], count: 0, i_reacted: false}
				]});
			}
			const thumbsDownExists = this.uniqueReactions.some((reaction) => this.getBaseEmoji(reaction.emoji) === '👎');
			if (!thumbsDownExists) {
				this.uniqueReactions.push({emoji: '👎', count: 0, i_reacted: false, variants: [
					{emoji: '👎', reactors: [], count: 0, i_reacted: false}
				]});
			}
		}
	}

	private getBaseEmoji (emoji) {
		// remove 5 different skin tones from emoji (as listed below in addSkinTone)
		// so we have a base emoji for comparison
		return emoji.replace(/[\u{1F3FB}-\u{1F3FF}]/gu, '');
	}

	private addSkinTone (emoji) {
		const preferredSkinTone = localStorage.getItem(SKIN_TONE_KEY);
		if (preferredSkinTone) {
			const utfs = {
				1: '',
				2: '\u{1F3FB}',
				3: '\u{1F3FC}',
				4: '\u{1F3FD}',
				5: '\u{1F3FE}',
				6: '\u{1F3FF}'
			};
			emoji = emoji + utfs[preferredSkinTone];
		}
		return emoji;
	}
}
