import * as angular from 'angular';
import { ORIENTATION } from '../bg-splitter.controller';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { Subject, takeUntil } from 'rxjs';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

/* @ngInject */
export const BgSplitterHandleController = function (
	$element: ng.IRootElementService,
	WebStorage,
	$window: ng.IWindowService,
	$scope: ng.IScope,
	$translate: ng.translate.ITranslateService,
	feedbackSession,
	eventService: EventService
) {
	const vm = this;
	const storage = new WebStorage('splitter');
	let destroy$: Subject<void>;
	let handleId,
		handleWidth,
		handleHeight,
		prev,
		next,
		accessibilityElem: HTMLSpanElement,
		dragging: {
			offsetX: number;
			offsetY: number;
		} | null = null;

	vm.grabbed = false;
	vm.isDragging = false;

	const clearStyles = {
		top: '',
		bottom: '',
		right: '',
		left: '',
		height: '',
		width: ''
	};

	const feedbackDisplayPanelHeight = 74;

	/**
	 * Handle controller init
	 */
	vm.$onInit = () => {
		destroy$ = new Subject<void>();
		if (vm.bgSplitterController.getId()) {
			handleId = 'resize-handle-' + vm.bgSplitterController.getId();
		}
		eventService.listen([
			EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_EXPANDED
		])
			.pipe(takeUntil(destroy$))
			.subscribe(() => {
				const bounds = vm.bgSplitterController.getBounds();
				setHandleHeightIfNotSet();
				resize({
					offsetX: 0,
					offsetY: Math.min((bounds.height - handleHeight) / 3,
						bounds.height - handleHeight - vm.bgSplitterController.getMinSize())
				});
			});
	};

	/**
	 * Handle post link
	 */
	vm.$postLink = () => {
		prev = angular.element($element[0].previousElementSibling);
		next = angular.element($element[0].nextElementSibling);

		// Add event listeners
		$element.on('mousedown', onMouseDown);
		angular.element(document).on('mousemove', onMouseMove);
		angular.element(document).on('mouseup', onMouseUp);
		$element.on('focusout', onFocusOut);
		$element.on('focusin', onFocusIn);
		accessibilityElem = $element[0].querySelector('.accessibility-text');

		$element.on('touchstart', onTouchStart);
		angular.element(document).on('touchmove', onTouchMove);
		angular.element(document).on('touchend', onTouchEnd);

		$element.on('resize-split-handle', onResize);
		angular.element(window).on('resize', onResize);
		angular.element(document).on('mouseout', onMouseOutWindow);

		// Accessibility listeners
		$element.on('keydown', onKeyDown);

		if (vm.bgSplitterController.isMobile) {
			resize({
				offsetX: 0,
				offsetY: $element[0].parentElement.offsetHeight / 3
			});
		} else {
			resize(storage.get(handleId));
		}
	};

	/**
	 * Handle controller destroy
	 */
	vm.$onDestroy = () => {
		// Remove event listeners
		$element.off('mousedown', onMouseDown);
		angular.element(document).off('mousemove', onMouseMove);
		angular.element(document).off('mouseup', onMouseUp);
		$element.off('focusout', onFocusOut);
		$element.off('focusin', onFocusIn);

		$element.off('touchstart', onTouchStart);
		angular.element(document).off('touchmove', onTouchMove);
		angular.element(document).off('touchend', onTouchEnd);

		$element.off('resize-split-handle', onResize);
		angular.element(window).off('resize', onResize);
		angular.element(document).off('mouseout', onMouseOutWindow);

		$element.off('keydown', onKeyDown);

		if (destroy$) {
			destroy$.next();
			destroy$.complete();
		}
	};

	vm.clicked = () => {
		if (!vm.isDragging && vm.bgSplitterController.isMobile) {
			const currentPos = $element[0].offsetTop;
			const bounds = vm.bgSplitterController.getBounds();
			let obj;
			// if fully expanded then collapse
			if (currentPos <= 0) {
				obj = {
					offsetX: 0,
					offsetY: 9999999
				};
			// else if collapsed then split 2/3
			} else if (currentPos === bounds.height - feedbackDisplayPanelHeight) {
				setHandleHeightIfNotSet();
				obj = {
					offsetX: 0,
					offsetY: Math.min((bounds.height - handleHeight) / 3,
						bounds.height - handleHeight - vm.bgSplitterController.getMinSize())
				};
			// else if in the middle fully expand
			} else {
				obj = {
					offsetX: 0,
					offsetY: bounds.height - window.innerHeight
				};
			}
			resize(obj);
		}
	};

	/**
	 * Trigger resize after element is completely destroyed
	 */
	$element.on('destroy', () => {
		vm.bgSplitterController.triggerResize();
	});

	function onFocusOut () {
		$scope.$evalAsync(() => {
			vm.grabbed = false;
			accessibilityElem.textContent = $translate.instant('bg-splitter_released');
		});
	}

	function onFocusIn () {
		accessibilityElem.textContent = $translate.instant('bg-splitter_focus-in');
	}

	/**
	 * Resize
	 *
	 * @param evt
	 */
	function resize (evt) {

		$scope.$evalAsync(() => {
			// get orientation
			// we can get the last orientation here if we call
			// it before we call bgSplitterController.update
			const lastOrientation = vm.bgSplitterController.getOrientation();

			// update the bounds and orientation
			vm.bgSplitterController.update();

			// get container bounds
			const bounds = vm.bgSplitterController.getBounds();

			// get x and y offsets, if none provided
			// default to the current offset
			const coordinate = {
				offsetX: evt && evt.offsetX ? evt.offsetX : $element[0].offsetLeft,
				offsetY: evt && evt.offsetY ? evt.offsetY : $element[0].offsetTop
			};

			// after the orientation has changed
			// reset width and height properties
			if (lastOrientation !== vm.bgSplitterController.getOrientation()) {
				angular.forEach([$element, prev, next], (item) => {
					item.css(clearStyles);
				});
			}

			if (vm.bgSplitterController.isLandscape()) {
				if (!handleWidth) {
					handleWidth = outerWidth($element[0]);
				}

				const min = vm.bgSplitterController.getMinSize();
				const max = bounds.width - handleWidth - vm.bgSplitterController.getMinSize();
				if (min > (bounds.width - handleWidth) / 2) {
					// split evenly to avoid jittering back and forth
					coordinate.offsetX = (bounds.width - handleWidth) / 2;
				} else {
					if (coordinate.offsetX < min) {
						coordinate.offsetX = min;
					} else if (coordinate.offsetX > max) {
						coordinate.offsetX = max;
					}
				}

				$element.css({
					height: '100%',
					left: coordinate.offsetX + 'px',
					zIndex: 'auto'
				});

				prev.css({
					top: 0,
					bottom: 0,
					left: 0,
					height: 'auto',
					width: coordinate.offsetX + 'px'
				});

				const nextLeft = coordinate.offsetX + handleWidth;
				next.css({
					top: 0,
					bottom: 0,
					right: 0,
					left: nextLeft + 'px',
					height: 'auto',
					width: bounds.width - nextLeft + 'px',
					zIndex: 'auto'
				});

			} else {
				setHandleHeightIfNotSet();

				const min = vm.bgSplitterController.getMinSize();
				const max = bounds.height - handleHeight - vm.bgSplitterController.getMinSize();
				if (vm.bgSplitterController.isMobile) {
					// dont go off screen
					if (coordinate.offsetY < (bounds.height - window.innerHeight)) {
						coordinate.offsetY = bounds.height - window.innerHeight;
					// collapse
					} else if (coordinate.offsetY > max) {
						coordinate.offsetY = bounds.height - feedbackDisplayPanelHeight;
						feedbackSession.toggleFeedbackTools(false);
					} else {
						feedbackSession.toggleFeedbackTools(true);
					}
				} else {
					if (min > (bounds.height - handleHeight) / 2) {
						// split evenly to avoid jittering back and forth
						coordinate.offsetY = (bounds.height - handleHeight) / 2;
					} else {
						if (coordinate.offsetY < min) {
							coordinate.offsetY = min;
						} else if (coordinate.offsetY > max) {
							coordinate.offsetY = max;
						}
					}
				}

				$element.css({
					width: '100%',
					top: coordinate.offsetY + 'px',
					zIndex: vm.bgSplitterController.isMobile ? 6 : 'auto'
				});

				prev.css({
					left: 0,
					right: 0,
					top: 0,
					width: 'auto',
					height: coordinate.offsetY + 'px'
				});

				const nextTop = coordinate.offsetY + handleHeight;
				next.css({
					left: 0,
					right: 0,
					top: nextTop + 'px',
					width: 'auto',
					height: bounds.height - nextTop + 'px',
					zIndex: vm.bgSplitterController.isMobile ? 5 : 'auto'
				});
			}

			// ignore storing handle coordinates when the
			// handle is not visible
			const handleBounds = $element[0].getBoundingClientRect();
			if (handleBounds.height <= 0 || handleBounds.width <= 0) {
				return;
			}

			// store position
			if (handleId) {
				storage.put(handleId, {
					offsetX: coordinate.offsetX,
					offsetY: coordinate.offsetY
				});
			}
		});
	}

	/**
	 * Get outer width
	 *
	 * @param el
	 * @returns {number}
	 */
	function outerWidth (el) {
		let width = el.offsetWidth;
		const style = getComputedStyle(el);

		width += parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);
		return width;
	}

	function setHandleHeightIfNotSet () {
		if (!handleHeight) {
			handleHeight = outerHeight($element[0]);
		}
	}

	/**
	 * Get outer height
	 *
	 * @param el
	 * @returns {number}
	 */
	function outerHeight (el) {
		let height = el.offsetHeight;
		const style = getComputedStyle(el);

		height += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
		return height;
	}

	/**
	 * On mouse down event handler
	 *
	 * @param evt
	 */
	function onMouseDown (evt) {
		$scope.$evalAsync(() => {
			evt.preventDefault();
			vm.isDragging = false;

			const bounds = $element[0].getBoundingClientRect();
			vm.grabbed = true;

			// capture drag offsets
			dragging = {
				offsetX: evt.clientX - bounds.left,
				offsetY: evt.clientY - bounds.top
			};
		});
	}

	/**
	 * On mouse move event handler
	 *
	 * @param evt
	 */
	function onMouseMove (evt) {
		if (!dragging) { return; }

		$scope.$evalAsync(() => {
			vm.isDragging = true;
			const bounds = vm.bgSplitterController.getBounds();

			resize({
				offsetX: evt.clientX - bounds.left - dragging.offsetX,
				offsetY: evt.clientY - bounds.top - dragging.offsetY
			});

			// tell container to resize all children
			vm.bgSplitterController.triggerResize();
		});
	}

	/**
	 * On mouse up event handler
	 *
	 * @param evt
	 */
	function onMouseUp () {
		dragging = null;
		vm.grabbed = false;
	}

	function onKeyDown (event: JQueryEventObject) {

		$scope.$evalAsync(() => {
			// Get orientation info
			const orientation = vm.bgSplitterController.getOrientation();
			const { left, right, grabSpeech } = orientation === ORIENTATION.LANDSCAPE ?
				{ left: 'ArrowLeft', right: 'ArrowRight', grabSpeech: 'bg-splitter_grabbed-horizontal' } :
				{ left: 'ArrowUp', right: 'ArrowDown', grabSpeech: 'bg-splitter_grabbed-vertical' };

			// Update grabbed status
			if (event.key === ' ') {
				vm.grabbed = !vm.grabbed;
				accessibilityElem.textContent = vm.grabbed ?
					$translate.instant(grabSpeech) :
					$translate.instant('bg-splitter_released');
			} else {
				accessibilityElem.textContent = '';
			}

			// Move left or right while grabbed
			if (vm.grabbed && (event.key === left || event.key === right)) {
				const offsets = {} as any;
				const direction = event.key === left ? -1 : 1;

				if (orientation === ORIENTATION.LANDSCAPE) {
					offsets.offsetX = $element[0].offsetLeft + (10 * direction);
				} else {
					offsets.offsetY = $element[0].offsetTop + (10 * direction);
				}

				accessibilityElem.textContent = $translate.instant(getAccessibleMoveText(direction, orientation));
				resize(offsets);

				// tell container to resize all children
				vm.bgSplitterController.triggerResize();
			}
		});
	}

	function getAccessibleMoveText (direction: number, orientation: ORIENTATION) {
		if (orientation === ORIENTATION.LANDSCAPE && direction === -1) {
			return 'bg-splitter_moved-horizontal-left';
		} else if (orientation === ORIENTATION.LANDSCAPE) {
			return 'bg-splitter_moved-horizontal-right';
		} else if (orientation === ORIENTATION.PORTRAIT && direction === -1) {
			return 'bg-splitter_moved-vertical-up';
		} else {
			return 'bg-splitter_moved-vertical-down';
		}
	}

	/**
	 * On touch start event
	 *
	 * @param evt
	 */
	function onTouchStart (evt) {
		const touch = getTouches(evt)[0];
		touch.preventDefault = angular.noop;
		onMouseDown(touch);
	}

	/**
	 * On touch move event
	 *
	 * @param evt
	 */
	function onTouchMove (evt) {
		if (!dragging) { return; }
		const touch = getTouches(evt)[0];
		onMouseMove(touch);
	}

	/**
	 * On touch end event handler
	 *
	 * @param evt
	 */
	function onTouchEnd () {
		onMouseUp();
	}

	/**
	 * On resize event handler
	 */
	function onResize (evt) {
		evt.preventDefault();
		resize(evt);
	}

	/**
	 * On mouse out of window
	 * Stop dragging when mouse leaves window
	 */
	function onMouseOutWindow (evt) {
		evt = evt ? evt : $window.event;
		const from = evt.relatedTarget || evt.toElement;
		if (!from || from.nodeName === 'HTML') {
			onMouseUp();
		}
	}

	/**
	 * Returns an array of touch changes
	 *
	 * @param evt
	 * @returns {*}
	 */
	function getTouches (evt) {
		let touches;

		if (evt.originalEvent) {
			if (evt.originalEvent.touches) {
				touches = evt.originalEvent.touches;
			} else if (evt.originalEvent.changedTouches) {
				touches = evt.originalEvent.changedTouches;
			}
		} else if (evt.touches) {
			touches = evt.touches;
		}

		return touches;
	}
};
