import * as angular from 'angular';
import { each, union } from 'lodash';
import { MODE as INSTRUCTIONS_MODE } from '../modals/activity-instructions/modes';
import { LayoutManager, LayoutManagerConfiguration } from './layout-manager';
import { FeedbackMode, MediaDisplayMode } from './feedback-session.provider';
import { SessionFeedbackClass } from './services/session-feedback.factory';
import { Events, InternetStatus } from '../internet-status';
import { PubnubService } from 'go-modules/go-pubnub/pubnub.service';
import { CommentModelClass } from 'go-modules/models/comment/comment.factory';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import {
	NgxUnsavedDataManagerService
} from 'ngx/go-modules/src/services/unsaved-data-manager/unsaved-data-manager.service';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import { License } from 'go-modules/services/group/license';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';
import { SESSION_RESTART } from 'go-modules/video-scene/video-scene.options';
import { CloseTypeEnum } from 'go-modules/modals/post-discard-session/modal.factory';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { States } from 'go-modules/enums/states.enum';

export interface Bindings {
	activity: any;
	readonly feedbackMode: FeedbackMode;
	readonly options: any;
	readonly user: any;
	license: License;
	userGroup: any;
	readonly viewMode: MediaDisplayMode;
	readonly orgSettings: any;
	session: any;
	viewFrom: string;
}

export interface Locals {
	feedbackSession: any;
}

export interface FeedbackSessionControllerScope extends ng.IScope, Locals { }

export const DEGRADED_FEEDBACK_THRESHOLD = 75;

export declare class FeedbackSessionControllerClass implements ng.IController, Bindings {
	public activity: any;
	public readonly feedbackMode: FeedbackMode;
	public readonly options: any;
	public readonly user: any;
	public userGroup: any;
	public license: License;
	public readonly viewMode: MediaDisplayMode;
	public readonly orgSettings: any;
	public session: any;
	public environmentVarsService: EnvironmentVarsService;
	public viewFrom: string;
	public tags: any;

	public internetStatus: InternetStatus;
	public channels: string[];
	public feedbackSession: any;
	public helpUrls: any;
	public media: any;
	public playerSync: any;
	public sessionFeedback: SessionFeedbackClass;
	public settings: any;
	public sourceMedia: any;
	public wasRecorded: boolean;
	public whitelabel: boolean;
	public notifications: object[];
	public rubricDirty: boolean;
	public eventNames: string[];
	public eventSubscription: any;
	public degraded: boolean;
	public onBeforeUnload: any;

	public $onInit (): void;
	public $onDestroy (): void;
	public hasOrRequiresResponseMedia (): boolean;
	public setStatus (status, user): void;
	public grade (score: number): ng.IPromise<any>;
	public saveSession ();
	public postSession (): Promise<void>;
	public discardSessionConfirm (): Promise<void>;
	public discardSession (): Promise<void>;
	public toggleFeedbackOnlyMode (on: boolean): void;
	public showStatusIndicator (message): void;
	public restartSession (): Promise<void>;
	public hideStatusIndicator (): void;
	public saveDraft(exit?: boolean): void;
	public setNotification (id): void;
	public commentsUpdated (): void;
	public preventSourceMediaPreview (): boolean;
	public validateSourceMedia (media?): void;
	public requestExit (exitData?): ng.IPromise<void>;
	public exit (exitData?): void;
	public openConfirmLeaveSingleAttemptModal (singleCommentOnly?: boolean): Promise<any>;
	public initLayoutManager (): void;
	public showPostDiscardModal (): any;
	public isCommentOnlySingleAttempt (): boolean;
	public canSeeCommentKind (): boolean;
	public canSeeRubricKind (): boolean;
	public canSeeTranscriptions (): boolean;
	public maySeeTranscriptions(): boolean;
	public canSeeAnalytics (): boolean;
	public updateDegradedState (): void;
	public allowDownload (): boolean;
}

/* @ngInject */
export function FeedbackSessionController (
	$element: ng.IAugmentedJQuery,
	$scope: FeedbackSessionControllerScope,
	$q: ng.IQService,
	$window: ng.IWindowService,
	$timeout: ng.ITimeoutService,
	feedbackSession,
	SessionFeedback: typeof SessionFeedbackClass,
	TimingOrchestrator,
	pubnub: PubnubService,
	User,
	CommentModel,
	goModal,
	featureCache,
	SyncEvent,
	$filter,
	activityInstructionsModal,
	helpUrls,
	confirmModal,
	ngxUnsavedDataManagerService: NgxUnsavedDataManagerService,
	postDiscardSessionModal,
	Session,
	eventService: EventService,
	TagSetModel,
	TagModel,
	selectedService: SelectedService,
	$cookies: ng.cookies.ICookiesService,
	ngxDowngradeModalService: DowngradeModalService,
	$translate,
	ngxGoToastService: NgxGoToastService,
	$state
) {
	const vm: FeedbackSessionControllerClass = this;
	vm.environmentVarsService = EnvironmentVarsService.getInstance();

	/**
	 * Handles controller init life-cycle hook
	 */
	vm.$onInit = (): void => {
		if (!vm.session.viewed && (vm.session.status === 1 || vm.session.status === 2)) {
			// Only mark as viewed if we have a media that we're viewing.
			// Otherwise we're not viewing an existing media.
			vm.session.viewed = true;
			Session.markAsViewed({
				session_id: vm.session.session_id
			});
			if (vm.userGroup.hasReviewerRole(true)) {
				vm.activity.unviewed_sessions = vm.activity.unviewed_sessions - 1;
				if (vm.activity.unviewed_sessions === 0 && vm.userGroup.attention_needed) {
					vm.userGroup.attention_needed = !vm.userGroup.activities.every((activity) => {
						return activity.unviewed_sessions === 0;
					});
				}
			}
		}
		selectedService.setSession(vm.session);

		vm.tags = [];
		if (vm.activity.tag_set_id) {
			TagSetModel.get({ id: vm.activity.tag_set_id })
				.$promise.then((tagSet) => {
					const modelizedTags = [];
					tagSet.tags.forEach((tag) => {
						modelizedTags.push((TagModel.model(tag)));
					});
					vm.tags = modelizedTags;
				});
		}

		// Whether the number of comments reached a certain threshold
		vm.degraded = false;

		vm.helpUrls = helpUrls;

		// add activity, media and source media to scope
		vm.media = vm.session.media;
		vm.sourceMedia = vm.session.source_media;

		// whether or not session was recorded initially when data was loaded
		vm.wasRecorded = vm.session.isRecorded();

		// feedback session settings
		vm.settings = feedbackSession;

		// TODO: this needs to go away
		// expose session settings to scope
		$scope.feedbackSession = vm.feedbackSession = feedbackSession;

		// Instantiate session feedback instance
		vm.sessionFeedback = new SessionFeedback(vm.session, vm.user);

		vm.whitelabel = vm.environmentVarsService.get(EnvironmentVarsService.VAR.WHITELABEL);

		// Media sometimes are not yet loaded in when oninit is called
		// This ensures that media has been loaded when initLayoutManager watches collection changes for LayoutManager
		$timeout(() => {
			// If the session doesn't require media, ensure that feedback only mode is turned on by default
			const onlyDisplayFeedback: boolean = !vm.hasOrRequiresResponseMedia();
			vm.toggleFeedbackOnlyMode(onlyDisplayFeedback);
		});

		vm.initLayoutManager();

		// Feedback mode change event handler
		$scope.$watch('mainController.feedbackMode', (mode, oldMode) => {
			eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_MODE_CHANGE, { mode, oldMode });
		});

		// Session has feedback change event handler.
		// When a session has feedback, we need to make sure
		// that the post discard functionality is turned off.
		$scope.$watch(() => {
			return vm.sessionFeedback.hasFeedback();
		}, (value) => {
			feedbackSession.setManualPostDiscard(
				!value &&
				!vm.activity.isLiveSessionEnabled() &&
				!vm.activity.has_single_recording_attempt
			);
		});

		vm.eventNames = [
			EVENT_NAMES.FEEDBACK_SESSION_REQUEST_EXIT,
			EVENT_NAMES.RUBRIC_SAVE_SESSION_DONE,
			EVENT_NAMES.RUBRIC_CHANGE
		];
		vm.eventSubscription = eventService.events
			.pipe(filter((ev: GoEvent) => vm.eventNames.includes(ev.name)))
			.subscribe((ev: GoEvent) => {
				switch (ev.name) {
					case EVENT_NAMES.FEEDBACK_SESSION_REQUEST_EXIT:
						vm.requestExit(ev.data.exitData)
							.then(() => { if (ev.data.callback) ev.data.callback(); });
						break;
					case EVENT_NAMES.RUBRIC_SAVE_SESSION_DONE:
						if (ev.data.succeeded) {
							vm.rubricDirty = false;
						}
						break;
					case EVENT_NAMES.RUBRIC_CHANGE:
						vm.rubricDirty = true;
						break;
				}
			});

		// On before unload, handle leaving before recordings are wrapped up
		vm.onBeforeUnload = (evt: Event) => {
			const hasRestartedSession = $window.sessionStorage.getItem(SESSION_RESTART) !== null;
			$window.sessionStorage.removeItem(SESSION_RESTART);

			if (((feedbackSession.isReviewMode() &&
				feedbackSession.lastMediaDisplayMode === feedbackSession.MediaDisplayMode.RECORD) ||
				feedbackSession.isRecordMode()) &&
				!vm.session.archived_at &&
				vm.session.started_at &&
				!hasRestartedSession
			) {
				evt.preventDefault();
				evt.returnValue = true;
				return;
			}
		};

		$window.addEventListener('beforeunload', vm.onBeforeUnload);

		// handle scope destroy event
		$scope.$on('$destroy', () => {
			// Only destroy player sync if it isn't already
			if (vm.playerSync) {
				vm.playerSync.destroy();
			}
			feedbackSession.restoreDefaults();
			// Close all open modals
			goModal.dismissAll();
			$window.removeEventListener('beforeunload', vm.onBeforeUnload);
			pubnub.unsub(vm.channels, 'feedback-session');
		});

		vm.internetStatus = new InternetStatus();

		vm.internetStatus.on(Events.ONLINE, () => {
			// Back online, refresh session and media states
			startPubnub();
			vm.session.$get().then(() => {
				// if we were recording then post the session
				if (vm.viewMode === feedbackSession.MediaDisplayMode.RECORD) {
					vm.postSession();
				} else {
					vm.media = vm.session.media;
					// Get us back in the correct visual state
					validateMediaDisplayMode();
				}
			});
		});

		// NOTE: When the source_media changes (student adds slides from recording screen)
		// single-media-display is removed and multi-media-display is added
		// but we need to make sure as everything is destroyed, that the masterTimer
		// in playerSync now points at the new mediaPlayer created for multi-media-display.
		// Destroying and re-creating the playerSync re-sets everything and works like a charm
		$scope.$watch(() => {
			return vm.session.source_media;
		}, (newSrc, oldSrc) => {
			if (newSrc && !oldSrc) {
				vm.playerSync.destroy();
				vm.playerSync = null;
				$timeout(() => {
					initPlayerSync();
				});
			}
		});

		init();
	};

	vm.updateDegradedState = () => {
		const rootComments = vm.sessionFeedback.getComments();
		let totalCount = rootComments.length;
		for (const reply of rootComments) {
			if (reply.children && reply.children.length > 0) {
				totalCount += reply.children.length;
			}
		}

		vm.degraded = totalCount >= DEGRADED_FEEDBACK_THRESHOLD;
	};

	vm.$onDestroy = () => {
		vm.eventSubscription?.unsubscribe();
		vm.internetStatus.destroy();
	};

	/**
	 * If there is a response media present or the activity requires response media
	 */
	vm.hasOrRequiresResponseMedia = (): boolean => {
		return !!vm.media || vm.activity.has_response_media;
	};

	/**
	 * Set the current session status
	 *
	 * @param status
	 */
	vm.setStatus = (status, user) => {
		const oldStatus = vm.session.status;
		status = parseInt(status, 10);
		if (oldStatus !== status) {
			// set the new status
			vm.session.setStatus(status);
			// broadcast session status change
			eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_STATUS_CHANGE, {
				status: vm.session.status, oldStatus, user
			});
		}
	};

	/**
	 * Grade this session by giving it a score. When a
	 * falsy value is supplied the grade is retracted.
	 */
	vm.grade = (score: number = null) => {
		let promise;

		if (score || score === 0) {
			promise = vm.session.postGrade(score);
		} else {
			promise = vm.session.retractGrade();
		}

		return promise;
	};

	/**
	 * Save the current session
	 *
	 * @returns {Object}
	 */
	vm.saveSession = () => {
		return vm.session.save();
	};

	/**
	 * Post the current session
	 *
	 * @returns {Object}
	 */
	vm.postSession = () => {

		// show the posting / discarding media indicator modal
		showStatusIndicator($filter('translate')('feedback-session_controller-posting'));

		// Batch save all sync events.
		// We do this as an added precaution to
		// ensure that all sync events get saved.
		let events = [];
		each(vm.playerSync.tracks, (track) => {
			events = union(events, track.getSyncEvents());
		});

		return SyncEvent.saveBatch({ session_id: vm.session.getId() }, events)
			.then(() => vm.session.post())
			.then(() => {
				// broadcast posted event, and mark submitted at this point cuz it will always be true here
				vm.activity.have_i_submitted = true;
				eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_POSTED, vm.session);
			})
			.finally(() => {
				hideStatusIndicator();
			});
	};

	/**
	 * Discard the current session
	 *
	 * @returns {Object}
	 */
	vm.discardSessionConfirm = () => {
		return ngxDowngradeModalService.openConfirmDialog({
			title: $translate.instant('modal-confirm-session-discard_confirm-discard'),
			message: $translate.instant('modal-confirm-session-discard_sure-discard'),
			confirmText: $translate.instant('common_delete')
		}).then(() => {
			return vm.discardSession();
		}).catch(() => {
			return $q.reject();
		});
	};

	vm.discardSession = () => {
		// show the posting / discarding media indicator modal
		showStatusIndicator($filter('translate')('feedback-session_controller-discarding'));
		eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_DISCARD, { session: vm.session });

		// discard the session
		const result = vm.session.archive();

		result.finally(() => {
			hideStatusIndicator();
		});

		return result;
	};

	/**
	 * Toggle feedback only mode
	 */
	vm.toggleFeedbackOnlyMode = (on: boolean) => {
		feedbackSession.toggleFeedbackOnlyMode(on);
		eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_ONLY_MODE_CHANGE, {
			mode: feedbackSession.options.feedbackOnlyMode
		});
	};

	// expose status modal function
	vm.showStatusIndicator = showStatusIndicator;
	vm.hideStatusIndicator = hideStatusIndicator;

	/**
	 * Notification message
	 *
	 * @param id
	 */
	vm.setNotification = (id) => {
		$timeout(() => {
			eventService.broadcast(EVENT_NAMES.FS_NOTIFICATION_ADD, id);
		});
	};

	// comments created with the new feedback creator arent updated unless we do this
	vm.commentsUpdated = () => {
		$scope.$digest();
	};

	/**
	 * Whether source media preview should be prevented
	 *
	 * @returns {boolean}
	 */
	vm.preventSourceMediaPreview = () => {

		// We only block source media in certain cases when
		// students are on an activity with the has_single_recording_attempt
		if (vm.activity.has_single_recording_attempt && vm.userGroup.hasPresenterRole()) {

			// Never block while video is being recorded
			if (feedbackSession.isRecordMode() && vm.session.isLive()) {
				return false;
			}

			// Always block before recording of tests
			if (feedbackSession.isRecordMode() && !vm.session.isLive()) {
				return true;
			}

			// Also block if activity has a score
			// but session isn't graded yet
			if (vm.activity.isScoreEnabled() && !vm.session.isScoreAssigned()) {
				return true;
			}
		}

		return false;
	};

	/**
	 * Ensures that the source media property is set correctly
	 *
	 * @param media
	 */
	vm.validateSourceMedia = (media) => {
		vm.sourceMedia = angular.isDefined(media) ? media : vm.session.source_media;
		// In the case that the activity does not have response media,
		// we need to swap source media with media.
		if (!vm.activity.has_response_media && vm.sourceMedia && !vm.media) {
			vm.media = vm.sourceMedia;
			vm.sourceMedia = null;
		}
	};

	/**
	 * The exit must be requested because
	 * there might be some items that need
	 * attention before the exit can happen.
	 */
	vm.requestExit = (exitData) => {
		const deferred = $q.defer<void>();

		deferred.promise.then(() => {
			vm.exit(exitData);
		}).catch(angular.noop);

		// Pause the player sync
		vm.playerSync.pause();

		// If we are in the readonly mode, we don't want to affect the session, just exit.
		if (vm.environmentVarsService.get(EnvironmentVarsService.VAR.READONLY)) {
			deferred.resolve();
		} else if (ngxUnsavedDataManagerService.isInUnsavedState()) {
			// Handle any components in an unsaved state
			ngxUnsavedDataManagerService.resolve().then(deferred.resolve).catch(deferred.reject);
		} else if (vm.activity.is_conversation && !vm.session.isRecorded()) {
			deferred.resolve();
		} else if (vm.activity.requiresComments() && vm.session.isOwner(vm.user) &&
			vm.sessionFeedback.getFeedbackByUser(vm.user, SessionFeedback.KIND.COMMENT).length === 0 &&
			vm.sessionFeedback.getFeedbackByUser(vm.user, SessionFeedback.KIND.RUBRIC).length === 0 &&
			!vm.session.is_activity_placeholder) {
			if (vm.activity.isCommentOnlySingleAttempt()) {
				vm.openConfirmLeaveSingleAttemptModal(true)
					.then(deferred.reject)
					.catch(() => {
						// set have_i_commented so they cant open it again to comment
						vm.session.have_i_commented = true;
						vm.postSession().then(deferred.resolve);
					});
			} else {
				deferred.resolve();
			}

		} else if (vm.session.isOwner(vm.user) &&
			vm.session.isPosted() &&
			feedbackSession.isRecordMode() &&
			vm.activity.has_single_recording_attempt) {
			vm.openConfirmLeaveSingleAttemptModal()
				.then(deferred.reject)
				.catch(() => vm.postSession().then(deferred.resolve));
		} else if (!vm.session.isPosted()
			&& vm.session.isOwner(vm.user)
			&& (feedbackSession.isRecordMode() || feedbackSession.isPlaybackMode())) {

			if (feedbackSession.options.manualPostDiscard) {
				// when session is in a cancelled state and we're in record mode, just discard the session
				if (vm.session.isCancelled()) {
					vm.discardSession().then(deferred.resolve).catch(deferred.reject);
				} else if (vm.session.isRecorded() || vm.session.isLive()) {
					// when session is live or recorded, prompt to post / discard
					vm.showPostDiscardModal().then(deferred.resolve).catch(deferred.reject);
				} else {
					deferred.resolve();
				}
			} else if (vm.activity.has_single_recording_attempt && vm.session.hasViewedRecordingInstructions()) {
				// In a test scenario, we need to auto-post the session when the user
				// tries to back out after already seeing the recording instructions.
				vm.openConfirmLeaveSingleAttemptModal()
					.then(deferred.reject)
					.catch(() => vm.postSession().then(deferred.resolve));
			} else if (vm.session.isRecorded()) {
				vm.postSession().then(deferred.resolve).catch(deferred.reject); // auto post
			} else if (vm.session.isLive()) {
				vm.showPostDiscardModal().then(deferred.resolve).catch(deferred.reject);
			} else {
				deferred.resolve();
			}
		} else {
			// check to see if the current user has any unpublished rubric sessions
			const rubric: any = vm.sessionFeedback.getFeedbackByUser(vm.user, SessionFeedback.KIND.RUBRIC)[0];
			if (!vm.session.isAwaitingStart() && feedbackSession.isReviewMode() &&
				((rubric?.getId() && !rubric.isPublished()) || vm.rubricDirty)) { // if rubric unpublished/dirty and unsaved, warn
				showRubricUnpublishedModal(rubric).then(deferred.resolve).catch(deferred.reject);
			} else if (vm.activity.isCommentOnlySingleAttempt() && vm.session.isOwner(vm.user)) {
				const comments = vm.sessionFeedback.getFeedbackByUser(vm.user, SessionFeedback.KIND.COMMENT);
				if (comments.some((comment) => comment.saving)) {
					vm.openConfirmLeaveSingleAttemptModal(true)
						.then(deferred.reject)
						.catch(() => vm.postSession().then(() => {
							// set have_i_commented so they cant open it again to comment
							vm.session.have_i_commented = true;
							comments.forEach((comment) => {
								if (comment.saving) {
									// update property to false
									// prevents reopening of modal after exiting and visiting the session on vert nav
									comment.saving = false;
								}
							});
							deferred.resolve();
						}));
				} else {
					deferred.resolve();
				}
			} else if (vm.activity.mayScore(vm.userGroup)
				&& vm.activity.isScoreEnabled()
				&& !vm.session.isAwaitingStart()
				&& !vm.activity.isAutoScored()
				&& !vm.session.isScoreAssigned()) {

				showEditScoreModal().then(deferred.resolve).catch(deferred.reject);
			} else {
				deferred.resolve();
			}
		}
		return deferred.promise;
	};

	/**
	 * Add modal to confirm leaving test
	 */
	vm.openConfirmLeaveSingleAttemptModal = (singleCommentOnly: boolean = false): Promise<any> => {
		const normalModalData = {
			message: 'feedback-session-header_single-recording-attempt-warning-message',
			title: 'feedback-session-header_single-recording-attempt-warning-title',
			no: 'feedback-session-header_single-recording-attempt-warning-quit',
			yes: 'feedback-session-header_single-recording-attempt-warning-continue'
		};
		const commentOnlyModalData = {
			message: 'feedback-session-header_single-recording-attempt-submit-message',
			title: 'feedback-session-header_single-recording-attempt-submit-title',
			no: 'feedback-session-header_single-recording-attempt-submit-quit',
			yes: 'feedback-session-header_single-recording-attempt-warning-continue-commenting'
		};
		const modalData = singleCommentOnly ? commentOnlyModalData : normalModalData;
		return confirmModal.open({
			size: 'sm',
			modalData
		}).result;
	};

	/**
	 * Apply different layout classes to the host element as these properties change
	 */
	vm.initLayoutManager = (): void => {
		$scope.$watchCollection(() => {
			return {
				feedbackOnlyMode: feedbackSession.options.feedbackOnlyMode,
				feedbackTools: feedbackSession.options.feedbackTools,
				feedbackDisabled: !vm.activity.isFeedbackEnabled(),
				commentsDisabled: !vm.activity.isCommentsEnabled(),
				tagsDisabled: !vm.activity.isTagsEnabled()
			};
		}, (config: LayoutManagerConfiguration) => {
			LayoutManager.apply($element, config);
		});
	};

	function emitExit (exitData) {
		updateViewedMetadata();
		eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_EXITED, { session: vm.session, newUrl: exitData });
	}

	function updateViewedMetadata () {
		const previousSessionUnviewed = vm.session.num_direct_unviewed_comments;
		const parentComments = vm.sessionFeedback.getComments();
		const childComments = parentComments.flatMap((comment) => comment.children as CommentModelClass[]);
		const allComments = parentComments.concat(childComments);
		const unviewedComments = allComments.filter((comm) => !comm.viewed_by_me);

		// Filter out my direct unviewed comments
		vm.session.num_direct_unviewed_comments = unviewedComments.filter((comment) => {
			comment.views = comment.views || [];
			comment.directed_at_me = comment.directed_at_me || false;
			return comment.directed_at_me && comment.views.filter((view) => {
				return view.user_id === vm.user.user_id;
			}).length === 0;
		}).length;

		// Filter out my direct unviewed comments
		vm.session.num_unviewed_comments = unviewedComments.filter((comment) => {
			comment.views = comment.views || [];
			return comment.views.filter((view) => {
				return view.user_id === vm.user.user_id;
			}).length === 0;
		}).length;

		// if we reduced the number of unviewed comments on the session
		// then we need to reduce the number of unviewed on the activity and group as well
		if (previousSessionUnviewed > vm.session.num_direct_unviewed_comments) {
			const commentsJustViewed = previousSessionUnviewed - vm.session.num_direct_unviewed_comments;
			vm.activity.unviewed_comments = vm.activity.unviewed_comments - commentsJustViewed;
			vm.userGroup.num_unviewed_comments = vm.userGroup.num_unviewed_comments - commentsJustViewed;
		}
	}

	/**
	 * Show feedback instructions
	 */
	function showFeedbackInstructions () {
		const modalData: any = {};
		modalData.activity = vm.activity;
		modalData.session = vm.session;
		modalData.mode = INSTRUCTIONS_MODE.FEEDBACK;
		modalData.embiggen = !!vm.activity.feedback_instructions.media &&
			!vm.userGroup.license_trial_exists;

		const outcome = activityInstructionsModal.open({
			modalData
		});

		outcome.result.finally(() => {
			if ($cookies.get('is_self_demo_experiment') !== 'true') {
				featureCache.seen(
					vm.activity.getFeedbackInstructionCacheIdentifier(vm.user.getId()),
					featureCache.FEATURE.FeedbackInstructions
				);
			}
		});

		return outcome;
	}

	/**
	 * Start pubnub
	 */
	function startPubnub () {
		pubnub.sub(vm.channels, 'feedback-session', (message) => {
			const user = message.user ? User.model(message.user) : false;

			// mobile recorder time synchronization
			if (message.hasOwnProperty('length')) {
				let time = parseInt(message.length, 10);
				if (message.hasOwnProperty('duration')) { // offset by message duration
					time += parseInt(message.duration, 10);
				}

				// prevent negative times
				if (time < 0) {
					time = 0;
				}

				eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_TIME_SYNC, time);
			}

			// did a live recorder end a recording
			if (message.hasOwnProperty('recordingManuallyEnded')) {
				eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_RECORDING_MANUALLY_ENDED,
					message.recordingManuallyEnded);
			}

			// session object synchronization
			if (angular.isObject(message.session)) {

				// detect when server has posted session
				if (message.session.hasOwnProperty('posted_at')) {
					if (!vm.session.isPosted() && message.session.posted_at &&
						!message.session.activity?.has_single_recording_attempt) {
						eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_POSTED, vm.session);
					}
				}

				const status = message.session.status;
				delete message.session.status;
				vm.session.extend(message.session);
				vm.setStatus(status, user);

				// media sync
				vm.media = vm.session.media;

				// source sync
				vm.validateSourceMedia();

				// When a session has been deleted by a user, broadcast the event.
				if (vm.session.archived_at) {
					eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_DELETED, vm.session);
				}
			}

			// Comment synchronization
			if (angular.isObject(message.comment)) {
				const comment = CommentModel.newInstance(message.comment);
				eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_COMMENT_SYNC + comment.getId(), comment);
				if (comment.isSyncEventType()) {
					if (comment.isDeleted()) {
						vm.sessionFeedback.remove(comment);
					} else {
						vm.sessionFeedback.add(comment);
					}
				}
			}

			// media sync
			if (message.media) {
				eventService.broadcast(EVENT_NAMES.MEDIA_SYNC, message.media);
			}

			// Sync event synchronization
			if (angular.isObject(message.sync_event)) {
				const syncEvent = SyncEvent.model(message.sync_event),
					suppressBroadcast = true;
				if (syncEvent.isDeleted()) {
					vm.playerSync.removeSyncEvent(syncEvent, suppressBroadcast);
				} else {
					vm.playerSync.addSyncEvent(syncEvent, suppressBroadcast);
				}
			}

			// refresh the view
			$scope.$evalAsync();
		});
	}

	/**
	 * Validate media display mode. Ensure that mode is set correctly
	 */
	function validateMediaDisplayMode () {
		let mode = vm.viewMode || feedbackSession.options.mediaDisplayMode;
		const isPresenter = vm.session.presenters.find((p) => p.user_id === vm.user.getId());
		const isOwner = vm.user.user_id === vm.session.created_by;

		if (vm.session.isRecorded()) {
			mode = feedbackSession.MediaDisplayMode.PLAYBACK;
		} else if (vm.activity.isMulticamLiveReview()) {
			mode = isPresenter ? feedbackSession.MediaDisplayMode.CONVERSATION : feedbackSession.MediaDisplayMode.LIVE;
		} else if (vm.activity.is_conversation) {
			mode = feedbackSession.MediaDisplayMode.CONVERSATION;
		} else if (vm.session.isLive() || mode !== feedbackSession.MediaDisplayMode.RECORD) {
			mode = feedbackSession.MediaDisplayMode.LIVE;
		} else if (mode === feedbackSession.MediaDisplayMode.RECORD) {
			const isRootUser = vm.user.is_root_user;
			const isInstructor = vm.userGroup.hasInstructorRole(true);
			const isAdmin = vm.userGroup.hasAdminRole(true);

			if (!isPresenter && !(isOwner || isRootUser || isAdmin || isInstructor)) {
				mode = feedbackSession.MediaDisplayMode.LIVE;
			}
		}

		// in a test scenario, make sure sessions is posted right away
		// so a student cant exit and try and record again
		if (mode === feedbackSession.MediaDisplayMode.RECORD &&
			vm.activity.has_single_recording_attempt) {

			// ensure that the session is not posted already before posting
			if (!vm.session.isPosted()) {
				vm.session.post();
			}
		}

		feedbackSession.setMediaDisplayMode(mode);
	}

	/**
	 * Show the post discard decision modal
	 */
	vm.showPostDiscardModal = () => {
		return postDiscardSessionModal.open({
			modalData: {
				title: vm.session.description,
				firstFocusSelector: '#media-title',
				allowPractice: vm.activity.allowsPractice()
			}
		}).result.then((response: { type: CloseTypeEnum, data: string }) => {
			if (response.type === CloseTypeEnum.DISCARD) {
				return vm.discardSessionConfirm();
			} else if (response.type === CloseTypeEnum.POST) {
				vm.session.description = response.data;
				return vm.postSession();
			} else if (response.type === CloseTypeEnum.RESTART) {
				return vm.restartSession().then(() => $q.reject());
			} else if (response.type === CloseTypeEnum.SAVE_AND_DRAFT) {
				// We need a callback here so we will just exit the recording page
				// after we stopped the recording
				eventService.broadcast(EVENT_NAMES.VIDEO_SCENE_STOP_SESSION, { callback: vm.exit });
				vm.saveDraft(false);
			}
		});
	};

	vm.saveDraft = (exit = true) => {
		ngxGoToastService.createToast({
			type: GoToastStatusType.SUCCESS,
			message: 'feedback-session_save-draft'
		});

		if (exit) {
			vm.exit();
		}
	};

	vm.restartSession = () => {
		return ngxDowngradeModalService.openConfirmDialog({
			title: $translate.instant('modal-confirm-restart-session_title'),
			message: $translate.instant('modal-confirm-restart-session_message'),
			confirmText: $translate.instant('modal-confirm-restart-session_title')
		}).then(() => {
			eventService.broadcast(EVENT_NAMES.VIDEO_SCENE_RESTART_SESSION);

			return vm.session.restart()
				.then(() => {
					$window.sessionStorage.setItem(SESSION_RESTART, 'true');
					$window.location.href = $state.href(States.DASHBOARD_SESSION_VIEW, {
						session_id: vm.session.session_id,
						feedbackMode: FeedbackMode.VIEW,
						viewMode: MediaDisplayMode.RECORD
					});
				})
				.catch(() => {
					ngxGoToastService.createToast({
						type: GoToastStatusType.ERROR,
						message: 'session-restart_failed'
					});
				});
		}).catch(() => angular.noop);
	};

	/**
	 * Show status indicator modal
	 */
	let statusModal;
	function showStatusIndicator (message) {
		statusModal = goModal.open({
			modal: 'status',
			modalData: { message }
		});
	}

	/**
	 * Exit the feedback session
	 */
	vm.exit = (exitData) => {
		if (statusModal) {
			statusModal.closed.then(() => {
				emitExit(exitData);
			});
		} else {
			emitExit(exitData);
		}
	};

	/**
	 * Hide status indicator modal
	 */
	function hideStatusIndicator () {
		if (statusModal) {
			statusModal.close();
			statusModal = null;
		}
	}

	/**
	 * Show rubric unpublished modal
	 */
	function showRubricUnpublishedModal (rubric) {
		return goModal.open({
			modal: 'rubricUnpublished',
			modalData: {
				dirty: vm.rubricDirty,
				isPublished: rubric.isPublished()
			}
		}).result.then((action) => {
			if (action === 'close') {
				return $q.resolve();
			}
			return $q((resolve, reject) => {
				eventService.broadcast(EVENT_NAMES.RUBRIC_SAVE, {
					callback: (succeeded: boolean) => {
						if (!succeeded) {
							return reject();
						}

						if (action === 'post') {
							return resolve(rubric.publish());
						}

						resolve();
					}
				});
			});
		});
	}

	/**
	 * Show edit score modal
	 */
	function showEditScoreModal () {
		const modal = goModal.open({
			modal: 'editSessionScore',
			modalData: {
				session: vm.session,
				activity: vm.activity
			}
		});

		return modal.result.then((save) => {
			// Ensure animation has finished before trying to save and leave
			return modal.closed.then(() => {
				if (save) {
					showStatusIndicator($filter('translate')('feedback-session_controller-saving'));
					return vm.grade(vm.session.score).finally(hideStatusIndicator);
				}
			});
		});
	}

	function initPlayerSync () {
		// Create new player sync instance
		vm.playerSync = new TimingOrchestrator(vm.session);
	}

	/**
	 * INIT ALL THE THINGS
	 */
	function init () {
		if (!angular.isObject(vm.session)) {
			const errorMessage = $filter('translate')('feedback-session_controller-failed-initialize');
			throw new Error(errorMessage);
		}

		vm.channels = [
			vm.session.settings.pubnub.pubnub_channel
		];
		const pubnubChannel = vm.environmentVarsService.get(EnvironmentVarsService.VAR.USER_PUBNUB_CHANNEL);
		if (pubnubChannel && pubnubChannel !== undefined) {
			vm.channels.push(pubnubChannel);
		}

		// validate media display
		validateMediaDisplayMode();

		// Validate source media
		vm.validateSourceMedia();

		// setup player sync
		initPlayerSync();

		// Initialize pubnub
		startPubnub();

		// default to review mode when no mode is supplied
		feedbackSession.setFeedbackMode(vm.feedbackMode || feedbackSession.FeedbackMode.REVIEW);

		// set whether feedback tools are enabled
		feedbackSession.toggleFeedbackTools(vm.activity.isFeedbackEnabled());

		// set whether manual post discard is enabled
		feedbackSession.setManualPostDiscard(!vm.activity.isLiveSessionEnabled() &&
			!vm.activity.has_single_recording_attempt);

		// show feedback instructions
		if (!feedbackSession.isRecordMode() && vm.feedbackMode !== feedbackSession.FeedbackMode.VIEW) {
			if (vm.activity.hasFeedbackInstructions()) {
				if (!featureCache.hasSeen(
					vm.activity.getFeedbackInstructionCacheIdentifier(vm.user.getId()),
					featureCache.FEATURE.FeedbackInstructions
				)) {
					showFeedbackInstructions();
				}
			}
		}
	}

	vm.canSeeCommentKind = () => {
		return vm.activity.isCommentsEnabled()
			&& (!feedbackSession.isRecording() || vm.activity.live_session_enabled);
	};

	vm.canSeeRubricKind = () => {
		if (feedbackSession.isRecording() && !vm.activity.live_session_enabled) {
			return false;
		}
		if (vm.activity.isPeerRubricEnabled()) {
			return true;
		}
		if (vm.activity.isRubricEnabled()) {
			return vm.userGroup.hasReviewerRole(true) || vm.session.isOwner(vm.user);
		}
		return false;
	};

	vm.canSeeTranscriptions = () => {
		return vm.maySeeTranscriptions()
			&& !feedbackSession.isRecording()
			&& !vm.activity.isCommentOnly();
	};

	vm.maySeeTranscriptions = () => {
		if (!vm.license) {
			return true;
		}

		if (vm.license.salesforce_license.may_tease) {
			return true;
		}

		return vm.license.salesforce_license.transcriptions_enabled;
	};

	vm.canSeeAnalytics = () => {
		return feedbackSession.isPlaybackMode();
	};

	vm.allowDownload = () => {
		if (!!vm.session.download_link &&
			vm.activity.has_response_media &&
			!feedbackSession.isRecording()) {
			if (vm.userGroup.hasInstructorRole(true)) {
				return true;
			}
			if (!vm.userGroup.block_presenter_downloads &&
				!vm.activity.has_single_recording_attempt) {
				return true;
			}
		}
		return false;
	};
}
