import { noop } from 'angular';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS, LTI_ENVIRONMENT_MODES } from 'ngx/go-modules/src/services/environment-vars/environments';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';

const dirty = {
	message: 'activity-settings_controller-message-2',
	title: 'activity-settings_controller-unsaved-changes'
};

export class ActivityEditorPanelController implements Partial<ng.ui.bootstrap.IModalScope> {

	public $close: (result?: any) => boolean;
	public $dismiss: (result?: any) => boolean;

	public isDirty: boolean;
	public saving: boolean;
	public editMode: boolean;
	public options: any;
	public hideDates: boolean = false;
	public isLtiEnvironment: boolean;

	public activityEditorForm: ng.IFormController;
	public environmentVarsService: EnvironmentVarsService;

	/* @ngInject */
	constructor (
		public activityTemplates,
		private $document: ng.IDocumentService,
		private confirmModal,
		private $timeout,
		private $scope: ng.IScope,
		private ngxGoToastService: NgxGoToastService,
		private ngxDowngradeModalService: DowngradeModalService,
		private $translate: ng.translate.ITranslateService,
		private $q: ng.IQService,
		private featureFlag: FeatureFlag,
		private $http: ng.IHttpService,
		private ActivityModel
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public $onInit () {
		this.isDirty = false;
		this.saving = false;
		this.editMode = this.options.headerOptions.editMode || false;

		const environment = this.environmentVarsService.get(EnvironmentVarsService.VAR.ENVIRONMENT) as any;
		this.isLtiEnvironment = environment.name === ENVIRONMENTS.LTI &&
			environment.mode === LTI_ENVIRONMENT_MODES.DEFAULT;
		this.hideDates = this.isLtiEnvironment || this.options.libraryMode === true;
	}

	/**
	 * Save the activity and close this panel
	 */
	public onFormSubmit (form: ng.IFormController): void {
		if (form.$invalid) {
			this.$scope.$broadcast('toggleAccordionEvent', { invalidControls: form.$error});
			// wait for accordion to open
			this.$timeout(() => {
				// Check for invalid element, and focus it
				let el = this.$document[0].body.querySelector(':not(form):not(ng-form).ng-invalid') as HTMLElement;
				el = el ? el.querySelector('input') || el.querySelector('button') || el : el;
				el?.focus();
			});
			return;
		}

		// To duplicate activities, we need to set it to dirty.
		if (this.options.activity.is_duplicate) {
			form.$setDirty();
		}

		// If form is pristine and we didn't do anything, just close
		if (form.$pristine) {
			this.$close(this.options.activity);
			return;
		}

		this.saving = true;

		//if we are adding to a library collection, then group id should always be null
		if (this.options.libraryMode) {
			this.options.activity.group_id = null;
		}

		// Handle changes made on fields that could be problematic
		let confirmPromise = this.$q.when(true);
		if (this.options.activity.activity_id &&
			this.options.activity.parent_id == null &&
			this.options.activity.parent_child_sync) {
			confirmPromise = this.ActivityModel.childrenCount({
				activity_id: this.options.activity.activity_id
			}).$promise.then((res) => {
				if (res.activities > 0) {
					return this.ngxDowngradeModalService.openConfirmDialog({
						title: this.$translate.instant('modal-activity-edit-master-warning_edit-activity'),
						message: this.$translate.instant('modal-activity-edit-master-warning_warning-text', { folders: res.activities, videos: res.sessions }),
						confirmText: this.$translate.instant('common_save')
					});
				} else {
					return this.$q.when(true);
				}
			});
		} else if (this.changesRequireWarning(form)) {
			confirmPromise = this.$q.when(this.ngxDowngradeModalService.openConfirmDialog({
				title: this.$translate.instant('modal-activity-edit-warning_edit-activity'),
				message: this.$translate.instant('modal-activity-edit-warning_warning-text', { total: this.options.activity.usage }),
				confirmText: this.$translate.instant('common_continue')
			}));
		}

		confirmPromise.then((confirmed) => {
			if (confirmed) {
				if (this.featureFlag.isAvailable('HEYGEN')) {
					this.handleHeyGen();
				}

				this.options.activity.save()
					.then(() => {
						this.$close(this.options.activity);
					})
					.catch(() => {
						this.ngxGoToastService.createToast({
							type: GoToastStatusType.ERROR,
							message: 'common_unknown-error_message'
						});
					})
					.finally(() => {
						this.saving = false;
					});
			}
		}).catch(() => this.saving = false);
	}

	/**
	 * Cancelling will dismiss this panel. If there are changes to the form,
	 * the user will be prompted to confirm that they'd like to discard the changes.
	 * If they choose 'yes', then the changes will be discarded and this panel
	 * will be dismissed. If they choose 'no', only the confirmation modal will close.
	 */
	public cancel (form: ng.IFormController): void {
		if (form.$dirty) {
			this.confirm(dirty.message, dirty.title);
		} else {
			this.$dismiss();
		}
	}

	public getPanelHeaderTextTranslation (): string {
		if (this.options.libraryMode && this.editMode) {
			return 'activity-editor-panel-header_library-edit';
		} else if (this.options.libraryMode) {
			return 'activity-editor-panel-header_library-create';
		} else if (this.editMode) {
			return 'activity-editor-panel-header_edit';
		}

		return 'activity-editor-panel-header_create';
	}

	public getPrimaryButtonTextTranslation (): string {
		if (this.editMode) {
			return 'common_save-and-close';
		}
		return 'activity-editor-panel-header_create';
	}

	private confirm (message, title) {
		this.confirmModal.open({
			size: 'sm',
			modalData: {
				message,
				title,
				yes: 'common_discard-changes',
				no: 'common_cancel'
			}
		}).result.then(() => {
			this.$dismiss();
		}).catch(noop);
	}

	private changesRequireWarning (form: ng.IFormController): boolean {
		if (!this.options.activity.usage || this.options.activity.usage < 1) {
			return false;
		}

		// List form fields that are safe to change without warning
		const safeChanges = ['name'];

		return form.$getControls().some((control) => {
			return !safeChanges.includes(control.$name) && control.$dirty;
		});
	}

	private handleHeyGen () {
		this.$http.post('/api/v2/heygen/prompts', {
			prompts: this.options.activity.hey_gen_prompts
		});
		delete this.options.activity.hey_gen_prompts;
	}
}
