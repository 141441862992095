import { UIRouterGlobals } from '@uirouter/angularjs';
import { SessionInterceptor } from './session-manager.interceptor';

/* @ngInject */
export function sessionManagerConfig ($httpProvider) {

	// Register the auth interceptor
	$httpProvider.interceptors.push(function (
		$q,
		sessionManager,
		$injector: ng.auto.IInjectorService,
		$uiRouterGlobals: UIRouterGlobals
	) {
		return {
			responseError (response) {
				return $q.reject(SessionInterceptor.responseError(response, sessionManager, $injector.get('eventService'), $uiRouterGlobals));
			}
		};
	});
}
