import * as angular from 'angular';
import * as dayjs from 'dayjs';
import { pick, isEqual, clone } from 'lodash';
import { clientSettings } from '../common/client.settings';
import { ActivityInstructionModel } from './activity-instruction';
import {
	PrivacyLevel,
	privacyLevelConfigurations
} from './privacy-level';
import { MediaSource } from '../media';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

import type { ActivityFactory } from './activity.schema';
export type { ActivityFactory } from './activity.schema';

export const EQUIPMENT_CHECK_STORAGE_KEY_PREFIX: string = 'equipment-check-passed';

/* @ngInject */
export function ActivityModelFactory (
	$resource: ng.resource.IResourceService,
	ActivityTemplateModel,
	activityModelInterceptor,
	activityModelTransformer,
	modelConfig,
	cacheManager,
	$translate: ng.translate.ITranslateService
): ActivityFactory {

	const baseUrl = modelConfig.defaults.serviceBaseUrl + '/activities';
	const resourceUrl = baseUrl + '/index/:activity_id';

	const ActivityModel: any = $resource(resourceUrl, {
		activity_id: '@activity_id', group_id: '@group_id'
	}, {
		copy: {
			method: 'PUT',
			transformResponse: activityModelTransformer.response,
			url: `${clientSettings.GoReactV2API}/courses/:group_id/activities/:activity_id/copy`
		},
		delete: {
			method: 'DELETE',
			url: `${clientSettings.GoReactV2API}/activities/:activity_id`
		},
		get: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/activities/:activity_id`,
			transformResponse: activityModelTransformer.response,
			interceptor: activityModelInterceptor
		},
		save: {
			method: 'POST',
			url: `${clientSettings.GoReactV2API}/activities`,
			transformRequest: activityModelTransformer.request,
			transformResponse: activityModelTransformer.response,
			interceptor: activityModelInterceptor
		},
		update: {
			method: 'PUT',
			url: `${clientSettings.GoReactV2API}/activities/:activity_id`,
			transformRequest: activityModelTransformer.request,
			transformResponse: activityModelTransformer.response,
			interceptor: activityModelInterceptor
		},
		getByGroup: {
			url: `${clientSettings.GoReactV2API}/groups/:group_id/activities`,
			method: 'GET',
			isArray: true,
			transformResponse: activityModelTransformer.response,
			interceptor: activityModelInterceptor
		},
		getAllActivityStats: {
			url: `${clientSettings.GoReactV2API}/groups/:group_id/activity-stats`,
			method: 'GET',
			isArray: true,
			interceptor: activityModelInterceptor
		},
		getSummaryReport: {
			url: `${clientSettings.GoReactV2API}/groups/:group_id/activity-report`,
			method: 'GET',
			isArray: true
		},
		getRubricReport: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/activities/:activity_id/rubric_report?reportType=:report_type&timezone=:timezone`
		},
		getSummaryReportCsv: {
			url: `${clientSettings.GoReactV2API}/groups/:group_id/activity-report?csv=true`,
			method: 'GET',
			responseType: 'blob',
			transformResponse (data, headers) {
				return { data, headers };
			}
		},
		markAsViewed: {
			method: 'POST',
			url: `${clientSettings.GoReactV2API}/activities/:activity_id/views`
		},
		setupComplete: {
			method: 'POST',
			url: `${clientSettings.GoReactV2LTI}/v1p1/activities/:activity_id/assignment-setup-complete`,
			transformRequest: (req) => {
				delete req.activity_id;
				return angular.toJson(req);
			}
		},
		childrenCount: {
			url: `${clientSettings.GoReactV2API}/activities/:activity_id/children-count`,
			method: 'GET'
		}
	});

	/**
	 * Name of the default activity
	 *
	 * @type {string}
	 */
	(ActivityModel as Mutable<ActivityFactory>).DefaultName = '__ACCT_DEFAULT__';
	(ActivityModel as Mutable<ActivityFactory>).NEW_ACTIVITY_SORT_INDEX = 999999999;
	(ActivityModel as Mutable<ActivityFactory>).RESOURCE_TYPE = 'activity';

	/**
	 * Get activity from cache
	 *
	 * @param data
	 * @param skipCache
	 * @returns {Object}
	 */
	ActivityModel.model = (data, skipCache) => {
		let newInstance = new ActivityModel(activityModelTransformer.response[1](data || {})) as any;

		if (newInstance.getId() > 0) {
			const cachedInstance = ActivityModel.getCache().get(newInstance.getId() as string);

			// if instance is already in the cache,
			// the new instance should use that reference
			if (cachedInstance && !skipCache) {
				angular.extend(cachedInstance, newInstance);
				newInstance = cachedInstance;
			} else {
				ActivityModel.getCache().put(newInstance.getId() as string, newInstance);
			}
		}

		// Define empty sessions array if reference is not already defined
		if (!angular.isArray(newInstance.sessions)) {
			newInstance.sessions = [];
		}

		return newInstance;
	};

	/**
	 * Get / set activity in cache
	 */
	ActivityModel.getCache = () => {
		return cacheManager('activity');
	};

	/**
	 * Get id
	 *
	 * @returns {string}
	 */
	ActivityModel.prototype.getId = function () {
		return this.activity_id;
	};

	/**
	 * Set group id
	 */
	ActivityModel.prototype.setGroupId = function (groupId) {
		this.group_id = groupId;
	};

	/**
	 * Set whether activity is saving
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.setSaving = function (value) {
		this.saving = !!value;
	};

	/**
	 * Set whether activity requires self select stimulus
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.requiresSelfSelectStimulus = function () {
		return !this.template.isNone('source_media') && this.has_self_select_source_media;
	};

	/**
	 * Set number of attachments
	 *
	 * @param value
	 */
	ActivityModel.prototype.setNumAttachments = function (value) {
		this.num_attachments = value;
	};

	/**
	 * Whether activity requires comments
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.requiresComments = function () {
		return !this.has_response_media;
	};

	/**
	 * Whether activity set is saved
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.isSaved = function () {
		return this.getId() > 0;
	};

	/**
	 * Extend this activity
	 *
	 * @param data
	 * @returns {*}
	 */
	ActivityModel.prototype.extend = function (data, ...args) {
		args.unshift(this);

		// transform the data
		activityModelTransformer.response[1](data);

		return angular.merge.apply(this, args);
	};

	/**
	 * Whether activity is a 'default' activity, meaning it is the default activity for an account
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.isDefault = function () {
		return this.name === ActivityModel.DefaultName;
	};

	/**
	 * Whether peer review is enabled
	 */
	ActivityModel.prototype.isPeerReview = function () {
		return this.peer_review;
	};

	/**
	 * Whether submissions are graded individually
	 */
	ActivityModel.prototype.isIndividualGraded = function () {
		return this.is_individual_graded;
	};

	/**
	 * Whether peer view is enabled
	 */
	ActivityModel.prototype.isPublicFeedback = function () {
		return this.public_feedback;
	};

	/**
	 * Whether live sessions is enabled
	 */
	ActivityModel.prototype.isLiveSessionEnabled = function () {
		return this.live_session_enabled;
	};

	/**
	 * Whether conversation is enabled
	 */
	ActivityModel.prototype.isConversation = function () {
		return this.is_conversation;
	};

	/**
	 * Whether conversation board is enabled
	 */
	ActivityModel.prototype.isConversationBoard = function () {
		return this.is_conversation && !this.has_response_media;
	};

	/**
	 * Whether single attempt is enabled
	 */
	ActivityModel.prototype.isSingleAttempt = function () {
		return this.has_single_recording_attempt;
	};

	/**
	 * Whether sessions in this activity are synchronous
	 */
	ActivityModel.prototype.isSynchronous = function () {
		return this.is_conversation || this.live_session_enabled;
	};

	/**
	 * Whether sessions in this activity are asynchronous
	 */
	ActivityModel.prototype.isAsynchronous = function () {
		return !this.isSynchronous();
	};

	/**
	 * Whether or not this activity is available yet
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.isAvailable = function () {
		let result = true;
		if (this.available_at) {// if none, it is available
			result = angular.isDate(this.available_at) && dayjs().diff(this.available_at) >= 0;
		}
		return result;
	};

	/**
	 * Whether activity is passed due
	 */
	ActivityModel.prototype.isPastDue = function () {
		return dayjs().isAfter(this.due_at);
	};

	/**
	 * Whether activity has due date
	 */
	ActivityModel.prototype.hasDueDate = function () {
		return angular.isDate(this.due_at);
	};

	/**
	 * Whether activity is within 48 hours of the due date
	 */
	ActivityModel.prototype.isAlmostDue = function () {
		return dayjs().isAfter(dayjs(this.due_at).subtract(2, 'day'));
	};

	/**
	 * Whether or not this activity is auto scored
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.isAutoScored = function () {
		return this.isRubricEnabled() && this.getTotalPoints() > 0;
	};

	/**
	 * Whether slides are enabled for this activity
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.isSlidesEnabled = function () {
		return this.is_slides_enabled;
	};

	/**
	 * Whether rewind and skip are enabled for this activity
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.isVideoSeekingDisabled = function () {
		return this.is_video_seeking_disabled;
	};

	/**
	 * Whether the video is shared
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.isVideoShareEnabled = function () {
		return this.is_video_share_enabled;
	};

	/**
	 * Set recording instructions
	 *
	 * @param instructions
	 */
	ActivityModel.prototype.setRecordingInstructions = function (instructions) {
		this.recording_instructions = instructions;
	};

	/**
	 * Get feedback instructions
	 *
	 * @returns {string}
	 */
	ActivityModel.prototype.getFeedbackInstructions = function () {
		return this.feedback_instructions;
	};

	/**
	 * Whether activity has instructions
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.hasInstructions = function () {
		return this.hasRecordingInstructions() || this.hasFeedbackInstructions();
	};

	/**
	 * Whether activity has recording instructions
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.hasRecordingInstructions = function () {
		// Once the old activity editor goes way, we can simplify this
		return this.recording_instructions instanceof ActivityInstructionModel &&
			(this.recording_instructions.text || this.recording_instructions.media_id);
	};

	/**
	 * Whether activity has feedback instructions
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.hasFeedbackInstructions = function () {
		// Once the old activity editor goes way, we can simplify this
		return this.feedback_instructions instanceof ActivityInstructionModel &&
			(this.feedback_instructions.text || this.feedback_instructions.media_id);
	};

	/**
	 * Returns the source media associated with this activity
	 *
	 * @returns {Object}
	 */
	ActivityModel.prototype.getSourceMedia = function () {
		return this.source_media;
	};

	/**
	 * Whether this activity has source media
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.hasSourceMedia = function () {
		return angular.isObject(this.getSourceMedia());
	};

	/**
	 * Whether this activity has response media
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.hasResponseMedia = function () {
		return this.has_response_media;
	};

	/**
	 * Get total score
	 *
	 * @returns {Number}
	 */
	ActivityModel.prototype.getTotalPoints = function () {
		return parseFloat(this.total_score) || null;
	};

	/**
	 * Whether group user may give an overall score
	 *
	 * @param group
	 * @returns {boolean}
	 */
	ActivityModel.prototype.mayScore = function (group) {
		if (!group || !group.hasReviewerRole(true)) {
			return false;
		}

		if (!this.isScoreEnabled()) {
			return false;
		}

		return true;
	};

	/**
	 * Whether or not feedback is enabled
	 *
	 * @returns {*}
	 */
	ActivityModel.prototype.isFeedbackEnabled = function () {
		return this.isCommentsEnabled() ||
			this.isRubricEnabled();
	};

	/**
	 * Whether commenting is enabled
	 *
	 * @returns {*}
	 */
	ActivityModel.prototype.isCommentsEnabled = function () {
		return this.use_comments;
	};

	/**
	 * Whether scoring is enabled for this activity
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.isScoreEnabled = function () {
		return this.getTotalPoints() > 0;
	};

	/**
	 * Whether rubric evaluations are enabled for this activity
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.isRubricEnabled = function () {
		return this.getRubricTemplateId() > 0;
	};

	/**
	 * Whether peer rubric evaluations are enabled for this activity
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.isPeerRubricEnabled = function () {
		return this.getPeerRubricTemplateId() > 0 &&
			(!!this.peer_rubric_critique_enabled || !!this.self_rubric_critique_enabled);
	};

	/**
	 * Whether tags are enabled
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.isTagsEnabled = function () {
		return this.tag_set_id > 0;
	};

	/**
	 * Returns the rubric template id associated with this activity
	 *
	 * @returns {Number}
	 */
	ActivityModel.prototype.getRubricTemplateId = function () {
		return this.rubric_template_id;
	};

	/**
	 * Returns the peer rubric template id associated with this activity
	 *
	 * @returns {Number}
	 */
	ActivityModel.prototype.getPeerRubricTemplateId = function () {
		return this.peer_rubric_id;
	};

	/**
	 * Get activity template id
	 */
	ActivityModel.prototype.getActivityTemplateId = function () {
		return this.activity_template_id;
	};

	/**
	 * Set activity template id
	 */
	ActivityModel.prototype.setActivityTemplateId = function (value) {
		this.activity_template_id = value;
	};

	/**
	 * Whether this activity has usage
	 */
	ActivityModel.prototype.hasUsage = function () {
		return this.usage > 0;
	};

	/**
	 * Whether this activity has attachments
	 */
	ActivityModel.prototype.hasAttachments = function () {
		return this.num_attachments > 0;
	};

	/**
	 * Set activity template
	 *
	 * @param template
	 */
	ActivityModel.prototype.setActivityTemplate = function (template) {

		if (!(template instanceof ActivityTemplateModel)) {
			throw new Error('setActivityTemplate:: Activity template must be an instance of ActivityTemplateModel');
		}

		this.template = template;

		// validate activity
		this.validate();
	};

	/**
	 * Validate activity
	 */
	ActivityModel.prototype.validate = function () {

		// ensure activity template id matches template id
		this.setActivityTemplateId(this.template.getId());

		// apply the template rules to this activity
		this.applyTemplate(this.template);
	};

	/**
	 * Apply a given template's rules to this activity
	 */
	ActivityModel.prototype.applyTemplate = function (template: any): any {
		for (const key in template) {
			if (template.hasOwnProperty(key)) {
				const rule: string = template[key];

				switch (rule) {
					case ActivityTemplateModel.RULE.NONE:
						this[key] = false;
						break;
					case ActivityTemplateModel.RULE.ACTIVE:
						this[key] = true;
						break;
				}
			}
		}

		return this;
	};

	/**
	 * Get feedback instruction cache identifier
	 *
	 * @param userId
	 * @returns {string}
	 */
	ActivityModel.prototype.getFeedbackInstructionCacheIdentifier = function (userId) {
		return `instruction-viewed-${this.getId()}-${userId}`;
	};

	/**
	 * Whether template may be changed to another
	 */
	ActivityModel.prototype.mayChangeTemplateTo = function (template: any): boolean {
		if (!this.hasUsage()) {
			return true;
		}

		// If the current template is fundamentally different from the supplied
		// template, then don't allow the current template to be changed.
		if (this.template.isFundamentallyDifferentFrom(template)) {
			return false;
		}

		// Properties that if changed, may cause irreversible issues
		const properties: string[] = [
			'source_media',
			'has_response_media'
		];

		// Perform a dry run by cloning this activity and applying the supplied
		// template's rules to it. Then, compare the two activities to determine
		// whether or not a fundamental difference between the two exists.
		const copy = clone(this).applyTemplate(template);

		return isEqual(
			pick(this, properties),
			pick(copy, properties)
		);
	};

	/**
	 * Use activity as a template
	 *
	 * @param activity
	 * @returns {ActivityModel}
	 */
	ActivityModel.useAsTemplate = (activity) => {
		const template = ActivityModel.model();

		if (activity instanceof ActivityModel) {

			// options
			template.activity_template_id = activity.activity_template_id;
			template.use_comments = activity.use_comments;
			template.use_score = activity.use_score;
			template.live_session_enabled = activity.live_session_enabled;
			template.has_self_select_source_media = activity.has_self_select_source_media;
			template.is_slides_enabled = activity.is_slides_enabled;
			template.is_video_seeking_disabled = activity.is_video_seeking_disabled;
			template.is_video_share_enabled = activity.is_video_share_enabled;
			template.ai_prompts_enabled = activity.ai_prompts_enabled;
			template.peer_review = activity.peer_review;
			template.public_feedback = activity.public_feedback;
			template.peer_view = activity.peer_view;
			template.has_single_recording_attempt = activity.has_single_recording_attempt;
			template.can_reviewers_see_grades = activity.can_reviewers_see_grades;
			template.time_limit = activity.time_limit;
			template.is_feedback_published = activity.is_feedback_published;
			template.is_source_media_cc_disabled = activity.is_source_media_cc_disabled;
			template.tag_set_id = activity.tag_set_id;
		}

		return template;
	};

	/**
	 * Save this activity
	 *
	 * @returns {Object}
	 */
	ActivityModel.prototype.save = function () {
		const fn = this.isSaved() ? this.$update.bind(this) : this.$save.bind(this);

		// Hold on to the sessions when an activity is updated or saved
		const sessions = this.sessions;
		const unviewedComments = this.unviewed_comments;
		const unviewedSessions = this.unviewed_sessions;

		// validate activity before submission
		this.validate();

		this.setSaving(true);

		return fn().then(() => {
			this.sessions = sessions;
			this.unviewed_comments = unviewedComments;
			this.unviewed_sessions = unviewedSessions;
			this.setSaving(false);
		});
	};

	ActivityModel.prototype.duplicate = function (appendToName: boolean = true) {
		const activity = angular.copy(this);
		activity.parent_id = activity.activity_id;
		activity.activity_id = null;
		activity.name = appendToName ?
			// In order to allow characters like '&' in users activity tiles, we have to do something special
			// with this translation. Normally, we only render things we put through translate, but
			// in the case of this duplicate method, we intend to save this value. We don't want to save the
			// escaped HTML when adding to library, or show it in the activity editor when duplicating an activity.
			// So we use a trick here to remove the escaped values before passing it along.
			$translate.instant('group-view_copy-in-parenthesis', { activity_name: activity.name }, null, false, []) :
			activity.name;
		activity.available_at = null;
		activity.due_at = null;
		activity.is_duplicate = true;
		activity.usage = 0;
		return activity;
	};

	/**
	 * Remove this activity
	 *
	 * @returns {Object}
	 */
	ActivityModel.prototype.remove = function () {
		return ActivityModel.delete({
			activity_id: this.getId(),
			group_id: this.group_id
		}, () => { }).$promise;
	};

	ActivityModel.prototype.setupComplete = function (isComplete = true) {
		return ActivityModel.setupComplete({ activity_id: this.activity_id, isComplete });
	};

	/**
	 * Whether to withhold feedback for a group/activity combo
	 *
	 * @param group
	 * @returns {boolean}
	 */
	ActivityModel.prototype.shouldHoldFeedback = function (hasPresenterRole) {
		return hasPresenterRole
			&& !this.getFeedbackPublished();
	};

	/**
	 * Returns whether a given property on an activity
	 * is viewable defined by it's template
	 *
	 * @param property
	 * @returns {boolean}
	 */
	ActivityModel.prototype.isViewable = function (property) {
		return this.template &&
			!this.template.isNone(property) &&
			!this.template.isActive(property);
	};

	/**
	 * Returns is_feedback_published
	 *
	 * @returns {string | null}
	 */
	ActivityModel.prototype.getFeedbackPublished = function () {
		return this.is_feedback_published;
	};

	/**
	 * Determine whether or not this activity has a given privacy level
	 */
	ActivityModel.prototype.getPrivacyLevel = function (): PrivacyLevel {
		return privacyLevelConfigurations.findIndex((item) => {
			return item.public_feedback === this.public_feedback
				&& item.peer_review === this.peer_review;
		});
	};

	/**
	 * Populates a skeleton activity with default values on the given group
	 *
	 * @param group
	 * @returns {ActivityModel}
	 */
	ActivityModel.prototype.populateEmptyActivityOnGroup = function (group) {
		const activityTemplate = ActivityTemplateModel.model({
			id: this.getActivityTemplateId()
		});

		this.setGroupId(group.getId());
		this.setActivityTemplate(activityTemplate);
		this.setNumAttachments(0);
		this.sort_index = ActivityModel.NEW_ACTIVITY_SORT_INDEX;
	};

	/**
	 * Determines if the current user needs to do an equipment check
	 *
	 * @returns {boolean}
	 */
	ActivityModel.prototype.isEquipmentCheckRequired = function (sessionStorage: { get: (value: string) => string }) {
		if (this.has_single_recording_attempt) {
			return true;
		}

		const storageKey = `${EQUIPMENT_CHECK_STORAGE_KEY_PREFIX}-${MediaSource.OPENTOK}`;
		return !sessionStorage.get(storageKey);
	};

	/**
	 * Determines if activity is Comment Only - Single Attempt
	 */
	ActivityModel.prototype.isCommentOnly = function (): boolean {
		return !this.is_conversation &&
			!this.has_response_media;
	};

	/**
	 * Determines if activity is Comment Only - Single Attempt
	 */
	ActivityModel.prototype.isCommentOnlySingleAttempt = function (): boolean {
		return !this.is_conversation &&
			!this.has_response_media &&
			this.has_single_recording_attempt;
	};

	/**
	 * Checks if activity is multi ca live review.
	 */
	ActivityModel.prototype.isMulticamLiveReview = function (): boolean {
		return this.is_conversation && this.live_session_enabled;
	};

	ActivityModel.prototype.allowsPractice = function (): boolean {
		return this.has_response_media &&
			!this.live_session_enabled &&
			!this.has_single_recording_attempt &&
			!this.is_conversation;
	};

	return ActivityModel;
}

ActivityModelFactory.NG1_INJECTION_NAME = 'ActivityModel' as const;
export const activityToken = upgradeNg1Dependency(ActivityModelFactory);
