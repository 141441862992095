import template from './go-uploader.component.html';

export default {
	bindings: {
	},
	require: {
		umcController: '^universalMediaChooser'
	},
	template
};
