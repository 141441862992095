import { versionCompare } from './version-compare.helper';
import { BROWSER_NAMES } from './browser-name.constants';
import { OS_NAMES } from './os-name.constants';

import type UAParser from 'ua-parser-js';

export class BrowserDetector {
	constructor (public result: UAParser.IResult) {}

	public is (browser: string, minVersion: string, maxVersion: string): boolean {
		return this.result.browser.name.toLowerCase() === browser
			&& (minVersion == null || versionCompare(this.result.browser.version, minVersion) >= 0)
			&& (maxVersion == null || versionCompare(this.result.browser.version, maxVersion) < 0);
	}

	public isChrome (minVersion?: string, maxVersion?: string): boolean {
		return this.is(BROWSER_NAMES.CHROME, minVersion, maxVersion);
	}

	public isFirefox (minVersion?: string, maxVersion?: string): boolean {
		return this.is(BROWSER_NAMES.FIREFOX, minVersion, maxVersion);
	}

	public isSafari (minVersion?: string, maxVersion?: string): boolean {
		return this.is(BROWSER_NAMES.SAFARI, minVersion, maxVersion);
	}

	public isMobileSafari (minVersion?: string, maxVersion?: string): boolean {
		return this.is(BROWSER_NAMES.MOBILE_SAFARI, minVersion, maxVersion);
	}

	public isIE (minVersion?: string, maxVersion?: string): boolean {
		return this.is(BROWSER_NAMES.IE, minVersion, maxVersion);
	}

	public isEdge (minVersion?: string, maxVersion?: string): boolean {
		return this.is(BROWSER_NAMES.EDGE, minVersion, maxVersion);
	}

	public isOldEdge (minVersion?: string, maxVersion: string = '75'): boolean {
		return this.is(BROWSER_NAMES.EDGE, minVersion, maxVersion);
	}

	public isChromiumEdge (minVersion: string = '76', maxVersion?: string): boolean {
		return this.is(BROWSER_NAMES.EDGE, minVersion, maxVersion);
	}

	public isIOSNonSafari (): boolean {
		return this.result.browser.name.toLowerCase() !== BROWSER_NAMES.SAFARI
			&& this.result.browser.name.toLowerCase() !== BROWSER_NAMES.MOBILE_SAFARI
			&& this.result.os.name.toLowerCase() === OS_NAMES.IOS;
	}

	public isPWA (): boolean {
		const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
		return 'standalone' in window.navigator || isStandalone;
	}

	public get browser () {
		return {
			...this.result.browser,
			uniqueName: this.isChromiumEdge() ? BROWSER_NAMES.CHROMIUM_EDGE : this.result.browser.name
		};
	}
}
