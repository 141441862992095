import { AfterContentInit, AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, Inject, NgZone, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { UserService as NgxUserService } from 'ngx/go-modules/src/services/user/user.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { Input } from '@angular/core';

@Component({
	selector: 'survey-snack-bar',
	template: require('./survey-snack-bar.component.html'),
	styles: [require('./survey-snack-bar.component.scss')]
})
export class SurveySnackBarComponent implements AfterViewInit, AfterContentInit {
	@ViewChild('firstFocusableElement') public firstFocusableElement!: ElementRef;
	@ContentChild('projectedContent', { static: false }) public projectedContent!: TemplateRef<any>;
	@Input() public form!: FormGroup;
	@Output() public submit: EventEmitter<any>  = new EventEmitter();
	@Output() public close: EventEmitter<any>  = new EventEmitter();
	public hoveredRating: number = null;
	public hasProjectedContent = false;

	constructor (
		protected ngxUserService: NgxUserService,
		protected snackbarRef: MatSnackBarRef<SurveySnackBarComponent>,
		protected ngxGoToastService: NgxGoToastService,
		protected snackbar: MatSnackBar,
		protected ngZone: NgZone,
		@Inject(userServiceToken) protected userService: UserService
	) {}

	public static get localStorageKey (): string {
		throw new Error('Subclasses must define localStorageKey');
	}

	public static open (_snackBar: MatSnackBar) {
		throw new Error('open must be implemented in the subclass');
	}

	public static canShow (_userId) {
		throw new Error('canShow must be implemented in the subclass');
	}

	public ngAfterViewInit (): void {
		if (this.constructor.name !== 'SurveySnackBarComponent') return;

		this.snackbarRef.afterOpened().subscribe(() => {
			this.firstFocusableElement.nativeElement.focus();
		});
	}

	public ngAfterContentInit (): void {
		this.hasProjectedContent = !!this.projectedContent;
	}
}
