import * as angular from 'angular';
import * as uiTooltip from 'angular-ui-bootstrap/src/tooltip';
import * as uiPopover from 'angular-ui-bootstrap/src/popover';
import * as uiModal from 'angular-ui-bootstrap/src/modal';
import * as uiDropdown from 'angular-ui-bootstrap/src/dropdown';
import * as angulartics from 'angulartics';
import * as angularTranslate from 'angular-translate';
import * as angularMessages from 'angular-messages';
import * as ngMessages from 'angular-messages';
import * as angularMaterial from 'angular-material';

import { helpUrlsModule } from '../help-urls';
import { detectModule } from '../detect';
import { sessionManager } from '../session-manager';
import { masqueradeModule } from '../masquerade';
import { goListModule } from '../go-list';
import { uiDecimal } from '../ui-decimal';
import { userGroupModel } from '../models/user-group';
import { group } from '../models/group';
import { groupDepModel } from '../models/group-dep';
import { tagSetModel } from '../models/tag-set';
import { mediaModel } from '../models/media';
import { userModel } from '../models/user';
import { userActivityHistory } from '../user-activity-history';
import { commonFiltersModule } from '../common-filters';
import { timeagoModule } from '../timeago';
import { mediaPlayerModule } from '../media-player';
import { goPdfModule } from '../go-pdf';
import { goDateTimePickerModule } from '../datetimepicker';
import { formValidationModule } from '../form-validation';
import { confirmModalModule } from './confirm';
import { inviteModelModule } from '../models/invite';
import { featureFlagModule } from 'go-modules/feature-flag';
import { featureSupportModule } from 'go-modules/feature-support';
import { zoomServiceModule } from 'go-modules/services/zoom';
import { emailList } from '../email-list';
import { lazyPaymentPanelModule } from 'go-modules/payment-panel/lazy-index';
import { ngxGoToastModule } from 'ngx/go-modules/src/components/go-toast';

// TODO: These are modals opened by modals
// Eventually these should be all gone
import { messageModalModule } from './message';
import { rubricEditorModalModule } from './rubric-editor';

import './style.less';

import { goModal as goModalFactory } from './go-modal.factory';
import { templateCache } from './templates';
import { time } from 'go-modules/time';
import { NgxGoClipboardModule } from 'ngx/go-modules/src/directives/go-clipboard';

// TODO: fix circular dependency.
// Until then, we need to import directly to force side-effect of angular module
// loading. If we leave the `fooModule from` part, webpack will use tree-shaking
// and the module will not be loaded.
// eslint-disable-next-line @typescript-eslint/tslint/config
import /*{ accessibilityAttachmentsModule } from*/ 'go-modules/accessibility-attachments';
// eslint-disable-next-line @typescript-eslint/tslint/config
import /*{ sessionEditorModule } from*/ '../session-editor';
// eslint-disable-next-line @typescript-eslint/tslint/config
import /*{ universalMediaChooserModule } from*/ '../universal-media-chooser';
// eslint-disable-next-line @typescript-eslint/tslint/config
import /*{ mediaThumbnailModule } from*/ '../media-thumbnail';
// eslint-disable-next-line @typescript-eslint/tslint/config
import /*{ mediaPreviewModalModule } from*/ 'go-modules/modals/media-preview';
import { authServiceModule } from 'go-modules/services/auth';
import { licenseModel } from 'go-modules/models/license';
import { ngxDowngradeModalServiceModule } from 'ngx/go-modules/src/services/downgrade-modal';
import { ngxSsoProviderButtonModule } from 'ngx/go-modules/src/components/sso-provider-button';
import { richDropdownModule } from 'go-modules/rich-dropdown';

export const goModal = angular.module('go.modal', [
	uiTooltip,
	uiPopover,
	uiModal,
	uiDropdown,
	angularMaterial,
	helpUrlsModule,
	mediaPlayerModule,
	'accessibility-attachments',
	goPdfModule,
	detectModule,
	authServiceModule,
	sessionManager,
	masqueradeModule,
	goListModule,
	uiDecimal,
	rubricEditorModalModule,
	messageModalModule,
	featureFlagModule,
	featureSupportModule,
	zoomServiceModule,
	emailList,
	lazyPaymentPanelModule,
	ngxGoToastModule,
	ngxSsoProviderButtonModule,
	richDropdownModule,
	goDateTimePickerModule,
	userGroupModel,

	// library viewer
	group,
	groupDepModel,
	tagSetModel,
	mediaModel,
	licenseModel,
	angulartics,

	userModel,
	formValidationModule,
	userActivityHistory,
	commonFiltersModule,
	angularTranslate,
	timeagoModule,
	angularMessages,
	confirmModalModule,
	inviteModelModule,
	time,
	NgxGoClipboardModule,
	ngxDowngradeModalServiceModule,
	ngMessages,

	// TODO: use module strings directly until circular dependency is fixed
	'go.session-editor',
	'universal-media-chooser',
	'modal.media-preview',
	'go.mediaThumbnail'
])
	.run(templateCache)
	.factory(goModalFactory.NG1_INJECTION_NAME, goModalFactory)
	.name;
