import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { goPasswordValidator } from 'ngx/go-modules/src/form-validator/go-password/go-password.validator';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { AuthProvider } from 'ngx/go-modules/src/interfaces/auth-providers';

export interface ChangePasswordDialogData {
	user: any;
	currentUser: any;
	sessionId?: string;
	isUnlinking?: boolean;
	authProvider?: AuthProvider;
};

@Component({
	selector: 'change-password-dialog',
	template: require('./change-password-dialog.component.html'),
	styles: [require('./change-password-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordDialogComponent {

	public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public error: any;
	public form: FormGroup;

	constructor (
		public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ChangePasswordDialogData,
		@Inject('Window') private window: Window,
		private eventService: EventService,
		private ngxAuthService: NgxAuthService,
		private ngxGoToastService: NgxGoToastService,
		private translate: TranslateService
	) {}

	public ngOnInit () {
		this.form = new FormGroup({
			newPassword: new FormControl(null, [Validators.required, goPasswordValidator()])
		});

		if (this.shouldShowOldPasswordField()) {
			this.form.addControl('oldPassword', new FormControl(null, [Validators.required]));
		}
	}

	public shouldShowOldPasswordField () {
		return (this.data.user.user_id === this.data.currentUser.user_id || !this.data.currentUser.is_root_user) &&
			!this.data.isUnlinking;
	}

	public submit (form) {
		if (form.invalid) {
			return;
		}

		this.loading$.next(true);
		// Create a new password when linking using the same validation as changing a password
		if (this.data.isUnlinking){
			return this.ngxAuthService.unlinkSsoAccount(this.data.authProvider.provider_name, {
				password: form.get('newPassword').value
			}).subscribe({
				next: (response) => {
					this.dialogRef.close(response);
				},
				error: () => {
					this.error = this.translate.instant('unlink-sso-dialog_error');
					this.loading$.next(false);
				}
			});
		}

		return this.ngxAuthService.changePassword({
			user_id: this.data.user.user_id,
			old_password: form.get('oldPassword')?.value ?? null,
			new_password: form.get('newPassword').value
		}).subscribe({
			next: () => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: 'common-save-success'
				});
				this.close();
			},
			error: (response) => {
				this.error = null;
				this.loading$.next(false);

				if (response.status === 401) {
					const options = {
						accountLockTime: response.error.remainingAccountLockSeconds
					};
					return this.ngxAuthService.logout(options).then(() => {
						this.dialogRef.close({success: false});

						if (this.data.sessionId) {
							this.eventService.broadcast(EVENT_NAMES.ACCOUNT_LOCKED, options);
						} else {
							this.window.location.replace(`https://${this.window.location.host}/dashboard/auth/login/`);
						}
					});
				} else {
					this.error = response.error;
				}
			}
		});
	}

	public close () {
		this.dialogRef.close();
	}
}
