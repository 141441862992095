import { Injectable } from '@angular/core';
import type { FeatureTypes, ComplexFeatureFlagValue } from './feature-flag-types';
import { UADetect as UADetectClass } from 'go-modules/detect/ua-detect.service';
import { clientSettings } from 'go-modules/models/common/client.settings';
import * as dayjs from 'dayjs';

// IMPORTANT: if adding a new feature test that relies on a client settings property
// that is set on the frontend like orgList or currentFolder
// be sure to preserve that property in watchForSettingChanges as that resets clientSettings every 15 minutes

@Injectable({
	providedIn: 'root'
})
export class FeatureTestsService {

	constructor (private UADetect: UADetectClass) {}

	public userCreatedAfter (createdAt: string|Date,
		next: (feature: FeatureTypes<FeatureTestsService>) => boolean) {

		return next(createdAt && dayjs(createdAt).utc().isBefore(dayjs(clientSettings.userCreatedAt).utc()));
	}

	// How to use userSegment flag
	// add a `userSegment` section to feature flags
	// The object can look as follows:
	// {
	//   <num-from-1-100>: <true/false>,
	//   DEFAULT: <true/false>
	// }
	public userSegment (limiter: ComplexFeatureFlagValue<FeatureTestsService>,
		next: (feature: FeatureTypes<FeatureTestsService>) => boolean) {

		const keys = Object.keys(limiter);

		let segmentPercentage = 0;
		let segmentKey = null;

		for (const key of keys) {
			if (key === 'DEFAULT') continue;
			const numValue = parseInt(key, 10);
			if (!isNaN(numValue)) {
				// use the first numeric segment we find
				segmentPercentage = numValue;
				segmentKey = key;
				break;
			}
		}

		if (!segmentKey) {
			return next(limiter.DEFAULT);
		}

		const remainderPercentage = 100 - segmentPercentage;
		const gcd = this.findGCD(segmentPercentage, remainderPercentage);

		const modDivisor = 100 / gcd;
		const userIdMod = clientSettings.userId % modDivisor;

		// user either belongs in A or B group
		if (userIdMod < (segmentPercentage / gcd)) {
			return next(limiter[segmentKey]);
		}

		return next(limiter.DEFAULT);
	}

	public browsers (browsers: ComplexFeatureFlagValue<FeatureTestsService>,
		next: (feature: FeatureTypes<FeatureTestsService>) => boolean) {

		const browserName = this.UADetect.browserDetector.browser.uniqueName.toUpperCase();
		const hasBrowserTest = browserName in browsers;
		if (!hasBrowserTest) {
			return next(browsers.DEFAULT);
		}
		return next(browsers[browserName]);
	}

	// see possible values https://docs.uaparser.dev/info/os/name.html
	// when putting in a flag should be all uppercase and replace spaces with underscores
	public operatingSystems (operatingSystems: ComplexFeatureFlagValue<FeatureTestsService>,
		next: (feature: FeatureTypes<FeatureTestsService>) => boolean) {

		const osName = this.UADetect.browserDetector.result.os.name.toUpperCase().replaceAll(' ', '_');
		const hasOSTest = osName in operatingSystems;
		if (!hasOSTest) {
			return next(operatingSystems.DEFAULT);
		}
		return next(operatingSystems[osName]);
	}

	public partners (partners: ComplexFeatureFlagValue<FeatureTestsService>,
		next: (feature: FeatureTypes<FeatureTestsService>) => boolean) {

		const activePartners: string[] = (clientSettings.partners || []).map((value) => value.guid);
		const hasPartnerTest = activePartners.some((partner) => partner in partners);
		if (!hasPartnerTest) {
			return next(partners.DEFAULT);
		}
		return activePartners.some((partner) => next(partners[partner]));
	}

	public orgs (orgs: ComplexFeatureFlagValue<FeatureTestsService>,
		next: (feature: FeatureTypes<FeatureTestsService>) => boolean) {

		const activeOrgs: number[] = clientSettings.orgList || [];
		const hasOrgTest = activeOrgs.some((org) => org in orgs);
		if (!hasOrgTest) {
			return next(orgs.DEFAULT);
		}
		return activeOrgs.some((org) => next(orgs[org]));
	}

	public folders (folders: ComplexFeatureFlagValue<FeatureTestsService>,
		next: (feature: FeatureTypes<FeatureTestsService>) => boolean) {
		const selectedFolder = clientSettings.currentFolder ?? undefined;

		if (selectedFolder === undefined || folders[selectedFolder.group_id] === undefined) {
			return next(folders.DEFAULT);
		}

		return next(folders[selectedFolder.group_id]);
	}

	public orgTypes (orgsTypes: ComplexFeatureFlagValue<FeatureTestsService>,
		next: (feature: FeatureTypes<FeatureTestsService>) => boolean) {
		const orgSettings = clientSettings.orgSettings;

		if (!orgSettings) {
			return false;
		}

		const foundKey = Object.keys(orgsTypes).find((item) => orgSettings.org_type === item.toLowerCase());

		if (!foundKey) {
			return next(orgsTypes.DEFAULT);
		}

		return next(orgsTypes[foundKey]);
	}

	public releaseAt (releaseAt: string,
		next: (feature: FeatureTypes<FeatureTestsService>) => boolean) {

		return next(releaseAt && dayjs.utc(releaseAt).isBefore(dayjs()));
	}

	// https://sourajeetm717.medium.com/euclids-gcd-method-iterative-and-recursive-6ee32935bcc8
	// https://en.wikipedia.org/wiki/Euclidean_algorithm#Implementations:~:text=%E2%88%921.-,Implementations,-%5Bedit%5D
	private findGCD (a: number, b: number): number {
		a = Math.abs(a);
		b = Math.abs(b);

		// Iterative Euclidean Algorithm (recursion begone!)
		while (b !== 0) {
			const temp = b;
			b = a % b;
			a = temp;
		}

		return a;
	}
}
