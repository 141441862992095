import { isObject } from 'lodash';
import { IPromise, IDeferred } from 'angular';
import { Events, States } from 'go-modules/video-scene/state-emitter/state-emitter';
import { VideoSceneController } from 'go-modules/video-scene/video-scene.controller';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { VideoQualityUtil } from 'ngx/go-modules/src/utilities/video-quality/video-quality.util';
import { SelectedService } from 'go-modules/services/selected/selected.service';

interface Bindings {
	mainController: any;
	singleMediaDisplayController: any;
}

export class RecordViewController implements Bindings {
	public mainController: any;
	public mediaDisplayController: any;
	public singleMediaDisplayController: any;
	public recorder: VideoSceneController;
	public umcOptions: any;
	public isWhiteLabel: boolean;
	public startedByMe: boolean;
	public environmentVarsService: EnvironmentVarsService;
	private eventSubscription: any;
	private eventNames: string[];

	/* @ngInject */
	constructor (
		private $q: ng.IQService,
		private feedbackSession,
		private Session,
		private pubnub,
		private goRecorderManagerService,
		private UniversalMediaChooser: any,
		private eventService: EventService,
		private selectedService: SelectedService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	/**
	 * Handles controller init life-cycle hook
	 */
	public $onInit () {
		this.isWhiteLabel = this.environmentVarsService.get(EnvironmentVarsService.VAR.WHITELABEL) as boolean;
		this.startedByMe = false;

		this.umcOptions = {
			defaultSection: this.UniversalMediaChooser.MEDIA_TYPE.RECORD_VIDEO,
			groupId: this.mainController.session.group_id,
			media: this.mainController.session.media,
			resourceId: this.mainController.session.session_id,
			resourceType: this.Session.RESOURCE_TYPE,
			goRecorder: {
				overlayNameEnabled: this.mainController.activity.is_conversation,
				showCaptionButton: this.mainController.activity.is_conversation,
				hideTimeDisplay: this.mainController.activity.is_conversation,
				isMultiCam: this.mainController.activity.is_conversation,
				timeLimit: this.mainController.activity.time_limit,
				disableResume: this.mainController.activity.has_single_recording_attempt,
				displayName: this.mainController.activity.isConversation()
					? this.mainController.user.getFullName()
					: '',
				videoQuality: this.selectedService.getLicense()?.salesforce_license.video_quality ??
					VideoQualityUtil.MINIMUM_RESOLUTION
			}
		};

		// When this is a test or we are in multi-display mode,
		// the recorder state labels need to say something different.
		if (!this.singleMediaDisplayController || this.mainController.activity.has_single_recording_attempt) {
			this.umcOptions.goRecorder.stateLabels = {
				uninitialized: 'media-recorder_controller-record',
				initialized: 'media-recorder_controller-record',
				active: 'media-recorder_controller-record',
				not_ready: 'feedback-session-media-display_begin',
				ready: 'feedback-session-media-display_begin',
				starting: 'media-recorder_controller-pause',
				recording: 'media-recorder_controller-pause',
				pausing: 'feedback-session-media-display_resume',
				paused: 'feedback-session-media-display_resume',
				stopping: 'common_stop',
				stopped: 'common_stop'
			};
			this.umcOptions.goRecorder.stateAnalyticsActions = {
				not_ready: 'begin',
				ready: 'begin',
				starting: 'pause',
				recording: 'pause',
				pausing: 'resume',
				paused: 'resume',
				stopping: 'record',
				stopped: 'record'
			};
		}

		if (!this.mainController.activity.hasRecordingInstructions()) {
			this.feedbackSession.toggleFeedbackTools(false);
		} else {
			this.feedbackSession.toggleFeedbackTools(true);
		}

		// Notifications... group is no longer active
		if (this.mainController.userGroup.past) {
			this.mainController.setNotification('GROUP_INACTIVE');
		}

		this.eventNames = [
			EVENT_NAMES.FEEDBACK_SESSION_DELETED,
			EVENT_NAMES.FEEDBACK_SESSION_POSTED,
			EVENT_NAMES.FEEDBACK_SESSION_DISCARD,
			EVENT_NAMES.FEEDBACK_SESSION_STATUS_CHANGE
		];
		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => this.eventNames.includes(ev.name)))
			.subscribe((ev: GoEvent) => {
				switch (ev.name) {
					case EVENT_NAMES.FEEDBACK_SESSION_DELETED:
						this.stopRecording().then(() => {
							this.mainController.exit();
						});
						break;
					case EVENT_NAMES.FEEDBACK_SESSION_POSTED:
					case EVENT_NAMES.FEEDBACK_SESSION_DISCARD:
						this.stopRecording();
						break;
					case EVENT_NAMES.FEEDBACK_SESSION_STATUS_CHANGE:
						const status = ev.data.status;
						const user = ev.data.user;
						switch (status) {
							case this.Session.LIVE:
								// Session started by another user or not a Multi-camera Meeting Session
								if (!this.startedByMe && !this.mainController.activity.is_conversation) {
									// Since the recording has already been started, swap the display mode to live
									this.feedbackSession.setMediaDisplayMode(
										this.feedbackSession.MediaDisplayMode.LIVE);

									// Ensure feedback tools are enabled
									this.feedbackSession.toggleFeedbackTools(true);

									// Show notification message
									this.mainController.setNotification('STARTED_BY_REMOTE_DEVICE');
								}
								break;
							case this.Session.RECORDED:
								// In response to remote stop request
								if (isObject(user)) {
									this.stopRecording().then(() => {
										this.mainController.setNotification('STOPPED_BY_REMOTE_DEVICE');
									});
								}
								break;
						}
				}
			});
	}

	/**
	 * Handles controller destroy life-cycle hook
	 */
	public $onDestroy () {
		this.goRecorderManagerService.unregister(
			this.feedbackSession.sessionRecorderId(this.mainController.session)
		);
		this.eventSubscription?.unsubscribe();
	}

	/**
	 * Handle UMC media determine event
	 */
	public onMediaDetermined (media: any): void {
		if (!this.mainController.session.media) {
			this.mainController.session.setMedia(media);
			this.mainController.session.save();
		}
	}

	/**
	 * Handles go recorder init
	 */
	public onRecorderInit (recorder: VideoSceneController): void {
		// Register the VideoSceneController instance with the gorecorder manager
		const recorderId: string = this.feedbackSession.sessionRecorderId(this.mainController.session);
		this.goRecorderManagerService.register(recorderId, recorder);

		recorder.on(Events.STATE_CHANGE, (state: States) => {
			switch (state) {
				case States.STARTING:
					this.handleRecorderStarting();
					break;
				case States.PAUSING:
					this.handleRecorderPause();
					break;
				case States.STOPPED:
					this.handleRecorderStopped();
					break;
			}
		});

		this.recorder = recorder;
	}

	/**
	 * Pause the recording and go to preview mode
	 */
	public stopRecording (): IPromise<void> {
		const deferred: IDeferred<void> = this.$q.defer();
		if (!this.recorder) {
			deferred.resolve();
			return deferred.promise;
		}
		const isRecording: boolean = [States.STARTING, States.RECORDING].includes(this.recorder.state);

		if (isRecording) {
			this.recorder.stop().then(() => {
				deferred.resolve();
			});
		} else {
			deferred.resolve();
		}

		return deferred.promise;
	}

	/**
	 * Whether an activity recording time_limit has been reached
	 */
	public timeLimitReached (): boolean {
		return this.mainController.activity.time_limit > 0 &&
			(this.recorder.getDuration() / 1000) >= this.mainController.activity.time_limit * 60;
	}

	/**
	 * Whether recording is enabled.
	 *
	 * Recording will be disabled for the following reasons:
	 * 1. The group may be expired.
	 * 2. Session recording may be explicitly disabled.
	 *
	 * @returns {boolean}
	 */
	public isRecordEnabled (): boolean {
		return !this.mainController.userGroup.past &&
			!this.feedbackSession.options.disableSessionRecording;
	}

	private handleRecorderStarting () {
		// Set started by me flag
		this.startedByMe = true;
		// Update session status to live
		this.mainController.session.setStatus(this.Session.LIVE);
	}

	// Handles recording pausing event
	private handleRecorderPause () {
		// Update session status to recorded
		this.mainController.session.setStatus(this.Session.RECORDED);
	}

	// Handles recording stopped event
	private handleRecorderStopped () {
		this.mediaDisplayController.setRecordingStoppedState(this.recorder.getRecordingStoppedState());

		// Publish that we ended to live viewers
		this.pubnub.pub(this.mainController.session.settings.pubnub.pubnub_channel, { recordingManuallyEnded: true });

		// If instant playback is not available, auto post and exit
		// immediately (don't make the user wait for processing to finish).
		if (!this.recorder.isInstantPlaybackAvailable()) {
			if (this.mediaDisplayController.wasRecordingStoppedByMe()) {
				if (this.mainController.activity.has_single_recording_attempt) {
					this.mainController.postSession().then(() => {
						this.mainController.exit();
					});
				} else {
					this.mainController.session.setStatus(this.Session.RECORDED);
					this.mainController.requestExit();
				}
			} else {
				this.mainController.exit();
			}
			return;
		}

		// If post discard feature is enabled and we are not in player sync mode, go to preview mode
		if (this.feedbackSession.options.manualPostDiscard && this.singleMediaDisplayController) {
			this.feedbackSession.setMediaDisplayMode(this.feedbackSession.MediaDisplayMode.PLAYBACK);
		} else if (this.mainController.activity.has_single_recording_attempt
			|| this.timeLimitReached()
			|| this.mainController.session.source_media
			&& this.mainController.activity.has_response_media) {
			if (this.mainController.activity.has_single_recording_attempt) {
				this.mainController.postSession().then(() => {
					this.mainController.exit();
				});
			} else {
				this.mainController.session.setStatus(this.Session.RECORDED);
				this.mainController.requestExit();
			}
		} else {
			this.mainController.requestExit();
		}
	}
}
