import { Injectable } from '@angular/core';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { VideoShareDialogComponent, VideoShareDialogData } from './video-share-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { InvalidEmailsErrorDialogComponent, InvalidEmailsErrorDialogData } from '../invalid-emails-error-dialog/invalid-emails-error-dialog.component';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { from, Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class VideoShareDialogService {
	constructor (
		private modal: GoModalService,
		private dialog: MatDialog,
		private translate: TranslateService,
		private ngxGoToastService: NgxGoToastService
	) {}

	public open (data: VideoShareDialogData): Observable<any> {
		return from(
			this.modal.open(VideoShareDialogComponent, false, {data}).afterClosed()
		).pipe(
			switchMap((res) => {
				if (res.dismissed) {
					return [res];
				}

				if (res.status !== undefined && res.status !== 200) {
					let message;

					switch (res.status) {
						case 422:
							message = this.translate.instant(
								'modal-video-share_share-fail-max-users_message',
								{ limit: clientSettings.SessionShareGuestReviewerLimit }
							);
							break;
						case 403:
							message = this.translate.instant('modal-video-share_share-fail-no-permission_message');
							break;
						default:
							message = this.translate.instant('common-generic-error-message');
							break;
					}

					return from(
						this.dialog
							.open(MessageDialogComponent, {
								data: {
									title: this.translate.instant(
										'modal-video-share_share-fail_title'
									),
									message
								}
							})
							.afterClosed()
					).pipe(map(() => res));
				}

				if (res.invalidEmails?.length > 0 && res.invalidUserNames?.length > 0) {
					return this.openErrorDialog({
						title: this.translate.instant('modal-video-share_send-invite-fail_title'),
						message: this.translate.instant('modal-video-share_send-invite-fail_message_invalid_emails_and_usernames_part_one'),
						additionalMessage: this.translate.instant('modal-video-share_send-invite-fail_message_invalid_emails_and_usernames_part_two'),
						closingMessage: this.translate.instant('modal-video-share_send-invite-fail_message_invalid_emails_and_usernames_part_three'),
						emails: res.invalidEmails,
						userNames: res.invalidUserNames
					}, 'invalidEmailsAndUserNames');
				}

				if (res.invalidEmails?.length > 0) {
					return this.openErrorDialog({
						title: this.translate.instant('modal-video-share_send-invite-fail_title'),
						message: this.translate.instant('modal-video-share_send-invite-fail_message_invalid_emails_part_one'),
						closingMessage: this.translate.instant('modal-video-share_send-invite-fail_message_invalid_emails_part_two'),
						emails: res.invalidEmails
					}, 'invalidEmails');
				}

				if (res.invalidUserNames?.length > 0) {
					return this.openErrorDialog({
						title: this.translate.instant('modal-video-share_send-invite-fail_title'),
						message: this.translate.instant('modal-video-share_send-invite-fail_message_invalid_user_ids_part_one'),
						closingMessage: this.translate.instant('modal-video-share_send-invite-fail_message_invalid_user_ids_part_two'),
						userNames: res.invalidUserNames
					}, 'invalidUserNames');
				}

				this.ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: 'modal-video-share_send-invite-success'
				});

				return [res];
			})
		);
	}

	private openErrorDialog (data: InvalidEmailsErrorDialogData, error: string): Observable<any> {
		return from(
			InvalidEmailsErrorDialogComponent.open(this.dialog, { data })
				.afterClosed()
		).pipe(map(() => ({ error })));
	}
}
