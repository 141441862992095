import * as angular from 'angular';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { Subject } from 'rxjs';
import { States } from 'go-modules/enums/states.enum';
import { UADetect as UADetectClass } from 'go-modules/detect/ua-detect.service';
import type { StudentRegisterRequestInterface } from 'ngx/dashboard/src/interfaces/sign-up/student-register-request';
import { CourseIdValidationDataInterface } from 'ngx/dashboard/src/interfaces/auth-response-course-id.interface';
import type { InviteValidation } from 'ngx/dashboard/src/interfaces/invite-validation.interface';
import type { LogoutSubscribeOptions } from 'ngx/go-modules/src/services/auth/interfaces/logout-subscribe-options.interface';
import type { AuthResponseSuccessDataInterface, AuthUserResponse, AuthGoreactProviderResponse } from 'ngx/go-modules/src/services/auth/interfaces/auth-response-success.interface';
import { ResetPasswordSources } from 'ngx/go-modules/src/enums';

export const AUTH = {
	ROLE_COOKIE_NAME: 'gr_role',
	AUTH_USER_NAME: 'gr_user',
	IS_LTI_COOKIE_NAME: 'gr_is_lti',
	EXPIRES_AT_COOKIE_NAME: 'gr_expires_at',
	HIGHEST_USER_ROLE_COOKIE_NAME: 'gr_highest_user_role'
};

export const USER_TYPES = {
	ADMIN: 'admin',
	ADMIN_WITH_BILLING: 'admin_with_billing',
	USER: 'user'
};

export const USER_ROLES = {
	ADMIN: 'admin',
	OWNER: 'owner',
	INSTRUCTOR: 'instructor',
	REVIEWER: 'reviewer',
	PRESENTER: 'presenter'
};

/**
 * AuthService setup result interface.
 */
export interface SetupResult {
	name: string;
	params: any;
	options: any;
}

/**
 * AuthService.
 *
 * Note: In the future this should be combined with the AuthService in Admin Tools when upgrading to latest Angular.
 */
export class AuthService {

	public authLogoutSubject = new Subject();

	/* @ngInject */
	constructor (
		private $http: ng.IHttpService,
		private $window: angular.IWindowService,
		private UADetect: UADetectClass,
		private $q: ng.IQService
	) {
	}

	/**
	 * Login to a user's account
	 */
	public login (username: string, password: string): ng.IHttpPromise<AuthResponseSuccessDataInterface> {
		const payload = {
			username: username || null,
			password: password || null
		};

		return this.$http.post(`${clientSettings.GoReactV2API}/users:login`, payload, {
			headers: {
				isPWA: this.UADetect.browserDetector.isPWA()
			}
		});
	}

	/**
	 * Log out the current user
	 */
	public logout (options?: LogoutSubscribeOptions): ng.IPromise<void> {
		return this.$http.post(`${clientSettings.GoReactV2API}/users:logout`, null)
			// We don't care if the endpoint fails (and it likely will if the user's token is invalid)
			// Continue with deleting all session information
			.catch(() => {})
			.then(() => this.authLogoutSubject.next(options));
	}

	/**
	 * Validate invite id
	 */
	public validateInvite (inviteId: string): angular.IHttpPromise<InviteValidation> {
		return this.$http.get(`${clientSettings.GoReactV2API}/invites/${inviteId}`);
	}

	/**
	 * Validates course code.
	 */
	public validateGroupCode (courseCode: string): angular.IHttpPromise<CourseIdValidationDataInterface> {
		return this.$http.get(`${clientSettings.GoReactV2API}/groups/validate-code/${courseCode}`);
	}

	/**
	 * Request password
	 */
	 public requestPassword (
		username: string,
		uuid: string = null,
		source: string = ResetPasswordSources.DASHBOARD
	): ng.IHttpPromise<void> {
		return this.$http.post(`${clientSettings.GoReactV2API}/users:request_password`, { username, uuid, source });
	}

	/**
	 * Register student.
	 */
	public registerStudent (
		studentRequest: StudentRegisterRequestInterface
	): ng.IHttpPromise<AuthResponseSuccessDataInterface> {
		return this.$http.post(
			`${clientSettings.GoReactV2API}/users`,
			studentRequest
		);
	}

	/**
	 * Setup user.
	 * Handler on successful login or register.
	 *
	 * @todo moving of route for accepting invite to new auth module located in routes.php
	 */
	public setUpUser (user: AuthUserResponse, options: any = {}): ng.IPromise<SetupResult | void> {
		// Start GA user tracking
		try {
			const role = user?.isInstructor ? 'instructor' : 'student';
			this.$window.ga('send', 'login', `${role} login`);
		} catch (e) { } // Do nothing

		let promise = this.$q.resolve({});
		if (options.inviteId) {
			promise = this.acceptInvite(user.user_id, options.inviteId).then((result) => result.data);
		}

		return promise.then(() => {
			if (options.redirect) {
				this.$window.location.href = options.redirect;
			} else {
				const state = {
					name: States.DASHBOARD_ROOT,
					params: {},
					options: {reload: true}
				};

				if (options.groupId) {
					state.name = States.DASHBOARD_FOLDER_VIEW;
					state.params = {folder_id: options.groupId};
				}
				return state;
			}
		});
	}

	/**
	 * Accepts invite.
	 */
	public acceptInvite (userId: number, inviteId: string): ng.IHttpPromise<AuthResponseSuccessDataInterface> {
		return this.$http.post(`${clientSettings.GoReactV2API}/invites/${inviteId}/accept`, {
			user_id: userId,
			invite_uuid: inviteId
		});
	}

	/**
	 * Checks if user has GoreactProvider.
	 */
	public hasGoreactProvider (userId): ng.IHttpPromise<AuthGoreactProviderResponse> {
		return this.$http.get(`${clientSettings.GoReactV2API}/users/${userId}/has_goreact_provider`);
	}

	/**
	 * Gets current user.
	 */
	public getCurrentUser (): ng.IHttpPromise<AuthUserResponse> {
		return this.$http.get(`${clientSettings.GoReactV2API}/users/self`);
	}

	/**
	 * LoginAs other user.
	 */
	public loginAs (userId): ng.IHttpPromise<AuthResponseSuccessDataInterface> {
		return this.$http.post(`${clientSettings.GoReactV2API}/users/${userId}/login_as`, {});
	}

	public refresh (params = {}): ng.IHttpPromise<AuthResponseSuccessDataInterface> {
		return this.$http.post(`${clientSettings.GoReactV2API}/users/refresh`, params);
	}

	public getCookie (name: string): string | null {
		const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
		if (match) {
			return match[2];
		}
		return null;
	}
}
