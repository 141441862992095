import type { Attachment } from 'go-modules/services/attachment/attachment';
import type { Media } from 'go-modules/services/attachment/media';
import type { AiPrompt } from 'ngx/go-modules/src/services/ai-prompt/ai-prompt.service';
import type { AiUseType } from './ai-use-type';
import { LlmDefinition } from './llm-definition';

export interface Instructions {
	media: Media;
	media_id: number;
	text: string;
	type: string;
}

export interface SourceMedia {
	media: Media;
	media_id: number;
}

export interface ActivityTemplate {
	id: number;
	type: string;
	description: string;
	live_session_enabled: string;
	peer_review: string;
	public_feedback: string;
	use_comments: string;
	use_score: string;
	use_endnotes: string;
	tag_set_id: string;
	source_media: string;
	has_self_select_source_media: string;
	is_source_media_cc_disabled: string;
	rubric_template_id: string;
	peer_rubric_id: string;
	recording_instructions: string;
	feedback_instructions: string;
	has_response_media: string;
	has_single_recording_attempt: string;
	can_reviewers_see_grades: string;
	time_limit: string;
	is_slides_enabled: string;
	is_video_seeking_disabled: string;
	is_conversation: string;
	is_feedback_published: string;
	is_video_share_enabled: string;
	ai_prompts_enabled: string;
	slug: string;
	feedback_types: any[];
}

// See the ActivityResource
export interface Activity {
	activity_id: number;
	parent_id: number|null;
	group_id: number|null;
	name: string;
	sort_index: number|null;
	available_at: string|null;
	due_at: string|null;
	total_score: number|null;
	use_comments: boolean|null;
	use_score: boolean|null;
	live_session_enabled: boolean;
	peer_review: boolean;
	public_feedback: boolean;
	has_self_select_source_media: boolean;
	has_response_media: boolean;
	is_individual_graded: boolean;
	is_slides_enabled: boolean;
	is_points_possible_readonly: boolean;
	is_conversation: boolean;
	has_single_recording_attempt: boolean;
	time_limit: number|null;
	peer_rubric_critique_enabled: boolean;
	self_rubric_critique_enabled: boolean;
	is_feedback_published: boolean;
	rubric_template_id: number|null;
	tag_set_id: number|null;
	activity_template_id: number|null;
	peer_rubric_id: number|null;
	created_by: number;
	created_at: string;
	deleted_by: number|null;
	deleted_at: string|null;
	template: ActivityTemplate;
	recording_instructions: Instructions[]|null;
	feedback_instructions: Instructions[]|null;
	source_media: SourceMedia[]|null;
	attachments: Attachment[];
	num_attachments: number;
	unviewed_comments?: number;
	unviewed_sessions?: number;
	num_graded?: number;
	usage?: number;
	my_score?: number;
	have_i_submitted?: boolean;
	viewed_by_me?: boolean;
	owner_name: string | null;
	ai_prompts_enabled: boolean;
	ai_prompts?: AiPrompt[];
	is_parent_sync_enabled?: boolean;
	ai_transcription_language?: string|null;
	isCommentOnlySingleAttempt?: Function;
	aiUseType?: AiUseType;
	llm_definitions?: LlmDefinition[];
}

export interface ActivitySyncChildrenCount {
	activities: number;
	sessions: number;
}

export enum ActivityInstructionType {
	RECORDING = 'recording',
	FEEDBACK = 'feedback'
}
