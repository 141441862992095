import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import type { License } from 'go-modules/services/group/license';
import { Product } from 'go-modules/services/group/product';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { DataTransformer } from 'go-modules/models/common/data-transformer';
import type { LicensesResponse } from 'ngx/go-modules/src/interfaces/licenses';
import { Observable, shareReplay } from 'rxjs';
import type { OrgSettings } from 'ngx/go-modules/src/interfaces/groups/org-settings';
import type { Activity } from 'ngx/go-modules/src/interfaces/activity';
import type { ActivitiesQueryParams } from 'ngx/go-modules/src/interfaces/groups/activities-query-params';
import { GroupType } from 'ngx/go-modules/src/enums/group-type';
import { UserRole } from 'ngx/go-modules/src/interfaces';
import { Account } from 'ngx/go-modules/src/interfaces/account';
import { CourseFolder } from 'ngx/go-modules/src/services/course/interfaces/course-folder.interface';

export interface BillingOptions {
	products: Product[];
	licenses: License[];
}

interface OrgUpdate {
	name: string;
	is_public: boolean;
	org_type: string;
	block_presenter_downloads: boolean;
}

export interface ConsumeSeatResponse {
	billing_entity_id: number;
}

export interface CreateAccountPayload {
	name: string;
	org_id: number;
	use_type_id: number;
}

export interface AppRegistration {
	lms_name: string;
}

export interface Org {
	group_id: number;
	name: string;
	lms_name?: string;
}

export interface Member {
	user_id: number;
	first_name: string;
	last_name: string;
	email: string;
}

@Injectable({
	providedIn: 'root'
})
// TODO - these were naively ported to return promises.  Need to check if in use and refactor to observables
export class NgxGroupService {

	private getBillingOptions$: Observable<BillingOptions>;

	constructor (private http: HttpClient) {}

	public getProducts (groupId: number): Observable<Product[]> {
		return this.http.get<Product[]>(`${clientSettings.GoReactV2API}/courses/${groupId}/products`);
	}

	public async consumeCredit (groupId: number): Promise<void> {
		return this.http.post<void>(`${clientSettings.GoReactV2API}/courses/${groupId}/consume_credit`, null)
			.toPromise();
	}

	public consumeSeat (userId: number, groupId: number): Observable<ConsumeSeatResponse> {
		return this.http.post<ConsumeSeatResponse>(`${clientSettings.GoReactV2API}/users/${userId}/groups/${groupId}/consume-seat`, null);
	}

	public async updateOrg (orgId: number, orgUpdate: OrgUpdate): Promise<void> {
		return this.http.put<void>(`${clientSettings.GoReactV2API}/orgs/${orgId}`, orgUpdate)
			.toPromise();
	}

	public copyActivities (copyFrom: number, copyTo: number): Observable<void> {
		return this.http.post<void>(`${clientSettings.GoReactV2API}/courses/${copyFrom}/activities:copy`,
			{ targetGroup: copyTo }
		);
	}

	public getCurrentLicenses (orgId: number): Observable<LicensesResponse> {
		return this.http.get<LicensesResponse>(`${clientSettings.GoReactV2API}/orgs/${orgId}/current-licenses`);
	}

	public getExpiredLicenses (orgId: number): Observable<LicensesResponse> {
		return this.http.get<LicensesResponse>(`${clientSettings.GoReactV2API}/orgs/${orgId}/expired-licenses`);
	}

	public async getEligibleBilling (groupId: number): Promise<BillingOptions> {
		return this.http.get<BillingOptions>(
			`${clientSettings.GoReactV2API}/groups/${groupId}/eligible-billing`
		).pipe(
			map( (data: BillingOptions) => {
				data.licenses.forEach( (license) =>
					['starts_at', 'current_access_date', 'ends_at'].forEach( (prop) => license[prop] = DataTransformer.transformToDate(license, prop) )
				);
				return data;
			})
		).toPromise();
	}

	public getEligibleBillingObservable (groupId: number): Observable<BillingOptions> {
		if (typeof this.getBillingOptions$ === 'undefined') {
			this.getBillingOptions$ = this.http.get<BillingOptions>(
				`${clientSettings.GoReactV2API}/groups/${groupId}/eligible-billing`
			).pipe(shareReplay(1));
		}

		return this.getBillingOptions$;
	}

	public getOrgSettings (orgId: number): Observable<OrgSettings> {
		return this.http.get<OrgSettings>(`${clientSettings.GoReactV2API}/groups/${orgId}/org_settings`);
	}

	public getActivities (queryParams: ActivitiesQueryParams): Observable<Activity[]> {
		const searchParams = new URLSearchParams();

		Object.keys(queryParams).forEach((key) => {
			if (queryParams[key] !== null) {
				searchParams.append(key, queryParams[key]);
			}
		});

		return this.http.get<Activity[]>(`${clientSettings.GoReactV2API}/groups/${queryParams.folder_id}/activities?${searchParams.toString()}`);
	}

	public getOrgMembers (orgId: number): Observable<Member[]> {
		return this.http.get<Member[]>(`${clientSettings.GoReactV2API}/orgs/${orgId}/members`);
	}

	public deleteGroup (groupId: number) {
		return this.http.delete<void>(`${clientSettings.GoReactV2API}/groups/${groupId}`);
	}

	public createAccount (payload: CreateAccountPayload): Observable<any> {
		const params = {
			...payload,
			type: GroupType.ACCOUNT
		};

		return this.http.post<any>(`${clientSettings.GoReactV2API}/orgs/${payload.org_id}/accounts`, params);
	}

	public getAllOrgs (includeLms: boolean = true): Observable<Org[]> {
		return this.http.get<Org[]>(`${clientSettings.GoReactV2API}/orgs?include_private=true&include_lms=${includeLms}`);
	}

	public moveGroup (groupId: number, payload: any): Observable<any> {
		return this.http.put(
			`${clientSettings.GoReactV2API}/groups/${groupId}/move`,
			payload
		);
	}

	public updateAccount (accountId: number, account): Observable<any> {
		return this.http.put<any>(`${clientSettings.GoReactV2API}/accounts/${accountId}`, account);
	}

	public getUsers (groupId: number): Observable<UserRole[]> {
		return this.http.get<UserRole[]>(`${clientSettings.GoReactV2API}/user_group/${groupId}/users`);
	}

	public getAccount (groupId: number): Observable<Account> {
		return this.http.get<Account>(`${clientSettings.GoReactV2API}/groups/${groupId}`);
	}

	public getByUser (userId: number, explicitAccessOnly: boolean, onlyLeaf: boolean): Observable<CourseFolder[]> {
		return this.http.get<CourseFolder[]>(`${clientSettings.GoReactV2API}/users/${userId}/groups?explicitAccessOnly=${explicitAccessOnly}&onlyLeaf=${onlyLeaf}`);
	}
}
