import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { UseType } from 'go-modules/models/use-type/use-type.interface';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { AiPrompt, Language, NgxAiPromptService } from 'ngx/go-modules/src/services/ai-prompt/ai-prompt.service';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { SignUpService } from 'ngx/go-modules/src/services/sign-up/sign-up.service';
import {
	BehaviorSubject,
	Observable,
	combineLatest,
	forkJoin,
	of,
	defer,
	catchError,
	EMPTY,
	Subject
} from 'rxjs';
import { filter, map, shareReplay, take, takeUntil, tap } from 'rxjs/operators';
import { TeaseWallConfig } from 'ngx/go-modules/src/directives/tease-wall/tease-wall.config';
import { SelfPayService } from 'ngx/go-modules/src/services/self-pay/self-pay.service';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import {
	TEASE_WALL_BETA_REQUEST_REMEMBER_KEY,
	TEASE_WALL_UPGRADE_REMEMBER_KEY
} from 'ngx/go-modules/src/directives/tease-wall/constants';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
import { NgxLicenseUpgradeService } from 'ngx/go-modules/src/services/license/license-upgrade/license-upgrade.service';
import {
	ActivityEditorAiMarkersTour,
	activityEditorAiMarkersTourToken
} from 'go-modules/activity-editor/activity-editor-ai-markers-tour/activity-editor-ai-markers-tour.service';
import { TranslateService } from '@ngx-translate/core';
import { Activity } from 'ngx/go-modules/src/interfaces/activity';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NgxActivityService } from 'ngx/go-modules/src/services/activity/activity.service';
import { CourseTrialService } from 'ngx/go-modules/src/services/course-trial/course-trial.service';
import type { CourseFreeTrialResult } from 'ngx/go-modules/src/services/course-trial/course-trial.service';

export const AI_MARKERS_SELECTED_USE_TYPE_ID = 'ai-markers-selected-use-type';

export interface AiMarkersDialogData {
	activity: Activity;
	aiPrompts: AiPrompt[];
	aiTranscriptionLanguage: string | null;
}

@Component({
	selector: 'ai-markers-dialog',
	template: require('./ai-markers-dialog.component.html'),
	styles: [require('./ai-markers-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AIMarkersDialogComponent implements OnInit, OnDestroy {
	public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	public filterBy$ = new BehaviorSubject({use_type_id: 0});
	public allPrompts$: Observable<AiPrompt[]>;
	public useTypes: UseType[] = null;
	public aiLanguageFlag = false;
	public isAIPromptsVisible = true;
	public languages: Language[] = null;
	public selectedLanguage: Language;
	public selectedCount: number = 0;
	public selectedUseType: UseType;
	public shouldTease = false;
	public readonly UseTypeAll = {use_type_id: 0, name: 'All', active: 1, category: '', slug: ''};
	public readonly AiMarkersLimit = clientSettings.AiMarkersLimit;
	private destroyed$ = new Subject<void>();
	private defaultUseTypeId = 0;
	private shouldAutoSelectMarkers = true;

	public aiMarkerTeaseConfig: TeaseWallConfig = {
		useRealData: true,
		translationKey: 'tease-wall_request-button',
		rememberKey: TEASE_WALL_BETA_REQUEST_REMEMBER_KEY,
		select: [
			// Some elements need their descendents' tabindex disabled to avoid tabbing to internal inputs
			'.ai-comments-toggle .mat-slide-toggle-input',
			'.ai-comments-toggle',
			'.use-types-container',
			'.markers',
			'.mat-checkbox-input',
			'.marker-icon',
			'.ai-marker-form-link > a',
			'.save-btn'
		]
	};

	public transcriptTeaseConfig: TeaseWallConfig = {
		useRealData: true,
		translationKey: '',
		rememberKey: null,
		select: [
			'.transcript-language'
		]
	};

	public saveButtonTeaseConfig: TeaseWallConfig = {
		useRealData: true,
		translationKey: '',
		rememberKey: null,
		select: [
			'.save-btn'
		]
	};

	constructor (
		public dialogRef: GoDialogRef,
		@Inject(GO_MODAL_DATA) public data: AiMarkersDialogData,
		private signUpService: SignUpService,
		private aiPromptService: NgxAiPromptService,
		private selfPayService: SelfPayService,
		private ngxGoToastService: NgxGoToastService,
		private featureFlagService: NgxFeatureFlagService,
		private ngxLicenseUpgradeService: NgxLicenseUpgradeService,
		private translate: TranslateService,
		private ngxActivityService: NgxActivityService,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(activityEditorAiMarkersTourToken) private activityEditorAiMarkersTour: ActivityEditorAiMarkersTour,
		@Inject('Window') private window: Window,
		private courseTrialService: CourseTrialService
	) {
	}

	// Todo: Need to refactor this file to a more declarative way
	// Ticket: STAB-1785
	public ngOnInit (): void {
		this.aiLanguageFlag = this.featureFlagService.isAvailable('AI_LANGUAGE');
		this.ngxActivityService.getDefault()
			.subscribe((defaultActivity) => {
				this.defaultUseTypeId = defaultActivity.aiUseType?.use_type_id;
				if (!this.defaultUseTypeId) {
					this.defaultUseTypeId = this.selectedService.getAccount()?.use_type_id ?? 0;
					this.shouldAutoSelectMarkers = false;
				}
				this.shouldTease = !this.selectedService.getLicense()?.salesforce_license.ai_prompts_enabled;
				if (this.shouldTease) {
					this.initMockData();
				} else {
					this.initRealData();
				}

				// End the "activity editor ai marker tour" when user reaches here to prevent showing it again
				// Currently it is set to show 10 times
				if (this.activityEditorAiMarkersTour.tour) {
					const tour = this.activityEditorAiMarkersTour.tour;
					this.activityEditorAiMarkersTour.goTour.markTourViewed(
						tour.getConfig().viewTrackKey,
						tour.getConfig().constraints.maxTourViews
					);
				}
			});
	}

	public ngOnDestroy (): void {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	public filterUseType (useType): void {
		this.selectedUseType = useType;
		this.filterBy$.next(useType);
	}

	public selectPrompt (checkedValue: boolean, item: AiPrompt): void {
		item.checked = checkedValue;
		this.selectedCount += checkedValue ? 1 : -1;

		// Remove from activity
		if (!checkedValue) {
			this.removeFromActivity(item);
		}
	}

	public cancel () {
		this.dialogRef.close(null);
	}

	public save () {
		const selectedUseTypeId = String(this.filterBy$.getValue().use_type_id);
		// Need to reset the filter to 'All' to prepare the prompts to be filtered below,
		// otherwise, hidden(unfiltered) prompts won't get included in the filter
		this.filterUseType(this.useTypes[0]);

		this.allPrompts$.pipe(
			take(1)
		).subscribe((prompts) => {
			const selectedPrompts = prompts.filter((prompt) => prompt.checked === true);

			if (selectedPrompts.length <= this.AiMarkersLimit) {
				this.window.localStorage.setItem(
					`${this.userService.currentUser.user_id}-${AI_MARKERS_SELECTED_USE_TYPE_ID}`,
					selectedUseTypeId
				);
				this.dialogRef.close({
					ai_transcription_language: this.selectedLanguage.language_code,
					prompts: selectedPrompts
				});
			}
		});
	}

	public toggleAiMarkers (event: MatSlideToggleChange) {
		if (event.checked) {
			this.isAIPromptsVisible = true;
			this.selectedCount = 0;

			this.allPrompts$.pipe(
				take(1),
				map((prompts) => {
					prompts.map((prompt) => {
						if (this.shouldAutoSelectMarkers) {
							prompt.checked = prompt.use_types.some((useType) =>
								useType.use_type_id === this.defaultUseTypeId && useType.is_default);
						}
						return prompt;
					}).forEach((prompt) => {
						if (prompt.checked) {
							this.selectedCount++;
						}
					});
				})
			).subscribe();

		} else {
			this.data.aiPrompts = [];
			this.filterUseType(this.useTypes[0]);
			this.allPrompts$.pipe(
				take(1)
			).subscribe((prompts) => {
				prompts.map((prompt) => prompt.checked = false);
				this.isAIPromptsVisible = false;
			});
		}
	}

	private initMockData (): void {
		this.transcriptTeaseConfig.useRealData = false;
		this.saveButtonTeaseConfig.useRealData = false;
		this.aiMarkerTeaseConfig.useRealData = false;
		this.aiMarkerTeaseConfig.secondaryLink = NgxLicenseUpgradeService.getLearnMoreLink();
		this.languages = [
			{language_code: null, language_name: this.translate.instant('common_auto')}
		];
		this.selectedLanguage = this.languages[0];

		if (this.featureFlagService.isAvailable('LICENSE_UPGRADE_PURCHASE')) {
			const license = this.selectedService.getLicense();
			if (this.courseTrialService.licenseTrialBlocked(this.selectedService.getOrg().group_id)) {
				this.aiMarkerTeaseConfig.promptAction =
					this.ngxLicenseUpgradeService.createTeaseWallUpgradePromptAction(license);
				this.aiMarkerTeaseConfig.licenseId = license?.id;
				this.aiMarkerTeaseConfig.translationKey = 'common_upgrade';
				this.aiMarkerTeaseConfig.rememberKey = TEASE_WALL_UPGRADE_REMEMBER_KEY;
				this.ngxLicenseUpgradeService.payformOpened$
					.pipe(
						takeUntil(this.destroyed$),
						filter((opened) => opened)
					)
					.subscribe(() => {
						// we need to close this modal otherwise it shows over the payment panel
						this.cancel();
					});
			} else {
				this.aiMarkerTeaseConfig.titleTranslationKey = 'tease-wall_see-tools-in-action';
				this.aiMarkerTeaseConfig.translationKey = 'tease-wall_start-trial-button';
				this.aiMarkerTeaseConfig.secondaryLink = null;
				this.aiMarkerTeaseConfig.promptAction = this.courseTrialService
					.createTeaseWallCourseTrialPromptAction({
						org_id: this.selectedService.getOrg().group_id
					})
					.pipe(
						filter((result) => !!result),
						tap((result: CourseFreeTrialResult) => {
							const startedTrialOnCurrentlySelectedFolder =
								result.selectedCourse.folderId === this.selectedService.getGroup()?.group_id;

							if (startedTrialOnCurrentlySelectedFolder) {
								this.selectedService.setLicense(result.license);
								this.shouldTease = false;
								this.initRealData();
							} else {
								// switch to another tease wall
								this.aiMarkerTeaseConfig.titleTranslationKey = null;
								this.shouldTease = true;
								this.initMockData();
							}
						})
					);
			}
		} else {
			// Remove this when cleaning up LICENSE_UPGRADE_PURCHASE
			this.aiMarkerTeaseConfig.promptAction = this.requestBetaAction();
		}

		defer(() =>
			import(/* webpackChunkName: "MockAiMarkerData" */ './ai-markers-dialog-mock.json')
				.then((data: any) => data as { prompts: AiPrompt[], useTypes: UseType[] })
		).pipe(
			takeUntil(this.destroyed$)
		).subscribe((data) => {
			this.useTypes = data.useTypes;
			this.selectedUseType = this.useTypes[0];
			this.allPrompts$ = of(data.prompts);
			this.loading$.next(false);
		});
	}

	private initRealData () {
		this.loading$.next(true);
		this.selectedCount = 0;
		this.transcriptTeaseConfig.useRealData = true;
		this.saveButtonTeaseConfig.useRealData = true;
		this.aiMarkerTeaseConfig.useRealData = true;

		forkJoin({
			useTypes: this.signUpService.getUseTypes(false, true).pipe(
				map((useTypes) => [this.UseTypeAll, ...useTypes])
			),
			prompts: this.aiPromptService.all().pipe(
				shareReplay(1),
				map((prompts) => {
					prompts.map((prompt: AiPrompt) => {
						if (!prompt.checked && this.data.aiPrompts) {
							prompt.checked = !!this.data.aiPrompts.find((item) => item.id === prompt.id);
						}

						// When aiPrompts is empty (this is the first launch):
						// 1. check the default ones
						// 2. set the selected use type to account use type
						if (!this.data.aiPrompts || this.data.aiPrompts.length === 0) {
							if (this.shouldAutoSelectMarkers) {
								prompt.checked = prompt.use_types.some((useType) =>
									useType.use_type_id === this.defaultUseTypeId && useType.is_default);
							}
						}

						return prompt;
					}).forEach((prompt) => {
						if (prompt.checked) {
							this.selectedCount++;
						}
					});
					return prompts;
				})
			),
			languages: this.aiPromptService.languages()
		}).subscribe(({useTypes, prompts, languages}) => {
			this.useTypes = useTypes;
			this.languages = [
				{language_code: null, language_name: this.translate.instant('common_auto')},
				...languages
			];
			this.selectedLanguage = this.data.aiTranscriptionLanguage
				? languages.find((lang) => lang.language_code === this.data.aiTranscriptionLanguage)
				: this.languages[0];

			const previouslySelectedUseTypeId = this.window.localStorage.getItem(`${this.userService.currentUser.user_id}-${AI_MARKERS_SELECTED_USE_TYPE_ID}`);
			let previousUseType;
			if (previouslySelectedUseTypeId) {
				previousUseType = this.useTypes.find((useType) => {
					return Number(useType.use_type_id) === Number(previouslySelectedUseTypeId);
				});
			}

			this.selectedUseType = previousUseType ||
				this.useTypes.find((useType) => Number(useType.use_type_id) === Number(this.defaultUseTypeId)) ||
				this.useTypes[0];

			this.filterUseType(this.selectedUseType);

			this.allPrompts$ = combineLatest([
				of(prompts),
				this.filterBy$
			]).pipe(
				map(([prompts, filterBy]) => {
					return filterBy.use_type_id !== 0 ?
						prompts.filter(
							(prompt: AiPrompt) => prompt.use_types
								.some((useType) => useType.use_type_id === filterBy.use_type_id)
						) :
						prompts;
				})
			);

			// Set loading to false once both requests are complete
			this.loading$.next(false);
		});
	}

	private requestBetaAction () {
		const group = this.selectedService.getGroup();

		if (!group) return null;

		return this.selfPayService.requestBeta(group.group_id).pipe(
			map(() => {
				// On success, need to map response to translation key for tease wall
				return 'tease-wall_request-sent-message';
			}),
			catchError(() => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.ERROR,
					message: 'tease-wall_request-failed-message'
				});
				return EMPTY;
			})
		);
	}

	private removeFromActivity (item: AiPrompt): void {
		if (this.data.aiPrompts) {
			const indexToRemove = this.data.aiPrompts.findIndex((prompt) => item.id === prompt.id);

			if (indexToRemove !== -1) {
				this.data.aiPrompts.splice(indexToRemove, 1);
			}
		}
	}
}
