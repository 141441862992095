import type { RealtimeTranscriber } from 'assemblyai';
import { xhr } from 'go-modules/xhr/xhr';

class WatsonInitalization {
	private watsonSpeechPromise: Promise<{
		RecognizeStream?: Watson.Speech.RecognizeStream;
		FormatStream?: Watson.Speech.FormatStream;
		RealtimeTranscriber?: typeof RealtimeTranscriber;
		WebAudioL16Stream: Watson.Speech.WebAudioL16Stream;
	}> = null;
	private watsonTokenPromise: Promise<Watson.Speech.Token> = null;

	public speechPromise (heyGenEnabled: boolean = false) {
		if (this.watsonSpeechPromise != null) {
			return this.watsonSpeechPromise;
		}

		if (heyGenEnabled) {
			return this.watsonSpeechPromise = import(/* webpackChunkName: "watsonSpeechEngine" */ 'watson-speech/speech-to-text').then((watson) => {
				return import(/* webpackChunkName: "assemblyaiSpeechEngine" */ 'assemblyai').then((assemblyai) => {
					return {
						RealtimeTranscriber: assemblyai.RealtimeTranscriber,
						WebAudioL16Stream: watson.WebAudioL16Stream
					};
				});
			})
				.catch((e) => {
					this.watsonSpeechPromise = null;
					throw e;
				});
		}

		return this.watsonSpeechPromise = import(/* webpackChunkName: "watsonSpeechEngine" */ 'watson-speech/speech-to-text')
			.catch((e) => {
				this.watsonSpeechPromise = null;
				throw e;
			});
	}

	public tokenPromise (heyGenEnabled: boolean = false) {
		if (this.watsonTokenPromise != null) {
			return this.watsonTokenPromise;
		}

		let tokenPath = '/watson/token';
		if (heyGenEnabled) {
			tokenPath = '/assemblyai/token';
		}
		return this.watsonTokenPromise = xhr<Watson.Speech.Token>(tokenPath, { method: 'POST'})
			.then((watsonToken: Watson.Speech.Token) => {
				setTimeout(() => {
					this.watsonTokenPromise = null;
				}, watsonToken.expires_in * 0.95 * 1000);
				return watsonToken;
			}).catch((e) => {
				this.watsonTokenPromise = null;
				throw e;
			});
	}
};

export const watsonSingleton = new WatsonInitalization();
