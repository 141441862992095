import { RecordingStoppedState } from 'go-modules/video-scene/recording-stopped-state';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { filter } from 'rxjs/operators';

/* @ngInject */
export function FeedbackSessionMediaDisplayController (
	featureCache,
	feedbackSession,
	goModal,
	eventService: EventService
) {
	const vm = this;
	let wasAutoPlayInitiallyDisabled = false;
	let recordingStoppedState: RecordingStoppedState = RecordingStoppedState.NOT_STOPPED;

	/**
	 * Handle controller init
	 */
	vm.$onInit = () => {
		wasAutoPlayInitiallyDisabled = !vm.shouldAutoPlay();

		vm.eventNames = [
			EVENT_NAMES.GO_MODAL_OPENED,
			EVENT_NAMES.GO_MODAL_ALL_CLOSED
		];
		vm.eventSubscription = eventService.events
			.pipe(filter((ev: GoEvent) => vm.eventNames.includes(ev.name)))
			.subscribe((ev: GoEvent) => {
				switch (ev.name) {
					case EVENT_NAMES.GO_MODAL_OPENED:
						vm.mainController.playerSync.halt();
						break;
					case EVENT_NAMES.GO_MODAL_ALL_CLOSED:
						let forceResume = false;
						// In order to determine whether or not we need to force resume,
						// we first need to know if auto play was initially disabled. This
						// gives us insight into whether we should even consider forcing
						// a resume. If auto play was initially disabled, then all that's
						// left is to see if auto play is currently enabled. If it is,
						// we will force player sync to resume (for one time only).
						if (wasAutoPlayInitiallyDisabled) {
							forceResume = vm.shouldAutoPlay();
							wasAutoPlayInitiallyDisabled = false;
						}
						vm.mainController.playerSync.resume(forceResume);
						break;
				}
			});
	};

	vm.$onDestroy = () => {
		vm.eventSubscription?.unsubscribe();
	};

	/**
	 * Whether auto play should be enabled.
	 *
	 * In the scenario where a modal is active, we should not
	 * auto play but begin playing after all modals are closed.
	 * Also, auto play should only be enabled in live and playback mode.
	 *
	 * @returns {boolean}
	 */
	vm.shouldAutoPlay = () => {
		let feedbackInstructionsViewed = true;
		if (vm.mainController.activity.hasFeedbackInstructions()) {
			feedbackInstructionsViewed = featureCache.hasSeen(
				vm.mainController.activity.getFeedbackInstructionCacheIdentifier(vm.mainController.user.getId()),
				featureCache.FEATURE.FeedbackInstructions
			);
		}
		return !goModal.getTop() &&
			(feedbackSession.isPlaybackMode() || feedbackSession.isLiveMode()) && feedbackInstructionsViewed;
	};

	vm.setRecordingStoppedState = (state: RecordingStoppedState) => {
		recordingStoppedState = state;
	};

	vm.wasRecordingStoppedByMe = (): boolean => {
		return recordingStoppedState === RecordingStoppedState.STOPPED_BY_ME;
	};

	vm.wasRecordingStoppedBySomeoneElse = (): boolean => {
		return recordingStoppedState === RecordingStoppedState.STOPPED_BY_SOMEONE_ELSE;
	};
}
