import { Component, Inject, NgZone } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import * as dayjs from 'dayjs';
import { SurveySnackBarComponent } from 'ngx/go-modules/src/components/snack-bars/survey/survey-snack-bar.component';
import { CustomerSurveySnackBarComponent } from 'ngx/go-modules/src/components/snack-bars/customer-survey/customer-survey-snack-bar.component';
import { UserService as NgxUserService } from 'ngx/go-modules/src/services/user/user.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';

@Component({
	selector: 'ai-trial-survey-snack-bar',
	template: require('./ai-trial-survey-snack-bar.component.html'),
	styles: [require('./ai-trial-survey-snack-bar.component.scss')]
})
export class AiTrialSurveySnackBarComponent extends SurveySnackBarComponent {
	public form = new FormGroup({
		rating: new FormControl(null, [Validators.required]),
		feedback: new FormControl(''),
		invite: new FormControl('AI Trial')
	});

	constructor (
		protected ngxUserService: NgxUserService,
		protected snackbarRef: MatSnackBarRef<SurveySnackBarComponent>,
		protected ngxGoToastService: NgxGoToastService,
		protected snackbar: MatSnackBar,
		protected ngZone: NgZone,
		@Inject(userServiceToken) protected userService: UserService
	) {
		super(
			ngxUserService,
			snackbarRef,
			ngxGoToastService,
			snackbar,
			ngZone,
			userService
		);
	}

	public static override get localStorageKey (): string {
		 return 'ai-trial-survey';
	}

	public static override open (snackBar: MatSnackBar) {
		return snackBar.openFromComponent(
			AiTrialSurveySnackBarComponent,
			{
				panelClass: 'snack-bar-panel',
				horizontalPosition: 'end'
			}
		);
	}

	public static override canShow (userId) {
		const newKey = localStorage.getItem(`${userId}-${AiTrialSurveySnackBarComponent.localStorageKey}`);
		return !newKey;
	}

	public override ngAfterContentInit (): void {
		this.hasProjectedContent = true;
		const { user_id } = this.userService.currentUser;
		localStorage.setItem(`${user_id}-${AiTrialSurveySnackBarComponent.localStorageKey}`, '1');

		// prevent the customer survey from showing for another 3 months
		localStorage.setItem(`${user_id}-${CustomerSurveySnackBarComponent.localStorageKey}`, dayjs().add(90, 'day').toString());
	}

	public onClose () {
		// prevent an occasional issue where it doesnt close immediately
		this.ngZone.run(() => {
			this.snackbarRef.dismiss();
		});
	}

	public onSubmit () {
		if (this.form.valid) {
			this.ngZone.run(() => {
				this.snackbarRef.dismiss();
			});
			this.ngxUserService.sendFeedback(
				this.form.controls.rating.value,
				this.form.controls.feedback.value,
				this.form.controls.invite.value
			)
				.subscribe({
					next: () => {
						this.ngxGoToastService.createToast({
							type: GoToastStatusType.SUCCESS,
							message: 'customer-feedback-thanks'
						});
					},
					error: () => {
						// re open
						AiTrialSurveySnackBarComponent.open(this.snackbar);
						this.ngxGoToastService.createToast({
							type: GoToastStatusType.ERROR,
							message: 'customer-feedback-error'
						});
					}
				});
		}
	}
}
