import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import * as angularSanitize from 'angular-sanitize';

import { UpdateManagerService } from './update-manager.service';
import { goBannerModule } from 'go-modules/go-banner';

export const updateManagerModule = angular.module('go.update-manager', [
	goBannerModule,
	angularTranslate,
	angularSanitize
])
	.service('GoVersionManager', UpdateManagerService)
	.name;
