import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export function timeagoFilter () {
	return function (date) {
		const input = date;
		if (!input) {
			return;
		}
		if (!dayjs(date).isValid()) {
			return 'Invalid Date';
		}
		return dayjs(date).fromNow();
	};
}
