import { GoMediaPlayer } from 'go-modules/media-player';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';

/* @ngInject */
export function MultiMediaDisplayController (
	$q: ng.IQService,
	feedbackSession,
	mediaPlayer,
	eventService: EventService
) {
	const vm = this,
		trackMap = {};

	// Supported display modes
	const DISPLAY_MODE = {
		STIMULUS_RESPONSE: 'stimulus-response',
		SLIDE_PRESENTATION: 'slide-presentation'
	};

	// The current display mode
	vm.displayMode = DISPLAY_MODE.STIMULUS_RESPONSE;

	/**
	 * Controller init handler
	 */
	vm.$onInit = () => {
		const session = vm.mainController.session;

		// Create track mappings
		trackMap[feedbackSession.sessionRecorderId(session)] = 1;
		trackMap[feedbackSession.sessionPlayerId(session)] = 1;
		trackMap[feedbackSession.sourcePlayerId(session)] = 2;

		// Default player controls options
		vm.playerControlsOptions = {
			autoPlay: vm.mediaDisplayController.shouldAutoPlay(),
			toggleCommentCaptionsCallback: feedbackSession.toggleCommentCaptions,
			shouldDisplayAudioDescriptionButton: vm.sourceMedia.hasSupplementaryAudio(),
			isVideoSeekingDisabled: false,
			toggleAudioDescriptionCallback: () => {
				eventService.broadcast(EVENT_NAMES.MEDIA_PREVIEW_TOGGLE_AUDIO_DESCRIPTION);
			}
		};

		if (session.source_media.media_type === 'document') {
			$q.when(mediaPlayer.get(feedbackSession.sessionPlayerId(session))).then((result) => {
				vm.playerControlsOptions.playerSyncSessionPlayer = result;
			});
		} else {
			$q.all({
				sessionPlayer: mediaPlayer.get(feedbackSession.sessionPlayerId(session)),
				sourcePlayer: mediaPlayer.get(feedbackSession.sourcePlayerId(session))
			}).then((result: { sessionPlayer: GoMediaPlayer; sourcePlayer: GoMediaPlayer }) => {
				vm.playerControlsOptions.playerSyncSessionPlayer = result.sessionPlayer;
				vm.playerControlsOptions.playerSyncSourcePlayer = result.sourcePlayer;
			});
		}

		// Ensure that the media display mode is correct
		vm.validateDisplayMode();
	};

	/**
	 * Validate the current display mode
	 */
	vm.validateDisplayMode = () => {
		// For slide documents, we need to use the slide renderer
		if (vm.mainController.activity.isSlidesEnabled()) {
			vm.displayMode = DISPLAY_MODE.SLIDE_PRESENTATION;
		} else {
			vm.displayMode = DISPLAY_MODE.STIMULUS_RESPONSE;
		}
	};

	/**
	 * Whether comment captions is enabled
	 *
	 * @returns {boolean}
	 */
	vm.isCommentCaptionsEnabled = () => {
		return feedbackSession.options.commentCaptionsEnabled &&
			feedbackSession.isPlaybackMode() && !vm.contentController.isInAsyncSlideEditMode();
	};

	/**
	 * Get track number by player id
	 *
	 * @param playerId
	 * @returns Number
	 */
	vm.getTrackNumber = (playerId) => {
		return trackMap[playerId];
	};

	/**
	 * Whether the player controls are enabled
	 *
	 * @returns {boolean}
	 */
	vm.isPlayerControlsEnabled = () => {
		return feedbackSession.isPlaybackMode() && vm.mainController.playerSync.$resolved;
	};

	vm.discardSession = () => {
		this.mainController.discardSessionConfirm().then(() => {
			this.mainController.exit();
		});
	};

	vm.postSession = () => {
		this.mainController.postSession().then(() => {
			this.mainController.exit();
		});
	};

	vm.shouldDisplayPostDiscardControls = () => {
		const session = vm.mainController.session;

		return feedbackSession.isPlaybackMode() &&
			!session.isPosted() &&
			session.isOwner(this.mainController.user) &&
			!session.archived_at;
	};
}
