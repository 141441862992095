import { Injectable } from '@angular/core';
import {
	LibraryCollectionItem,
	PseudoLibraryCollectionItem
} from 'ngx/go-modules/src/interfaces/library/library-collection-item';

@Injectable({
	providedIn: 'root'
})
export class LibraryCollectionItemCacheService {
	private cache: Record<string, LibraryCollectionItem | PseudoLibraryCollectionItem> = {};

	public addItem (item: LibraryCollectionItem | PseudoLibraryCollectionItem) {
		this.cache[item.hash] = item;
	}

	public getItem (hash: string): LibraryCollectionItem | PseudoLibraryCollectionItem | undefined {
		return this.cache[hash];
	}

	public clearItems () {
		this.cache = {};
	}
}
