import { GoSource, GoSourceConfig } from '../go-source';
import { MediaStreamSource } from '../media-stream-source';
import { DeviceKit } from 'go-modules/device-kit';
import { MotionDetector } from './motion-detector';
import { MediaStreamKinds } from 'go-modules/device-kit/media-stream-factory/media-stream-factory';
import { BackgroundBlur, SegmentationStates } from './background-blur/background-blur';

export class CameraSource extends MediaStreamSource {
	public static readonly event = 'camera_status';
	private backgroundBlur: BackgroundBlur;

	constructor (public config: GoSourceConfig, private deviceKit: DeviceKit) {
		super(config);
		this.initVideoElement(config);
	}

	public async init (): Promise<void> {
		this.setState(GoSource.STATES.INITIALIZING);
		if(this.stream) this.stopStream();
		await this.deviceKit.ensureDevicePermissions(true, false);
		const device = await (this.resource
			? this.deviceKit.getDeviceById(this.resource as string, MediaStreamKinds.VIDEO)
			: this.deviceKit.getPreferredCamera()
		);

		if(!device) {
			// An error message stating that a device could not be found
			// Would be more appropriate. Setting it to the generic
			// issue with camera for now.
			this.setFailed(GoSource.REASONS.CAMERA_NO_MOTION, this.init);
			return;
		}

		this.deviceKit.setPreferredCamera(device);
		this.resource = device.getId();
		try {
			const cameraConfig = {frameRate: { max: 30 }} as MediaTrackConstraints;

			if(this.config.width || this.config.height) {
				cameraConfig.width = this.config.width;
				cameraConfig.height = this.config.height;
			}

			this.stream = await device
				.getStream(cameraConfig);
			this.element.srcObject = this.stream;
			await this.test();
			this.backgroundBlur = new BackgroundBlur({
				video: this.element,
				width: this.resourceWidth,
				height: this.resourceHeight
			});
		} catch (err) {
			navigator.mediaDevices.getUserMedia({ video: true, audio: true })
				.catch((error) => {
					if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
						this.setFailed(GoSource.REASONS.CAMERA_NO_PERMISSION, this.init, err);
					} else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
						this.setFailed(GoSource.REASONS.CAMERA_IN_USE, this.init, err);
					} else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
						this.setFailed(GoSource.REASONS.CAMERA_NOT_FOUND, this.init, err);
					} else {
						this.setFailed(GoSource.REASONS.CAMERA_NO_MOTION, this.init, err);
					}
				});
		}
	}

	public destroy (): void {
		super.destroy();
		if (this.backgroundBlur instanceof BackgroundBlur) {
			this.backgroundBlur.destroy();
		}
	}

	public setActive ( active: boolean ): void {
		if (!this.stream) return;
		this.stream.getVideoTracks()[0].enabled = active;
		super.setActive(active);
	}

	public async test (): Promise<boolean> {
		const motionDetector = new MotionDetector(this.element);
		const pass = await motionDetector.run();
		if (pass) {
			this.setState(GoSource.STATES.ACTIVE);
		} else {
			this.setFailed(GoSource.REASONS.CAMERA_NO_MOTION, this.init);
		}
		return pass;
	}

	public get eventName (): string {
		return CameraSource.event;
	}

	public get segmentationState (): SegmentationStates {
		return this.backgroundBlur?.getSegmentationState() ?? SegmentationStates.UNINITIALIZED;
	}

	public async toggleBlur (): Promise<void> {
		await this.backgroundBlur.toggleBlur();
	}

	public getRenderableElement () {
		return this.segmentationState === SegmentationStates.ACTIVE
			? this.backgroundBlur.getCanvas() as unknown as CanvasImageSource
			: this.element;
	}
}
