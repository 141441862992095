import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivityInstructionType } from '../../interfaces/activity';

@Component({
	selector: 'rich-instructions',
	template: require('./rich-instructions.component.html'),
	styles: [require('./rich-instructions.component.scss')]
})
export class RichInstructionsComponent implements OnInit {
	@Input() public instruction: any;
	public ariaLabelTitleKey: string;
	public media: any;
	public richText: string;

	constructor (
		private sanitizer: DomSanitizer
	) {}

	public ngOnInit () {
		this.ariaLabelTitleKey = this.instruction.type === ActivityInstructionType.RECORDING ?
			'activity-info_recording-instructions' :
			'activity-info_feedback-instructions';
		this.richText = this.getRichText();
	}

	public getRichText (): string {
		if (this.instruction.text) {
			const hasHtmlTag = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
			if (!hasHtmlTag(this.instruction.text)) {
				// added to keep multi-line format from old instructions texts
				this.instruction.text = this.instruction.text.replace(/\r?\n|\r|\n/g, '<br/>');
			}
			return this.sanitizer.sanitize(SecurityContext.HTML, this.instruction.text);
		}
		return '';
	}
}
