import { noop } from 'angular';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { UADetect as UADetectClass } from 'go-modules/detect/ua-detect.service';
import * as dayjs from 'dayjs';
import { AllPlayersService } from 'ngx/go-modules/src/services/all-players/all-players.service';
import { COMMENT_RESOURCE_TYPES } from 'go-modules/models/comment/comment.constant';
import { MODES } from 'ngx/go-modules/src/components/feedback-session/comment-reactions/comment-reactions.component';
import { colors } from 'ngx/go-modules/src/interfaces/colors';
import { CommentReaction } from 'ngx/go-modules/src/interfaces/comments/comment';

/* @ngInject */
export class FeedbackTreeCommentController {
	public editingTime: boolean = false;
	public environmentVarsService: EnvironmentVarsService;
	public mainController;
	public mediaResources: any[] = [];
	public tagResources: any[] = [];
	public mediaPreviewOptions = { autoPlay: false, darkMode: true, showFullscreenOption: true };
	public commentMode: any;
	public totalDuration: number;
	public originalTime: number|null;
	public editableTime: number|null;
	private commentPubnubWatch: any;
	private commentReply = null;
	private resource: any;
	private eventSubscription: any;
	private eventNames: string[];
	public modes = MODES;
	public isSelfDemo: boolean = false;

	constructor (
		private $scope,
		private $q,
		private $timeout,
		private CommentModel,
		private $analytics,
		private messageModal: any,
		private $translate: ng.translate.ITranslateService,
		private $filter: ng.IFilterService,
		private eventService: EventService,
		private MediaModel,
		private UADetect: UADetectClass,
		private CommentResourceTypes,
		private $sanitize,
		private allPlayersService: AllPlayersService,
		private confirmModal,
		private $cookies: ng.cookies.ICookiesService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();

		this.eventNames = [
			EVENT_NAMES.FEEDBACK_TREE_TIME_REACHED,
			EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_SAVED,
			EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_REMOVED,
			EVENT_NAMES.FEEDBACK_TREE_COMMENT_REPLY_ADDED,
			EVENT_NAMES.MEDIA_SYNC
		];
		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => this.eventNames.includes(ev.name)))
			.subscribe((ev: GoEvent) => {
				switch (ev.name) {
					case EVENT_NAMES.FEEDBACK_TREE_TIME_REACHED:
						this.onTimeReached(ev);
						break;
					case EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_SAVED:
						this.onFeedbackSaved(ev);
						break;
					case EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_REMOVED:
						this.onFeedbackRemoved(ev);
						break;
					case EVENT_NAMES.FEEDBACK_TREE_COMMENT_REPLY_ADDED:
						this.onCommentReplyAdded(ev);
						break;
					case EVENT_NAMES.MEDIA_SYNC:
						this.$scope.feedbackItem.resource?.filter((commentResource) =>
							commentResource.resource_type === COMMENT_RESOURCE_TYPES.MEDIA &&
							parseInt(commentResource.item.media_id, 10) === parseInt(ev.data.media_id, 10)
						)
							.forEach((matchingResource) => {
								Object.assign(matchingResource.item, ev.data);
							});
						break;
				}
			});
		this.$scope.$watch('commentController.$scope.feedbackItem.resource', this.watchResources);
	}

	public $onInit (): void {
		this.isSelfDemo = this.$cookies.get('is_self_demo_experiment') === 'true';
		this.$scope.feedbackItemCopy = null;
		const name = EVENT_NAMES.FEEDBACK_SESSION_COMMENT_SYNC + this.$scope.feedbackItem.getId();
		this.commentPubnubWatch = this.$scope.feedbackItem.getId()
			? this.eventService.events
				.pipe(filter((ev: GoEvent) => ev.name === name))
				.subscribe(() => this.commentSync)
			: null;

		if (!this.$scope.feedbackItem.isSaved() && !this.$scope.feedbackItem.isSaving()) {
			this.$scope.feedbackItemController.beforeEdit();
		}

		if (this.$scope.feedbackItem.isSyncEventType()) {
			// Change seek offset for sync event comments
			// to zero so that when clicked, we seek
			// to the exact time that was specified.
			this.$scope.feedbackItemController.seekOffset = 0;
		}

		this.mainController = this.$scope.settings.mainController;

		this.totalDuration = this.$scope.settings.mainController.playerSync.getDuration();
		this.originalTime = this.$scope.feedbackItem.time;
		this.editableTime = this.originalTime;
	}

	public $onDestroy (): void {
		this.eventSubscription?.unsubscribe();
		this.commentPubnubWatch?.unsubscribe();
	}

	public updateReactions (reactions) {
		// if emoji was added from menu then we need to
		// copy the reactions array so changes propagate
		// to the display mode component
		this.$scope.feedbackItem.reactions = [...reactions];
		this.$scope.$digest();
	}

	public reactionAdded (reaction: CommentReaction) {
		if (reaction.emoji.replace(/[\u{1F3FB}-\u{1F3FF}]/gu, '') === '👎'
			&& this.$scope.feedbackItem.was_ai_generated
			&& this.$scope.feedbackItemController.isInstructorOnCourse()
			&& this.$scope.feedbackItem.numChildren === 0
		) {
			this.$scope.feedbackItemController.remove();
		}
	}

	/**
	 * Set edit mode
	 *
	 * @returns {*}
	 */
	public edit = () => {
		this.commentMode = (this.$scope.feedbackItemController.mode === 'beforeEdit') ? 'reply' : 'edit';
		// When the duration is Infinity, we know that we are in live scenario where there is no duration.
		const duration = this.$scope.settings.mainController.playerSync.getDuration();
		if (duration === Number.POSITIVE_INFINITY) {
			this.totalDuration = this.$scope.settings.mainController.playerSync.getTime();
		} else {
			this.totalDuration = duration;
		}
		// Copy feedback item
		if (this.$scope.feedbackItemController.mode !== 'edit') { // only copy once
			// TODO: We need to refactor to use parent controllers
			this.$scope.feedbackItemCopy = this.$scope.feedbackItem.copy();
		}
		this.prepComment().then(this.$scope.feedbackItemController.edit);
	};

	/**
	 * Add comment reply
	 *
	 * @param feedbackReplyItem
	 */
	public reply = (feedbackReplyItem) => {
		if (feedbackReplyItem) {
			this.edit();
		} else {
			// since no feedback reply item was supplied,
			// we need to create a new comment reply
			if (!this.$scope.feedbackItem.hasChild(this.commentReply)) {
				this.setCommentReply();
			}

			// we need to wait until the next digest cycle to broadcast this event
			this.$timeout(() => {
				this.eventService.broadcast(EVENT_NAMES.FEEDBACK_TREE_COMMENT_REPLY_ADDED, this.commentReply);
			});
		}
	};

	/**
	 * Save the time edit and hide the time editor
	 */
	public keepTimeEdit (): void {
		this.originalTime = this.editableTime;
		this.$scope.feedbackItemCopy.time = this.editableTime;
		this.saveEdit();
		this.editingTime = false;
	}

	/**
	 * Discard the time code edit by reverting back to the
	 * original value and hiding the time editor.
	 */
	public discardTimeEdit (): void {
		this.confirmModal.open({
			size: 'sm',
			modalData: {
				title: 'activity-settings_controller-unsaved-changes',
				message: 'feedback-creator_discard-message',
				yes: 'common_discard-changes',
				no: 'common_cancel'
			}
		}).result.then(() => {
			this.editableTime = this.originalTime;
			this.$scope.feedbackItemCopy.time = this.originalTime;
			this.cancelEdit();
		}).catch(noop);
	}

	/**
	 * Save edit
	 */
	public saveEdit = () => {
		const defer = this.$q.defer();
		const promise = defer.promise;

		defer.resolve();

		promise.then(() => {

			// When the time has been editing, when need to remove / add
			// reached listeners. Essential clean up the old and add the new.
			if (this.$scope.feedbackItem.time !== this.$scope.feedbackItemCopy.time) {
				this.$scope.feedbackItemController.resetTimeReachedListener(
					this.$scope.feedbackItem.time,
					this.$scope.feedbackItemCopy.time
				);
			}

			this.$scope.feedbackItem.setText(this.$scope.feedbackItemCopy.text);
			this.$scope.feedbackItem.setTime(this.$scope.feedbackItemCopy.time);

			// Keep sync event comment resource time synced with edited copy
			if (this.$scope.feedbackItem.isSyncEventType()) {
				this.$scope.feedbackItem.resource[0].item.setTime(this.$scope.feedbackItemCopy.resource[0].item.time);

				// For now, just force the player sync to validate after
				// the master time has been updated on an individual comment.
				// In the future, it would be preferred if the player sync
				// could automatically detect this.
				this.$scope.settings.mainController.playerSync.validate();
			}

			// save feedback item
			const savePromise = this.$scope.feedbackItem.save();

			// success
			savePromise.then(() => {
				if (!this.commentPubnubWatch) {
					const name = EVENT_NAMES.FEEDBACK_SESSION_COMMENT_SYNC + this.$scope.feedbackItem.getId();
					this.commentPubnubWatch = this.eventService.events
						.pipe(filter((ev: GoEvent) => ev.name === name))
						.subscribe(() => this.commentSync);
				}
				// TODO: We need to refactor treeView
				this.eventService.broadcast(EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_SAVED, this.$scope.feedbackItem);
			});

			// TODO: We need to refactor treeView
			this.eventService.broadcast(EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_SAVE, this.$scope.feedbackItem);

			// Restore defaults
			this.restoreDefaults();

			// go back to default mode
			this.$scope.feedbackItemController.defaultMode();
			this.commentMode = '';

			this.$analytics.eventTrack('edit-transition', {
				category: 'session'
			});

			return savePromise;
		});

		return promise;
	};

	public editTime = ($event) => {
		$event.stopPropagation();
		if (this.originalTime !== this.editableTime) {
			return this.discardTimeEdit();
		}
		this.editingTime = !this.editingTime;
	};

	/**
	 * Cancel edit
	 */
	public cancelEdit = () => {
		// Reset resources to previous
		if(this.commentMode === 'edit' && this.$scope.feedbackItemCopy) {
			this.$scope.feedbackItem.resource = this.$scope.feedbackItemCopy.resource;
			this.$scope.feedbackItemCopy = null;
		}
		this.commentMode = '';


		// Restore defaults
		this.restoreDefaults();

		this.$timeout(() => {
			this.$scope.feedbackItemController.cancelEdit();
		});
	};

	/**
	 * Set comment reply
	 */
	public setCommentReply = () => {
		this.commentReply = this.CommentModel.getReplyStub(
			this.$scope.feedbackItem,
			this.$scope.settings.mainController.user
		);
		this.$scope.feedbackItem.addChild(this.commentReply);
	};

	/**
	 * Whether or not the comment reply button should be visible
	 *
	 * @returns {boolean}
	 */
	public showReplyButton = () => {
		if (this.environmentVarsService.get(EnvironmentVarsService.VAR.READONLY)) {
			return false;
		}

		if (this.$scope.feedbackItem.parent_id) {
			return false;
		}

		if (this.$scope.settings.hideCommentReply) {
			return false;
		}

		const { user, license, userGroup } = this.$scope.settings.mainController;

		// User needs to be able to have same permission as creating a session
		return userGroup.validateCanCreateSession(user, license).isValid;
	};

	/**
	 * Restore to defaults
	 */
	public restoreDefaults = () => {
		this.$scope.feedbackItemCopy = null;
	};

	/**
	 * Check if comment editing is disabled.
	 */
	public isEditDisabled = () => {
		const { activity, session, user, license, userGroup } = this.$scope.settings.mainController;
		return session.isLimitCommentOnlySingleAttempt(activity, user) ||
			!userGroup.validateCanCreateSession(user, license).isValid;
	};

	public getCommentViewers (feedbackItem) {
		if (!feedbackItem.viewers) {
			feedbackItem.viewersLoading = true;
			this.CommentModel.getViewers({
				session_id: this.$scope.settings.mainController.session.session_id,
				comment_id: feedbackItem.comment_id
			}).$promise.then((viewers) => {
				viewers.forEach((viewer) => {
					viewer.viewed_at = this.UADetect.isMobile() ? dayjs(viewer.viewed_at).format('M/D/YY h:mm A') : dayjs(viewer.viewed_at).format('MMM D, YYYY h:mm A');
				});
				viewers.sort((a, b) => dayjs(b.viewed_at).isAfter(dayjs(a.viewed_at)) ? 1 : -1);
				feedbackItem.viewersLoading = false;
				feedbackItem.viewers = viewers;
			});
		}
	}

	/**
	 * Intercept menu item click
	 *
	 */
	 public isMenuItemEnabled ($event, commentResource) {
		if (this.isResourceTypeMedia(commentResource) && commentResource.item.media_status !== 'ready') {
			$event.stopPropagation();
		}
	}

	public isResourceTypeMedia (commentResource) {
		return commentResource.resource_type === this.CommentResourceTypes.MEDIA;
	}

	/**
	 * Download uploaded comment
	 *
	 */
	 public download ($event, commentResource) {
		$event.preventDefault();
		this.MediaModel.download(commentResource.item.media_id);
	}

	public returnAsTrusted (): string {
		if (this.$scope.feedbackItem.text) {
			return this.$sanitize(this.$scope.feedbackItem.text);
		}
	}

	public deleteMediaResource (media: any) {
		this.messageModal.open({
			modalData: {
				message: 'feedback-session-feedback-tree-comment_sure-remove-media',
				title: 'feedback-session-feedback-tree-comment_sure-remove-media-title',
				resolveBtnClass: 'primary-btn',
				resolveBtnText: 'common_continue',
				rejectBtnClass: 'tertiary-btn',
				rejectBtnText: 'common_cancel'
			}
		}).result.then(() => {
			this.$scope.feedbackItem.resource = this.$scope.feedbackItem.resource
				.filter((res) => {
					return res.resource_type !== this.CommentResourceTypes.MEDIA
						|| res.item.media_id !== media.media_id;
				});

			if(this.commentMode !== 'edit') {
				if(this.$scope.feedbackItem.resource.length === 0 && !this.$scope.feedbackItem.text) {
					// Delete main comment itself if no resource/s and no text linked to comment
					this.$scope.feedbackItemController.remove();
				} else {
					// Immediately save the deletion after confirming when in non-edit comment mode
					const savePromise = this.$scope.feedbackItem.save();
					savePromise.then(() => {
						this.eventService.broadcast(EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_SAVED, this.$scope.feedbackItem);
					});
				}
			}
		});
	}

	public openAiMarkers ($event: MouseEvent) {
		$event.stopPropagation();
		this.eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_OPEN_AI_MARKERS);
	}

	public shouldShowManageAiMarkersButton () {
		const { activity } = this.$scope.settings.mainController;
		return this.$scope.feedbackItem.was_ai_generated &&
			!(activity.is_parent_sync_enabled && activity.parent_child_sync) &&
			!this.environmentVarsService.get(EnvironmentVarsService.VAR.DISABLE_ACTIVITY_EDITING);
	}

	/**
	 * Request a comment and resolve when it's available.
	 *
	 * @return Promise
	 */
	private prepComment = () => {
		// Pause external player when creating feedback
		if (this.$scope.feedbackItem.hasMediaResource()) {
			this.$scope.settings.mainController.playerSync.halt();
		}

		// Ensure editable resource is available
		let promise;
		if (this.$scope.feedbackItem.isSyncEventType()) {
			promise = this.$scope.feedbackItem.resource[0].item.$get().then(() => {
				this.resource = [{ item: this.$scope.feedbackItem.resource[0].item.media }];
				this.resource[0].item.time = this.$scope.feedbackItem.resource[0].item.time;
			});
		} else {
			this.resource = [{ item: this.$scope.feedbackItem.resource?.[0].item }];
			promise = this.$q.when();
		}

		return promise;
	};

	/**
	 * Comment sync
	 *
	 * @param evt
	 * @param comment
	 */
	private commentSync = (event) => {
		const comment = event.data;
		if (comment) {
			// Update comment, ensure created_at stays the same
			const createdAt = this.$scope.feedbackItem.created_at;
			const time = this.$scope.feedbackItem.time;
			Object.assign(this.$scope.feedbackItem, comment);
			this.$scope.feedbackItem.created_at = createdAt;
			this.$scope.feedbackItem.time = time;
		}
	};

	/**
	 * Media comment auto play handler
	 */
	private onTimeReached = (event) => {
		const feedbackItem = event.data;
		if (feedbackItem === this.$scope.feedbackItem &&
			this.$scope.settings.autoPlayEnabled() &&
			this.mediaResources.length > 0) {
			this.$scope.settings.mainController.playerSync.halt();
			this.allPlayersService.playCommentResources(this.mediaResources).then(() => {
				this.$scope.settings.mainController.playerSync.resume();
			});
		}
	};

	/**
	 * Feedback saved event handler
	 */
	private onFeedbackSaved = (event) => {
		const feedbackItem = event.data;
		if (this.commentReply === feedbackItem) {
			this.setCommentReply();
		}
	};

	/**
	 * Feedback remove event handler
	 */
	private onFeedbackRemoved = (event) => {
		const feedbackItem = event.data;
		if (this.commentReply) {
			if (this.commentReply === feedbackItem || this.commentReply.isOnlyChild()) {
				this.commentReply.remove();
				this.commentReply = null;
			}
		}
	};

	/**
	 * Feedback reply added event handler
	 */
	private onCommentReplyAdded = (event) => {
		const commentReply = event.data;
		if (this.$scope.feedbackItem === commentReply) {
			this.edit();
		}
	};

	/**
	 * Listen for comment resources changes
	 */
	private watchResources = (resource) => {
		if(resource && this.$scope.feedbackItem.resource) {
			this.tagResources = this.$scope.feedbackItem.resource.filter((res) => {
				return res.resource_type === this.CommentResourceTypes.TAG || res.item.tag_id;
			});

			this.mediaResources = this.$scope.feedbackItem.resource.filter((res) => {
				return res.resource_type === this.CommentResourceTypes.MEDIA;
			});
		} else {
			this.tagResources = [];
			this.mediaResources = [];
		}
	};

	private strip = (html) => {
		const doc = new DOMParser().parseFromString(html, 'text/html');
		return doc.body.textContent || '';
	};

	private getAriaLabel = (feedbackItem) => {
		let translation = this.$translate.instant('feedback-item_new-aria-label', {
			time: this.$filter<(time: number, aria: boolean) => string>('timeFormat')(feedbackItem.time, true),
			name: feedbackItem.fullname
		});
		if (this.$scope.shared.active === feedbackItem) {
			translation += `, ${this.$filter('date')(feedbackItem.created_at, 'MMM d, y h:mm a')}`;
		}
		if (this.tagResources) {
			this.tagResources.forEach((res) => {
				const deprecatedColors = [
					{hex: 'ffcc00', name: 'Tangerine Yellow', translation: 'colors_tangerine-yellow'},
					{hex: '55aa22', name: 'Kelly Green', translation: 'colors_kelly-green'},
					{hex: '1799dd', name: 'Pacific Blue', translation: 'colors_pacific-blue'},
					{hex: '448888', name: 'Paradiso Teal', translation: 'colors_paradiso-teal'},
					{hex: 'ff8800', name: 'Dark Orange', translation: 'colors_dark-orange'}
				];
				const foundColor = colors.concat(deprecatedColors).find(
					(color)=> color.hex === res.item.tag_color);
				translation += `, ${this.$translate.instant('feedback-item_aria-label-tag', {
					firstTwoLetters: res.item.tag_abbreviation ||
						res.item.tag_name.substring(0, 2).toUpperCase(),
					color: this.$translate.instant(foundColor ? foundColor.translation : 'colors_other-color'),
					text: res.item.tag_name
				})}`;
			});
		}

		if (feedbackItem.text) {
			translation += `, ${this.strip(feedbackItem.text)}`;
		}

		if (this.mediaResources) {
			this.mediaResources.forEach((res) => {
				if (res.item.media_type === this.MediaModel.TYPE.VIDEO ||
					res.item.media_type === this.MediaModel.TYPE.AUDIO) {
					translation += `, ${this.$translate.instant('feedback-item_aria-label-duration-media', {
						duration: this.$filter<(time: number, aria: boolean) => string>('timeFormat')(res.item.duration, true)
					})}`;
				}
				else if (res.item.media_type === this.MediaModel.TYPE.DOCUMENT) {
					translation += `, ${this.$translate.instant('feedback-item_aria-label-document', {
						type: res.item.getFileExtension().toUpperCase()
					})}`;
				}
			});
		}

		if (feedbackItem.viewed_by_me) {
			translation += `, ${this.$translate.instant('feedback-item_aria-label-viewed')}`;
		} else if (feedbackItem.directed_at_me) {
			translation += `, ${this.$translate.instant('feedback-item_aria-label-directed-at-me')}`;
		} else {
			translation += `, ${this.$translate.instant('feedback-item_aria-label-unviewed')}`;
		}

		return translation;
	};
}
