import * as angular from 'angular';
import template from './umc-youtube-link.directive.html';

import { MediaSource } from '../../models/media';
import { UmcInstancesService } from 'go-modules/services/umc-instances/umc-instances.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { YoutubeProcessor } from 'go-modules/models/media/youtube.processor';

/* @ngInject */
export function umcYoutubeLinkDirective (
	MediaModel,
	UniversalMediaChooser,
	$q,
	umcInstancesService: UmcInstancesService,
	eventService: EventService,
	$http: angular.IHttpService
) {
	return {
		scope: {options: '='},
		template,
		link: (scope, _elem, attr) => {

			// check params
			if (!scope.options || !scope.options.groupId || !attr.umcInstanceName) {
				throw new Error('umc-youtube-link:: expecting group_id and umcInstanceName params');
			}

			let defer;

			// media to save
			scope.media = MediaModel.model({
				media_type: MediaModel.TYPE.VIDEO,
				media_status: MediaModel.READY,
				group_id: scope.options.groupId,
				source: MediaSource.YOUTUBE
			});

			scope.mediaPreviewOptions = {autoPlay: false};

			// add to collection param (conditional)
			if (scope.options.addToCollection) {
				scope.media.add_to_collection = scope.options.addToCollection;
			}

			// add to collection param (conditional)
			if (scope.options.addToFolder) {
				scope.media.add_to_folder = scope.options.addToFolder;
			}

			// current playlist item
			scope.mediaPlaylistItem = null;

			scope.umc = UniversalMediaChooser.get(attr.umcInstanceName);

			// reset if a different media chooser option is selected
			const eventNames = [
				EVENT_NAMES.UMC_CHANGE,
				EVENT_NAMES.UMC_CHOSEN
			];
			scope.eventSubscription = eventService.events
				.pipe(filter((ev: GoEvent) => eventNames.includes(ev.name as any)))
				.subscribe((ev: GoEvent) => {
					switch (ev.name) {
						case EVENT_NAMES.UMC_CHANGE:
							scope.mediaPlaylistItem = null;
							scope.media = MediaModel.model({
								media_type: MediaModel.TYPE.VIDEO,
								media_status: MediaModel.READY,
								group_id: scope.options.groupId,
								source: MediaSource.YOUTUBE
							});
							break;
						case EVENT_NAMES.UMC_CHOSEN:
							if (!ev.data) {
								umcInstancesService.cleanupOrphanedMedia();
								scope.media.$save(function (media) {
									defer.resolve(media);
								});
							}
							break;
					}
				});

			scope.cancel = function () {
				scope.umc.selectedMedia = null;
				scope.mediaPlaylistItem = null;
				scope.media = MediaModel.model(angular.extend({
					media_type: MediaModel.TYPE.VIDEO,
					media_status: MediaModel.READY,
					source: MediaSource.YOUTUBE
				}, scope.options));
			};

			/**
			 * Media url change event handler
			 */
			scope.$watch('media.media_url', function (url) {
				// clear it out so changes are reflected
				scope.mediaPlaylistItem = null;
				scope.umc.selectedMedia = null;
				if (MediaModel.isValidYoutubeUrl(url)) {
					const videoId = YoutubeProcessor.getYoutubeId(url);
					$http({
						method: 'GET',
						url: `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`,
						// errors are returned as plain text so we don't want to parse the response
						transformResponse: (data) => data
					}).then(() => {
						defer = $q.defer();
						scope.umc.selectedMedia = defer.promise;
						scope.youtubeLinkForm.media_url.$setValidity('validYoutubeUrl', true);
						scope.youtubeLinkForm.media_url.$setValidity('privateYoutubeUrl', true);
						previewMedia();
					}).catch((error) => {
						if (error.status === 400 || error.status === 404) {
							scope.youtubeLinkForm.media_url.$setValidity('validYoutubeUrl', false);
							scope.youtubeLinkForm.media_url.$setValidity('privateYoutubeUrl', true);
						} else if (error.status === 403) {
							scope.youtubeLinkForm.media_url.$setValidity('validYoutubeUrl', true);
							scope.youtubeLinkForm.media_url.$setValidity('privateYoutubeUrl', false);
						}
					});
				} else {
					scope.youtubeLinkForm.media_url.$setValidity('validYoutubeUrl', false);
				}
			});

			scope.$on('$destroy', () => {
				scope.eventSubscription?.unsubscribe();
			});

			/**
			 * Preview media url
			 */
			function previewMedia () {
				scope.mediaPlaylistItem = scope.media;
			}
		}
	};
}
