import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { LICENSE_TRANSACTION_TYPE, PURCHASE_TYPES } from './payment-panel.controller';
import { PaymentPanel } from './payment-panel.service';
import { NgxSelfPayService } from 'ngx/go-modules/src/services/self-pay';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';
import { Product } from 'go-modules/services/group/product';
import { ProductTier } from 'ngx/go-modules/src/interfaces/licenses/product-tier';

export class LazyPaymentLoader {
	public static readonly NG1_INJECTION_NAME = 'PaymentLoader' as const;

	public lazyModulePromise: ng.IPromise<any>;
	private panelOpen: ng.IPromise<any> = null;
	private timer: ng.IPromise<any>;
	private panelOpenedAt: number;

	/* @ngInject */
	constructor (
		private $ocLazyLoad: oc.ILazyLoad,
		private $injector: ng.auto.IInjectorService,
		private $interval: ng.IIntervalService,
		private $log: ng.ILogService,
		private $q: ng.IQService,
		private ngxSelfPayService: NgxSelfPayService,
		private ngxDowngradeModalService: DowngradeModalService,
		private featureFlag: FeatureFlag
	) {
		// Pre-fetch pay form
		this.preLoad();
	}

	public openPayForm (
		user,
		group,
		action: (transaction?: any) => void,
		license?,
		type?: string,
		purchaseType: PURCHASE_TYPES = PURCHASE_TYPES.COURSE,
		licenseTransactionType: LICENSE_TRANSACTION_TYPE|null = null,
		product = null,
		onCancelled: (e?) => void = ()=> {},
		trialEnded: boolean = false
	): ng.IPromise<any> {
		if (this.panelOpen !== null) {
			return this.panelOpen;
		}

		// If for whatever reason the first pre-load failed,
		// let's try again
		return this.lazyModulePromise
			.catch((err) => {
				this.$log.error(err);
				return this.preLoad();
			}).then(() => {
				if (
					purchaseType !== PURCHASE_TYPES.LICENSE ||
					licenseTransactionType === LICENSE_TRANSACTION_TYPE.SEATS ||
					!this.featureFlag.isAvailable('LICENSE_UPGRADE_PURCHASE')
				) {
					return this.openPayPanel(
						user,
						group,
						type,
						purchaseType,
						license,
						licenseTransactionType,
						action,
						product,
						onCancelled
					);
				}

				this.ngxSelfPayService.getProducts(group.org_id).subscribe((products) => {
					// Skip product chooser modal when renewal and it's already the most expensive product
					const isRenewalAndMostExpensive =
						this.isRenewalAndMostExpensive(products, license, purchaseType, licenseTransactionType);
					if(isRenewalAndMostExpensive) {
						return this.openPayPanel(
							user,
							group,
							type,
							purchaseType,
							license,
							licenseTransactionType,
							action,
							product,
							onCancelled
						);
					}

					// Open product chooser modal
					this.ngxDowngradeModalService.openCompareProductsDialog({
						transactionType: licenseTransactionType,
						license,
						products
					}).afterClosed().subscribe((selectedProduct) => {
						if (selectedProduct) {
							if (trialEnded && selectedProduct.licenseProduct.tier_name === ProductTier.Standard) {
								return onCancelled(ProductTier.Standard);
							}
							return this.openPayPanel(user,
								group,
								type,
								purchaseType,
								license,
								licenseTransactionType,
								action,
								selectedProduct,
								() => {
									onCancelled(ProductTier.Plus);
								}
							);
						} else {
							onCancelled();
						}
					});
				});

			});
	}

	private openPayPanel (
		user,
		group,
		type,
		purchaseType,
		license,
		licenseTransactionType,
		action,
		product = null,
		onCancelled: () => void
	) {
		const payPanel: PaymentPanel = this.$injector.get('PaymentPanel');
		const panel = payPanel.open({
			user,
			group,
			type,
			purchaseType,
			license,
			licenseTransactionType,
			product
		});

		this.panelOpenedAt = Date.now();

		this.panelOpen = panel.result.then(action)
			.catch(onCancelled)
			.finally(() => {
				this.$interval.cancel(this.timer);
				this.panelOpen = null;
			});

		this.timer = this.$interval(() => this.checkExpired(panel), 1000); // Check every second

		return this.panelOpen;
	}

	private isRenewalAndMostExpensive (
		products: Product[],
		license,
		purchaseType: string,
		licenseTransactionType: string
	): boolean {
		if (purchaseType === PURCHASE_TYPES.LICENSE &&
			licenseTransactionType === LICENSE_TRANSACTION_TYPE.INITIAL &&
			license !== null) {

			const mostExpensiveProduct = products.reduce((maxItem, currentItem) => {
				return parseFloat(currentItem.price) > parseFloat(maxItem.price) ? currentItem : maxItem;
			}, products[0]);

			// STAB-1455 Accessing invertedSfLicense.license_product
			return parseFloat(mostExpensiveProduct.price) === parseFloat(license.license_product.product.price);
		}

		return false;
	}

	private checkExpired (panel: ng.ui.bootstrap.IModalInstanceService) {
		const now = Date.now();
		if (now - this.panelOpenedAt > 1000 * 60 * 90 /* 90 minutes */) {
			panel.dismiss();
		}
	}

	private preLoad (): ng.IPromise<void> {
		return this.lazyModulePromise = this.$q.when(import(/* webpackChunkName: "PaymentPanel" */ 'go-modules/payment-panel'))
			.then((module: any) => {
				return this.$ocLazyLoad.load(module.paymentPanelModule);
			}).catch((error) => {
				throw new Error(`Failed to lazy load PaymentPanel module: ${error.message}`);
			});
	}
}

export const lazyPaymentLoaderToken = upgradeNg1Dependency(LazyPaymentLoader);
