import * as angular from 'angular';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import type { CourseGroup } from 'go-modules/models/group-dep/group.interface';
import type { CourseFolder } from 'ngx/go-modules/src/services/course/interfaces/course-folder.interface';

/* @ngInject */
export class GroupCreatorService {
	public static readonly NG1_INJECTION_NAME = 'GroupCreator' as const;

	/* @ngInject */
	constructor (
		private $log: angular.ILogService,
		private createCourseModal,
		private Group,
		private groupsIndex,
		private $rootScope,
		private $timeout: ng.ITimeoutService,
		private $q
	) {}

	public createCourseFolder (
		account,
		user,
		accountOwnerId,
		duplicateFromGroup = null,
		autoSelectLicense = false
	): ng.IPromise<CourseFolder> {
		// This CourseGroup is missing some things that the folderListDataSource needs.
		// We'll manually fill in this data
		return this.createCourse(account, user, accountOwnerId, null, duplicateFromGroup, autoSelectLicense)
			.then((groupModel: CourseGroup) => {
				return this.Group.model({
					...groupModel,
					stats: {
						attention_needed: false,
						num_unviewed_comments: 0
					},
					user_role: {
						// We can make some educated guesses on the user role created when creating a folder.
						user_id: user.user_id,
						group_id: groupModel.group_id,
						role: 'owner',
						billing_entity_id: groupModel.billing_entity_id,
						created_by: user.user_id,
						created_at: new Date(),
						modified_at: new Date(),
						deleted_by: null,
						deleted_at: null
					},
					role: 'owner',
					owner_name: user.full_name
				});
			}).catch(() => {return this.$q.reject();});
	}

	public createCourse (
		account,
		user,
		accountOwnerId?,
		callback?,
		duplicateFromGroup = null,
		autoSelectLicense = false
	) {
		const deferred = this.$q.defer();
		this.Group.getOrgSettings({group_id: account.org_id})
			.then((orgSettings) => {
				const modalScope = this.$rootScope.$new();
				const group = this.Group.getTemplate();
				group.type = this.Group.COURSE;
				group.parent_id = account.group_id;
				angular.extend(modalScope, {
					account,
					currentGroup: account,
					group,
					currentUser: user,
					groups: this.groupsIndex,
					orgSettings,
					duplicateFromGroup,
					autoSelectLicense
				});

				const modal = this.createCourseModal.open({
					scope: modalScope
				});

				return modal.result
					.then((result) => {
						this.saveGroup(result.group, accountOwnerId, callback).then((res) => deferred.resolve(res));
					}).catch((err) => {
						deferred.reject();
						if (err) {
							this.$log.error(err);
							throw err;
						}
					})
					.finally(modalScope.$destroy.bind(modalScope));
			})
			.catch((err) => {
				deferred.reject();
				this.$log.error(err);
				throw err;
			});
		return deferred.promise;
	}

	public saveGroup (group, accountOwnerId?, callback?) {

		const isNew = !group.getId();

		// do save
		const result = group.save(group.copyFrom?.group_id, accountOwnerId);

		// success
		return result.$promise.then((savedGroup) => {

			// for new groups
			if (isNew) {

				// add group
				this.groupsIndex.add(savedGroup);

				// callback, (ie. redirect to the group)
				if (callback) {
					this.$timeout(() => {
						callback(savedGroup);
					});
				}
			}
			return savedGroup;
		}).catch(this.$log.error);
	}
}

export const groupCreatorServiceToken = upgradeNg1Dependency(GroupCreatorService);
