import * as angular from 'angular';

import { event } from '../event-channel';
import { fineUploaderDirective } from './fine-uploader.directive';
import { progressDirective } from './progress.directive';
import { progressBarDirective } from './progress-bar.directive';
import { FineUploaderFileController } from './fine-uploader-file.controller';
import { bytesToSizeFilter } from './bytes-to-size.filter';
import { toBytesFilter } from './to-bytes.filter';
import { cacheTemplates } from './cache-templates.run';
import { cacheResources } from './cache-resources.run';
import './style.less';
import { FineUploaderService } from './fine-uploader.service';
import * as angularTranslate from 'angular-translate';
import { messageModalModule } from 'go-modules/modals/message';
import { featureFlagModule } from 'go-modules/feature-flag';
import { sessionManager } from 'go-modules/session-manager';
import { fullstoryModule } from 'go-modules/services/fullstory';

export const fineUploaderModule = angular.module('go.fineUploader', [
	event,
	angularTranslate,
	messageModalModule,
	featureFlagModule,
	fullstoryModule,
	sessionManager
])
	.service(FineUploaderService.NG1_INJECTION_NAME, FineUploaderService)
	.directive('fineUploader', fineUploaderDirective)
	.directive('fineUploaderProgress', progressDirective)
	.directive('fineUploaderProgressBar', progressBarDirective)
	.controller('FineUploaderFileController', FineUploaderFileController)
	.filter('bytesToSize', bytesToSizeFilter)
	.filter('toBytes', toBytesFilter)
	.run(cacheTemplates)
	.run(cacheResources)
	.name;
