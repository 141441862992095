export enum SORT_OPTIONS {
	LASTMODIFIED = 'lastmodified',
	ALPHANUMERIC = 'alphanumeric',
	ALPHANUMERICREVERSE = 'alphanumericreverse',
	NEWEST='newest',
	OLDEST='oldest'
};

export enum CONTENT_TYPES {
	ALL = 'all',
	MEDIA = 'media',
	RUBRICS = 'rubric',
	MARKERS = 'markers',
	DOCUMENTS = 'document',
	ACTIVITIES = 'activity'
};

export enum MODES {
	SELECT = 'select'
};

export enum LIBRARY_TYPES {
	ARCHIVE = 'archive',
	LEGACY = 'legacy',
	MINE = 'mine',
	ORG = 'org',
	SHARED = 'shared',
	MANAGED = 'managed',
	DIRECT_SHARE = 'direct_share'
};

export const SortOrderTranslations: Record<SORT_OPTIONS, string> = {
	[SORT_OPTIONS.LASTMODIFIED]: 'library-collections-sort_lastmodified',
	[SORT_OPTIONS.ALPHANUMERIC]: 'common_asc',
	[SORT_OPTIONS.ALPHANUMERICREVERSE]: 'common_desc',
	[SORT_OPTIONS.NEWEST]: 'common_newest',
	[SORT_OPTIONS.OLDEST]: 'common_oldest'
};
