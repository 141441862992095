import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

@Component({
	selector: 'ngx-go-uploader',
	template: require('./go-uploader.component.html'),
	styles: [require('./go-uploader.component.scss')]
})
export class GoUploader implements AfterViewInit, OnDestroy {
	public uppy: Uppy;
	public ngAfterViewInit (): void {
		this.uppy = new Uppy().use(Dashboard, { inline: true, target: '#go-uploader-container' });
	}

	public ngOnDestroy (): void {
		this.uppy.close();
	}
}
