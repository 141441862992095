import { Component, Inject, Input, OnInit } from '@angular/core';
import { mediaToken } from 'go-modules/models/media/media.factory';

@Component({
	selector: 'media-card',
	template: require('./media-card.component.html'),
	styles: [require('./media-card.component.scss')]
})
export class MediaCardComponent implements OnInit {
	@Input() public media: any;
	@Input() public ariaLabelTitleKey: string = '';
	@Input() public label: string = '';

	constructor (
		@Inject(mediaToken) private MediaModel
	) {}

	public ngOnInit (): void {
		this.media = this.MediaModel.model(this.media);
	}
}
