import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { clientSettings } from '../common/client.settings';

/* @ngInject */
export function UserGroupModel ($resource) {

	const baseUrl = `${clientSettings.GoReactV2API}/user_groups`;

	const userGroupModel = $resource(baseUrl + '/:group_id', {
		user_id: '@user_id',
		group_id: '@group_id'
	}, {
		get: {
			url: `${clientSettings.GoReactV2API}/user_group/:group_id`,
			method: 'GET'
		},
		getData: {
			url: `${clientSettings.GoReactV2API}/user_group/:group_id/data`,
			method: 'GET'
		},
		willCreditBack: {
			url: `${clientSettings.GoReactV2API}/user_group/credit-back/:group_id/:user_id`,
			method: 'GET'
		},
		getCanRemoveUser: {
			url: `${clientSettings.GoReactV2API}/user_group/:group_id/can-remove-user/:user_id`,
			method: 'GET'
		}
	});

	return userGroupModel;
}

UserGroupModel.NG1_INJECTION_NAME = 'UserGroupModel' as const;
export const userGroupToken = upgradeNg1Dependency(UserGroupModel);
