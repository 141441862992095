import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
	Utterance
} from 'ngx/go-modules/src/components/feedback-session/transcript-viewer/transcript-viewer.component';
import type { Session } from 'ngx/go-modules/src/services/session-list-datasource/session-list.datasource';
import dayjs from 'dayjs';

@Injectable()
export class TranscriptDownloaderService {
	constructor (private translateService: TranslateService) {}

	/**
	 * Generate WebVTT content from utterances
	 */
	private generateVttContent (utterances: Utterance[]): string {
		let vttContent = 'WEBVTT\n\n';

		utterances.forEach((utterance) => {
			// note: dayjs normalizes hours to a 24 hour clock
			const startTime = dayjs.duration(utterance.start, 'milliseconds').format('HH:mm:ss.SSS');
			const endTime = dayjs.duration(utterance.end, 'milliseconds').format('HH:mm:ss.SSS');
			vttContent += `${startTime} --> ${endTime}\n`;

			// Don't append label if not ascii. Ex: '勇'.length = 1, should not have label
			const isNameAscii = /^[\x00-\x7F]*$/.test(utterance.speaker);
			const speakerLabel = utterance.speaker.length === 1 && isNameAscii
				? this.translateService.instant('transcript-downloader_speaker-label', { speaker: utterance.speaker })
				: utterance.speaker;

			vttContent += `<v ${speakerLabel}>${utterance.text}\n\n`;
		});
		return vttContent;
	}

	/**
	 * Download transcript as WebVTT file
	 */
	public downloadVtt (utterances: Utterance[], session: Session): void {
		const vttContent = this.generateVttContent(utterances);

		const blob = new Blob([vttContent], { type: 'text/vtt;charset=utf-8' });
		const url = URL.createObjectURL(blob);

		const element = document.createElement('a');
		element.setAttribute('href', url);
		const filename = `${session.description || 'transcript'}.vtt`;
		element.setAttribute('download', filename);

		element.style.display = 'none';
		document.body.appendChild(element);

		element.click();
		element.remove();
		URL.revokeObjectURL(url); // release from memory
	}
}
