import { Injectable } from '@angular/core';
import type { Comment } from 'ngx/go-modules/src/interfaces/comments/comment';
import { Marker } from 'ngx/go-modules/src/interfaces/markers/marker';

export interface UniqueUser {
	id: number;
	fullname: string;
	was_ai_generated: boolean;
	count: number;
}

@Injectable({
	providedIn: 'root'
})
export class CommentFilterService {
	// TODO: make this more uniform
	public selectedUserIds: number[] | null = []; // a null value will indicate that no users have been selected
	public selectedMarkerIds: number[] = []; // a -1 value will indicate that no users have been selected

	constructor () {}

	public getUniqueUsers (comments: Comment[]): UniqueUser[] {
		const userMap = new Map<number, UniqueUser>();

		const processComment = (comment: Comment) => {
			const userId = Number(comment.created_by);
			if (!userMap.has(userId)) {
				userMap.set(userId, {
					id: userId,
					fullname: comment.fullname,
					was_ai_generated: !!comment.was_ai_generated,
					count: 1
				});
			} else {
				const existingUser = userMap.get(userId);
				existingUser.count++;
			}
			if (comment.children?.length) {
				comment.children.forEach((child) => {
					processComment(child);
				});
			}
		};

		comments.forEach((comment) => {
			processComment(comment);
		});

		return Array.from(userMap.values()).sort((a, b) => {
			if (a.was_ai_generated) {
				return -1;
			} else {
				return a.fullname.localeCompare(b.fullname);
			}
		});
	}

	public getUniqueMarkers (comments: Comment[]): Marker[] {
		const markerMap = new Map<number, Marker>();

		const processComment = (comment: Comment) => {
			if (comment.resource?.length) {
				comment.resource.forEach((res) => {
					if (res.resource_type === 'Tag') {
						const markerId = res.resource_id;
						const existingMarker = markerMap.get(markerId);

						if (existingMarker) {
							existingMarker.count++;
						} else {
							markerMap.set(markerId, {
								...res.item,
								count: 1,
								was_ai_generated: !!comment.was_ai_generated
							});
						}
					}
				});
			}

			if (comment.children?.length) {
				comment.children.forEach((child) => {
					processComment(child);
				});
			}
		};

		comments.forEach((comment) => {
			processComment(comment);
		});

		return Array.from(markerMap.values());
	}

	public shouldCommentShow (feedbackItem: Comment, filterOutSyncEventComments: boolean = true): boolean {
		if (filterOutSyncEventComments && feedbackItem.isSyncEventType()) {
			return false;
		}

		return this.userIsSelected(feedbackItem) && this.markerIsSelected(feedbackItem);
	}

	private userIsSelected (feedbackItem: Comment): boolean {
		// all selected
		if (this.selectedUserIds?.length === 0) {
			return true;
		}

		if (this.selectedUserIds?.includes(Number(feedbackItem.created_by))) {
			return true;
		}

		// Walk up my parent to see if it matches any of the selected users
		while (feedbackItem.hasParent() && !feedbackItem.getParent().isRoot()) {
			const parent = feedbackItem.getParent();
			if (this.selectedUserIds?.includes(Number(parent.created_by))) {
				return true;
			}

			// Do I have any siblings that match comparator?
			const siblings = parent.getChildren().filter((comment) => comment.comment_id !== feedbackItem.comment_id);
			if (siblings.some((sibling) => this.selectedUserIds?.includes(Number(sibling.created_by)))) {
				return true;
			}

			feedbackItem = parent;
		}

		// Do I have any children that match comparator?
		if (feedbackItem.hasChildMatching((child) => this.selectedUserIds?.includes(Number(child.created_by)))) {
			return true;
		}

		return false;
	}

	private markerIsSelected (feedbackItem: Comment): boolean {
		// all selected
		if (this.selectedMarkerIds.length === 0) {
			return true;
		}

		// none selected
		if (this.selectedMarkerIds[0] === -1) {
			if (feedbackItem.resource?.some((res) => res.resource_type === 'Tag')) {
				return false;
			}
			return true;
		}

		if (feedbackItem.resource?.some((res) => res.resource_type === 'Tag' && this.selectedMarkerIds.includes(res.resource_id))) {
			return true;
		}

		// Walk up my parent to see if it matches any of the selected users
		while (feedbackItem.hasParent() && !feedbackItem.getParent().isRoot()) {
			const parent = feedbackItem.getParent();
			if (parent.resource?.some((res) => res.resource_type === 'Tag' && this.selectedMarkerIds.includes(res.resource_id))) {
				return true;
			}

			// Do I have any siblings that match comparator?
			const siblings = parent.getChildren().filter((comment) => comment.comment_id !== feedbackItem.comment_id);
			if (siblings.some((sibling) => sibling.resource?.some((res) => res.resource_type === 'Tag' && this.selectedMarkerIds.includes(res.resource_id)))) {
				return true;
			}

			feedbackItem = parent;
		}

		// Do I have any children that match comparator?
		if (feedbackItem.hasChildMatching((child) => child.resource?.some((res) => res.resource_type === 'Tag' && this.selectedMarkerIds.includes(res.resource_id)))) {
			return true;
		}

		return false;
	}
}
