import {
	AfterViewInit,
	ContentChild,
	Directive,
	ElementRef,
	Renderer2
} from '@angular/core';
import { MAT_SELECT_CONFIG, MatSelect } from '@angular/material/select';
import { filter, interval, take } from 'rxjs';

/**
	* Example:
	* 		<mat-form-field goSelect>
	*         <mat-label></mat-label>
	*         <mat-select>
	*            <mat-option></mat-option>
	*            <mat-option></mat-option>
	*         </mat-select
	*         <mat-error></mat-error>
	*      </mat-form-field>
	*/
@Directive({
	selector: '[goSelect]',
	providers: [
		{
			provide: MAT_SELECT_CONFIG,
			useValue: { overlayPanelClass: 'go-select-overlay-panel' }
		}
	]
})
export class GoSelectDirective implements AfterViewInit {
	@ContentChild(MatSelect) public matSelect: MatSelect;

	constructor (
		private renderer: Renderer2,
		private elementRef: ElementRef
	) {
		this.renderer.addClass(this.elementRef.nativeElement, 'go-select');
	}

	public ngAfterViewInit () {
		interval(1).pipe(
			filter(() => !!this.matSelect),
			take(1))
			.subscribe(() => {
				this.matSelect.panelClass += ' go-select-panel';
				// move mat-label inside the trigger element
				const matLabel = this.elementRef.nativeElement.querySelector('mat-label');
				if (matLabel != null) {
					// would set role to presentation but we still need role combobox
					this.matSelect._elementRef.nativeElement.removeAttribute('aria-labelledby');
					this.matSelect._elementRef.nativeElement.querySelector('.mat-select-trigger .mat-select-min-line')
						.prepend(matLabel);
				}
			});
	}
}
