// eslint-disable-next-line max-classes-per-file
import { Destination, DestinationType } from '../destination';
import { Reasons, States } from '../../state-emitter/state-emitter';
import { OTDestinationOptions } from './options';
import logger from 'go-modules/logger/logger';
import { xhr } from 'go-modules/xhr/xhr';
import { Subscriber } from '@opentok/client';
import { UAParser } from 'ua-parser-js';
import { VideoQuality } from 'ngx/go-modules/src/utilities/video-quality/video-quality.util';
import WebRTCIssueDetector, { IssueReason } from 'webrtc-issue-detector';
import { RecordingConnectionQuality } from 'ngx/go-modules/src/services/media-recording-stats/media-recording-stats.service';

// Opentok has let us down. This seems to get by their issue for now
// before re-enabling OPENTOK_HACK flag we need to re-factor this function
// now all these parameters come in the first parameter ie name.channels
const reachInAndDoNaughtyThings = (session: OT.Session) => {
	session._.streamCreate = ((oldFn) => {
		return function (
			name,
			streamId,
			audioFallbackEnabled,
			channels,
			minBitrate,
			maxBitrate,
			sourceStreamId,
			completion
		) {

			channels.filter((channel) => channel.type === 'video').forEach((videoChannel) => {
				videoChannel.source = 'camera';
			});

			return oldFn.call(
				this,
				name,
				streamId,
				audioFallbackEnabled,
				channels,
				minBitrate,
				maxBitrate,
				sourceStreamId,
				completion
			);
		};
	})(session._.streamCreate);
};

interface SessionInfo {
	numArchives: number;
}

enum DestroyedReason {
	INIT = 'init',
	RESOLUTION = 'resolution',
	CLEANUP = 'cleanup'
}

const DestroyedReasons = [DestroyedReason.INIT, DestroyedReason.RESOLUTION, DestroyedReason.CLEANUP];

export const SUBSCRIBER_CHANGE = 'SUBSCRIBER_CHANGE';
export const QUALITY_CHANGE = 'QUALITY_CHANGE';

// Opentok doesn't provide access to the RTCPeerConnection
// We use to modify their code directly, but then we couldn't easily update.
// I looked inside trying to hook into something, but everything was in closures
// But they had to eventually touch the window, so that's where we'll hijack and
// get a reference to the RTCPeerConnection.
export class HookedRTCPeerConnection extends RTCPeerConnection {
	public static connectionsMap: {[MediaId: string]: HookedRTCPeerConnection} = {};

	constructor (configuration?: RTCConfiguration) {
		super(configuration);

		this.addEventListener('signalingstatechange', () => {
			if (this.signalingState !== 'stable') {
				return;
			}

			const [stream] = (this as any).getLocalStreams() as MediaStream[];
			if (stream == null) {
				return;
			}

			HookedRTCPeerConnection.connectionsMap[stream.id] = this;
		});
	}
};

export class OpentokDestination extends Destination {
	public static TEST_REMOTE_STREAM_QUALITY_DELAY = 1000;
	public static HEART_BEAT_INTERVAL = 10000;
	public static hasHookedRTCPeerConnection = false;

	public hasUsedScreenCapture = false;
	public myScreenShareIsActive: boolean = false;
	public screenShareActive: boolean = false;
	public type: DestinationType = Destination.TYPES.OPENTOK;
	private OT: typeof OT;
	private options: OTDestinationOptions;
	private stream: MediaStream;
	private session: OT.Session;
	private publisher: OT.Publisher;
	private archive: any;
	private disconnectedWhileRecording: boolean = false;
	private sessionInfo: SessionInfo = { numArchives: 0 };
	private heartBeatIntervalId: number;
	private recordingWasStartedByMe: boolean = false;
	private failedInitCount: number = 0;
	private hasManyPublishers: boolean = false;
	private audioContext: AudioContext;
	private audioDestination: MediaStreamAudioDestinationNode;
	private publishDetectInterval: number;
	// https://github.com/microsoft/TypeScript/issues/1778 - have to use string instead of alias
	/* eslint-disable-next-line max-len */
	private audioSources: {[key: /*OT.Stream['streamId']*/ string]: {stream: MediaStream; source: MediaStreamAudioSourceNode }} = {};
	private subscribers: {[key: /*OT.Stream['streamId']*/ string]: Subscriber};
	private captionStream: MediaStream;
	private focusId: string = null;
	private resolution = VideoQuality.SD;
	private preventSetFocusRequest = false;
	private screenShareInterrupted = false;
	private lastFailedReason: Reasons = Destination.REASONS.OPENTOK_CONNECTION_FAILED;
	private issueDetector: WebRTCIssueDetector;
	private issueTimeout: NodeJS.Timeout;

	private sessionListeners = {
		sessionReconnecting: (_event: OT.EventType<OT.Session, 'sessionReconnecting'>) => {
			// This would make more sense to have in the `sessionDisconnected` handler, but
			// the handler doesn't seem to fire, so this is the next best place.
			if (this.state === Destination.STATES.RECORDING) {
				this.disconnectedWhileRecording = true;
			}
			this.lastFailedReason = Destination.REASONS.OPENTOK_RECONNECTING;
			const error = new Error('Error reconnecting');
			this.setFailed(this.lastFailedReason, null, error);
		},
		sessionReconnected: (_event: OT.EventType<OT.Session, 'sessionReconnected'>) => {
			// Since the stream will continue to be archived after a disconnect,
			// ensure that we are still in the recording state after reconnecting.
			if (this.disconnectedWhileRecording) {
				this.disconnectedWhileRecording = false;
				this.setState(Destination.STATES.RECORDING);

				if (this.screenShareActive) {
					this.hasUsedScreenCapture = true;
					this.videoScene.updateResolution();
				}
			} else {
				this.setState(Destination.STATES.ACTIVE);
			}
		},
		archiveStarted: (event: OT.EventType<OT.Session, 'archiveStarted'>) => {
			this.archive = event.archive;
			// When all clients leave a session that is being archived
			// and then one comes back in, this event is fired but with
			// the archive status set to "paused". Consequently, this
			// event never fires again even though the archive auto resumed.
			// So set the state to recording no matter the status.
			this.setState(Destination.STATES.RECORDING);

			if (this.screenShareActive) {
				this.hasUsedScreenCapture = true;
				this.videoScene.updateResolution();
			}
		},
		archiveStopped: (_event: OT.EventType<OT.Session, 'archiveStopped'>) => {
			this.archive = null;
			this.setState(Destination.STATES.PAUSED);
		},
		connectionCreated: (event: OT.EventType<OT.Session, 'connectionCreated'>) => {
			// Notify a subscriber connection about the elapsed time.
			if (this.recordingWasStartedByMe) {
				this.notifyElapsedTime(event.connection);
			}

			// If screen sharing is active when this connection arrived, let them know
			if (this.myScreenShareIsActive) {
				this.session.signal({
					type: 'screenShare',
					data: JSON.stringify({
						active: true,
						streamId: this.publisher.stream.streamId
					}),
					to: event.connection
				}, (err) => {
					if (err) logger.logError('Failed to signal screen capture to new subscriber', err);
				});
			}
		},
		streamCreated: (event: OT.EventType<OT.Session, 'streamCreated'>) => {
			this.hasManyPublishers = true;
			const streamId = event.stream.streamId;

			// Immediately subscribe to a stream that is created.
			this.subscribers[streamId] = this.session.subscribe(event.stream, this.options.containerElement, {
				insertMode: 'append',
				height: '100%',
				width: '100%',
				fitMode: 'contain',
				showControls: !!event.stream.name,
				style: {
					nameDisplayMode: (this.options.overlayNameEnabled ? 'off': 'on'),
					buttonDisplayMode: 'off',
					audioLevelDisplayMode: 'off',
					archiveStatusDisplayMode: 'off'
				} as any,
				subscribeToAudio: true,
				subscribeToVideo: true
			}, (error) => {
				if (error) {
					logger.logError(error);
					return;
				}

				const stream = this.subscribers[streamId]._.webRtcStream().clone();

				// For whatever reason, connecting immediately can cause issues
				// with other participants hearing audio. Having a setTimeout
				// seems to resolve the issue
				if (stream.getAudioTracks().length > 0) {
					const source = this.audioContext.createMediaStreamSource(stream);
					this.audioSources[streamId] = {source, stream};
					setTimeout(() => source.connect(this.audioDestination));
				}

				if (streamId === this.focusId) {
					this.setFocus(streamId, true);
				}

				this.adjustLayout();
			});
		},
		streamDestroyed: (event: OT.EventType<OT.Session, 'streamDestroyed'>) => {
			// stream they are not the one sharing the screen
			// do nothing
			if (event.stream.streamId === this.focusId) {
				this.setFocus(event.stream.streamId, false);
			}

			if (this.subscribers[event.stream.streamId]) {
				delete this.subscribers[event.stream.streamId];
			}

			this.adjustLayout();

			const sourceData = this.audioSources[event.stream.streamId];
			if (sourceData) {
				this.destroySourceData(sourceData);
				delete this.audioSources[event.stream.streamId];
			}
		},
		signal: (event: OT.EventType<OT.Session, 'signal'>) => {
			// Signal has 'subtypes'
			if ((event as any).type === 'signal:state') {
				this.setState(event.data as States);
			} else if ((event as any).type === 'signal:sessionInfo') {
				this.sessionInfo = JSON.parse(event.data);
			} else if ((event as any).type === 'signal:screenShare') {
				const data = JSON.parse(event.data);

				// Make the new sharer in focus (or turn it off)
				this.setFocus(data.streamId, data.active);

			} else if ((event as any).type === 'signal:resolutionUpdateProcessingStatus') {
				const data = JSON.parse(event.data);
				this.videoScene.setResolutionUpdateProcessingState(data.state);
			}
		}
	};

	private publishListeners = {
		streamCreated: (_event: OT.Event<'streamScreated', OT.Publisher>) => {
			this.adjustLayout();
		},
		streamDestroyed: (event: OT.EventType<OT.Publisher, 'streamDestroyed'>) => {
			this.adjustLayout();

			// Log out info about stream destruction
			// eslint-disable-next-line no-console
			console.info('PUBLISHER STREAM DESTROYED', event);

			// Allows for resuming. If this is gone the publisher is destroyed on unpublish
			event.preventDefault();
			const reasons = [Destination.REASONS.OPENTOK_NETWORK_DISCONNECTED, Destination.REASONS.FORCE_UNPUBLISHED];
			const state = this.state;
			if (reasons.includes(event.reason as Reasons)) {
				this.handleNetworkDisconnect(state, event);
			}
		},
		destroyed: (event: OT.EventType<OT.Publisher, 'destroyed'>) => {
			if (!DestroyedReasons.includes(event.reason as DestroyedReason)) {
				const parser = new UAParser();
				const result = parser.getResult();
				if (result.browser.name === 'Chrome' && result.os.name === 'Chromium OS') {
					this.lastFailedReason = Destination.REASONS.CHROME_OS_DOO_DOO;
				} else {
					this.lastFailedReason = Destination.REASONS.OPENTOK_CONNECTION_FAILED;
				}
				const error = new Error('OpentokDestination publisher destroyed');
				this.setFailed(this.lastFailedReason, null, error);
			}
		}
	};

	constructor (options: OTDestinationOptions) {
		super(options);
		this.options = options;
		this.options.publisherWatchInterval = this.options.publisherWatchInterval || 10000;
		this.initHeartBeatInterval();
		this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
		this.audioDestination = this.audioContext.createMediaStreamDestination();
		this.audioSources = {};
		this.subscribers = {};
		this.options.params = this.options.params || {};
	}


	public async init (stream: MediaStream): Promise<void> {
		this.setState(Destination.STATES.INITIALIZING);
		this.stream = stream;
		this.resolution = this.videoScene.maxResolution();

		try {
			// Inject the hijacking code if we haven't done it before
			if (!OpentokDestination.hasHookedRTCPeerConnection) {
				window.RTCPeerConnection = HookedRTCPeerConnection;
				OpentokDestination.hasHookedRTCPeerConnection = true;
			}

			const module = await import(/* webpackChunkName: "OpenTokSDK" */ '@opentok/client');
			this.OT = module.default;
		} catch (e) {
			this.setFailed(Destination.REASONS.OPENTOK_CONNECTION_FAILED, this.internalInit, e);
			throw e;
		}

		// Add our audio source only once
		// This is used for real-time captioning currently
		if (this.stream.getAudioTracks().length > 0 && this.audioSources[this.stream.id] == null) {
			this.captionStream = this.stream.clone();
			const source = this.audioContext.createMediaStreamSource(this.captionStream);
			this.audioSources[this.captionStream.id] = {source, stream: this.captionStream};
			source.connect(this.audioDestination);

			// Need to know when track is muted/un-muted and connect/disconnect from audioDestination
			this.on('microphoneMuted', () => source.disconnect(this.audioDestination));
			this.on('microphoneUnmuted', () => source.connect(this.audioDestination));
		}

		try {
			if (this.session) {
				this.session.off(this.sessionListeners as any, this as any);
				this.session.disconnect();
			}
			this.session = this.OT.initSession(this.options.api_key, this.options.session_id);

			if (this.options.opentokHack) {
				reachInAndDoNaughtyThings(this.session);
			}

			this.session.on(this.sessionListeners as any, this as any);

			// Attempt to connect to opentok
			const connectionPromise = this.connect();

			if (this.publisher) this.publisher.destroy(DestroyedReason.INIT);
			const publisherPromise = this.initPublisher(stream, this.resolution)
				.then((publisher) => this.publisher = publisher);

			await Promise.all([connectionPromise, publisherPromise]);
			await this.publish(this.publisher);
			if (this.publishDetectInterval) clearInterval(this.publishDetectInterval);
			this.publishDetectInterval = this.watchPublisher();
			await this.test();
			this.failedInitCount = 0;
			this.setState(Destination.STATES.ACTIVE);
			if (this.publisher?._) {
				this.setupIssueDetector(this.publisher._.webRtcStream().id);
			}
		} catch (e) {
			this.failedInitCount++;
			if (this.failedInitCount > 2) {
				this.failedInitCount = 0;
				this.setFailed(this.lastFailedReason, this.internalInit, e);
			} else {
				return this.internalInit();
			}
		}
	}

	public setupIssueDetector (streamId): void {
		try {
			this.issueDetector = new WebRTCIssueDetector({
				autoAddPeerConnections: false,
				onIssues: (issues) => {
					// reset timer if new issue is detected
					clearTimeout(this.issueTimeout);
					const reasons = issues.map((issue) => issue.reason);
					if (reasons.includes(IssueReason.EncoderCPUThrottling)) {
						this.emit(QUALITY_CHANGE, RecordingConnectionQuality.POOR);
					}

					// they don't have a 'no-issues' event, so we have to do this ourselves
					// Auto-recover to GOOD after 10 seconds if no further issues
					this.issueTimeout = setTimeout(() => {
						this.emit(QUALITY_CHANGE, RecordingConnectionQuality.GOOD);
					}, 10000);
				},
				onNetworkScoresUpdated: (scores) => {
					const { rtt, avgJitter } = scores.statsSamples.outboundStatsSample || {};
					// rtt = Total round trip time for a packet - High RTT causes lag & delay
					// avgJitter = Variability in delay between packets - High jitter causes choppy audio/video
					let quality = RecordingConnectionQuality.GOOD;
					if (rtt > 200 || avgJitter > 400) {
						quality = RecordingConnectionQuality.POOR;
					} else if (rtt > 100 || avgJitter > 200) {
						quality = RecordingConnectionQuality.ACCEPTABLE;
					}
					this.emit(QUALITY_CHANGE, quality);
				}
			});
			const pc = HookedRTCPeerConnection.connectionsMap[streamId];
			this.issueDetector.handleNewPeerConnection(pc);
		} catch (e) {
			logger.logError(e);
		}
	}

	public setVideoSource (track: MediaStreamTrack): void {
		this.publisher?.setVideoSourceTrack(track);
	}

	public setAudioSource (track: MediaStreamTrack): void {
		if (this?.publisher?.setAudioSource) {
			this.publisher.setAudioSource(track);
		}
	}

	public getAudioStream (): MediaStream {
		return this.audioDestination.stream;
	}

	public async screenCaptureStart () {
		this.preventSetFocusRequest = false;
		this.screenShareInterrupted = false;
		this.screenShareActive = true;
		this.sendResolutionUpdateProcessingStatusSignal(Reasons.SCREEN_SHARE_PREPARING);
		await this.ensurePublisherResolution(this.videoScene.maxResolution());
		await this.setFocusToMyStream(true);
	}

	public async updateScreenResolution (resolution: VideoQuality, shouldSetFocus: boolean = false) {
		if (!this.screenShareInterrupted) {
			this.sendResolutionUpdateProcessingStatusSignal(Reasons.RESOLUTION_UPDATING);
		}

		this.screenShareInterrupted = false;

		if (shouldSetFocus) {
			await this.setFocusToMyStream(this.myScreenShareIsActive);
		}
		await this.ensurePublisherResolution(resolution);
	}

	public sendResolutionUpdateProcessingStatusSignal (reason: Reasons) {
		this.videoScene.setResolutionUpdateProcessingState(Reasons.SCREEN_SHARE_PREPARING);

		this.session.signal({
			type: 'resolutionUpdateProcessingStatus',
			data: JSON.stringify({state: reason})
		}, (error) => {
			if (error) logger.logError(error);
		});
	}

	public async start (): Promise<void> {
		this.recordingWasStartedByMe = true;
		this.setState(Destination.STATES.STARTING);
		try {
			await Promise.all([
				this.startArchiving(),
				this.whenState(Destination.STATES.RECORDING, 10000)
			]);
		} catch (e) {
			this.setFailed(Destination.REASONS.OPENTOK_CONNECTION_FAILED, this.internalInit, e);
			throw(e);
		}
	}

	public async pause (): Promise<void> {
		this.recordingWasStartedByMe = false;
		this.setState(Destination.STATES.PAUSING);
		try {
			if (this.archive != null) {
				await Promise.all([
					this.stopArchiving(),
					this.whenState(Destination.STATES.PAUSED, 10000)
				]);
			} else {
				this.setState(Destination.STATES.PAUSED);
			}
		} catch (e) {
			this.setFailed(Destination.REASONS.OPENTOK_CONNECTION_FAILED, this.internalInit, e);
		}
	}

	public async stop (): Promise<void> {
		if (this.state === Destination.STATES.RECORDING) {
			await this.pause();
		}

		if (this.state === Destination.STATES.PAUSED) {
			this.setState(Destination.STATES.STOPPING);

			// Notify clients of the state change
			try {
				await this.syncState(Destination.STATES.STOPPED);
			} catch (error) {
				logger.logError(error);
			}

			this.setState(Destination.STATES.STOPPED);
		}
	}

	public publishAudio (publish: boolean): void {
		this.publisher?.publishAudio(publish);
	}

	/**
	 * Checks the quality of the remote stream.
	 * Returns true if all is well.
	 * Will return false if video or audio was
	 * intended to be published but is not.
	 */
	public async test (): Promise<void> {
		// Check if the users local stream contains video and/or audio
		const shouldHaveVideo = !!this.stream.getVideoTracks().length;
		const shouldHaveAudio = !!this.stream.getAudioTracks().length;
		const sample1 = await this.remoteStreamStats(OpentokDestination.TEST_REMOTE_STREAM_QUALITY_DELAY);
		const sample2 = await this.remoteStreamStats(OpentokDestination.TEST_REMOTE_STREAM_QUALITY_DELAY);
		// Check video bytes sample from 1 second and 2 seconds. If the change in bytes is 0, the users
		// video stream is not being sent.
		if (shouldHaveVideo
			&& (!sample1.video || !sample2.video || sample2.video.bytesSent - sample1.video.bytesSent === 0)) {
			throw new Error('OpentokDestination not transmitting video');
		}

		// Check audio bytes sample from 1 second and 2 seconds. If the change in bytes is 0, the users
		// audio stream is not being sent.
		if (shouldHaveAudio
			&& (!sample1.audio || !sample2.audio || sample2.audio.bytesSent - sample1.audio.bytesSent === 0)) {
			throw new Error('OpentokDestination not transmitting audio');
		}
	}

	public async destroy () {
		// Allow publishers to leave and come back. If no one comes back
		// in 1 minute, the archive will automatically be stopped.
		if (!this.hasManyPublishers) {
			await this.stop();
		}
		this.session?.off(this.sessionListeners as any, this as any);
		if (this.publisher) this.publisher.destroy(DestroyedReason.CLEANUP);
		this.session?.disconnect();
		this.destroyHeartBeatInterval();
		for (const sourceData of Object.values(this.audioSources)) {
			this.destroySourceData(sourceData);
		}
		if (this.publishDetectInterval) clearInterval(this.publishDetectInterval);
		this.captionStream = null;
		this.audioSources = {};
		this.audioDestination.disconnect();
		await this.audioContext?.close();
		this.audioContext = null;
		this.removeAllListeners();
		super.destroy();
	}

	/**
	 * Whether the video recording will be ready for playback once recording is complete
	 */
	public isInstantPlaybackAvailable (): boolean {
		return this.sessionInfo.numArchives === 1;
	}

	private async ensurePublisherResolution (resolution: VideoQuality): Promise<void> {
		if (this.resolution !== resolution) {
			if (this.publisher) {
				await this.retryAsync(async () => {
					const newPublisher = await this.initPublisher(this.stream, resolution);
					await this.publish(newPublisher);
					this.publisher.destroy(DestroyedReason.RESOLUTION);
					this.publisher = newPublisher;
				});
			}
			this.resolution = resolution;
		}
	}

	private async retryAsync (fn: () => void, times: number = 3) {
		try {
			return await fn();
		}
		catch (error) {
			times--;
			if (times === 0) {
				this.setFailed(Destination.REASONS.OPENTOK_CONNECTION_FAILED, null, error);
				throw error;
			}
			return await this.retryAsync(fn, times);
		}
	}

	private setFocus (streamId: any, active: boolean): void {
		this.setFocusOnStream(streamId, active);
		this.options.containerElement.classList.remove('screen-share-layout');

		if (active) {
			this.focusId = streamId;

			// Only do this layout if it is a multi-camera/group assignment
			if (this.options.isMultiCam){
				this.options.containerElement.classList.add('screen-share-layout');
			}

		} else {
			// If the person in focus is turning it off, set focusId to null.
			// Ignore if someone not in focus is turning screen share off.
			this.focusId = null;
		}

		this.adjustLayout();
	}

	private setFocusOnStream (streamId: string, active: boolean) {
		this.screenShareActive = active;

		if (this.state === States.RECORDING) {
			this.hasUsedScreenCapture = active;
		}

		// always clear whoever sharing their screen
		this.options.containerElement.querySelector('.screen-share-active')?.classList.remove('screen-share-active');

		if (streamId === this.publisher.stream?.streamId) {
			// I'm the one screen sharing, so toggle the class on me
			this.options.containerElement.querySelector('.GR_publisher').classList.toggle('screen-share-active', active);
			this.myScreenShareIsActive = active;

		} else if (this.subscribers[streamId] != null) {
			// If someone else is sharing and I was sharing, stop sharing my screen.
			if (this.myScreenShareIsActive) {
				this.screenShareInterrupted = true;
				this.preventSetFocusRequest = true;
				this.myScreenShareIsActive = false;
				this.videoScene.screenCaptureInterrupt();
			}

			// A different person is sharing, so toggle the class on them
			this.subscribers[streamId].element.classList.toggle('screen-share-active', this.screenShareActive);
		}

		this.videoScene.updateResolution();
	}

	/**
	 * Set Focus Stream
	 */
	 private setFocusToMyStream (active: boolean): Promise<void> {
		// prevent requesting to backend when current presenter ScreenShare was interrupted
		// or feature flag is off
		if (this.preventSetFocusRequest) {
			this.preventSetFocusRequest = false;
			return;
		}

		const url = `/opentok/session/${this.session.sessionId}/focus`;
		return xhr(url, {
			method: 'PUT',
			body: {
				name: new URLSearchParams(this.options.params).toString(),
				active,
				stream_id: this.publisher.stream.streamId
			}
		});
	}

	private destroySourceData (sourceData: {source: MediaStreamAudioSourceNode; stream: MediaStream}) {
		sourceData.source.disconnect();
		sourceData.stream.getTracks().forEach((track) => track.stop());
	}

	private internalInit = (): Promise<void> => {
		return this.init(this.stream);
	};

	private initPublisher (
		stream: MediaStream,
		resolution: OT.GetUserMediaProperties['resolution']
	): Promise<OT.Publisher> {
		return new Promise((resolve, reject) => {
			const audioTrack = stream.getAudioTracks()[0];
			const publisher = this.OT.initPublisher(null, {
				insertDefaultUI: false,
				videoSource: stream.getVideoTracks()[0] || null,
				audioSource: audioTrack || null,
				publishAudio: audioTrack?.enabled || false,
				scalableVideo: false,
				videoContentHint: 'motion',
				resolution,
				name: this.options.displayName,
				style: {
					nameDisplayMode: (this.options.overlayNameEnabled ? 'off': 'on'),
					buttonDisplayMode: 'off',
					audioLevelDisplayMode: 'off',
					archiveStatusDisplayMode: 'off'
				}
			}, (error) => {
				if (error) {
					publisher.destroy();
					return reject(error);
				}
				this.extendPublisher(publisher);
				return resolve(publisher);
			});
			// Register publisher listeners
			publisher.on(this.publishListeners as any, this as any);
		});
	}

	// Remove this when opentok implements Publisher.setVideoSource
	private extendPublisher (publisher: OT.Publisher): void {
		publisher.setVideoSourceTrack = function (this: OT.Publisher, track: MediaStreamTrack) {
			const connection = HookedRTCPeerConnection.connectionsMap[this._.webRtcStream().id];
			const sender = connection.getSenders().find((s) => s.track.kind === track.kind);
			sender.replaceTrack(track);
		};
	}

	/**
	 * Connect to the session
	 */
	private connect (): Promise<void> {
		return new Promise((resolve, reject) => {
			this.session.connect(this.options.token, (error) => {
				if (error) {
					reject(error);
					return;
				}
				resolve();
			});
		});
	}

	/**
	 * Call publish on the session
	 */
	private publish (publisher: OT.Publisher): Promise<void> {
		return new Promise((resolve, reject) => {
			this.session.publish(publisher, (error: OT.OTError) => {
				if (error) {
					publisher.destroy();
					logger.logError(error);
					reject(error);
					return;
				}
				resolve();
			});
		});
	}

	private initHeartBeatInterval (): void {
		this.heartBeatIntervalId = window.setInterval(this.heartBeat, OpentokDestination.HEART_BEAT_INTERVAL);
	}

	private heartBeat = () => {
		if (this.state === States.RECORDING && this.recordingWasStartedByMe) {
			this.notifyElapsedTime();
		}
	};

	private destroyHeartBeatInterval (): void {
		window.clearInterval(this.heartBeatIntervalId);
	}

	/**
	 * Notify a specific client or all clients connected to
	 * the session about the current elapsed time.
	 */
	private notifyElapsedTime (to?: OT.Connection): void {
		const signal: { type?: string; data?: string; to?: OT.Connection } = {
			type: 'elapsedTime',
			data: this.options.timer.getTime().toString()
		};
		if (to) {
			signal.to = to;
		}
		this.session.signal(signal, (error) => {
			if (error) {
				logger.logError(error);
			}
		});
	}

	private adjustLayout (): void {
		const subscriberCount = Object.keys(this.subscribers).length + 1;
		this.options.containerElement.setAttribute('num-streams', subscriberCount.toString());
		this.emit(SUBSCRIBER_CHANGE, subscriberCount);
	}

	/**
	 * Notify all connected clients of a state change
	 */
	private syncState (state: States): Promise<void> {
		return new Promise((resolve, reject) => {
			const signal: { type?: string; data?: string; to?: OT.Connection } = {
				type: 'state',
				data: state
			};
			this.session.signal(signal, (error) => {
				if (error) {
					reject(error);
					return;
				}
				resolve();
			});
		});
	}

	public remoteStreamStats (delay: number = 0): Promise<OT.PublisherStats> {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				this.publisher.getStats((error, stats) => {
					if(error) return reject(error);

					if (!stats || stats.length === 0) {
						return reject(new Error('No stats available'));
					}

					resolve(stats[0].stats);
				});
			}, delay);
		});
	}

	/**
	 * Start Archiving
	 */
	private async startArchiving (): Promise<void> {
		const url = `/opentok/session/${this.options.session_id}/archive`;
		return xhr(url, {
			method: 'POST',
			body: {
				name: new URLSearchParams(this.options.params).toString(),
				focusId: this.focusId
			}
		});
	}

	private watchPublisher (): number {
		return setInterval(() => {
			this.publisher.getStats((err) => {
				if (err) {
					this.handleNetworkDisconnect(this.state, {
						reason: 'LOST NETWORK CONNECTION'
					});
				}
			});
		}, this.options.publisherWatchInterval) as unknown as number;
	}

	private handleNetworkDisconnect (state, event) {
		const error = new Error('Network disconnected');
		// eslint-disable-next-line no-console
		console.log('NETWORK DISCONNECT', event);
		this.setFailed(Destination.REASONS.OPENTOK_NETWORK_DISCONNECTED, null, error);
		this.internalInit().then(() => {
			this.setState(state); // Return to prior state
		}).catch(() => {
			this.setFailed(Destination.REASONS.OPENTOK_NETWORK_FAILED, null, {
				reason: event?.reason,
				type: event?.type,
				streamId: event?.stream?.streamId,
				name: event?.stream?.name,
				data: event?.stream?.connection?.data,
				sessionId: this.options.session_id
			});
		});
	}

	/**
	 * Stop Archiving
	 */
	private async stopArchiving (): Promise<void> {
		const url = `/opentok/session/${this.options.session_id}/archive`;
		return xhr(url, {
			method: 'DELETE'
		});
	}
}
