import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { Observable } from 'rxjs';
import type { Session } from 'ngx/go-modules/src/services/session-list-datasource/session-list.datasource';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { ActivityListDataSource } from '../activity-list-datasource/activity-list.datasource';
import { Activity } from 'ngx/go-modules/src/interfaces/activity';
import { FolderListDataSource } from '../folder-list-datasource/folder-list-datasource';
import { CourseFolder } from '../course/interfaces/course-folder.interface';
import { UADetect as UADetectClass, uaDetectToken } from 'go-modules/detect/ua-detect.service';
import { NgxFeatureFlagService } from '../feature-flag/feature-flag.service';

export interface SharePrivatePayload {
	external_reviewer_emails: string[];
	internal_reviewer_user_ids: number[];
}

export interface SharePrivateResponse {
	invalidExternalUsers: string[];
	invalidInternalUsers: number[];
}

export interface SharePublicResponse {
	uuid: string;
}

@Injectable({
	providedIn: 'root'
})
export class NgxSessionService {
	constructor (
		private http: HttpClient,
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(uaDetectToken) public UADetect: UADetectClass,
		private activityList: ActivityListDataSource,
		private folderList: FolderListDataSource,
		private featureFlag: NgxFeatureFlagService
	) {}

	public getSession (sessionId: number, relations?: any[]): Observable<Session> {
		let url = `${clientSettings.GoReactV2API}/submissions/${sessionId}`;

		if (relations) {
			const relationParams = new URLSearchParams();
			relations.forEach((relation) => {
				relationParams.append('with[]', relation);
			});
			url += '?' + relationParams.toString();
		}

		return this.http.get<Session>(url);
	}

	public markViewed (session: Session) {
		this.http.post(`${clientSettings.GoReactV2API}/submissions/${session.session_id}/views`, {}).subscribe();
		const activity: Activity = this.selectedService.getActivity();
		session.viewed = true;
		// TODO DEV-15860
		activity.unviewed_sessions--;
		this.activityList.editActivity(activity);

		const folder: CourseFolder = this.selectedService.getGroup();
		if (
			folder != null &&
			folder.hasReviewerRole(true) &&
			activity.unviewed_sessions === 0 &&
			folder.stats?.attention_needed
		) {
			this.activityList.connect().subscribe((activities) => {
				if (activities.every((act) => act.unviewed_sessions === 0)) {
					folder.stats.attention_needed = false;
					this.folderList.editFolder(folder);
				}
			});
		}
	}

	public markUnviewed (session: Session) {
		this.http.delete(`${clientSettings.GoReactV2API}/submissions/${session.session_id}/views`, {}).subscribe();
		session.viewed = false;
		const activity: Activity = this.selectedService.getActivity();
		activity.unviewed_sessions++;
		this.activityList.editActivity(activity);
		const folder: CourseFolder = this.selectedService.getGroup();
		// TODO DEV-15860: Selected service doesn't have folder stats, only activity stats
		folder.stats.attention_needed = true;
		this.folderList.editFolder(folder);
	}

	public archive (session) {
		return this.http.patch(`${clientSettings.GoReactV2API}/sessions/${session.session_id}/archive`, {});
	}

	public hasInstructorRole (): boolean  {
		return this.selectedService.getGroup().hasInstructorRole(true);
	}

	public hasVideoSharePermission = (session): boolean => {
		const org = this.selectedService.getOrg();
		const activity = this.selectedService.getActivity();
		const orgEnabled = org.org_settings?.allow_video_share;
		const internalReviewersEnabled = org.org_settings?.internal_reviewers_enabled;
		const isOwner = session.isOwner(this.userService.currentUser);

		if (internalReviewersEnabled &&
			activity.hasResponseMedia() &&
			!!session.media) {
			return  this.hasInstructorRole() || (isOwner && !activity.has_single_recording_attempt);
		}

		if (orgEnabled &&
			(session.isPosted() || this.featureFlag.isAvailable('SHARE_LIVE')) &&
			activity.isViewable('is_video_share_enabled')) {
			return this.hasInstructorRole() ||
				(isOwner && !activity.has_single_recording_attempt && activity.isVideoShareEnabled());
		}
		return false;
	};

	public doesGroupSessionHaveMaxPresenterCount (session): boolean {
		const activity = this.selectedService.getActivity();
		const user = this.userService.currentUser;

		return session.hasMaxPresenterCount(activity)
			&& !session.isPresenter(user);
	}

	public mayEdit (session): boolean  {
		return session.mayEdit(
			this.userService.currentUser,
			this.selectedService.getGroup()
		);
	}

	public mayDelete (session): boolean  {
		const activity = this.selectedService.getActivity();

		if (!activity.has_response_media && !activity.is_individual_graded) {
			return false;
		}

		return session.mayDelete(
			this.userService.currentUser,
			this.selectedService.getGroup(),
			activity
		);
	}

	public shouldDisplayJoinButton = (session): boolean  => {
		return session.isAwaitingStart() || session.isLive();
	};

	public shouldDisplayOptionsMenu = (session): boolean  => {
		return this.mayEdit(session) || this.mayDelete(session) || this.hasVideoSharePermission(session);
	};

	public delete (sessionId: number): Observable<any> {
		return this.http.delete(`${clientSettings.GoReactV2API}/sessions/${sessionId}`);
	}

	public restore (sessionId: number): Observable<any> {
		return this.http.patch(`${clientSettings.GoReactV2API}/sessions/${sessionId}/unarchive`, {});
	}

	public getMedia (session) {
		const isCommentOnly = this.selectedService.getActivity().isCommentOnly();
		let activityMedia = this.selectedService.getActivity().source_media?.media;

		activityMedia = isCommentOnly ? activityMedia : undefined;

		return session.media || session.source_media || activityMedia;
	}

	public shouldDisplayScore = (session): boolean => {
		const group = this.selectedService.getGroup();
		const activity = this.selectedService.getActivity();

		return activity.isScoreEnabled() &&
			(group.hasReviewerRole(true) || (
				session.isOwner(this.userService.currentUser) &&
				!activity.shouldHoldFeedback(group.hasPresenterRole())
			));
	};

	public generateAiFeedback = (sessionId: number) => {
		return this.http.post(`${clientSettings.GoReactV2API}/sessions/${sessionId}/generate/ai-feedback`, null);
	};

	public sharePublic = (sessionId: number): Observable<SharePublicResponse> => {
		return this.http.post<SharePublicResponse>(`${clientSettings.GoReactV2API}/sessions/${sessionId}/share/public`, null);
	};

	public sharePrivate = (sessionId: number, payload: SharePrivatePayload): Observable<SharePrivateResponse> => {
		return this.http.post<SharePrivateResponse>(`${clientSettings.GoReactV2API}/sessions/${sessionId}/share/private`, payload);
	};
}
