import * as angular from 'angular';

import { sessionEditorModalModule } from '../modals/session-editor';
import { equipmentCheckModalModule } from '../modals/equipment-check';
import { activityInstructionsModalModule } from '../modals/activity-instructions';
import { activityModel } from '../models/activity';
import { sessionModel } from '../models/session';
import { groupDepModel } from '../models/group-dep';
import { userModel } from '../models/user';
import { mediaModel } from '../models/media';
import { sessionManager } from '../session-manager';
import { zoomServiceModule } from 'go-modules/services/zoom';

import { SessionCreationIntent } from './intent';
import type { SessionCreationWizardOptions as ExportSessionCreationWizardOptions } from './options';
import { SessionCreationWizardService } from './wizard.service';
import type { SessionCreationWizardStep as ExportSessionCreationWizardStep } from './steps';
import {
	InitiateStep,
	EquipmentCheckStep,
	StartTestStep,
	InstructionsStep,
	UploadStep,
	RecordStep,
	FinalizeStep
} from './steps';

// TODO: fix circular dependency.
// Until then, we need to import directly to force side-effect of angular module
// loading. If we leave the `fooModule from` part, webpack will use tree-shaking
// and the module will not be loaded.
// import /*{ goModal } from*/ '../modals'; -- commented to satisfy eslint
import '../modals';
// tslint:disable-next-line:import-spacing
import /*{ universalMediaChooserModule } from*/ '../universal-media-chooser';

export const sessionCreationWizardModule = angular.module('go.session-creation-wizard', [
	sessionEditorModalModule,
	equipmentCheckModalModule,
	activityInstructionsModalModule,
	activityModel,
	sessionModel,
	groupDepModel,
	userModel,
	mediaModel,
	sessionManager,
	zoomServiceModule,

	// TODO: use module strings directly until circular dependency is fixed
	'go.modal',
	'universal-media-chooser'
])
	.constant('SessionCreationIntent', SessionCreationIntent)
	.service('sessionCreationWizardService', SessionCreationWizardService)
	.service('sessionCreationWizardInitiateStep', InitiateStep)
	.service('sessionCreationWizardEquipmentCheckStep', EquipmentCheckStep)
	.service('sessionCreationWizardStartTestStep', StartTestStep)
	.service('sessionCreationWizardInstructionsStep', InstructionsStep)
	.service('sessionCreationWizardUploadStep', UploadStep)
	.service('sessionCreationWizardRecordStep', RecordStep)
	.service('sessionCreationWizardFinalizeStep', FinalizeStep)
	.name;

export {
	SessionCreationIntent,
	SessionCreationWizardService
};

export type SessionCreationWizardOptions = ExportSessionCreationWizardOptions;
export type SessionCreationWizardStep = ExportSessionCreationWizardStep;
