import { UserService } from 'go-modules/models/user/user.service';
import { noop } from 'angular';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { MessageModal } from 'go-modules/modals/message/modal.factory';
import { SessionListDataSource } from 'ngx/go-modules/src/services/session-list-datasource/session-list.datasource';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import {
	VideoDetailsDialogComponent
} from 'ngx/go-modules/src/components/dialogs/video-details-dialog/video-details-dialog.component';
import {
	VideoShareDialogService
} from 'ngx/go-modules/src/components/dialogs/video-share-dialog/video-share-dialog.service';
import { combineLatest, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectedService } from 'go-modules/services/selected/selected.service';

/* @ngInject */
export const sessionEditorPanelController = function (
	$scope,
	userService: UserService,
	User,
	Group,
	Session,
	$filter,
	confirmModal,
	$document: ng.IDocumentService,
	$timeout: ng.ITimeoutService,
	messageModal: MessageModal,
	$log: ng.ILogService,
	$translate: ng.translate.ITranslateService,
	ngxDowngradeModalService: DowngradeModalService,
	ngxSessionListDataSource: SessionListDataSource,
	ngxGoModalService: GoModalService,
	ngxVideoShareDialogService: VideoShareDialogService,
	selectedService: SelectedService,
	$injector
) {

	const vm = this;
	let previousScore;
	vm.saving = false;
	vm.showAddPresenterInput = false;
	vm.currentUserId = userService.currentUser.user_id;
	vm.selectedUser = null;
	vm.filteredUsers = [];
	vm.activeTab = vm.activeTab;

	vm.$onInit = () => {

		const mentoredUserIds$: Observable<number[]> = selectedService.getMentoredUserIds$();
		const users$: Observable<any> = from(User.getByGroup({
			groupId: vm.group.getId()
		}).$promise);

		vm.usersSubscription = combineLatest([users$, mentoredUserIds$])
			.pipe(
				map(([users, mentoredUserIds]) => {
					if (mentoredUserIds && mentoredUserIds.length > 0) {
						return users.filter((user) => user.user_id === vm.currentUserId
							|| mentoredUserIds.includes(user.getId())
						);
					}

					return users;
				})
			)
			.subscribe((filteredUsers) => {
				$scope.$evalAsync(() => {
					vm.users = filteredUsers;
				});
			});

		Group.getOrgSettings({group_id: vm.group.group_id})
			.then((orgSettings) => {
				vm.orgSettings = orgSettings;
			});

		previousScore = vm.session.score;
		vm.originalSourceMedia = vm.session.source_media;
	};

	vm.$onDestroy = () => {
		vm.usersSubscription.unsubscribe();
	};

	vm.openMediaDetailsModal = (event) => {
		event.stopPropagation();

		return ngxGoModalService.open(VideoDetailsDialogComponent, true, {
			data: {
				session: vm.session
			}
		});
	};

	vm.canRemovePresenter = (presenter) => {
		return vm.group.hasInstructorRole(true) || presenter.user_id !== vm.currentUserId;
	};

	vm.removePresenter = (presenter) => {
		const index = vm.session.presenters.findIndex((p) => {
			return parseInt(p.user_id, 10) === parseInt(presenter.user_id, 10);
		});

		vm.session.presenters.splice(index, 1);
	};

	vm.onSelect = (item) => {
		if (item && item.user_id) {
			vm.session.presenters.push(item);
			vm.showAddPresenterInput = false;
		}
	};

	vm.save = ($event) => {
		let savePromise;
		vm.saving = true;

		// When the score has changed, post or retract the grade
		// depending on whether the score value is falsy or not.
		if (vm.session.score !== previousScore) {
			if (vm.session.score) {
				vm.session.postGrade(vm.session.score);
				vm.activity.num_graded = vm.activity.num_graded + 1;
			} else {
				vm.session.retractGrade();
				vm.activity.num_graded = vm.activity.num_graded - 1;
			}
		}

		previousScore = vm.session.score;
		$event.preventDefault();

		if (!vm.session.source_media && vm.originalSourceMedia) {
			savePromise = vm.session.deleteSlideDeck();
		} else {
			savePromise = vm.session.save();
		}

		savePromise.then(() => {
			if (vm.session.activity_id !== vm.activity.activity_id) {
				ngxSessionListDataSource.removeSession(Session.model(vm.session));
			}
			vm.saving = false;
			vm.$close(vm.session);
		});
	};

	vm.showNewPresenterInput = () => {
		vm.showAddPresenterInput = true;

		$timeout(() => {
			($document[0].querySelector('.session-editor-panel input.typeahead-input') as HTMLInputElement).focus();
		});
	};

	vm.getReviewers = () => {
		return vm.session.guest_reviewers.concat(vm.session.internal_reviewers);
	};

	/**
	 * Filter users given a query string
	 *
	 * @param query
	 * @returns {Array}
	 */
	vm.filterUsers = () => {
		return $filter('filter')(vm.users, notSelectedFilter());
	};

	vm.removeGuest = (guest) => {
		ngxDowngradeModalService.openConfirmDialog({
			title: $translate.instant('modal-remove-video-reviewer_title'),
			message: guest.hasOwnProperty('uuid') ?
				$translate.instant('modal-remove-guest-reviewer_text', {fullname: guest.user.full_name}) :
				$translate.instant('modal-remove-video-reviewer_text', {fullname: guest.full_name}),
			confirmText: $translate.instant('common_remove')
		}).then(() => {
			if (guest.hasOwnProperty('uuid')) {
				Session.deleteGuestReviewer({ session_id: vm.session.session_id, guest_id: guest.id })
					.$promise.then(() => {
						guest.deleted_at = new Date();
					});
			} else {
				Session.deleteInternalReviewer({ session_id: vm.session.session_id, user_id: guest.user_id })
					.$promise.then(() => {
						vm.session.internal_reviewers = vm.session.internal_reviewers.filter((reviewer) => {
							return reviewer.user_id !== guest.user_id;
						});
					});
			}
		}).catch(noop);
	};

	vm.restoreGuest = (guest) => {
		confirmModal.open({
			modalData: {
				title: 'session-editor-panel_guest-reviewers-restore',
				message: $translate.instant('session-editor-panel_guest-reviewers-restore-message',
					{ name: guest.user == null ? guest.email : `${guest.user.first_name} ${guest.user.last_name}`}),
				yes: 'common_restore'
			}
		}).result.then(() => {
			const data = { session_id: vm.session.session_id, guest_id: guest.id };
			return Session.restoreGuestReviewer(data, data)
				.$promise.then(() => {
					guest.deleted_at = null;
				}).catch((err) => {
					$log.error(err);

					if (err && err.status === 409 &&
						err.data.message.includes('This video has reached the maximum amount of guest reviewers')
					) {
						messageModal.open({
							modalData: {
								title: 'modal-video-share_restore-fail_title',
								message: $translate.instant('modal-video-share_share-fail-max-users_message',
									{ limit: clientSettings.SessionShareGuestReviewerLimit })
							}
						});
					}
				});
		}).catch((msg) => $log.info(msg));
	};

	vm.shouldShowGuestReviewersSection = () => {
		return $injector.get('ngxSessionService').hasVideoSharePermission(vm.session);
	};

	vm.shareSession = () => {
		ngxVideoShareDialogService.open({
			orgSettings: vm.orgSettings,
			sessionId: vm.session.session_id
		}).subscribe((res) => {
			if (res && !res.dismissed) {
				Session.get({
					'session_id': vm.session.session_id,
					'with[]': ['externalSessionUsers', 'externalSessionUsers.user', 'internalReviewers']
				}).$promise.then((session) => {
					vm.session = session;
				});
			}
		});
	};

	vm.expireAccess = () => {
		confirmModal.open({
			modalData: {
				title: 'modal-expire-public-link-access_title',
				message: 'modal-expire-public-link-access_text',
				yes: 'session-editor-panel_expire-link',
				no: 'common_cancel'
			}
		}).result.then(() => {
			return Session.expirePublicLinkAccess({ session_id: vm.session.session_id }).$promise.then(() => {
				// Guests with a null uuid have access from the public link and should be removed from the list
				vm.session.guest_reviewers = vm.session.guest_reviewers.filter((guest) => guest.uuid !== null);
			}).catch((err) => $log.error(err));
		}).catch((msg) => $log.info(msg));
	};

	vm.deleteInvite = (guest) => {
		confirmModal.open({
			modalData: {
				title: 'group-settings_delete-invite',
				message: $translate.instant('modal-remove-invite_sure-delete-invite', { email: guest.email }),
				yes: 'common_delete',
				no: 'common_cancel'
			}
		}).result.then(() => {
			return Session.deleteGuestReviewer({ session_id: vm.session.session_id, guest_id: guest.id })
				.$promise.then(() => {
					guest.deleted_at = new Date();
				}).catch((err) => $log.error(err));
		}).catch((msg) => $log.info(msg));
	};

	/**
	 * Not selected filter
	 */
	 function notSelectedFilter () {
		return function filterFn (user) {
			return !vm.session.isPresenter(user);
		};
	}
};
