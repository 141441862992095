import { YoutubeProcessor } from '../models/media/youtube.processor';
import { GoMediaPlayer } from './adapters/media-player';
import { HlsStreamAdapter } from './adapters/hls-adapter';
import { YoutubePlayerAdapter } from './adapters/youtube-adapter';
import type { OpenTokSessionModel, OpenTokSessionFactory } from '../models/opentok-session';
import { OpenTokRole } from '../models/opentok-session';
import type { PlaylistItem } from './playlist-item';
import type { Options } from './options';
import logger from 'go-modules/logger/logger';
import { MediaSource } from 'go-modules/models/media';
import type { MediaSchema } from 'go-modules/models/media';
import { MediaAnalytics } from 'go-modules/models/media/analytics/media-analytics';
import { MediaAnalyticsService } from 'go-modules/services/media-analytics/media-analytics.service';
import { STATES, EVENTS } from '.';
import { UserService } from 'go-modules/models/user/user.service';
import { AllPlayersService } from 'ngx/go-modules/src/services/all-players/all-players.service';
import { HTML5PlayerAdapter } from './adapters/html5-player-adapter';
import type { HTML5PlayerOptions, HTMLTrackOptions } from './adapters/html5-player-adapter';
import {
	ApiGetMediaRecordingStats,
	NgxMediaRecordingStatsService, RecordingConnectionQuality
} from 'ngx/go-modules/src/services/media-recording-stats/media-recording-stats.service';
import { lastValueFrom } from 'rxjs';

interface PropertyBindings {
	id?: string;
	options?: Options;
	playlistItem: PlaylistItem;
	onInit: ({player: GoMediaPlayer}) => void;
	onInitError: ({error: Error}) => void;
}

const defaultOptions: Options = {
	liveStream: false,
	hideControls: false,
	autoPlay: false,
	forceCaptions: false,
	allowFullscreen: true,
	showSettings: true
};

let instanceCount = 0;

export class GoMediaPlayerController implements PropertyBindings {
	// Property bindings
	public audioStream: MediaStream;
	public playerState: STATES = STATES.IDLE;
	public mediaId: number = null;
	public id: string;
	public options: Options;
	public playlistItem: PlaylistItem;
	public onInit: ({player: GoMediaPlayer}) => void;
	public onInitError: ({error: Error}) => void;
	public mediaAnalytics: MediaAnalytics;
	public collapseAudio: boolean = false;
	public minimizedControls: boolean = false;
	public allowDownload: boolean = false;

	public player: GoMediaPlayer;
	public playerOptions: Options;
	public setupPromise: Promise<void> = Promise.resolve();
	private playerWrapper: HTMLElement;
	private shield: HTMLElement;

	public showPoorConnectionIcon = false;

	/* @ngInject */
	constructor (
		private $element: ng.IAugmentedJQuery,
		private $scope: ng.IScope,
		private OpenTokSession: OpenTokSessionFactory,
		private mediaPlayer: any,
		private mediaAnalyticsService: MediaAnalyticsService,
		private ngxMediaRecordingStatsService: NgxMediaRecordingStatsService,
		private userService: UserService,
		private allPlayersService: AllPlayersService
	) {
		this.playerWrapper = this.$element[0].querySelector('.player-wrapper');
	}

	public $onChanges (changes: ng.IOnChangesObject): void {
		if (changes.playlistItem) {
			this.setupPromise = this.setupPromise
				.then(() => this.destroyPlayer())
				.then(() => this.init(changes.playlistItem.currentValue));
		}
	}

	public async init (newPlaylistItem): Promise<void> {
		try {
			// Reset values in case $onChanges triggers another player init
			this.showPoorConnectionIcon = false;

			// Ignore falsy playlist items
			if (!newPlaylistItem ) {
				return;
			}
			this.playerOptions = this.buildOptions(defaultOptions, this.options);

			if (this.collapseAudio) {
				this.playerOptions.allowFullscreen = false;
				this.playerOptions.showSettings = false;
			}

			this.player = await this.initPlayerWith(newPlaylistItem, this.playerOptions);

			// Sets media analytics on player create.
			if(newPlaylistItem.media?.media_id) {
				this.mediaAnalytics = new MediaAnalytics(
					this.userService.currentUser?.user_id,
					newPlaylistItem.media.media_id,
					this.player.getDuration()
				);
			}

			this.player.on(EVENTS.AUDIO_STREAM, this.setAudioStream);
			this.player.on(EVENTS.PLAY, this.playHandler.bind(this));
			this.player.on(EVENTS.PAUSE, this.durationHandler.bind(this));
			this.player.on(EVENTS.REQUEST_SEEK, this.seekHandler.bind(this));
			this.player.on(EVENTS.COMPLETE, this.durationHandler.bind(this));

			this.handlePlayerInit(this.player);
		} catch (error) {
			logger.logError(error);
			this.onInitError({error});
		} finally {
			this.$scope.$evalAsync();
		}
	}

	public $onDestroy (): void {
		this.setupPromise = this.setupPromise
			.then(this.destroyPlayer.bind(this));
		this.shield?.removeEventListener('click', this.onShieldClick);
	}

	public seekHandler (): void {
		if (this.mediaAnalytics?.playCount > 0 && this.player.isPlaying()) {
			this.mediaAnalytics.setDuration(this.player.getTime());
		}
	}

	public playHandler (): void {
		this.mediaAnalytics?.incrementPlayCount();
		this.mediaAnalytics?.setStart(this.player.getTime());
		this.allPlayersService.setLastPlayed(this.player);
	}

	public durationHandler (): void {
		this.mediaAnalytics?.setDuration(this.player.getTime());
	}

	public async initPlayerWith (playlistItem: PlaylistItem | MediaSchema, options: Options): Promise<GoMediaPlayer> {
		let url, thumbnailUrl;
		if (this.isPlaylistItem(playlistItem) && 'media' in playlistItem) {
			this.mediaId = playlistItem.media.media_id;
			url = playlistItem.media.media_url;
			thumbnailUrl = playlistItem.media.thumbnail_url;
		} else if (this.isPlaylistItem(playlistItem) && !('media' in playlistItem)) {
			this.mediaId = null;
			url = playlistItem.file;
			thumbnailUrl = playlistItem.image;
		} else {
			this.mediaId = playlistItem.media_id;
			url = playlistItem.media_url;
			thumbnailUrl = playlistItem.thumbnail_url;
		}
		const element = document.createElement('div');
		this.playerWrapper.innerHTML = '';
		this.playerWrapper.appendChild(element);
		this.initShield(this.playerWrapper);

		if (YoutubeProcessor.isYoutubeUrl(url)) {
			element.id = `youtube-adapter-iframe-${++instanceCount}`;
			element.dataset.cy = 'youtube-iframe';		// Custom unique selector for Cypress testing

			return YoutubePlayerAdapter.setup(element.id, {
				height: '100%',
				width: '100%',
				url
			});
		} else if (url?.includes('.m3u8')) {
			return await HlsStreamAdapter.setup(this.playerWrapper, { url });
		} else if (options.liveStream && 'media' in playlistItem && playlistItem.media?.source === MediaSource.OPENTOK) {
			element.className = 'opentok-session-adapter-container';

			const openTokAdapterModulePromise = import(/* webpackChunkName: "OpenTokStreamSubscriberAdapter" */ './adapters/opentok-adapter');
			const opentokSession: OpenTokSessionModel = await this.OpenTokSession.getForMedia({
				media_id: playlistItem.media.media_id
			}).$promise;
			const opentokToken = await this.OpenTokSession.createToken({
				role: OpenTokRole.SUBSCRIBER,
				session_id: opentokSession.id
			}).$promise;

			const openTokAdapterModule = await openTokAdapterModulePromise;
			return openTokAdapterModule.OpenTokStreamSubscriberAdapter.setup({
				api_key: opentokToken.api_key,
				session_id: opentokSession.id,
				token: opentokToken.value,
				hasUsedScreenCapture: opentokSession.hasUsedScreenCapture,
				insertMode: 'append',
				height: '100%',
				width: '100%',
				showControls: false,
				fitMode: 'contain',
				subscribeToAudio: true,
				subscribeToVideo: true,
				containerElement: element
			});
		} else if (url == null) {
			throw new Error('Could not determine what to do with media');
		} else {
			element.className = 'html5-player-adapter-container';
			const html5options: HTML5PlayerOptions = {
				url,
				thumbnailUrl,
				tracks: []
			};
			if (this.isPlaylistItem(playlistItem)) {
				html5options.tracks = playlistItem.tracks.map((vtt, index): HTMLTrackOptions => ({
					default: index === 0,
					kind: 'captions',
					label: 'Caption ' + index,
					src: vtt.file
				}));
			}

			const playerPromise = HTML5PlayerAdapter.setup(element, html5options);

			if ('media' in playlistItem) {
				const statsPromise = lastValueFrom(
					this.ngxMediaRecordingStatsService.getMediaRecordingStats(playlistItem.media.media_id)
				);
				Promise.all([playerPromise, statsPromise]).then(([player, stats]) => {
					player.on(EVENTS.TIME, this.checkConnection.bind(this, stats));
				});
			}
			return playerPromise;
		}
	}

	private checkConnection (stats: ApiGetMediaRecordingStats[], { time }): void {
		this.showPoorConnectionIcon = stats.some((stat) => {
			return stat.connection_quality === RecordingConnectionQuality.POOR
				&& stat.record_start_ms <= time && time <= stat.record_end_ms;
		});
	}

	public setAudioStream = (stream: MediaStream | null) => {
		this.audioStream = stream?.clone();
		this.$scope.$evalAsync();
	};

	public setPlayerState = ({newstate}) => {
		this.playerState = newstate;
		this.$scope.$evalAsync();
	};

	private isPlaylistItem (playlistItem: PlaylistItem | MediaSchema): playlistItem is PlaylistItem {
		return 'media' in playlistItem || 'file' in playlistItem;
	}

	private buildOptions (defaults: Options, options: Options): Options {
		// TODO: the `live-view/view.controller` updates the `liveStreamCount` option
		// and the player controls is updating the UI when that value changes. The live
		// viewer count should really be managed by the media player instance and this controller.
		// TODO: The widgets refer to trimActive to know when to send trim points
		if (options && (options.liveStream || options.trimActive != null)) {
			return options;
		}

		return {
			...defaults,
			...options
		};
	}

	/**
	 * The shield prevents users from clicking on the display to toggle play/pause
	 */
	private initShield (container: HTMLElement): void {
		this.shield = document.createElement('div');
		this.shield.className = 'shield';
		container.append(this.shield);
		this.shield.addEventListener('click', this.onShieldClick);
	}

	private handlePlayerInit (player: GoMediaPlayer): void {
		if (this.id) {
			this.mediaPlayer.register(this.id, player);
		}
		this.onInit({player});
		this.playerEvents();
	}

	private playerEvents = () => {
		this.player.on(EVENTS.BUFFER, this.setPlayerState);
		this.player.on(EVENTS.PAUSE, this.setPlayerState);
		this.player.on(EVENTS.PLAY, this.setPlayerState);
		this.player.on(EVENTS.COMPLETE, this.setPlayerState);
	};

	private stopPlayerEvents () {
		this.player.off(EVENTS.BUFFER, this.setPlayerState);
		this.player.off(EVENTS.PAUSE, this.setPlayerState);
		this.player.off(EVENTS.PLAY, this.setPlayerState);
		this.player.off(EVENTS.COMPLETE, this.setPlayerState);
		this.player.off(EVENTS.AUDIO_STREAM, this.setAudioStream);
	}

	private destroyPlayer (): void {
		if (this.player) {
			this.stopPlayerEvents();

			if (this.mediaAnalytics) {
				// duration is only set on pause or complete so if still playing
				// then we need to set it
				if (this.player.isPlaying()) {
					this.mediaAnalytics.setDuration(this.player.getTime());
				}
				// only save if actually watched some of the video
				if (this.mediaAnalytics.getDuration() > 0) {
					this.mediaAnalyticsService.save(this.mediaAnalytics.toPayload())
						.catch((error) => {
							logger.logError(error);
						});
				}
			}

			if (this.id) {
				this.mediaPlayer.unregister(this.id);
			}
			this.player.destroy();
			this.player = null;
		}
	}

	private onShieldClick = () => {
		if (this.player.isPlaying()) {
			this.player.pause();
		} else {
			this.player.play();
		}
	};
}
