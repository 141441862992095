import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GroupCreatorService, groupCreatorServiceToken } from 'go-modules/group-creator/group-creator.service';
import { groupToken } from 'go-modules/models/group-dep/group.factory';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { NgxCourseService } from 'ngx/go-modules/src/services/course/course.service';
import { CourseFolder } from 'ngx/go-modules/src/services/course/interfaces/course-folder.interface';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';
import { FolderListDataSource } from 'ngx/go-modules/src/services/folder-list-datasource/folder-list-datasource';
import { BehaviorSubject } from 'rxjs';
import dayjs from 'dayjs';

export interface AiTrialCourseSelectorResult {
	folderId: number;
}

@Component({
	selector: 'app-ai-trial-course-selector-dialog',
	template: require('./ai-trial-course-selector-dialog.component.html'),
	styles: [require('./ai-trial-course-selector-dialog.component.scss')]
})
export class AiTrialCourseSelectorDialogComponent implements OnInit {

	public form: FormGroup;
	public folders: any[];

	public environmentVarsService: EnvironmentVarsService;
	public isCreating$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public createdFolder;

	constructor (
		public dialogRef: MatDialogRef<AiTrialCourseSelectorDialogComponent, AiTrialCourseSelectorResult | undefined>,
		private folderListDataSource: FolderListDataSource,
		private ngxCourseService: NgxCourseService,
		@Inject(groupToken) private group,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(groupCreatorServiceToken) private GroupCreator: GroupCreatorService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public ngOnInit () {
		const selectedGroup = this.selectedService.getGroup();
		const selectedOrg = this.selectedService.getOrg();
		this.form = new FormGroup({
			folder: new FormControl(null, [Validators.required])
		});

		if (this.isLti()) {
			this.folders = [selectedGroup];
			this.form.controls.folder.setValue(selectedGroup.group_id);
		} else {
			this.ngxCourseService.getCourses(selectedOrg.group_id).subscribe({
				next: (response) => {
					this.folders = response.map((folder) => {
						return this.group.model(folder, true);
					}).filter((folder) => {
						return folder.end_date === null ||
							(!!folder.end_date && dayjs(folder.end_date).diff(dayjs(), 'days') > 30);
					});

					if (selectedGroup && this.folders.some((folder) => folder.group_id === selectedGroup.group_id)) {
						this.form.controls.folder.setValue(selectedGroup.group_id);
					}
				}
			});
		}

		this.dialogRef.afterClosed()
			.subscribe((result) => {
				if (this.createdFolder) {
					if (result) {
						this.folderListDataSource.addFolder(this.createdFolder);
					} else {
						this.ngxCourseService.archiveCourse(this.createdFolder.group_id).subscribe();
					}
				}
			});
	}

	public isLti (): boolean {
		return this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI);
	}

	public startTrial () {
		this.form.markAllAsTouched();

		if (this.form.valid) {
			this.dialogRef.close({ folderId: this.form.get('folder').value });
		}
	}

	public close () {
		this.dialogRef.close();
	}

	public openCreateCoursePanel (): void {
		this.isCreating$.next(true);
		const account = this.selectedService.getAccount();
		const userId = this.userService.currentUser.user_id;

		const accountModel = this.group.model(account);
		this.GroupCreator.createCourseFolder(
			accountModel,
			this.userService.currentUser,
			userId,
			false,
			true
		).then((folder: CourseFolder) => {
			this.createdFolder = this.group.model(folder);
			this.folders.push(this.createdFolder);
			this.form.controls.folder.setValue(this.createdFolder.group_id);
		}).finally(() => this.isCreating$.next(false));
	}
}
