import AudioActivityDetectionOptions from './options';
import { UAParser } from 'ua-parser-js'; // For browser detection
import { VolumeIndicatorHelper } from 'ngx/go-modules/src/components/video-scene/volume-indicator/volume-indicator.helper';


type SamplingFinishedCallbackFunction = (averageSample: number) => void;

const AudioContext = window.AudioContext || window.webkitAudioContext; // Needed for Safari

const SAMPLE_INTERVAL = 150;
/**
 * This class is used to determine whether a given media stream has audio
 * activity above a certain threshold. A media stream whose audio activity
 * lies below the specified threshold is considered as having no activity.
 */
export default class AudioActivityDetector {
	private timeoutId: number;
	private detectionPromise: Promise<boolean> = null;
	private options: AudioActivityDetectionOptions = {
		duration: 5000,
		threshold: 0
	};
	private uaParser: any;
	private volumeIndicatorHelper: VolumeIndicatorHelper;
	private totalRuns: number;

	/**
	 * Constructor
	 *
	 * @param stream The media stream
	 * @param options Options for adjusting sensitivity/duration
	 */
	constructor (
		private stream: MediaStream,
		options: AudioActivityDetectionOptions = null
	) {
		Object.assign(this.options, options);
		this.totalRuns = Math.floor(this.options.duration / SAMPLE_INTERVAL);
		this.uaParser = new UAParser();
		this.volumeIndicatorHelper = new VolumeIndicatorHelper();
	}

	/**
	 * Initiates audio activity detection
	 *
	 * @returns {Promise<boolean>}
	 */
	public run (): Promise<boolean> {
		if (!this.detectionPromise) {
			this.detectionPromise = this.startSampling();
		}
		return this.detectionPromise;
	}

	/**
	 * Cancel audio detection
	 */
	public cancel (): void {
		clearTimeout(this.timeoutId);
	}

	private startSampling () {
		this.totalRuns--;

		if (this.totalRuns <= 0) {
			return Promise.resolve(false);
		}

		if (!this.stream.getAudioTracks().length) {
			return Promise.resolve(false);
		}

		if (this.uaParser.getBrowser().name === 'Safari') {
			return Promise.resolve(true);
		}

		return this.sampleAudioActivity(SAMPLE_INTERVAL).then((value: number) => {
			if (value > this.options.threshold) {
				return true;
			}

			return this.startSampling();
		});
	}

	/**
	 * Take multiple samples of the audio activity over a period of time
	 *
	 * @param duration The duration of the audio sampling test
	 * @returns {Promise<number>}
	 */
	private async sampleAudioActivity (duration: number): Promise<number> {
		let processor;
		const samples: number[] = [];
		const audioContext: AudioContext =
			this.options.audioContext || new AudioContext();
		const audioSourceNode: MediaStreamAudioSourceNode = audioContext.createMediaStreamSource(this.stream);

		try {
			processor = new AudioWorkletNode(audioContext, 'audio-processor');
		} catch (err) {
			await audioContext.audioWorklet.addModule(
				URL.createObjectURL(new Blob([this.volumeIndicatorHelper.generateAudioWorkletProcessor()], {type: 'application/javascript'}))
			);
			processor = new AudioWorkletNode(audioContext, 'audio-processor');
		}

		const analyser: AnalyserNode = audioContext.createAnalyser();
		const done = (resolve: SamplingFinishedCallbackFunction): void => {
			const averageSample: number =
				samples.reduce((sum, count) => sum + count, 0) / samples.length;
			analyser.disconnect();
			audioSourceNode.disconnect();
			processor.disconnect();
			resolve(averageSample);
		};

		analyser.fftSize = 512;
		analyser.smoothingTimeConstant = 0.1;
		audioSourceNode.connect(analyser);
		analyser.connect(processor);
		processor.connect(audioContext.destination);

		processor.port.onmessage = () => {
			const dataArray: Uint8Array = new Uint8Array(analyser.frequencyBinCount);
			const count: number = dataArray.length;
			let total: number = 0;
			analyser.getByteFrequencyData(dataArray);
			total = dataArray.reduce((sum, value) => sum + value, 0);
			samples.push(total / count);
		};

		return new Promise((resolve) => {
			this.timeoutId = window.setTimeout(done.bind(this, resolve), duration);
		});
	}
}
