import { Address } from 'go-modules/payment-panel/address-details-form/address-details-form.controller';
import { Avalara as AvalaraService, TaxEstimate, TaxEstimateParams } from '../models/avalara/avalara.service';
import type {
	BraintreePaymentError,
	CardPurchasePayload,
	PaymentValidationError,
	Transaction,
	TransactionStatus
} from '../braintree/braintree.service';
import { BraintreeService as BTService } from '../braintree/braintree.service';
import { Dropin, PaymentMethodPayload } from 'braintree-web-drop-in';
import { GroupService as GroupsService } from '../services/group/group.service';
import { MessageModal } from '../modals/message/modal.factory';
import { Product } from '../services/group/product';
import { TransactionFactory } from 'go-modules/models/transaction/transaction.factory';
import { TransactionMethod, TransactionPaymentType } from 'go-modules/models/transaction/transaction.schema';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';
import { LicenseDetailsForm } from './license-details-form/license-details-form.controller';
import { NgxSelfPayService } from 'ngx/go-modules/src/services/self-pay';
import { PURCHASE_LICENSE_STATUS } from 'ngx/go-modules/src/services/self-pay/license-purchase-status';
import { map, switchMap } from 'rxjs';
import type { LicensePurchasePayload } from 'ngx/go-modules/src/services/self-pay/license-purchase-payload';
import type {
	LicensePurchaseTransactionKey
} from 'ngx/go-modules/src/interfaces/selfpay/license-purchase-transaction-key';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { NgxPurchaseTypeService } from 'ngx/go-modules/src/services/purchase-type/purchase-type.service';
import { NgxPHPMathRoundService } from 'ngx/go-modules/src/services/php-math-round/php-math-round.service';

export enum PAYMENT_PANEL_STATES {
	ENTER_INFORMATION = 'ENTER_INFORMATION',
	CODE = 'CODE',
	REVIEW = 'REVIEW',
	COMPLETE = 'COMPLETE'
}

export enum PAYMENT_TYPES {
	CODE = 'CODE',
	CARD = 'CARD',
	REQUEST_INVOICE = 'REQUEST_INVOICE'
}

export enum PURCHASE_TYPES {
	COURSE = 'COURSE',
	LICENSE = 'LICENSE'
}

export enum LICENSE_TRANSACTION_TYPE {
	INITIAL = 'initial',
	SEATS = 'seats',
	UPGRADE = 'upgrade',
	TRIAL_END = 'trial_end',
}

export interface PaymentDeclineModalData {
	heading: string;
	errorMessage: string;
	resolveBtnText: string;
	callback?: () => void;
}

export interface PaymentOptionsModalData {
	user: any;
	group: any;
	license?: any|null;
	type: PAYMENT_TYPES;
	purchaseType: PURCHASE_TYPES;
	licenseTransactionType: LICENSE_TRANSACTION_TYPE|null;
}

export const TIMEOUT_STATUS = 0;

const PAYMENT_STATE_ANALYTIC_EVENT_MAP = {
	[PAYMENT_PANEL_STATES.ENTER_INFORMATION]: 'enter-payment-information',
	[PAYMENT_PANEL_STATES.REVIEW]: 'review-payment-information',
	[PAYMENT_PANEL_STATES.CODE]: 'enter-code'
};

export const PAYMENT_PANEL_REQUEST_PAY_METHOD = 'PAYMENT_PANEL_REQUEST_PAY_METHOD';

export class PaymentPanelController implements Partial<ng.ui.bootstrap.IModalScope> {

	public $close: (result?: any) => boolean;
	public $dismiss: (result?: any) => boolean;

	// Options passed in to panel service
	public options: any;
	public states = PAYMENT_PANEL_STATES;
	public types = PAYMENT_TYPES;
	public currentState: PAYMENT_PANEL_STATES;
	public dropinInstance: Dropin = null;
	public paying = false;
	public products: Product[];
	public selectedProduct: Product;
	public payload: PaymentMethodPayload;
	public taxEstimate: TaxEstimate = null;
	public reviewPromises: ng.IPromise<[
		ng.IPromiseResult<PaymentMethodPayload>,
		ng.IPromiseResult<TaxEstimate>
	]> = null;
	public requestInvoicePromise: ng.IPromise<any> | null = null;
	public address: Address = {
		line1: '',
		line2: '',
		city: '',
		region: '',
		postal_code: '',
		country: ''
	};
	public licenseDetailsForm: LicenseDetailsForm;
	public licenseCode: string;
	public transaction: Transaction;
	public paymentForm: ng.IFormController;
	public reChecking = false;
	public reCheckingRetry = 6;
	public transactionStartTime;
	public environmentVarsService: EnvironmentVarsService;
	public licensePurchaseKey;
	public isTaxExempt: boolean;

	/* @ngInject */
	constructor (
		public ngxPurchaseTypeService: NgxPurchaseTypeService,
		private $scope: ng.IScope,
		private $filter: ng.IFilterService,
		private GroupService: GroupsService,
		private BraintreeService: BTService,
		private Avalara: AvalaraService,
		private messageModal: MessageModal,
		private $analytics: angulartics.IAnalyticsService,
		private $log: ng.ILogService,
		private $q: ng.IQService,
		private $anchorScroll: ng.IAnchorScrollService,
		private $timeout,
		private $location,
		private $window,
		private TransactionModel: TransactionFactory,
		private ngxSelfPayService: NgxSelfPayService,
		private UseTypeModel,
		private Group,
		private selectedService: SelectedService,
		private ngxGoToastService: NgxGoToastService,
		private ngxPHPMathRoundService: NgxPHPMathRoundService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public $onInit () {
		this.currentState = this.options.type === PAYMENT_TYPES.CODE ? this.states.CODE : this.states.ENTER_INFORMATION;

		if (this.ngxPurchaseTypeService.isCoursePurchase(this.options)) {
			this.getProducts(this.options.group.group_id);
		}
	}

	/**
	 * Cancelling will dismiss this panel
	 */
	public cancel (): void {
		// Payment not completed. Caller handles cancel.
		this.$analytics.eventTrack('cancel', {
			category: PAYMENT_STATE_ANALYTIC_EVENT_MAP[this.currentState]
		});

		this.$dismiss();
	}

	public cancelOrClose () {
		if (this.currentState === this.states.COMPLETE) {
			if (this.ngxPurchaseTypeService.isLicenseSeatsPurchase(this.options)) {
				return this.reloadPage();
			} else {
				return this.close();
			}
		}

		this.cancel();
	}

	public selectedProductChanged (product: Product) {
		this.selectedProduct = product;
	}

	public payFormHasLoaded (dropinInstance: Dropin) {
		this.dropinInstance = dropinInstance;
		this.safeDigest();
	}

	public addressChanged (address: Address) {
		this.address = address;
	}

	public handleEditPayment () {
		this.currentState = this.states.ENTER_INFORMATION;
	}

	public onCodeChange (code) {
		this.licenseCode = code;
	}

	public shouldDisableNextButton () {
		return this.reviewPromises != null || this.dropinInstance == null;
	}

	public shouldDisableSendButton () {
		return this.requestInvoicePromise != null;
	}

	public shouldDisableSubmitButton () {
		// We're redeeming with code. Disable if no code.
		if (this.options.type === PAYMENT_TYPES.CODE) {
			return !this.licenseCode || this.paying;
		}
		// Otherwise, only disable if paying
		return this.paying;
	}

	public getPanelHeaderTextTranslation (): string {
		switch (this.currentState) {
			case this.states.CODE:
				return 'payment-panel-header_enter-code';
			case this.states.ENTER_INFORMATION:
				if (this.options.type === PAYMENT_TYPES.REQUEST_INVOICE) {
					return 'payment-panel-invoice-request';
				}

				if (this.ngxPurchaseTypeService.isLicenseRenewal(this.options)) {
					return 'payment-panel-header_renew-license';
				}

				if (this.ngxPurchaseTypeService.isLicenseSeatsPurchase(this.options)) {
					return 'payment-panel-license-details_license-seats-additional-label';
				}

				if (this.ngxPurchaseTypeService.isLicenseUpgradePurchase(this.options)) {
					return 'payment-panel-header_upgrade-license';
				}

				return 'payment-panel-header_enter-payment-information';
			case this.states.REVIEW:
				if (this.ngxPurchaseTypeService.isLicenseRenewal(this.options) ||
				this.ngxPurchaseTypeService.isLicenseSeatsPurchase(this.options)) {
					return'payment-panel-header_renew-license-review-payment-information';
				}
				return 'payment-panel-header_review-payment-information';
			case this.states.COMPLETE:
				return 'payment-panel-header_payment-complete';
		}
	}

	public attemptReview () {
		if (this.paymentForm.$invalid) {
			this.$anchorScroll('address-form-bottom');
			return;
		}

		this.taxEstimate = null;
		const payMethodPromise = this.BraintreeService.requestPaymentMethod(this.dropinInstance);
		const taxEstimateParams: TaxEstimateParams = this.buildTaxEstimateParams();

		const taxEstimatePromise = this.Avalara.getSalesTaxEstimate(taxEstimateParams);

		this.reviewPromises = this.$q.allSettled([payMethodPromise, taxEstimatePromise]);
		this.reviewPromises.then(([payMethod, taxEstimate]) => {
			if (payMethod.state === 'rejected') {
				return this.messageModal.open({
					name: 'payment-declined-modal',
					modalData: {
						title: this.$filter('translate')('payment-panel-payment-error_payment-processing-error'),
						message: this.$filter('translate')('payment-panel-payment-error-description'),
						resolveBtnText: this.$filter('translate')('common_continue'),
						resolveBtnClass: 'primary-btn'
					}
				});
			}

			if (taxEstimate.state === 'rejected' && (taxEstimate.reason as any).status === 504) {
				// If AvaTax is down, make a fake tax-free estimate
				this.taxEstimate = {
					subtotal: this.selectedProduct.price,
					total: this.selectedProduct.price,
					tax_amount: '0.00',
					transactionProducts: taxEstimateParams.transactionProducts.map((lineItem) => {
						return {
							line_subtotal: lineItem.line_subtotal,
							line_tax_amount: '0.00',
							product_id: lineItem.product_id,
							sort_order: lineItem.sort_order
						};
					})
				};
			} else if (taxEstimate.state === 'rejected') {
				return this.showAddressErrorModal(taxEstimate.reason);
			} else {
				this.taxEstimate = taxEstimate.value;
			}

			this.payload = payMethod.value;
			this.currentState = this.states.REVIEW;
			this.safeDigest();

		}).finally(() => {
			this.reviewPromises = null;
		});
	}

	public requestInvoice () {
		if (this.paymentForm.$invalid) {
			this.$anchorScroll('address-form-bottom');
			return;
		}

		const taxEstimateParams: TaxEstimateParams = this.buildTaxEstimateParams();
		taxEstimateParams.tax_exempt = this.isTaxExempt;

		this.requestInvoicePromise =  this.Avalara.getSalesTaxEstimate(taxEstimateParams)
			.then((taxEstimate) => {
				const groupId = this.options.group.group_id;
				const payload = {
					user_id: this.options.user.user_id,
					tax_amount: taxEstimate.tax_amount,
					total: taxEstimate.total,
					subtotal: taxEstimate.subtotal,
					address: this.address,
					transactionProducts: taxEstimate.transactionProducts,
					tax_exempt: this.isTaxExempt
				} as any;

				if (this.ngxPurchaseTypeService.isLicensePurchase(this.options)) {
					payload.license_name = this.licenseDetailsForm.licenseName;
					payload.starts_at = this.licenseDetailsForm.licenseStartDate;

					if (this.ngxPurchaseTypeService.isLicenseRenewal(this.options)) {
						payload.license_parent_id = this.options.license.license_id;
					}
				}

				this.ngxSelfPayService.requestInvoice(groupId, payload).subscribe({
					next: () => {
						this.requestInvoicePromise = null;
						this.ngxGoToastService.createToast({
							type: GoToastStatusType.SUCCESS,
							message: 'payment-panel-invoice-request-complete'
						});
						this.$dismiss();
					},
					error: () => {
						this.requestInvoicePromise = null;
						this.messageModal.open({
							name: 'request-invoice-error-modal',
							modalData: {
								message: this.$filter('translate')('payment-panel-invoice-request_modal-message'),
								resolveBtnClass: 'primary-btn',
								resolveBtnText: this.$filter('translate')('common_ok'),
								title: this.$filter('translate')('payment-panel-invoice-request')
							}
						});
					}
				});
			}).catch((response) => {
				this.requestInvoicePromise = null;
				this.showAddressErrorModal(response);
			});
	}

	public submitPayment () {
		switch (this.options.type) {
			case PAYMENT_TYPES.CARD:
				this.payWithCard();
				break;
			case PAYMENT_TYPES.CODE:
				this.payWithCode();
				break;
		}
	}

	public close () {
		// Payment successful. Caller handles success.
		this.$close(this.transaction);
	}

	public reloadPage () {
		this.$window.location.reload();
	}

	public handleCodeError (error?: PaymentValidationError) {
		let reasonStr;
		if (!error) {
			// This means we had a network issue or unknown issue
			reasonStr = this.$filter('translate')('payment-panel-payment-error_unknown-error');
		} else if (error.errors.code) {
			reasonStr = this.$filter('translate')('payment-panel-code-error_code-invalid');
		} else {
			const reasons = Object.keys(error.errors).map((key) => {
				return error.errors[key].join(', ');
			});
			reasonStr = reasons.join(', ');
		}
		this.messageModal.open({
			name: 'code-redeem-error-modal',
			modalData: {
				title: this.$filter('translate')('payment-panel-code-error_code-redeem-declined'),
				message: reasonStr,
				resolveBtnText: !error ? this.$filter('translate')('common_continue') : this.$filter('translate')('payment-panel-payment-error_start-over'),
				resolveBtnClass: 'primary-btn'
			}
		}).result.then(() => {
			// Start over if there wasn't a network error
			if (!error) {
				return this.$dismiss();
			}

			this.paying = false;
		});
	}

	public handleBraintreeError (error: BraintreePaymentError | PaymentValidationError | null) {
		if (error != null && 'result' in error) {
			// This means Braintree has returned a known error code
			const key = Object.keys(error.result)[0];
			const parsedKey = parseInt(key, 10);
			const { title, message } = this.getBraintreeErrorContent(parsedKey);

			this.paymentDeclineModal({
				heading: title,
				errorMessage: message,
				resolveBtnText: 'payment-panel-payment-error_start-over',
				callback: () => {
					this.currentState = this.states.ENTER_INFORMATION;
					this.paying = false;
				}
			});
		} else {
			this.paymentDeclineModal({
				heading: 'payment-panel-payment-error_payment-processing-error',
				errorMessage:'payment-panel-payment-error_payment-processing-error',
				resolveBtnText: 'payment-panel-payment-error_start-over',
				callback: () => {
					this.currentState = this.states.ENTER_INFORMATION;
					this.paying = false;
					this.reChecking = false;
				}
			});
		}
	}

	// Re-checking if the payment is already been made
	// Because some browser stop listening to response because of it`s default timeout

	public startRecheckingPaymentStatus () {
		this.BraintreeService
			.getPaymentTransactionStatus(this.options.group.group_id)
			.then((data: TransactionStatus) => {
				this.transactionStartTime = data.startAt;
				this.reCheckingPayment();
			})
			.catch(() => {
				this.$timeout(() => this.startRecheckingPaymentStatus(), 1000);
			});
	}

	public reCheckingPayment () {
		this.reChecking = true;
		const delay = this.reCheckingRetry === 6 ? 5000: 20000;

		this.$timeout(() => {
			this.TransactionModel.getTransactions(
				this.options.user.user_id,
				[TransactionMethod.CARD],
				[TransactionPaymentType.PURCHASE],
				this.options.group.group_id,
				this.transactionStartTime
			).then((transactions) => {
				if (transactions.length > 0) {
					this.transaction = transactions[0] as Transaction;
					this.currentState = this.states.COMPLETE;
					this.paying = false;

					return;
				}

				if (--this.reCheckingRetry > 0) {
					this.reCheckingPayment();
					return;
				}

				this.reCheckingRetry = 6;

				this.paymentDeclineModal({
					heading: 'browser-timeout-title',
					errorMessage:'browser-timeout-message',
					resolveBtnText: 'common_refresh-page',
					callback: () => {
						const environment = this.environmentVarsService
							.get(EnvironmentVarsService.VAR.ENVIRONMENT) as any;
						const isLtiEnvironment = environment.name === ENVIRONMENTS.LTI;

						if (isLtiEnvironment) {
							this.$location.path('/').search({});
							this.$timeout(() => this.$window.location.reload(), 1);
						} else {
							this.$window.location.reload();
						}
					}
				});
			}).catch(() => this.reCheckingPayment());
		}, delay);
	}

	public paymentDeclineModal ({heading, errorMessage, resolveBtnText, callback}: PaymentDeclineModalData) {
		this.messageModal.open({
			name: 'payment-declined-modal',
			modalData: {
				title: this.$filter('translate')(heading),
				message: this.$filter('translate')(errorMessage),
				resolveBtnText: this.$filter('translate')(resolveBtnText),
				resolveBtnClass: 'primary-btn'
			}
		}).result.then(() => {
			if (callback) {
				callback();
			}
		});
	}

	public getAddressErrorMessaging (code: string, description?: string) {
		let title, message;

		switch (code) {
			case 'AddressRangeError':
				title = 'payment-panel-address-error_address-range-error-title';
				message = 'payment-panel-address-error_address-range-error-message';
				break;
			case 'AddressUnknownStreetError':
				title = 'payment-panel-address-error_address-unknown-street-error-title';
				message = 'payment-panel-address-error_address-unknown-street-error-message';
				break;
			case 'TaxAddressError':
				title = 'payment-panel-address-error_tax-address-error-title';
				message = 'payment-panel-address-error_tax-address-error-message';
				break;
			case 'CountryError':
				title = 'payment-panel-address-error_country-error-title';
				message = 'payment-panel-address-error_country-error-message';
				break;
			case 'MaxStringLengthError':
				title = 'payment-panel-address-error_address-range-error-title';
				message = description || 'payment-panel-address-error_address-range-error-message';
				break;
			case 'InsufficientAddressError':
				title = 'payment-panel-address-error_insufficient-address-error-title';
				message = 'payment-panel-address-error_insufficient-address-error-message';
				break;
			case 'InvalidZipForStateError':
				title = 'payment-panel-address-error_invalid-zip-for-state-error-title';
				message = 'payment-panel-address-error_invalid-zip-for-state-error-message';
				break;
			case 'PostalCodeError':
				title = 'payment-panel-address-error_postal-code-error-title';
				message = 'payment-panel-address-error_postal-code-error-message';
				break;
			case 'RegionCodeError':
				title = 'payment-panel-address-error_region-code-error-title';
				message = 'payment-panel-address-error_region-code-error-message';
				break;
			case 'ZipNotValidError':
				title = 'payment-panel-address-error_zip-not-valid-error-title';
				message = 'payment-panel-address-error_zip-not-valid-error-message';
				break;
			case 'CityError':
				title = 'payment-panel-address-error_city-error-title';
				message = 'payment-panel-address-error_city-error-message';
				break;
			case 'MultipleAddressMatchError':
				title = 'payment-panel-address-error_multiple-address-match-error-title';
				message = 'payment-panel-address-error_multiple-address-match-error-message';
				break;
			case 'AddressEarlyWarningSystemError':
				title = 'payment-panel-address-error_address-early-warning-system-error-title';
				message = 'payment-panel-address-error_address-early-warning-system-error-message';
				break;
			case 'NonDeliverableAddressError':
				title = 'payment-panel-address-error_non-deliverable-address-error-title';
				message = 'payment-panel-address-error_non-deliverable-address-error-message';
				break;
			case 'JurisdictionNotFoundError':
			case 'JurisdictionError':
			case 'DestinationJurisdictionError':
			case 'OriginJurisdictionError':
				title = 'payment-panel-address-error_jurisdiction-errors-title';
				message = 'payment-panel-address-error_jurisdiction-errors-message';
				break;
			default:
				title = 'payment-panel-address-error_address-range-error-title';
				message = 'payment-panel-address-error_address-range-error-message';
		}

		return {title, message};
	}

	public getBraintreeErrorContent (code: number) {
		let title, message;

		switch (code) {
			case 2001:
				title = 'payment-panel-payment-error_payment-declined-insufficient-funds';
				message = 'payment-panel-payment-error_payment-declined-use-different-method';
				break;
			case 2002:
			case 2003:
				title = 'payment-panel-payment-error_payment-declined-limit-exceeded';
				message = 'payment-panel-payment-error_payment-declined-default-message';
				break;
			case 2004:
				title = 'payment-panel-payment-error_payment-declined-expired-card';
				message = 'payment-panel-payment-error_payment-declined-use-different-method';
				break;
			case 2005:
				title = 'payment-panel-payment-error_payment-declined-invalid-card-number';
				message = 'payment-panel-payment-error_payment-declined-please-correct-info';
				break;
			case 2006:
				title = 'payment-panel-payment-error_payment-declined-invalid-expiration';
				message = 'payment-panel-payment-error_payment-declined-please-correct-info';
				break;
			case 2008:
				title = 'payment-panel-payment-error_payment-declined-card-account-length';
				message = 'payment-panel-payment-error_payment-declined-please-correct-info';
				break;
			case 2010:
				title = 'payment-panel-payment-error_payment-declined-card-cvv';
				message = 'payment-panel-payment-error_payment-declined-please-correct-info';
				break;
			case 2016:
				title = 'payment-panel-payment-error_payment-declined-duplicate-transaction';
				message = 'payment-panel-payment-error_payment-declined-one-transaction-at-a-time';
				break;
			default:
				title = 'payment-panel-payment-error_payment-declined-default-heading';
				message = 'payment-panel-payment-error_payment-declined-default-message';
		}
		return { title, message };
	}

	public togglePaymentMode () {
		if (this.options.type === PAYMENT_TYPES.REQUEST_INVOICE) {
			this.options.type = PAYMENT_TYPES.CARD;
		} else {
			this.options.type = PAYMENT_TYPES.REQUEST_INVOICE;
		}
	}

	public getPaymentModeTextTranslation () {
		if (this.options.type === PAYMENT_TYPES.REQUEST_INVOICE) {
			return 'payment-panel-footer-switch-mode-payment-label';
		}
		return 'payment-panel-footer-switch-mode-invoice-label';
	}

	private buildTaxEstimateParams (): TaxEstimateParams {
		if (this.ngxPurchaseTypeService.isLicensePurchase(this.options)) {
			const transaction = {
				subtotal: this.licenseDetailsForm.subtotal,
				user_id: this.options.user.user_id,
				address: this.address,
				transactionProducts: [{
					line_subtotal: this.ngxPHPMathRoundService.round(
						this.licenseDetailsForm.qty *
						(+this.licenseDetailsForm.selectedProduct.price) *
						(this.licenseDetailsForm.prorate ?? 1),
						2
					),
					product_id: this.licenseDetailsForm.selectedProduct.product_id,
					qty: this.licenseDetailsForm.qty,
					sort_order: 1
				}]
			};

			if (this.ngxPurchaseTypeService.isLicenseUpgradePurchase(this.options)) {
				transaction.transactionProducts.push(
					{
						line_subtotal: this.ngxPHPMathRoundService.round(
							this.licenseDetailsForm.qty *
							(this.options.license.license.licenseProduct.product.price * -1) *
							(this.licenseDetailsForm.prorate ?? 1),
							2
						),
						product_id: this.options.license.license.licenseProduct.product.product_id,
						qty: this.licenseDetailsForm.qty,
						sort_order: 1
					}
				);
			}

			return transaction;
		} else {
			return {
				subtotal: this.selectedProduct.price,
				user_id: this.options.user.user_id,
				address: this.address,
				transactionProducts: [{
					line_subtotal: this.selectedProduct.price,
					product_id: this.selectedProduct.product_id,
					qty: 1,
					sort_order: 1
				}]
			};
		}
	}

	private showAddressErrorModal (reason) {
		const isUsersFault = reason.status >= 400 && reason.status < 500;

		// Get fault sub code to determine exact messaging
		const faultSubCode = reason.data?.error?.details[0]?.faultSubCode;
		const summaryCode = reason.data?.error?.details[0]?.code;
		const code = faultSubCode || summaryCode || null;

		const { title, message } = this.getAddressErrorMessaging(code, reason.data?.error?.details[0]?.description);

		const errorTitle = isUsersFault ?
			title : 'payment-panel-address-error-title';
		const errorMessage = isUsersFault ?
			message : 'payment-panel-unknown-error-description';

		const resolveBtnText = isUsersFault ?
			'payment-panel-address-error-button' :
			'common_continue';

		const modal = this.messageModal.open({
			name: 'address-error-modal',
			modalData: {
				message: this.$filter('translate')(errorMessage),
				resolveBtnClass: 'primary-btn',
				resolveBtnText: this.$filter('translate')(resolveBtnText),
				title: this.$filter('translate')(errorTitle)
			}
		});

		if (!isUsersFault) {
			modal.result.then(() => {
				return this.$dismiss();
			});
		}

		return modal;
	}

	private payWithCard () {
		this.paying = true;

		if (this.ngxPurchaseTypeService.isLicenseInitialPurchase(this.options)) {
			this.licensePurchase();
		} else if (this.ngxPurchaseTypeService.isLicenseModificationPurchase(this.options)) {
			this.licenseUpgradePurchase();
		} else {
			this.courseCardPurchase();
		}
	}

	private payWithCode () {
		this.paying = true;
		this.BraintreeService.codePurchase(this.options.group.group_id, this.licenseCode)
			.then((result) => {
				this.transaction = result;
				this.close();
			}).catch((err) => {
				this.$log.error(err);
				this.handleCodeError(err.data);
			});
	}

	private async getProducts (groupId: number) {
		this.GroupService.getProducts(groupId).then((products) => this.products = products);
	}

	private startRecheckingPurchaseLicenseStatus () {
		const timeoutDelay = 2000;
		this.reChecking = true;

		this.ngxSelfPayService.getPurchaseLicenseStatus(
			this.options.group.group_id,
			this.licensePurchaseKey
		).pipe(map((result) => {
			switch(result.status) {
				case PURCHASE_LICENSE_STATUS.PENDING:
					throw result;
				case PURCHASE_LICENSE_STATUS.COMPLETED:
					return result.transaction;
				case PURCHASE_LICENSE_STATUS.FAILED:
					throw result;
			}
		})).subscribe({
			next: (result) => {
				this.transaction = result;
				this.currentState = this.states.COMPLETE;
				this.paying = false;
				this.reChecking = false;
			},
			error: (err) => {
				if (err.status <=  TIMEOUT_STATUS || err.status === PURCHASE_LICENSE_STATUS.PENDING) {
					this.$timeout(() => {
						this.startRecheckingPurchaseLicenseStatus();
					}, timeoutDelay);
				} else {
					this.paymentDeclineModal({
						heading: 'payment-panel-payment-error_payment-processing-error',
						errorMessage:'payment-panel-payment-error_payment-processing-error',
						resolveBtnText: 'payment-panel-payment-error_start-over',
						callback: () => {
							this.currentState = this.states.ENTER_INFORMATION;
							this.paying = false;
							this.reChecking = false;
						}
					});
				}
			}
		});
	}

	private licensePurchase () {
		const payload: LicensePurchasePayload = this.createCardPurchasePayload() as LicensePurchasePayload;

		payload.license_name = this.licenseDetailsForm.licenseName;
		payload.starts_at = this.licenseDetailsForm.licenseStartDate;
		if (this.options.license) {
			payload.license_parent_id = this.options.license.license_id;
		}

		const account = this.Group.model(this.options.group);
		const request = this.ngxSelfPayService
			.generateLicensePurchaseTransactionKey(account.group_id).pipe(
				switchMap((licensePurchaseKey: LicensePurchaseTransactionKey) => {
					this.licensePurchaseKey = licensePurchaseKey;
					payload.transactionKey = this.licensePurchaseKey.key;

					return this.ngxSelfPayService.purchaseLicense(
						account.group_id,
						payload
					);
				})
			);

		// If selected org user don`t have account yet, then will create one for it.
		request.subscribe({
			next: (transaction: Transaction) => {
				this.transaction = transaction;
				this.currentState = this.states.COMPLETE;
				this.paying = false;
				this.safeDigest();
			},
			error: (err) => {
				this.$log.error(err);

				// Browser timed out but the backend still processing the request
				if (err.status <= TIMEOUT_STATUS) {
					this.startRecheckingPurchaseLicenseStatus();
				} else {
					this.handleBraintreeError(err.data);
				}
			}
		});
	}

	private licenseUpgradePurchase () {
		const payload: LicensePurchasePayload = this.createCardPurchasePayload() as LicensePurchasePayload;

		// If a user without an account group buys additional seats right after purchasing a license without
		// refreshing the page, "this.options.group" will still be null.
		const groupId = this.options.group?.group_id ?? this.selectedService.getAccount().group_id;

		this.ngxSelfPayService.upgradeLicense(
			groupId,
			this.options.license.license_id,
			payload)
			.subscribe({
				next: (transaction) => {
					this.transaction = transaction;
					this.currentState = this.states.COMPLETE;
					this.paying = false;
				},
				error: (err) => {
					this.currentState = this.states.ENTER_INFORMATION;
					this.paying = false;
					this.handleBraintreeError(err.data);
				}
			});
	}

	private courseCardPurchase () {
		const payload: CardPurchasePayload = this.createCardPurchasePayload();

		this.BraintreeService.cardPurchase(this.options.group.group_id, payload).then((transaction) => {
			this.transaction = transaction;
			this.currentState = this.states.COMPLETE;
			this.paying = false;
		}).catch((err) => {
			this.$log.error(err);

			if (!this.reChecking && err.status <= TIMEOUT_STATUS) {
				this.startRecheckingPaymentStatus();
			} else {
				this.handleBraintreeError(err.data);
			}
		});
	}

	private createCardPurchasePayload (): CardPurchasePayload {
		const payload: CardPurchasePayload = {
			nonce: this.payload.nonce,
			user_id: this.options.user.user_id,
			tax_amount: this.taxEstimate.tax_amount,
			total: this.taxEstimate.total,
			subtotal: this.taxEstimate.subtotal,
			address: this.address,
			transactionProducts: this.taxEstimate.transactionProducts
		};

		return payload;
	}

	private safeDigest () {
		// eslint-disable-next-line angular/no-private-call
		const phase = this.$scope.$root.$$phase || this.$scope.$$phase;
		if (!phase) {
			this.$scope.$digest();
		}
	}
}
