import * as angular from 'angular';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

export enum ORIENTATION {
	LANDSCAPE = 'horizontal',
	PORTRAIT = 'vertical'
};

/* @ngInject */
export const BgSplitterController = function (
	$q: ng.IQService,
	$scope: ng.IScope,
	$element: ng.IRootElementService,
	$attrs: ng.IAttributes,
	$timeout: ng.ITimeoutService,
	$debounce: ng.IDebounceService,
	eventService: EventService
) {
	const vm = this;
	vm.isReady = false;

	let bounds, orientation;

	/**
	 * Handle controller init
	 */
	vm.$onInit = () => {
		// When this splitter's bounds change,
		// we must trigger a resize event.
		$scope.$watch(() => {
			const watchedBounds = $element[0].getBoundingClientRect();
			return watchedBounds.width + watchedBounds.height;
		}, $debounce<void, any>(() => {
			if (vm.isReady) {
				vm.triggerResize();
			}
		}));
	};

	/**
	 * Handle post link
	 */
	vm.$postLink = () => {
		const deferred = $q.defer();

		// Once deferred object is resolved, we are ready to go
		deferred.promise.then(() => {
			vm.isReady = true;
		});

		// Run update before we do bounds check
		update();

		// Sometimes the width and height aren't
		// calculated yet, so we need to delay.
		if (bounds.width > 0 && bounds.height > 0) {
			deferred.resolve();
		} else {
			$timeout(() => {
				deferred.resolve();
			});
		}
	};

	/**
	 * Get container id
	 *
	 * @returns {string}
	 */
	vm.getId = () => {
		return $element.attr('id');
	};

	/**
	 * Get container bounds
	 *
	 * @returns {object}
	 */
	vm.getBounds = () => {
		return bounds;
	};

	/**
	 * Get container orientation
	 *
	 * @returns {string}
	 */
	vm.getOrientation = () => {
		return orientation;
	};

	/**
	 * Whether orientation is landscape
	 *
	 * @returns {boolean}
	 */
	vm.isLandscape = () => {
		return orientation === ORIENTATION.LANDSCAPE;
	};

	/**
	 * Whether orientation is portrait
	 *
	 * @returns {boolean}
	 */
	vm.isPortrait = () => {
		return orientation === ORIENTATION.PORTRAIT;
	};

	/**
	 * Get min size
	 *
	 * @returns {number}
	 */
	vm.getMinSize = () => {
		return parseInt(vm.minSize, 10) || 0;
	};

	/**
	 * Update
	 */
	vm.update = update;

	/**
	 * Trigger resize event on all children split handles
	 */
	vm.triggerResize = () => {
		const bgHandles = $element[0].querySelectorAll('bg-handle');
		for (const el of bgHandles) {
			angular.element(el).triggerHandler('resize-split-handle');
		}
		eventService.broadcast(EVENT_NAMES.SPLITTER_RESIZE);
	};

	/**
	 * Update
	 */
	function update () {
		updateBounds();

		if ($attrs.id === 'main-splitter') { // in feedback-session-content component
			orientation = vm.isMobile ? ORIENTATION.PORTRAIT : ORIENTATION.LANDSCAPE;
		} else {
			updateOrientation();
		}

		// update orientation class
		$element.removeClass(ORIENTATION.LANDSCAPE);
		$element.removeClass(ORIENTATION.PORTRAIT);
		$element.addClass(orientation);
	}

	/**
	 * Update container bounds
	 */
	function updateBounds () {
		bounds = $element[0].getBoundingClientRect();
	}

	/**
	 * Update orientation
	 */
	function updateOrientation () {
		orientation = bounds.width >= bounds.height ? ORIENTATION.LANDSCAPE : ORIENTATION.PORTRAIT;
	}
};
