import { ChangeDetectionStrategy, Component, ElementRef, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { emailValidator } from 'ngx/go-modules/src/form-validator/email.validator/email.validator';
import {
	EmailVerificationDialogComponent
} from 'ngx/go-modules/src/components/email-verification/email-verification-dialog/email-verification-dialog.component';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import angular from 'angular';

export interface ChangeEmailDialogData {
	user: any;
	sessionId?: string;
};

@Component({
	selector: 'change-email-dialog',
	template: require('./change-email-dialog.component.html'),
	styles: [require('./change-email-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeEmailDialogComponent {

	public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public error: any;
	public form: FormGroup;

	constructor (
		public dialogRef: MatDialogRef<ChangeEmailDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ChangeEmailDialogData,
		private elementRef: ElementRef,
		private eventService: EventService,
		private modal: GoModalService,
		private ngxAuthService: NgxAuthService,
		private ngxGoToastService: NgxGoToastService,
		@Inject('Window') private window: Window
	) {}

	public ngOnInit () {
		this.form = new FormGroup({
			newEmail: new FormControl(null, [Validators.required, emailValidator()]),
			password: new FormControl(null, [Validators.required])
		});
	}

	public submit (form) {
		if (form.invalid) {
			return;
		}
		this.loading$.next(true);
		this.error = null;

		this.ngxAuthService.checkPassword(form.get('password').value).subscribe({
			next: (result) => {
				if (!result.passwordMatched) {
					this.loading$.next(false);
					this.form.get('password').setErrors({ incorrect: true });
					this.elementRef.nativeElement.querySelector('.password input').focus();
					return;
				}

				const dialogRef = this.modal.open(EmailVerificationDialogComponent, false, {
					data: {
						email: form.get('newEmail').value
					}
				});
				dialogRef.afterClosed().subscribe((value) => {
					if (value) {
						this.changeEmail(value.email_signature);
					} else {
						this.loading$.next(false);
					}
				});
			},
			error: (response) => {
				this.loading$.next(false);

				if (response.status === 401) {
					const options = {
						accountLockTime: response.error.remainingAccountLockSeconds
					};
					return this.ngxAuthService.logout(options).then(() => {
						this.dialogRef.close({success: false});

						if (this.data.sessionId) {
							this.eventService.broadcast(EVENT_NAMES.ACCOUNT_LOCKED, options);
						} else {
							this.window.location.replace(`https://${this.window.location.host}/dashboard/auth/login/`);
						}
					});
				} else {
					this.error = response.error;
				}
			}
		});
	}

	public close () {
		this.dialogRef.close();
	}

	private changeEmail (emailSignature) {
		const user = angular.copy(this.data.user);
		user.email = this.form.get('newEmail').value;
		user.password = this.form.get('password').value;

		this.ngxAuthService.changeEmail(user.user_id, {
			email: user.email,
			password: user.password,
			email_signature: emailSignature
		}).subscribe({
			next: (_response) => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: 'common-save-success'
				});
				this.dialogRef.close(user);
			},
			error: (response) => {
				this.loading$.next(false);
				this.error = response.error;
			}
		});
	}
}
