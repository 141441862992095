import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'session-analytic-item-view',
	template: require('./session-analytic-item-view.component.html'),
	styles: [require('./session-analytic-item-view.component.scss')]
})
export class SessionAnalyticItemViewComponent {
	@Input() public title: string;
	@Input() public dataSummary;
	@Input() public isExpanded = false;
	@Input() public shouldTease?: boolean = false;
	@Output() public onExpanded = new EventEmitter<boolean>();

	constructor () {}

	public toggleExpand () {
		this.isExpanded = !this.isExpanded;
		this.onExpanded.emit(this.isExpanded);
	}
}
