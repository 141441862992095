import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';
import { ActivityInstructionModel } from '../models/activity';
import { Marker, MarkerSet } from 'ngx/go-modules/src/interfaces/markers/marker';

export class ActivityInfoController {

	// bindings
	public activity: any;
	public group: any;
	public hideSourceMedia: boolean;
	public hideRecordingInstructions: boolean;
	public hideFeedbackInstructions: boolean;
	public isOnDashboard: boolean;
	public session: any;

	public markerSet: MarkerSet;
	public aiMarkers: Marker[];

	/* @ngInject */
	constructor (
		private ActivityModel,
		private Group,
		private rubricEditorModal,
		private $translate,
		private ActivityTemplateModel,
		private mediaPreviewModal,
		private MediaModel,
		private TagSetModel: any,
		private ngxDowngradeModalService: DowngradeModalService
	) {}

	/**
	 * Handles init life cycle hook
	 */
	public $onInit () {
		if (!(this.activity instanceof this.ActivityModel)) {
			throw new Error('Parameter `activity` is required by ActivityInfoController');
		} else if (!(this.group instanceof this.Group.GroupModel)) {
			throw new Error('Parameter `group` is required by ActivityInfoController');
		}

		this.markerSet = this.determineSelectedMarkerSet();
		this.aiMarkers = this.activity.ai_prompts.map((prompt) => {
			return prompt.tag;
		}) as Marker[];
	}

	public somethingToShow () {
		return this.showSourceMedia()
			|| this.showInstructions(ActivityInstructionModel.TYPE.RECORDING)
			|| this.showInstructions(ActivityInstructionModel.TYPE.FEEDBACK)
			|| this.showAttachments();
	}

	public showSourceMedia () {
		return this.activity.hasSourceMedia() &&
			this.activity.source_media.media_id > 0 &&
			!this.hideSourceMedia;
	}

	public showInstructions (type) {
		if (type === ActivityInstructionModel.TYPE.RECORDING) {
			return this.activity.hasRecordingInstructions() && !this.hideRecordingInstructions;
		} else if (type === ActivityInstructionModel.TYPE.FEEDBACK) {
			return this.activity.hasFeedbackInstructions() &&
				!this.hideFeedbackInstructions &&
				this.session &&
				this.session.isRecorded();
		}
	}

	public showTextInstructions (type) {
		if (type === ActivityInstructionModel.TYPE.RECORDING) {
			return this.showInstructions(type) && this.activity.recording_instructions.text;
		} else if (type === ActivityInstructionModel.TYPE.FEEDBACK) {
			return this.showInstructions(type) && this.activity.feedback_instructions.text;
		}
	}

	public showAttachments () {
		return this.activity.attachments.length > 0;
	}

	public shouldShowActivityPicture () {
		return !this.activity.isConversationBoard();
	}

	public showActivityPicture () {
		const media = this.MediaModel.model({
			media_status: this.MediaModel.READY,
			media_type: this.MediaModel.TYPE.DOCUMENT,
			filename: 'fake_name.jpg'
		});
		switch (this.activity.template.type) {
			case 'Stimulus':
				media.media_url = 'https://staticassets.goreact.com/stimulus.png';
				media.title = this.$translate.instant('activity-info_example-stimulus');
				break;
			case 'Comment Only':
				media.media_url = 'https://staticassets.goreact.com/commentonly.png';
				media.title = this.$translate.instant('activity-info_example-comment-only');
				break;
			default:
				media.media_url = 'https://staticassets.goreact.com/standard.png';
				media.title = this.$translate.instant('activity-info_example-standard');
		}

		const modalData = {
			media,
			edit: false,
			allowDownload: false
		};

		this.mediaPreviewModal.open({
			modalData
		});
	}

	public getActivityTypeName () {
		if (this.activity.isConversation() && this.activity.hasResponseMedia()) {
			return this.activity.isLiveSessionEnabled()
				? this.$translate.instant('common_standard-group-with-live-review')
				: this.$translate.instant('common_standard-group');
		} else if (this.activity.isLiveSessionEnabled()) {
			if(this.activity.template.slug === this.ActivityTemplateModel.TYPE.STIMULUS_RESPONSE) {
				// Live stimulus
				return this.$translate.instant('common_stimulus-with-live-review');
			}
			return this.$translate.instant('common_standard-with-live-review');
		} else {
			return this.activity.template?.type;
		}
	}

	public isRubricEnabled (): boolean {
		return this.activity.isRubricEnabled() || this.activity.isPeerRubricEnabled();
	}

	public previewRubric () {
		const modalData = {
			options: {
				schemaId: this.activity.getRubricTemplateId() || this.activity.getPeerRubricTemplateId(),
				mode: 'print',
				previewOnly: true
			}
		};

		return this.rubricEditorModal.open({
			modalData
		}).result;
	}

	public canDownload () {
		if(this.group.hasInstructorRole(true)) {
			return true;
		}

		return !this.activity.isSingleAttempt();
	}

	public previewMarkerSet () {
		this.ngxDowngradeModalService.openPreviewMarkerSetDialog({
			markerSet: this.markerSet,
			withName: true
		});
	}

	public previewAiMarkers () {
		this.ngxDowngradeModalService.openPreviewMarkerSetDialog({
			markerSet: {
				tags: this.aiMarkers
			},
			aiStyle: true,
			withName: true
		});
	}

	private determineSelectedMarkerSet (): any {
		let markerSet: any = null;

		if (this.activity.tag_set_id > 0) {
			markerSet = this.TagSetModel.get({
				id: this.activity.tag_set_id
			});
		}

		return markerSet;
	}
}
