import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { States } from 'go-modules/enums/states.enum';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';

export interface TrialSuccessDialogData {
	folder_id?: string;
}

@Component({
	selector: 'app-ai-trial-success-dialog',
	template: require('./ai-trial-success-dialog.component.html'),
	styles: [require('./ai-trial-success-dialog.component.scss')]
})
export class AiTrialSuccessDialogComponent {
	public constructor (
		public dialogRef: MatDialogRef<AiTrialSuccessDialogComponent>,
		private ngxGoToastService: NgxGoToastService,
		@Inject(MAT_DIALOG_DATA) public data: TrialSuccessDialogData,
		@Inject($stateToken) private $state
	) {
		this.dialogRef.afterClosed().subscribe(() => this.showToast(this.data.folder_id));
	}

	public close () {
		this.dialogRef.close();
	}

	private showToast (folder_id: string) {
		this.ngxGoToastService.createToast({
			type: GoToastStatusType.SUCCESS,
			message: 'ai-trial-success-dialog_toast',
			actionLink:{
				title: 'ai-trial-success-dialog_action_link',
				action: () => this.$state.go(States.DASHBOARD_FOLDER_VIEW, {folder_id})
			}
		});
	}
}
