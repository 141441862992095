export enum EVENT_NAMES {
	ACCOUNT_LOCKED = 'accountSettings::accountLocked',
	ACTIVITY_VIEW_SESSION_SYNC = 'activityView::sessionSync',
	ACTIVTY_INCREMENT_NUM_GRADED = 'activity::incrementNumGraded',
	ACTIVTY_DECREMENT_NUM_GRADED = 'activity::decrementNumGraded',
	AUTH_ENDED = 'auth::ended',
	COURSE_ACTIVITIES_COPIED = 'course-list::activitiesCopied',
	COURSE_LIST_CHOOSE_AND_TOGGLE = 'course-list::chooseAndToggle',
	COURSE_LIST_CHOOSE_GROUP = 'course-list::chooseGroup',
	COURSE_LIST_ON_GROUP_DELETE = 'course-list::onGroupDelete',
	DOC_FIT = 'doc::fit',
	DOC_RESIZE = 'doc::resize',
	DOC_ZOOM_IN = 'doc::zoomIn',
	DOC_ZOOM_OUT = 'doc::zoomOut',
	FEEDBACK_SESSION_COMMENT_SYNC = 'feedbackSession::comment-sync::',
	FEEDBACK_SESSION_COMMENT_INSERT_QUOTE = 'feedbackSession::comment::insertQuote',
	FEEDBACK_SESSION_DELETED = 'feedbackSession::deleted',
	FEEDBACK_SESSION_DISCARD = 'feedbackSession::discard',
	FEEDBACK_SESSION_EXITED = 'feedbackSession::exited',
	FEEDBACK_SESSION_FEEDBACK_ADDED = 'feedbackSession::feedbackAdded',
	FEEDBACK_SESSION_FEEDBACK_DELETED = 'feedbackSession::feedbackDeleted',
	FEEDBACK_SESSION_FEEDBACK_EXPANDED = 'feedbackSession::feedbackExpanded',
	FEEDBACK_SESSION_FEEDBACK_LOADED = 'feedbackSession::feedbackLoaded',
	FEEDBACK_SESSION_FEEDBACK_MODE_CHANGE = 'feedbackSession::feedbackModeChange',
	FEEDBACK_SESSION_FEEDBACK_ONLY_MODE_CHANGE = 'feedbackSession::feedbackOnlyModeChange',
	FEEDBACK_SESSION_FEEDBACK_REMOVED = 'feedbackSession::feedbackRemoved',
	FEEDBACK_SESSION_FEEDBACK_SAVED = 'feedbackSession::feedbackSaved',
	FEEDBACK_SESSION_POSTED = 'feedbackSession::posted',
	FEEDBACK_SESSION_RECORDING_MANUALLY_ENDED = 'feedbackSession::recordingManuallyEnded',
	FEEDBACK_SESSION_REQUEST_EXIT = 'feedbackSession::requestExit',
	FEEDBACK_SESSION_STATUS_CHANGE = 'feedbackSession::statusChange',
	FEEDBACK_SESSION_TIME_SYNC = 'feedbackSession::timeSync',
	FEEDBACK_SESSION_TRIM_ACTIVATE = 'feedbackSession::trimActivate',
	FEEDBACK_SESSION_TRIM_DEACTIVATE = 'feedbackSession::trimDeactivate',
	FEEDBACK_SESSION_OPEN_AI_MARKERS  = 'feedbackSession::openAiMarkers',
	FEEDBACK_TREE_COMMENT_REPLY_ADDED = 'feedbackTree::commentReplyAdded',
	FEEDBACK_TREE_FEEDBACK_DELETED = 'feedbackTree::feedbackDeleted',
	FEEDBACK_TREE_FEEDBACK_REMOVED = 'feedbackTree::feedbackRemoved',
	FEEDBACK_TREE_FEEDBACK_SAVE = 'feedbackTree::feedbackSave',
	FEEDBACK_TREE_FEEDBACK_SAVED = 'feedbackTree::feedbackSaved',
	FEEDBACK_TREE_TIME_REACHED = 'feedbackTree::timeReached',
	FINE_UPLOADER_CANCEL_ALL = 'fineUploader::cancelAll',
	FINE_UPLOADER_SUCCESS = 'fineUploader::success',
	FINE_UPLOADER_UPLOAD = 'fineUploader::upload',
	FINE_UPLOADER_SLOW_UPLOAD_SPEED = 'fineUploader::slowUploadSpeed',
	FINE_UPLOADER_NORMAL_UPLOAD_SPEED = 'fineUploader::normalUploadSpeed',
	FS_NOTIFICATION_ADD = 'fs-notification::add',
	GO_MODAL_ALL_CLOSED = 'go-modal::allClosed',
	GO_MODAL_CLOSED = 'go-modal::closed',
	GO_MODAL_OPENED = 'go-modal::opened',
	KEY_DOWN_EVENT = 'keydownEvent',
	LIBRARY_ACTIVITY_CHANGE = 'library::activityChange',
	LICENSE_RENEWED = 'license::renewed',
	LTI_APP_DATA_UPDATED = 'lti::appDataUpdated',
	MEDIA_PREVIEW_TOGGLE_AUDIO_DESCRIPTION = 'mediaPreview::toggleAudioDescription',
	MEDIA_SYNC = 'media::sync',
	RUBRIC_CHANGE = 'rubric::change',
	RUBRIC_DATA_CHANGE = 'rubric::dataChange',
	RUBRIC_FEEDBACK_VIEWER_RUBRIC_LIST_CHANGE = 'rubricFeedbackViewer::rubricListChange',
	RUBRIC_READY = 'rubric::ready',
	RUBRIC_SAVE = 'rubric::save',
	RUBRIC_SAVE_SCHEMA_DONE = 'rubric::saveSchemaDone',
	RUBRIC_SAVE_SCHEMA_START = 'rubric::saveSchemaStart',
	RUBRIC_SAVE_SESSION_DONE = 'rubric::saveSessionDone',
	SESSION_ACTIVE_PAGINATION_FILTER = 'session::active-pagination-filter::change',
	SESSION_GRADED = 'session::graded',
	SESSION_UNGRADED = 'session::ungraded',
	SPEED_GRADER_CHANGE = 'speed-grader::change',
	SPLITTER_RESIZE = 'splitter::resize',
	UMC_CHANGE = 'umc::change',
	UMC_CHOOSE = 'umc::choose',
	UMC_CHOSEN = 'umc::chosen',
	UMC_CLOSE = 'umc::close',
	UMC_START_MEDIA_CAPTURE = 'umc::start-media-capture',
	VIDEO_SCENE_RESTART_SESSION = 'video-scene::restart-session',
	VIDEO_SCENE_STOP_SESSION = 'video-scene::stop-session'
}
