import { AuthService } from 'go-modules/services/auth/auth.service';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';

/* @ngInject */
export function SessionTimeoutModalController (
	$scope,
	User,
	authService: AuthService,
	$window,
	featureFlag: FeatureFlag,
	ngxAuthService) {

	$scope.loading = false;
	$scope.timeToLogout = 10;

	const vm = this;

	vm.$onInit = () => {

		$scope.user = {
			username: $scope.user.username,
			password: ''
		};

		$scope.featureFlag = featureFlag;
	};

	$scope.logout = function () {
		authService.logout().then(() => {
			$scope.$dismiss();
			$window.location.replace(`https://${$window.location.host}/dashboard/auth/login/`);
		});
	};

	$scope.signIn = function (username, password) {
		$scope.invalid = false;
		$scope.loading = true;
		const result = authService
			.login(username, password)
			.then((userInfo) => {
				$scope.$close(User.model(userInfo.data.user));
			}).catch(() => {
				$scope.invalid = true;
			}).finally(() => {
				$scope.loading = false;
			});

		return result;
	};

	$scope.loginWithSso = function (provider: string) {
		return ngxAuthService.getAuthUrl(provider, { redirect: $window.location.href })
			.then(({ url }: { url: string }) => {
				$window.location.href = url;
			});
	};
}
