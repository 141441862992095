import template from './library-filter-and-sort-tour.html';
import { GoTour, GoTourService, goTourServiceToken } from 'go-modules/go-tour/go-tour.service';
import dayjs from 'dayjs';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlacementEngine, placementEngineToken } from 'go-modules/go-tour/placement-engine.factory';

@Injectable({
	providedIn: 'root'
})
export class LibraryFilterAndSortTourService {
	public readonly startDate = dayjs('2024-12-14 08:00:00').toDate();
	public readonly title = 'library-filter-and-sort-tour_title' as const;
	public readonly viewTrackKey = 'library-filter-and-sort-tour' as const;
	public readonly selector = '.filter-and-sort-container' as const;
	private tour: GoTour;

	public constructor (
		@Inject(goTourServiceToken) private goTour: GoTourService,
		@Inject(placementEngineToken) private placementEngine: PlacementEngine,
		private translateFilter: TranslateService
	) {}

	public getTour (): GoTour {
		const placement = this.placementEngine.POSITION.BOTTOM;
		const title = this.translateFilter.instant(this.title);
		if (!this.tour) {
			this.tour = this.goTour.defineTour({
				viewTrackKey: this.viewTrackKey,
				constraints: {
					isOnboarding: false,
					tourStartDate: this.startDate
				},
				tourStepClass: this.viewTrackKey,
				steps: [
					{
						tourTitleAriaLabel: title,
						title,
						template,
						selector: this.selector,
						placement
					}
				]
			});
		}

		return this.tour;
	};
}
