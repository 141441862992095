import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { BehaviorSubject, distinctUntilChanged, skipUntil, Subject, timer } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export enum UploadSpeedEvent {
	SLOW='slow',
	NORMAL='normal'
}
export const UPLOAD_SPEED_THRESHOLD = 4.5 * 1024 * 1024; // Mbps
export const CONSECUTIVE_CHUNKS_THRESHOLD = 3;

/* @ngInject */
export function progressBarDirective (
	EventChannel,
	translateFilter: ng.translate.ITranslateFilter,
	$timeout,
	$document,
	eventService: EventService,
	featureFlag: FeatureFlag
) {
	return {
		restrict: 'A',
		require: '^fineUploader',
		scope: true,
		link (scope, element, attrs, uploadController) {
			const progressEventChannel = EventChannel.get(uploadController.getId()),
				fileId = scope.$eval(attrs.fineUploaderProgressBar);
			let screenReaderDiv: HTMLElement;
			let lastUploadedBytes = 0;
			let slowChunkCount = 0;
			let fastChunkCount = 0;
			let lastUpdateTime = Date.now();

			const destroyed$ = new Subject<void>();
			const speedStatus$ = new BehaviorSubject<UploadSpeedEvent | null>(null);

			// broadcast upload speed status on change
			speedStatus$.pipe(
				takeUntil(destroyed$),
				skipUntil(timer(15000)), // Skip emissions for first 15 seconds
				filter((status) => status !== null),
				distinctUntilChanged()
			).subscribe((status) => {
				const event = status === UploadSpeedEvent.SLOW ?
					EVENT_NAMES.FINE_UPLOADER_SLOW_UPLOAD_SPEED :
					EVENT_NAMES.FINE_UPLOADER_NORMAL_UPLOAD_SPEED;

				eventService.broadcast(event);
			});

			// set width initially to zero
			element.css('width', 0);
			element[0].setAttribute('role', 'progressbar');
			element[0].setAttribute('aria-valuemin', '0');
			element[0].setAttribute('aria-valuenow', '0');
			element[0].setAttribute('aria-valuemax', '100');

			// subscribe to file progress event
			progressEventChannel.subscribe(fileId, onProgress);

			/**
			 * Destroy event handler
			 */
			scope.$on('$destroy', function () {
				destroyed$.next();
				destroyed$.complete();
				progressEventChannel.unsubscribe(fileId, onProgress);
				screenReaderDiv?.remove();
			});

			/**
			 * Calculate and broadcast upload speed when consecutive chunks are slow/fast
			 * @param uploadedBytes
			 */
			function broadcastUploadSpeed (uploadedBytes: number) {
				if (featureFlag.isAvailable('FINE_UPLOAD_SPEED_CHECK')) {
					const currentTime = Date.now();
					const timeDiff = (currentTime - lastUpdateTime) / 1000; // seconds
					const bytesDiff = uploadedBytes - lastUploadedBytes;
					const uploadSpeed = bytesDiff / timeDiff; // bytes/second

					if (uploadSpeed < UPLOAD_SPEED_THRESHOLD) {
						slowChunkCount++;
						fastChunkCount = 0;
					} else {
						fastChunkCount++;
						slowChunkCount = 0;
					}

					lastUploadedBytes = uploadedBytes;
					lastUpdateTime = currentTime;

					if (slowChunkCount >= CONSECUTIVE_CHUNKS_THRESHOLD) {
						speedStatus$.next(UploadSpeedEvent.SLOW);
					} else if (fastChunkCount >= CONSECUTIVE_CHUNKS_THRESHOLD) {
						speedStatus$.next(UploadSpeedEvent.NORMAL);
					}
				}
			}
			/**
			 * On progress event handler
			 *
			 * @param uploadedBytes
			 * @param totalBytes
			 */
			function onProgress (uploadedBytes, totalBytes) {
				const progress = Math.round(uploadedBytes / totalBytes * 100);
				broadcastUploadSpeed(uploadedBytes);

				if(progress === 100) {
					screenReaderDiv = $document[0].createElement('div');
					screenReaderDiv.classList.add('accessibility-text');
					screenReaderDiv.classList.add('progress-bar-screen-reader-div');
					screenReaderDiv.setAttribute('role', 'alert');

					element[0].parentElement.appendChild(screenReaderDiv);
					$timeout(() => screenReaderDiv?.remove(), 2000);

					screenReaderDiv.textContent = translateFilter('fine-uploader_complete');
				} else {
					element[0].setAttribute('aria-valuenow', progress);
				}

				element.css('width', progress + '%');
			}
		}
	};
}
