import * as angular from 'angular';
import * as qq from 'fine-uploader/s3.fine-uploader/s3.fine-uploader.js';
import { IosUploadDialogComponent } from 'ngx/go-modules/src/components/dialogs/ios-upload-dialog/ios-upload-dialog.component';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { UADetect as UADetectClass } from '../detect/ua-detect.service';
import { FullstoryService } from 'go-modules/services/fullstory/fullstory.service';
import { FULLSTORY_EVENTS } from 'go-modules/services/fullstory/fullstory.events';

export const LOCAL_STORAGE_IOS_UPLOAD_SUCCESS = 'ios-upload-success';

export interface FineUploaderScope extends ng.IScope {
	options: {
		uniqueId: string;
		validation: {
			sizeLimit: number;
		}
	};
}

/* @ngInject */
export function FineUploaderController (
	$scope: FineUploaderScope,
	$http: ng.IHttpService,
	EventChannel,
	$window: ng.IWindowService,
	$filter: ng.IFilterService,
	$document: ng.IDocumentService,
	$timeout: ng.ITimeoutService,
	UADetect: UADetectClass,
	ngxGoModalService: GoModalService,
	fullstoryService: FullstoryService
) {
	const vm = this;
	const eventChannel = new EventChannel();

	let	uniqueId = '',
		uploader = null,
		progressEventChannel = null,
		retryCount = 0;

	vm.$onInit = () => {
		// file queue
		vm.files = [];

		// current options
		vm.options = {};

		// error
		vm.error = {message: ''};

		vm.bannerDismissed = false;
	};

	/**
	 * Init uploader
	 *
	 * @param fineUploader
	 */
	vm.init = function (fineUploader, fileTypeBlacklist) {

		// set uploader
		uploader = fineUploader;

		// use ID passed from umc
		uniqueId = $scope.options.uniqueId;

		// create event channel
		progressEventChannel = EventChannel.get(vm.getId());

		// set current options
		vm.options = uploader._options;

		// No one should see less than 4GB ever. If it happens, let us know
		if ($scope.options.validation.sizeLimit < 4 * 1024 * 1024 * 1024) {
			fullstoryService.createEvent(FULLSTORY_EVENTS.WARNING_2GB_UPLOAD, {});
		}

		// override show message function
		uploader._options.showMessage = function (message) {
			runDigest(function () {
				// localization fine-uploader external message
				const limitMessage = ' is too large, maximum file size is ';
				if (message.indexOf(limitMessage) !== -1) {
					// remove last dot symbol
					if (message[message.length - 1] === '.') {
						message = message.slice(0, -1);
					}
					// split for two parts
					const fields = message.split(limitMessage);
					const translationString = UADetect.isMobile() ? 'fine-uploader-resources_maximum-file-size-mobile' : 'fine-uploader-resources_maximum-file-size';
					message = $filter('translate')(translationString, {file: fields[0], limit: fields[1]});
				}
				vm.setErrorMessage(message);
			});
		};

		// add callbacks
		angular.extend(uploader._options.callbacks, {
			/**
			 * On submit
			 *
			 * @param id
			 * @param name
			 */
			onSubmit (id) {
				const file = uploader.getFile(id);

				runDigest(function () {
					// broadcast submit event
					eventChannel.broadcast('submit', file);
				});
			},
			/**
			 * On submitted
			 *
			 * @param id
			 * @param name
			 */
			onSubmitted (id) {
				const file = uploader.getFile(id);

				runDigest(function () {
					// set request params
					vm.setParams(vm.options.request.params, id);

					// add file to files queue
					addFile(id, file);

					// broadcast submit event
					eventChannel.broadcast('submitted', file);
				});
			},
			/**
			 * On upload
			 *
			 * @param id
			 * @param name
			 */
			onUpload (id) {
				const file = uploader.getFile(id);
				// focus on progress indicator to prevent losing focus to modal
				// when start uploading
				$document[0].querySelector('#progress-indicator-'+id).setAttribute('tabindex', '0');
				($document[0].querySelector('#progress-indicator-'+id) as HTMLElement).focus();

				localStorage.setItem(LOCAL_STORAGE_IOS_UPLOAD_SUCCESS, 'true');

				$timeout(() => {
					// broadcast submit event
					runDigest(function () {
						eventChannel.broadcast('upload', file, uploader.getKey(id));
					});
				});
			},
			/**
			 * On cancel
			 *
			 * @param id
			 * @param name
			 */
			onCancel (id) {

				// log whether or not file was in uploading state when cancel occurred
				const file = uploader.getFile(id),
					wasUploading = file.$uploading();

				// clear retry count
				retryCount = 0;

				runDigest(function () {
					// remove file from files queue
					removeFile(file);

					// broadcast cancel event
					eventChannel.broadcast('cancel', file, wasUploading);
				});

				// broadcast cancelled event
				// we need to wait a short delay until
				// the file actually gets cancelled
				$timeout(() => {
					runDigest(function () {
						eventChannel.broadcast('cancelled', file, wasUploading);
					});
				});
			},
			/**
			 * On complete
			 *
			 * @param id
			 * @param name
			 * @param responseJSON
			 * @param xhr
			 */
			onComplete (id) {
				const file = uploader.getFile(id),
					key = uploader.getKey(id),
					failed = file.$failed();

				// broadcast complete event
				runDigest(function () {

					if (failed) {
						eventChannel.broadcast('fail', file, key);
					} else {
						eventChannel.broadcast('success', file, key);
					}

					eventChannel.broadcast('complete', file, key);
				});
			},
			/**
			 * On all complete
			 *
			 * @param succeeded
			 * @param failed
			 */
			onAllComplete (succeeded, failed) {
				const succeededFiles = [],
					failedFiles = [];

				angular.forEach(succeeded, function (id) {
					succeededFiles.push(uploader.getFile(id));
				});

				angular.forEach(failed, function (id) {
					failedFiles.push(uploader.getFile(id));
				});

				runDigest(function () {

					// clear out the file queue
					clearFiles();

					// broadcast allComplete event
					eventChannel.broadcast('allComplete', succeededFiles, failedFiles);
				});
			},

			/**
			 * On validate
			 *
			 * First checks the blacklist for the file extension and shows a
			 * specific error if found. Otherwise any default Fine Uploader
			 * validators are executed
			 *
			 * @param file
			 * @returns {boolean}
			 */
			onValidate (file) {
				const fileExtension = qq.getExtension(file.name),
					blackList = vm.getOption('blackList') || fileTypeBlacklist || {},
					message = blackList.mappings[fileExtension && fileExtension.toLowerCase()],
					messageList = vm.getOption('validator');

				if (blackList.isBlacklist) {
					if (message) {
						runDigest(function () {
							vm.setErrorMessage($filter('translate')(message));
							$timeout(() => {
								($document[0].querySelector('.qq-upload-button input') as HTMLInputElement).focus();
							});
						});
						return !message;
					}
				} else if (!message) {
					runDigest(function () {
						vm.setErrorMessage($filter('translate')(blackList.message));
						$timeout(() => {
							($document[0].querySelector('.qq-upload-button input') as HTMLInputElement).focus();
						});
					});
					return !!message;
				}

				if (messageList && messageList.length) {
					messageList.forEach(function (fn) {
						const ms = fn(file);
						if (ms) {
							vm.addWarningMessage(ms);
						}
					});
				}

				vm.setErrorMessage(null);

				return true;
			},
			/**
			 * On error
			 *
			 * Once the auto retry process is finished, we send off
			 * and error report to the default endpoint
			 *
			 * @param id
			 * @param _name
			 * @param errorReason
			 * @param xhr
			 */
			onError (id, _name, errorReason, xhr) {
				const file = uploader.getFile(id),
					key = uploader.getKey(id),
					retryOptions = vm.options.retry;

				// only show final error message until auto retry is finished
				const autoRetryFinished = !retryOptions.enableAuto || retryOptions.maxAutoAttempts <= 0 ||
					retryCount === retryOptions.maxAutoAttempts;

				if (autoRetryFinished) {

					// reset retry count
					retryCount = 0;

					runDigest(function () {
						vm.setErrorMessage($filter('translate')('fine-uploader_controller-unable-upload-file-error'));
					});

					// log failed upload attempt
					const uploadFailOption = vm.getOption('uploadFail');
					if (angular.isObject(uploadFailOption) && uploadFailOption.endpoint) {
						$http.post(uploadFailOption.endpoint, angular.extend(vm.getOption('request').params, {
							object_key: key,
							uuid: key,
							name: file.name,
							size: file.size,
							client_time: new Date().toString(),
							s3ErrorInfo: {
								message: errorReason,
								responseText: xhr.responseText
							}
						}));
					}
				}
			},
			/**
			 * On status change
			 *
			 * @param id
			 * @param oldStatus
			 * @param newStatus
			 */
			onStatusChange (id, oldStatus, newStatus) {
				const file = uploader.getFile(id);

				runDigest(function () {

					// set new file status
					setStatus(id, newStatus);

					// broadcast status change event
					eventChannel.broadcast('statusChange', file, oldStatus, newStatus);
				});
			},
			/**
			 * On progress
			 *
			 * @param id
			 * @param _name
			 * @param uploadedBytes
			 * @param totalBytes
			 */
			onProgress (id, _name, uploadedBytes, totalBytes) {
				progressEventChannel.broadcast(id, uploadedBytes, totalBytes);
			},
			/**
			 * On auto retry
			 *
			 * @param id
			 * @param name
			 * @param attemptNumber
			 */
			onAutoRetry () {
				retryCount++;
			}
		});
	};

	/**
	 * Destroy
	 */
	vm.destroy = function () {
		progressEventChannel.unsubscribe();
		eventChannel.unsubscribe();
	};

	/**
	 * Get unique uploader id
	 *
	 * @returns {String}
	 */
	vm.getId = function () {
		return uniqueId;
	};

	/**
	 * Set an option
	 *
	 * @param name
	 * @param value
	 */
	vm.setOption = function (name, value) {
		if (angular.isObject(vm.options[name])) {
			angular.extend(vm.options[name], value);
		} else {
			vm.options[name] = value;
		}
	};

	/**
	 * Get an option
	 *
	 * @param name
	 * @returns {Object}
	 */
	vm.getOption = function (name) {
		return vm.options[name];
	};

	/**
	 * Set request params
	 *
	 * @param params
	 * @param id
	 */
	vm.setParams = function (params, id) {
		// disclude null/undefined params
		for (const i in params) {
			if (params[i] == null) {
				delete params[i];
			}
		}
		uploader.setParams(params, id);
	};

	/**
	 * Extend request params
	 *
	 * @param params
	 * @param id
	 */
	vm.extendParams = function (params, id) {
		vm.setParams(angular.extend(vm.options.request.params, params), id);
	};

	/**
	 * Set the error message
	 *
	 * @param message
	 */
	vm.setErrorMessage = function (message) {
		vm.error.message = message;
	};

	/**
	 * Set warning message
	 *
	 * @param message
	 */
	vm.addWarningMessage = function (message) {
		vm.error.warning = vm.error.warning || [];
		vm.error.warning.push(message);
	};

	vm.removeWarningMessage = function (message) {
		vm.error.warning.splice(vm.error.warning.indexOf(message), 1);
	};

	/**
	 * Cancel a single or all uploads
	 *
	 * @param id
	 */
	vm.cancel = function (id) {
		if (angular.isUndefined(id)) {
			uploader.cancelAll();
		} else {
			uploader.cancel(id);
		}
	};

	/**
	 * Returns the number of currently in progress uploads
	 *
	 * @returns {Number}
	 */
	vm.getNumInProgress = function () {
		return uploader.getInProgress();
	};

	/**
	 * Event subscription
	 */
	vm.on = function (eventType, callback) {
		eventChannel.subscribe(eventType, callback);
	};

	vm.showIosBanner = function (): boolean {
		return !vm.bannerDismissed &&
			!localStorage.getItem(LOCAL_STORAGE_IOS_UPLOAD_SUCCESS) &&
			UADetect.isIOS() &&
			parseInt(UADetect.browserDetector.result.os.version.split('.')[0], 10) >= 17;
	};

	vm.openIosUploadModal = function () {
		ngxGoModalService.open(IosUploadDialogComponent);
	};

	vm.closeIosBanner = function () {
		vm.bannerDismissed = true;
	};

	/**
	 * Add file to files queue
	 *
	 * @param id
	 */
	function addFile (id, file) {

		// add helper methods
		addHelperMethods(id, file);

		// add to file queue
		vm.files = [file];
	}

	/**
	 * Remove file from files queue
	 *
	 * @param file
	 */
	function removeFile (file) {

		// remove helper methods
		removeHelperMethods(file);

		// remove from file queue
		vm.files = [];
	}

	/**
	 * Clear out the files queue
	 */
	function clearFiles () {

		// remove helper methods
		angular.forEach(vm.files, function (file) {
			removeHelperMethods(file);
		});

		// clear files
		vm.files = [];
	}

	/**
	 * Set file status
	 *
	 * @param id
	 * @param status
	 */
	function setStatus (id, status) {
		const file = uploader.getFile(id);

		if (file instanceof $window.File) {
			file.$state = status;
		}
	}

	/**
	 * Add helper methods to file
	 *
	 * @param id
	 * @param file
	 */
	function addHelperMethods (id, file) {

		file.$start = function () {
			uploader.retry(id);
		};

		file.$resume = function () {
			uploader.continueUpload(id);
		};

		file.$pause = function () {
			uploader.pauseUpload(id);
		};

		file.$cancel = function () {
			uploader.cancel(id);
		};

		file.$delete = function () {
			uploader.delete(id);
		};

		file.$retry = function () {
			uploader.retry(id);
		};

		file.$id = function () {
			return id;
		};

		file.$uuid = function () {
			uploader.getUuid(id);
		};

		file.$key = function () {
			uploader.getKey(id);
		};

		file.$submitted = function () {
			return file.$state === qq.status.SUBMITTED;
		};

		file.$submitting = function () {
			return file.$state === qq.status.SUBMITTING;
		};

		file.$uploading = function () {
			return file.$state === qq.status.UPLOADING;
		};

		file.$paused = function () {
			return file.$state === qq.status.PAUSED;
		};

		file.$cancelled = function () {
			return file.$state === qq.status.CANCELED;
		};

		file.$deleted = function () {
			return file.$state === qq.status.DELETED;
		};

		file.$deleting = function () {
			return file.$state === qq.status.DELETING;
		};

		file.$queued = function () {
			return file.$state === qq.status.QUEUED;
		};

		file.$rejected = function () {
			return file.$state === qq.status.REJECTED;
		};

		file.$failed = function () {
			return file.$state === qq.status.UPLOAD_FAILED;
		};

		file.$retrying = function () {
			return file.$state === qq.status.UPLOAD_RETRYING;
		};

		file.$uploaded = function () {
			return file.$state === qq.status.UPLOAD_SUCCESSFUL;
		};
	}

	/**
	 * Remove helper methods from file
	 *
	 * @param file
	 */
	function removeHelperMethods (file) {

		if (file.hasOwnProperty('$hashKey')) {
			delete file.$hashKey;
		}

		if (file.hasOwnProperty('$start')) {
			delete file.$start;
		}

		if (file.hasOwnProperty('$resume')) {
			delete file.$resume;
		}

		if (file.hasOwnProperty('$pause')) {
			delete file.$pause;
		}

		if (file.hasOwnProperty('$cancel')) {
			delete file.$cancel;
		}

		if (file.hasOwnProperty('$delete')) {
			delete file.$delete;
		}

		if (file.hasOwnProperty('$retry')) {
			delete file.$retry;
		}

		if (file.hasOwnProperty('$id')) {
			delete file.$id;
		}

		if (file.hasOwnProperty('$uuid')) {
			delete file.$uuid;
		}

		if (file.hasOwnProperty('$key')) {
			delete file.$key;
		}

		if (file.hasOwnProperty('$state')) {
			delete file.$state;
		}

		if (file.hasOwnProperty('$uploading')) {
			delete file.$uploading;
		}

		if (file.hasOwnProperty('$submitted')) {
			delete file.$submitted;
		}

		if (file.hasOwnProperty('$submitting')) {
			delete file.$submitting;
		}

		if (file.hasOwnProperty('$paused')) {
			delete file.$paused;
		}

		if (file.hasOwnProperty('$cancelled')) {
			delete file.$cancelled;
		}

		if (file.hasOwnProperty('$deleted')) {
			delete file.$deleted;
		}

		if (file.hasOwnProperty('$deleting')) {
			delete file.$deleting;
		}

		if (file.hasOwnProperty('$queued')) {
			delete file.$queued;
		}

		if (file.hasOwnProperty('$rejected')) {
			delete file.$rejected;
		}

		if (file.hasOwnProperty('$failed')) {
			delete file.$failed;
		}

		if (file.hasOwnProperty('$retrying')) {
			delete file.$retrying;
		}

		if (file.hasOwnProperty('$uploaded')) {
			delete file.$uploaded;
		}
	}

	/**
	 * Run safe digest cycle
	 *
	 * @param fn
	 */
	function runDigest (fn, ...args) {
		// TODO: Can we avoid using private angular vars here?
		// eslint-disable-next-line angular/no-private-call
		const phase = $scope.$root.$$phase || $scope.$$phase;

		// execute function
		if (angular.isFunction(fn)) {
			fn.apply(vm, args);
		}

		// if we aren't in a digest phase, run digest
		if (!phase) {
			$scope.$apply();
		}
	}
}
