import { TimerEvent } from './event';
import { TimerBase } from './timer-base';
import { v4 as uuid } from 'uuid';

export class Timer extends TimerBase {
	public static EVENT: any = TimerEvent;
	private static timers: {[name: string]: Timer} = {};

	private previousTimeAmount: number = 0;
	private startTime: number | null = null;
	private durationTimeoutId: ReturnType<typeof setTimeout> = null;
	private intervalAlignmentTimeoutId: ReturnType<typeof setTimeout> = null;
	private heartbeatIntervalId: ReturnType<typeof setTimeout> = null;

	constructor (public id: string = uuid(), private duration: number = null) {
		super();
	}

	public static get (id: string, duration: number = null): Timer {
		if (!Timer.timers[id]) {
			Timer.timers[id] = new Timer(id, duration);
		}
		return Timer.timers[id];
	}

	public getId (): string {
		return this.id;
	}

	public getDuration (): number {
		return this.duration;
	}

	public isPaused (): boolean {
		return this.startTime == null;
	}

	public isComplete (): boolean {
		return this.getTime() >= this.getDuration();
	}

	/**
	 * returns the time calculated on the fly
	 */
	public getTime (): number {
		if (this.isPaused()) {
			return this.previousTimeAmount;
		}
		return this.previousTimeAmount + this.timeSinceResume();
	}

	public stop (): void {
		this.pause();
	}

	public pause (): void {
		if (this.isPaused()) {
			return;
		}
		this.previousTimeAmount = this.getTime();
		this.startTime = null;

		clearTimeout(this.durationTimeoutId);
		clearTimeout(this.intervalAlignmentTimeoutId);
		clearInterval(this.heartbeatIntervalId);

		this.trigger(TimerEvent.TIME, this.getTime());
		this.trigger(TimerEvent.PAUSE, this.getTime());
	}

	public start (): void {
		this.resume();
	}

	public resume (): void {
		if (!this.isPaused()) {
			return;
		}

		this.beginTiming();

		this.trigger(TimerEvent.TIME, this.getTime());
		this.trigger(TimerEvent.START, this.getTime());
	}

	public setTime (time: number): void {
		this.previousTimeAmount = time;

		if (!this.isPaused()) {
			this.beginTiming();
		}

		this.trigger(TimerEvent.TIME, this.getTime());
		this.trigger(TimerEvent.TIME_UPDATE, this.getTime());
	}

	public destroy (): void {
		this.stop();
		super.destroy();
		delete Timer.timers[this.id];
	}

	private timeSinceResume (): number {
		return window.performance.now() - this.startTime;
	}

	private beginTiming (): void {
		this.startTime = window.performance.now();
		if (this.duration !== null && this.duration !== Number.POSITIVE_INFINITY) {
			this.startDurationTimeout();
		}
		this.startHeartbeatInterval();
	}

	private startDurationTimeout (): void {
		clearTimeout(this.durationTimeoutId);
		this.durationTimeoutId = setTimeout(() => {
			this.pause();
			this.trigger(TimerEvent.COMPLETE, this.getTime());
		}, this.duration - this.getTime());
	}

	private startHeartbeatInterval (): void {
		clearInterval(this.heartbeatIntervalId);
		clearTimeout(this.intervalAlignmentTimeoutId);

		this.intervalAlignmentTimeoutId = setTimeout(() => {
			// Fire now and then again every 100ms
			this.trigger(TimerEvent.TIME, this.getTime());

			this.heartbeatIntervalId = setInterval(() => {
				this.trigger(TimerEvent.TIME, this.getTime());
			}, 100);

		}, 100 - (this.getTime() % 100));
	}
}
