import { BehaviorSubject, map, Observable } from 'rxjs';
import { GoPage } from 'go-modules/go-page/go-page.service';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { AppEnvService } from 'go-modules/go-app-env/app-env.service';
import { License } from 'go-modules/services/group/license';
import { GroupService as GroupsService } from 'go-modules/services/group/group.service';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { UserService } from 'go-modules/models/user/user.service';

export class SelectedService {
	public static readonly NG1_INJECTION_NAME = 'selectedService' as const;

	public selected = {
		group: null,
		activity: null,
		lastGroup: null,
		org: null,
		myOrgs: null,
		session: null,
		license: null,
		account: null,
		groups: null,
		mentored_user_ids: null
	};

	public selectedSubject = new BehaviorSubject<SelectedInterface>(this.selected);
	public environmentVarsService: EnvironmentVarsService;

	/* @ngInject */
	constructor (
		private goPage: GoPage,
		private $translate: angular.translate.ITranslateService,
		private translateFilter: angular.translate.ITranslateFilter,
		private appEnv: AppEnvService,
		private Group,
		private ActivityModel,
		private $injector,
		private $q: ng.IQService,
		private GroupService: GroupsService,
		private userService: UserService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public getActivity () {
		return this.selected.activity;
	}
	public getGroup () {
		return this.selected.group;
	}

	public getLastGroup () {
		return this.selected.lastGroup;
	}

	public getOrg () {
		return this.selected.org;
	}

	public getMyOrgs () {
		return this.selected.myOrgs;
	}

	public getSession () {
		return this.selected.session;
	}

	public getLicense (): License {
		return this.selected.license;
	}

	public getAccount () {
		return this.selected.account;
	}

	public getGroups () {
		return this.selected.groups;
	}

	public getSelected (): SelectedInterface {
		return this.selected;
	}

	public getMentoredUserIds$ (): Observable<number[]> {
		return this.selectedSubject.asObservable()
			.pipe(map((selected) => selected.mentored_user_ids));
	}

	public setMentoredUserIds (userIds: number[]) {
		this.selected.mentored_user_ids = userIds;
		this.selectedSubject.next(this.selected);
	}

	public setGroup (group?, activity?): void {
		if (group && !(group instanceof this.Group.GroupModel)) {
			throw new Error('Parameter `group` must be of type `Group`');
		}

		if (activity && !(activity instanceof this.ActivityModel)) {
			throw new Error('Parameter `activity` must be of type `ActivityModel`');
		}

		// If group isn't "fully loaded" then load it. Right now, upload_limit
		// won't exist unless the UserGroupDataResource was loaded. If we are missing this,
		// we haven't properly loaded this group.
		if (group && group?.upload_limit === undefined) {
			group.getData();
		}

		let titleName: string;
		const environment = this.environmentVarsService.get(EnvironmentVarsService.VAR.ENVIRONMENT) as any;

		if(group && this.selected.myOrgs?.length &&
			(!this.selected.org || parseInt(group.org_id, 10) !== parseInt(this.selected.org.group_id, 10))) {
			const selectedOrg = this.selected.myOrgs.find((mOrg) => {
				return parseInt(mOrg.group_id, 10) === parseInt(group.org_id, 10);
			});
			this.selected.org = this.Group.model(selectedOrg);
		};

		// keep the selected account in the super bar in sync with parent of selected group
		if (this.userService.currentUser.is_root_user &&
			group?.parent_id &&
			group.parent_id !== this.selected.account?.group_id) {
			this.GroupService.getAccount(group.parent_id)
				.then((account) => {
					this.setAccount(this.Group.model(account));
				});
		}

		this.selected.group = group;

		const orgSettings = this.selected.org?.org_settings;
		this.appEnv.configureOrgSettings(orgSettings || {});

		// If in LTI environment then use activity name for title
		if (activity && environment.name === ENVIRONMENTS.LTI) {
			titleName = activity.name;
		} else if (group) {
			titleName = group.name;
		}

		if (titleName != null) {
			this.$translate.onReady()
				.then(() =>	this.goPage.setTitle(this.translateFilter('document-title_dashboard', {name: titleName})));
		}

		if (this.selected.group && this.selected.group.hasOwnProperty('license')) {
			this.setLicense(this.selected.group.license);
		} else {
			this.selected.license = null;
			if (this.selected.group) {
				this.GroupService.getLicense(this.selected.group.group_id).then((license) => {
					this.setLicense(license);
				});
			}
		}

		this.selectedSubject.next(this.selected);

		// clear and fallback if group is not a course
		if (!this.selected.group || !this.selected.group.isCourse()) {
			clientSettings.currentFolder = null;

			return;
		}

		clientSettings.currentFolder = this.selected.group;
	}

	public setLastGroup (group) {
		if (group && !(group instanceof this.Group.GroupModel)) {
			throw new Error('Parameter `group` must be of type `Group`');
		}
		this.selected.lastGroup = group;
		this.selectedSubject.next(this.selected);
	}

	public setActivity (activity): void {
		if (activity && !(activity instanceof this.ActivityModel)) {
			throw new Error('Parameter `activity` must be of type `ActivityModel`');
		}
		this.selected.activity = activity;
		this.selectedSubject.next(this.selected);
	}

	public setOrg (org): ng.IPromise<void> {
		if (!org) {
			return this.$q.resolve();
		}
		if (org && !(org instanceof this.Group.GroupModel)) {
			return this.$q.reject(new Error('Parameter `org` must be of type `Group`'));
		}

		if (this.userService.currentUser.is_root_user) {
			if (this.selected.group?.org_id !== org.group_id) {
				this.selected.group = null;
			}

			if (this.selected.account?.org_id !== org.group_id) {
				this.selected.account = null;
			}
		}

		let promise = this.$q.resolve();

		if (!org.org_settings) {
			promise = this.Group.getOrgSettings({ group_id: org.group_id })
				.then((orgSettings) => {
					org.org_settings = orgSettings;
				});
		}

		return promise.then(() => {
			this.selected.org = org;
			this.selectedSubject.next(this.selected);
			this.appEnv.configureOrgSettings(org.org_settings);
		});
	}

	public setMyOrgs (myOrgs): void {
		this.selected.myOrgs = myOrgs;
		this.selectedSubject.next(this.selected);
	}

	public setSession (session): void {
		if (session && !(session instanceof this.$injector.get('Session'))) {
			throw new Error('Parameter `session` must be of type `Session`');
		}
		this.selected.session = session;
		this.selectedSubject.next(this.selected);
	}

	public setLicense (license): void {
		this.selected.license = license;
		this.selectedSubject.next(this.selected);
	}

	public setAccount (account): void {
		if (account && !(account instanceof this.Group.GroupModel)) {
			throw new Error('Parameter `account` must be of type `Group`');
		}
		if (account &&
			this.userService.currentUser?.is_root_user &&
			this.selected.group?.parent_id !== account.group_id) {
			this.selected.group = null;
		}
		this.selected.account = account;
		this.selectedSubject.next(this.selected);
	}

	public setGroups (groups): void {
		this.selected.groups = groups;
		this.selectedSubject.next(this.selected);
	}

	public clear (): void {
		this.selected = {
			group: null,
			activity: null,
			lastGroup: null,
			org: null,
			myOrgs: null,
			session: null,
			license: null,
			account: null,
			groups: null,
			mentored_user_ids: null
		};
		this.selectedSubject.next(this.selected);
	}
}

export interface SelectedInterface {
	group: any;
	activity: any;
	lastGroup: any;
	org: any;
	myOrgs: any;
	session: any;
	license: License;
	account: any;
	groups: any;
	mentored_user_ids: number[]
}

export const selectedServiceToken = upgradeNg1Dependency(SelectedService);
